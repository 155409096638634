import { gql, useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'
import {
  Card,
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap'
import UserContext from '../../contexts/UserContext'
import AccountBalanceAndProgress from '../../components/AccountBalanceAndProgress'
import DonationLinkDashboard from '../../components/DonationLinkDashboard'
import { GET_SCHOOL_DONATION_LINK } from '../../operations/schools'
import { QUERY_CURRENT_ACCOUNT_BALANCE } from '../../operations/accountBalance'
import RecentActivity from '../../components/RecentActivity'
import { balanceTypes } from '../../constants'
import { formatIFFPercent } from '../../utils/helpers'

// TODO hook this up with the sort bylatest and limit in the query
export const GET_RECENT_TRANSACTIONS = gql`
  query GetRecentTransactions(
    $schoolYearId: Int,
    $schoolId: [Int] #,
    # $limit: Int
  ) {
    transactions(schoolId: $schoolId, schoolYearId: $schoolYearId) { #, limit: $limit) {
      transactionType {
        name
        balanceType
      }
      transactionDate
      donor
      totalAmount
    }
  }
`
const Dashboard = () => {
  const {
    session
  } = useContext(UserContext)
  const { currentSchool } = session
  const schoolIsSelected = !!currentSchool?.id
  const [recentTransactions, setRecentTransactions] = useState([
    {
      transactionType: {
        name: 'Matching',
        balanceType: balanceTypes.CREDIT
      },
      donor: 'InvestED',
      totalAmount: 75,
      transactionDate: 1627591054000
    },
    {
      transactionType: {
        name: 'Donation',
        balanceType: balanceTypes.CREDIT
      },
      donor: 'Anonymous',
      totalAmount: 75,
      transactionDate: 1627410054000
    },
    {
      transactionType: {
        name: 'Student Transaction',
        balanceType: balanceTypes.DEBIT
      },
      donor: null,
      totalAmount: 175,
      transactionDate: 1625381054000
    }
  ])

  const {
    loading: schoolLoading,
    data: schoolData,
    error: schoolError
  } = useQuery(GET_SCHOOL_DONATION_LINK, {
    variables: { id: [currentSchool?.id] },
    skip: !schoolIsSelected
  })
  const donationUrl = schoolData?.schools[0]?.donateUrl

  const {
    loading: balanceLoading,
    data: balanceData,
    error: balanceError
  } = useQuery(QUERY_CURRENT_ACCOUNT_BALANCE, {
    variables: {
      schoolId: currentSchool?.id
    },
    skip: !schoolIsSelected
  })

  const {
    loading: recentTransactionsLoading,
    data: recentTransactionsData,
    error: recentTransactionsError
  } = useQuery(GET_RECENT_TRANSACTIONS, {
    variables: {
      schoolId: currentSchool?.id
    },
    skip: !schoolIsSelected,
    onCompleted: (data) => {
      setRecentTransactions(data.transactions)
    }
  })

  return (
    <Container>
      <Row className="pb-3 px-3">
        <Col className="p-0 banner-image-column" md={12} lg={8}>
          <Image alt="SPACE Portal banner image" className="banner-image" src="./space-portal-banner.jpg" width="100%"/>
        </Col>
        <Col className="p-0">
          <Card className="banner-text-card">
            <Card.Body>
              <p className="mb-0">
                <strong>{'Welcome to the InvestED SPACE portal.'}</strong>
                {' The central hub for our school partners to manage funding for students in need. You can get started by '}
                <a href="/finance/new">{'adding a transaction'}</a>
                {', adding '}
                <a href="/staff">{'staff'}</a>
                {', or '}
                <a href="/students">{'students'}</a>
                {" to the system, or viewing your school's "}
                <a href="/finance">{'financial ledger'}</a>
                {'. if you need assistance you can '}
                <a href="/contact">{'contact us here'}</a>
                {'.'}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {schoolIsSelected && (
        <Row className="p-2">
          <Col sm={12} lg={4} className="p-2">
            <h6>Account Balance</h6>
            <Card className="user-dashboard-card p-3">
              {balanceLoading && (
                <div>Loading balance information...</div>
              )}
              {balanceError && (
                <div className="alert alert-danger">
                  <label>Error loading balance information...</label>
                  {balanceError.message}
                </div>
              )}
              {balanceData && (
                <AccountBalanceAndProgress
                  percentFallFunding={formatIFFPercent(
                    balanceData.accountBalance?.fallFunding?.percentUsed
                  )}
                  accountBalance={balanceData.accountBalance?.currentBalance}
                />
              )}
            </Card>
          </Col>
          <Col sm={12} lg={4} className="p-2">
            <h6>Public Donation Link</h6>
            <Card className="user-dashboard-card p-3">
              {schoolLoading && (
                <div>Loading public donation link...</div>
              )}
              {schoolError && (
                <div className="alert alert-danger">
                  <label>Error loading donation link...</label>
                  {schoolError.message}
                </div>
              )}
              {schoolData && <DonationLinkDashboard donationUrl={donationUrl}/>}
            </Card>
          </Col>
          <Col sm={12} lg={4} className="p-2">
            <h6>Recent Activity</h6>
            <Card className="user-dashboard-card p-3">
              {recentTransactionsLoading && (
                <div>Loading recent transactions...</div>
              )}
              {recentTransactionsError && (
                <div className="alert alert-danger">
                  <label>Error loading donation link...</label>
                  {recentTransactionsError.message}
                </div>
              )}
              {recentTransactionsData && (
                <RecentActivity transactions={recentTransactions}/>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Dashboard
