import { gql } from '@apollo/client'

// * Queries
export const QUERY_STUDENT_STORY_DROPDOWN_LISTS = gql`
  query {
    studentStoryCategories {
      id
      name
    }
    grades {
      id
      name
    }
    ethnicities {
      id
      name
    }
    genders {
      id
      name
    }
  }
`
export const QUERY_STUDENT_STORY = gql`
  query studentStory (
    $id: Int!
  ) {
    studentStory (id: $id) {
      title
      story
      grade {
        id
      }
      gender {
        id
      }
      ethnicity {
        id
      }
      transaction {
        totalAmount
        transactionType {
          name
        }
        categoryCode {
          name
          categoryType {
            name
          }
        }
        transactionDate
        notes
      }
      studentStoryCategory {
        id
        name
      }
      studentStoryCategoryOther
    }
  }
`

export const QUERY_STUDENT_STORIES_BY_SCHOOL_AND_YEAR = gql`
  query GetStudentStoriesBySchoolAndYear (
    $schoolId: Int!
    $schoolYearId: Int
  ) {
    studentStories(
      schoolId: $schoolId
      schoolYearId: $schoolYearId
    ) {
      id
    }
  }
`

export const QUERY_STUDENT_STORIES_WITH_DETAILS = gql`
  query GetStudentStoriesBySchoolAndYear (
    $schoolId: Int!
    $schoolYearId: Int
  ) {
    studentStories(
      schoolId: $schoolId
      schoolYearId: $schoolYearId
    ) {
      id
      title
      story
      studentStoryCategory {
        name
      }
      studentStoryCategoryOther
      grade {
        name
      }
      gender {
        name
      }
      ethnicity {
        name
      }
      createdAt
      createdBy {
        first
        last
      }
    }
  }
`

export const QUERY_ALL_STUDENT_STORIES_BY_YEAR = gql`
  query (
    $schoolYearId: Int!
  ) {
    studentStories(
      schoolYearId: $schoolYearId
    ) {
      id
      school {
        name
        district {
          name
        }
        esdNumber
        addresses {
          address {
            county
          }
        }
      }
      district {
        name
        addresses {
          address {
            county
          }
        }
        esdNumber
      }
      title
      studentStoryCategory {
        name
      }
      gender {
        name
      }
      ethnicity {
        name
      }
      grade {
        name
      }
      story
    }
  }
`

// * Mutations
export const CREATE_STUDENT_STORY = gql`
  mutation CreateStudentStory (
    $transactionId: Int
    $title: String!
    $studentStoryCategoryId: Int!
    $schoolId: Int
    $districtId: Int
    $story: String!
    $gradeId: Int
    $genderId: Int
    $ethnicityId: Int
    $studentStoryCategoryOther: String
  ) {
    createStudentStory(
      transactionId: $transactionId
      title: $title
      studentStoryCategoryId: $studentStoryCategoryId
      schoolId: $schoolId
      districtId: $districtId
      story: $story
      gradeId: $gradeId
      genderId: $genderId
      ethnicityId: $ethnicityId
      studentStoryCategoryOther: $studentStoryCategoryOther
    ) {
      id
    }
  }
`
export const UPDATE_STUDENT_STORY = gql`
  mutation UpdateStudentStory (
    $id: Int!
    $title: String
    $studentStoryCategoryId: Int
    $story: String
    $gradeId: Int
    $genderId: Int
    $ethnicityId: Int
    $studentStoryCategoryOther: String
  ) {
    updateStudentStory(
      id: $id
      title: $title
      studentStoryCategoryId: $studentStoryCategoryId
      story: $story
      gradeId: $gradeId
      genderId: $genderId
      ethnicityId: $ethnicityId
      studentStoryCategoryOther: $studentStoryCategoryOther
    ) {
      affectedRows
    }
  }
`
export const DELETE_STUDENT_STORY = gql`
  mutation DeleteStudentStory ($id: Int!) {
    deleteStudentStory(id: $id) {
      affectedRows
    }
  }
`
