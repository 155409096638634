import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Button,
  Card
} from 'react-bootstrap'
import { useQuery, useLazyQuery } from '@apollo/client'
import {
  faChalkboardTeacher,
  faMoneyCheckEditAlt
} from '@fortawesome/pro-light-svg-icons'
import GlobalDataTable from '../../components/GlobalDataTable'
import GlobalModal from '../../components/GlobalModal'
import GlobalDataTableRowView from '../../components/GlobalDataTableRowView'
import LoadingSpinner from '../../components/LoadingSpinner'
import ClipboardText from '../../components/ClipboardText'
import { formatUSD, formatIFFPercent } from '../../utils/helpers'
import {
  QUERY_SCHOOLS,
  QUERY_ONE_SCHOOL
} from '../../operations/schools'
import { QUERY_DISTRICTS, QUERY_ONE_DISTRICT } from '../../operations/districts'
import UserContext from '../../contexts/UserContext'
import { isUserAdmin } from '../../utils/lib'
import { entityStatusDictionary } from '../../constants'

/**
 * Directory component for schools or districts
 * @date 2021-07-22
 * @param {string} [title="Directory"] - Page title
 * @param {bool} [isDistrict=false] - is a district directory or not (otherwise school)
 * @param {string} [path="/"] - current path, used to prepend for edit or new links
 * @param {string} [newLabel="+ Add New"] - label text for link to add a new entity
 * @param {string} [otherPath="/"] - href path for other entity (districts or schools)
 * @param {string} [otherLabel="Manage Other Type"] - label text for managing other type link (schools or districts)
 * @returns {any}
 */
const SchoolsOrDistricts = ({
  title = 'Directory',
  isDistrict = false,
  path = '',
  newLabel = '',
  otherPath = '',
  otherLabel = ''
}) => {
  const [modalData, setModalData] = useState(null)
  const [entityData, setEntityData] = useState([])
  const { user: userContext } = useContext(UserContext)
  const isAdmin = isUserAdmin(userContext.role.name)

  // queries
  const {
    loading,
    data: schoolOrDistrictsData,
    error
  } = useQuery(isDistrict
    ? QUERY_DISTRICTS
    : QUERY_SCHOOLS,
  {
    onCompleted: (data) => {
      // * FUTURE filter out nulls and sort on the server side
      const sortedEntities = (data.districts || data.schools)
        .filter((aDistrict) => aDistrict)
        .sort((a, b) => a.name.localeCompare(b.name))
      setEntityData(sortedEntities)
    }
  })
  const [
    lazyGetSchoolOrDistrict, {
      loading: lazyLoading
    }
  ] = useLazyQuery(isDistrict
    ? QUERY_ONE_DISTRICT
    : QUERY_ONE_SCHOOL, {
    onCompleted: (d) => {
      const [entity] = d.districts
        ? d.districts
        : d.schools
      setModalData(entity)
    },
    onError: (err) => {
      setModalData({
        error: err.message
      })
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore'
  })

  /**
   * renders status badge function
   * @param {Element} [as] - element to render
   * @param {string} [status='active'] - current status to return as a badge
   * @returns {any}
   */
  const renderStatusBadge = ({
    as = Badge,
    status = 'active'
  }) => {
    const StatusBadge = as
    const activeLabel = entityStatusDictionary.active
    const inactiveLabel = entityStatusDictionary.inactive
    const waitlistLabel = entityStatusDictionary.waitlisted

    if (status === inactiveLabel) {
      return <StatusBadge className="bg-danger">
        {inactiveLabel}
      </StatusBadge>
    }

    if (status === waitlistLabel) {
      return <StatusBadge className="bg-muted">
        {waitlistLabel}
      </StatusBadge>
    }

    return <StatusBadge className="bg-ed-dark-blue">
      {activeLabel}
    </StatusBadge>
  }
  if (loading) return <LoadingSpinner message="Loading..." />
  if (error) return <div>{error.message}</div>

  const rowClick = (row) => {
    // const idArray = [row.id]
    lazyGetSchoolOrDistrict({
      variables: {
        id: isDistrict
          ? row.id
          : [row.id]
      }
    })
  }
  const modalClose = () => {
    setModalData(null)
  }
  const columns = {
    district: [
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true
      },
      {
        name: 'County',
        selector: (row) => row.addresses[0]?.address?.county,
        sortable: true
      },
      {
        name: 'City',
        selector: (row) => row.addresses[0]?.address?.city,
        sortable: true
      },
      {
        name: 'Phone',
        selector: (row) => row.phoneNumber,
        sortable: false
      },
      {
        name: 'District Code',
        selector: (row) => row.districtCode,
        sortable: false
      },
      {
        name: 'Status',
        selector: (row) => row.status,
        cell: (row) => renderStatusBadge({
          status: row.status
        }),
        sortable: true,
        omit: !isAdmin
      },
      {
        name: '',
        button: true,
        cell: function ActionCell(row) {
          return <Button
            variant="link text-dark"
            size="sm"
            href={`${path}/edit/${row.id}`}
          >
            Edit
          </Button>
        }
      }
    ],
    school: [
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true
      },
      {
        name: 'District',
        selector: (row) => row.district.name,
        sortable: true
      },
      {
        name: 'County',
        selector: (row) => row.addresses[0]?.address?.county,
        sortable: true
      },
      {
        name: 'Phone',
        selector: (row) => row.phoneNumber,
        sortable: false
      },
      {
        name: 'OSPI',
        selector: (row) => row.ospiNumber,
        sortable: false
      },
      {
        name: 'Status',
        selector: (row) => row.status,
        cell: (row) => renderStatusBadge({
          status: row.status
        }),
        sortable: true,
        omit: !isAdmin
      },
      {
        name: '',
        button: true,
        cell: function ActionCell(row) {
          return <Button
            variant="link text-dark"
            size="sm"
            href={`${path}/edit/${row.id}`}
          >
            Edit
          </Button>
        }
      }
    ]
  }
  return (
    <>
      <div className="Home">
        <div className="lander">
          {path && newLabel && isAdmin && (
            <Button variant="primary" className="float-right" href={`${path}/new`}>
              {newLabel}
            </Button>
          )}
          {otherPath && otherLabel && (
            <Button variant="link text-dark" size="sm" className="float-right" href={otherPath}>
              {otherLabel}
            </Button>
          )}
          <h3 className="page-title border-bottom">
            {title}
          </h3>
        </div>
        <div className="m-3">
          {/* Prevent the data table from rendering before the data is ready */}
          {!!schoolOrDistrictsData && (
            <GlobalDataTable
              columns={
                isDistrict
                  ? columns.district
                  : columns.school
              }
              data={entityData}
              filterHandler
              pagination
              subHeader
              selectableRows={false}
              handleRowClick={rowClick}
            />
          )}
        </div>
      </div>
      {(lazyLoading || modalData) && (
        <GlobalModal
          title={
            isDistrict
              ? 'District Information'
              : 'School Information'
          }
          onClose={modalClose}
        >
          <>
            {lazyLoading && (<div>
              Loading...
            </div>
            )}
            {modalData && modalData?.error && (
              <div>{modalData.error}</div>
            )}
            {modalData && !(modalData?.error) && (
              <GlobalDataTableRowView
                title={modalData.name}
                subTitle={!isDistrict
                  ? `${modalData.district.name}`
                  : undefined}
                colData = {[
                  {
                    label: 'Status:',
                    value: renderStatusBadge({
                      status: modalData.status
                    }),
                    omit: !isAdmin
                  },
                  {
                    label: 'Phone Number:',
                    value: modalData.phoneNumber
                  },
                  {
                    label: 'District Code',
                    value: modalData.districtCode
                  },
                  {
                    label: 'OSPI Number:',
                    value: modalData.ospiNumber
                  },
                  {
                    label: 'Website',
                    value: modalData.website
                  },
                  {
                    label: 'Donation URL',
                    value: modalData.donationUrl
                  },
                  {
                    label: '',
                    value: <div className="d-flex">
                      {modalData.addresses.map((l, i) => {
                        // make a proper-cased string out of the address type, 'Physical/Mailing Address'
                        const addressType = l.addressType.name
                        const addressTitle = `${addressType[0].toUpperCase()}${addressType.slice(1)} Address`
                        return (
                          <div key={i} className="pr-5">
                            {l.addressType && (
                              <div className="mb-2">{addressTitle}</div>
                            )}
                            {l.address && (
                              <>
                                {l.address.addressLine1}<br />
                                {l.address.addressLine2 && (<span>{l.address.addressLine2}</span>)}
                                {l.address.city}, {l.address.state}{' '}
                                {l.address.zip}
                              </>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  },
                  {
                    label: 'Grades Served:',
                    value: (modalData.minimumGrade && modalData.maximumGrade)
                      ? `${modalData.minimumGrade.name} - ${modalData.maximumGrade.name}`
                      : undefined
                  },
                  {
                    label: 'ESD Number:',
                    value: modalData.esdNumber
                  },
                  {
                    label: 'Students Enrolled:',
                    value: modalData.numberOfStudents
                  },
                  {
                    label: 'Free and Reduced Lunch (FRL) Percentage',
                    value: <div>{parseFloat(modalData.frlPercentage)}%</div>,
                    omit: !modalData.frlPercentage
                  },
                  {
                    label: 'Staff',
                    icon: faChalkboardTeacher,
                    value: (modalData.userSchools || modalData.userDistricts)
                      .filter((userEntity) => (
                        userEntity !== null
                        && userEntity.user
                      ))
                      .map(({ user }, i) => (
                        <div
                          key={i}
                          className={(i % 2 === 1)
                            ? 'bg-faded-pale-yellow'
                            : ''
                          }
                        >
                          <span>
                            {`${user?.first} ${user?.last} - ${user?.investedRole?.name} - `}
                          </span>
                          <ClipboardText text={user.email} />
                        </div>
                      )),
                    omit: (modalData.userSchools || modalData.userDistricts).length === 0
                  },
                  !isDistrict
                    ? {
                      label: 'Financials',
                      icon: faMoneyCheckEditAlt,
                      value: <Card className="school-balance-card">
                        <div className="ledger-balance-left-box">
                          <div className="percentage-without-text-box">
                            <div className="percentage-box">{formatIFFPercent(modalData.accountBalance?.fallFunding?.percentUsed)}%</div>
                            <div className="iff-box">
                              ISF Spending
                            </div>
                          </div>
                        </div>
                        <div className="ledger-balance-amount" data-testid="ledger-balance-amount">{formatUSD.format(modalData.accountBalance?.currentBalance || 0)}</div>
                      </Card>
                    }
                    : {
                      omit: true
                    }
                ]}
                editAction={`${path}/edit/${modalData.id}`}
              ></GlobalDataTableRowView>
            )}
          </>
        </GlobalModal>
      )}
    </>
  )
}

SchoolsOrDistricts.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  newLabel: PropTypes.string,
  otherPath: PropTypes.string,
  otherLabel: PropTypes.string,
  isDistrict: PropTypes.bool
}

export default SchoolsOrDistricts
