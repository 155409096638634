/**
 * app vars
 */
export const AUTH_TOKEN = 'auth-token'
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000'

/**
 * roles definitions
 */
export const ROLE_ADMIN = 'admin'
export const ROLE_SUPER_ADMIN = 'super_admin'
export const ROLE_USER = 'coordinator'
export const ROLE_ADMINS = [ROLE_ADMIN, ROLE_SUPER_ADMIN]

/**
 * localStorage keys
 */
export const LAST_SCHOOL_SELECTED = 'LAST_SCHOOL_SELECTED'
export const LAST_DISTRICT_SELECTED = 'LAST_DISTRICT_SELECTED'
export const LAST_SCHOOL_YEAR = 'LAST_SCHOOL_YEAR'

/**
 * dictionaries
 */
export const balanceTypes = {
  CREDIT: 'credit',
  DEBIT: 'debit'
}
export const transactionTypeIds = {
  CARRYOVER: 1,
  MATCHING: 2,
  DONATION: 3,
  SEED_FUNDING: 4,
  STUDENT: 5,
  COVID: 6,
  IFF: 7,
  BANK_FEES: 8,
  REFUND: 9,
  INTEREST: 10,
  ADJUSTMENT: 11,
  DIGITAL_EQUITY: 12,
  BULK_PURCHASE: 13
}
// INV-302 requested Bulk Purchase be removed from the transaction entry form
export const inactiveTransactionTypeIds = [
  transactionTypeIds.BULK_PURCHASE
]
export const entityStatusDictionary = {
  active: 'active',
  inactive: 'inactive',
  waitlisted: 'waitlisted'
}
export const balancePathnames = [
  '/dashboard',
  '/finance'
]
export const reportFormats = {
  PDF: 'pdf',
  XLSX: 'xlsx'
}
export const reportPages = {
  transactionsMtd: 'transactions-mtd',
  transactionsYtd: 'transactions-ytd',
  studentTransactions: 'student-transactions',
  studentRoster: 'student-roster',
  studentStories: 'student-stories',
  yearEndReport: 'year-end-report'
}

/**
 * UI vars
 */
export const studentStoryMaxWordCount = 350
