/**
 * The Intl.NumberFormat object enables language-sensitive number formatting.
 */
export const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

/**
 * Uses regex to strip html and count words in a string
 * @param {string} value - an html string
 * @returns {int}
 */
export const stripHtmlAndCountWords = (value) => {
  // strips html tags
  const strippedValue = value.replace(/<[^>]+>/g, '')
  return strippedValue
    ? strippedValue.match(/\w+/g).length
    : 0
}

/**
 * Takes a raw IFF percentage value and returns a rounded percentage
 * @param {float} percentValue the raw percentage value to be handled
 * @returns {int} min of rounded percent or 100 if percent exists, 0 otherwise
 */
export const formatIFFPercent = (percentValue) => (
  Math.min(Math.round(percentValue), 100) || 0
)

/**
 * Takes an object with object properties like student.gender.name and flattens the object property to a string of its name property value
 * @param {Object} objectToFlatten
 * @returns {Object}
 */
export const flattenNameProperties = (objectToFlatten) => {
  const flatObject = { ...objectToFlatten }
  Object.keys(objectToFlatten).forEach((key) => {
    const keyValue = objectToFlatten[key] || {}
    if (keyValue.name) {
      flatObject[key] = keyValue.name
    }
  })
  return flatObject
}

/**
 * calls window.print() - so we don't have to litter other files with the eslint-disable line
 */
export const printThisPage = () => {
  // eslint-disable-next-line no-undef
  window.print()
}
