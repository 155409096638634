import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

export const initialAddressState = {
  entityAddressId: 0,
  addressId: null,
  addressTypeId: 1,
  address1: '',
  address2: '',
  county: '',
  city: '',
  state: '',
  zip: '',
  hidden: false
}

/**
 * Component for displaying address form fields
 *
 * @param  {string} [title] - title block
 * @param  {string} [helpfulText] - descriptive text block
 * @param  {bool} [showCheckbox=false] - show a checkbox that toggles visibility
 * @param  {string} [checkLabel="Mailing address is the same as school address above"] - descriptive text for visibility checkbox
 * @param  {string} [checkboxTestId="address-checkbox"] - sets data-testid on the visibility checkbox toggle
 * @param  {int} [addressTypeId] - address type
 * @param  {object} address - address object
 * @param  {string} [containerClass=""] - className for the parent div container
 * @param  {function} [onChange] - callback handler on change
 */
const AddressForm = ({
  title = '',
  helpfulText = '',
  showCheckbox = false,
  checkLabel = 'Mailing address is the same as school address above',
  checkboxTestId = 'address-checkbox',
  addressTypeId = 1,
  address = initialAddressState,
  containerClass = '',
  onChange = (() => {})
}) => {
  const handleChange = (data) => {
    onChange({
      ...address,
      addressTypeId,
      ...data
    })
  }
  return (
    <>
      {title && (
        <div className="border-bottom py-3">
          {title}
        </div>
      )}
      {helpfulText && (
        <div className="alert alert-secondary">
          {helpfulText}
        </div>
      )}
      {showCheckbox && (
        <Form.Check
          type="checkbox"
          label={checkLabel}
          checked={address.hidden}
          onChange={() => handleChange({
            hidden: !address.hidden
          })}
          role="checkbox"
          data-testid={checkboxTestId}
        />
      )}
      {!address.hidden && (
        <div className={containerClass}>
          <Form.Group controlId="formGroupAddress1">
            <Form.Label>Street Address 1</Form.Label>
            <Form.Control
              type="text"
              value={address.address1 || ''}
              onChange={(e) => handleChange({
                address1: e.target.value
              })}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupAddress2">
            <Form.Label>Street Address 2</Form.Label>
            <Form.Control
              type="text"
              value={address.address2 || ''}
              onChange={(e) => handleChange({
                address2: e.target.value
              })}
            />
          </Form.Group>
          <Form.Group controlId="formGroupCounty">
            <Form.Label>County</Form.Label>
            <Form.Control
              type="text"
              value={address.county || ''}
              onChange={(e) => handleChange({
                county: e.target.value
              })}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              value={address.city || ''}
              onChange={(e) => handleChange({
                city: e.target.value
              })}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupState">
            <Form.Label>State</Form.Label>
            <Form.Control
              as="select"
              value={address.state}
              onChange={(e) => handleChange({
                state: e.target.value
              })}
              required
            >
              <option value="WA">WA</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formGroupZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              value={address.zip || ''}
              onChange={(e) => handleChange({
                zip: e.target.value
              })}
              required
            />
          </Form.Group>
        </div>
      )}
    </>
  )
}

AddressForm.propTypes = {
  title: PropTypes.string,
  helpfulText: PropTypes.string,
  checkLabel: PropTypes.string,
  checkboxTestId: PropTypes.string,
  addressTypeId: PropTypes.number,
  address: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
  showCheckbox: PropTypes.bool,
  onChange: PropTypes.func
}

export default AddressForm
