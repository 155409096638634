import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

const AddTransactionButton = ({
  rounded,
  path,
  appendedClassName,
  children
}) => {
  const roundClass = rounded
    ? 'rounded-circle'
    : ''
  return (
    <Link to={path} className={`btn btn-primary ${roundClass} ${appendedClassName}`}>
      <FontAwesomeIcon icon={faPlus} />
      {children}
    </Link>
  )
}

AddTransactionButton.propTypes = {
  rounded: PropTypes.bool,
  path: PropTypes.string,
  appendedClassName: PropTypes.string,
  children: PropTypes.node
}

AddTransactionButton.defaultProps = {
  rounded: false,
  path: '/finance',
  appendedClassName: '',
  children: <span></span>
}

export default AddTransactionButton
