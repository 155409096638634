import React, { useContext, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Alert,
  Button,
  Card,
  Form
} from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserContext from '../../contexts/UserContext'
import { isUserAdmin, isUserDistrict } from '../../utils/lib'
import FinancialTransactionForm from './FinancialTransactionForm'
import StudentTransactionForm from './StudentTransactionForm'
import useLocalStorage from '../../utils/useLocalState'
import { LAST_SCHOOL_SELECTED, transactionTypeIds, inactiveTransactionTypeIds } from '../../constants'
import { QUERY_CURRENT_SCHOOL_YEAR_START_DATE } from '../../operations/schoolYear'
import {
  QUERY_TRANSACTION,
  QUERY_TRANSACTION_TYPES,
  CREATE_TRANSACTION,
  MUTATE_TRANSACTION
} from '../../operations/transactions'
import { QUERY_DISTRICT_SCHOOLS } from '../../operations/districts'

const initialState = {
  id: null,
  transactionTypeId: null,
  totalAmount: null,
  transactionDate: new Date(),
  donor: '',
  checkNumber: '',
  notes: '',
  categoryTypeId: null,
  categoryCodeId: null,
  students: {},
  quantity: null,
  ethnicityBreakdown: {},
  genderBreakdown: {},
  approved: true,
  errorMessage: null
}

/**
 * Component for creating or updating a transaction
 * @param {any} [title='New Transaction']
 * @param {any} [backPath='/finance']
 * @returns {any}
 */
const TransactionForm = ({
  title = 'Transaction',
  backPath = '/finance'
}) => {
  const history = useHistory()
  const {
    user: userContext,
    session
  } = useContext(UserContext)
  const { userSchools } = userContext
  const [lastSchool] = useLocalStorage(
    LAST_SCHOOL_SELECTED,
    userSchools.find((s) => s)?.school
  )
  const { currentDistrict } = session
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrictUser = isUserDistrict(userContext)
  const districtId = currentDistrict?.id
  const schoolId = lastSchool?.id
  const { id } = useParams()
  const intId = parseInt(id, 10)
  const [transactionTypesState, setTransactionTypesState] = useState()
  const [errorState, setErrorState] = useState()
  const [studentCountError, setStudentCountError] = useState(false)
  const [noStudentsError, setNoStudentsError] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [isBulkMode, setIsBulkMode] = useState(false)
  const [formState, setFormState] = useState({
    ...initialState,
    id: intId || id
  })
  const studentTransactionTotal = Object.keys(formState.students).reduce(
    // to avoid NaN this reducer checks for existence of amount before adding it
    (sum, key) => (
      formState.students[key].amount
        ? sum + formState.students[key].amount
        : sum
    ), 0
  )
  const [districtSchools, setDistrictSchools] = useState([])

  /**
   * determines if the transaction is for a district
   */
  const isDistrictTransaction = () => (
    districtId && !schoolId
  )

  /**
   * gets lastSchool or currentDistricts.schools as an array
   */
  const schools = () => {
    if (districtSchools.length) {
      return districtSchools
    }

    return [lastSchool]
  }

  // queries
  const {
    loading: transactionLoading,
    error: transactionError
  } = useQuery(QUERY_TRANSACTION, {
    variables: { id: intId },
    skip: !id,
    onCompleted: (({ transaction }) => {
      const studentsObject = {}
      // if student transactions exist, build the object for state
      if (transaction.studentTransactions.length) {
        transaction.studentTransactions.forEach((student) => {
          studentsObject[student.student.id] = {
            id: student.id,
            studentId: student.student.id,
            amount: student.amount,
            notes: student.notes
          }
        })
      }
      if (transaction.genderBreakdown) {
        setIsBulkMode(true)
      }
      setFormState({
        ...formState,
        transactionTypeId: transaction.transactionType.id,
        totalAmount: transaction.totalAmount,
        transactionDate: moment(transaction.transactionDate).format('YYYY-MM-DD'),
        donor: transaction.donor,
        checkNumber: transaction.checkNumber,
        notes: transaction.notes,
        categoryCodeId: transaction.categoryCode?.id || null,
        categoryTypeId: transaction.categoryCode?.categoryType?.id || null,
        students: studentsObject,
        quantity: transaction.quantity,
        ethnicityBreakdown: transaction.ethnicityBreakdown || {},
        genderBreakdown: transaction.genderBreakdown || {},
        approved: transaction.approved,
        modifiedById: userContext.id
      })
    })
  })

  const {
    loading: transactionTypesLoading
  } = useQuery(QUERY_TRANSACTION_TYPES, {
    onCompleted: (({ transactionTypes }) => {
      const activeTransactionTypes = transactionTypes.filter(
        (item) => !inactiveTransactionTypeIds.includes(item.id)
      )
      if (isAdmin) {
        setTransactionTypesState(activeTransactionTypes)
      } else {
        setTransactionTypesState(activeTransactionTypes.filter((item) => (
          item.investedOnly === false
        )))
      }
    })
  })

  useQuery(QUERY_CURRENT_SCHOOL_YEAR_START_DATE, {
    onCompleted: ({ schoolYear }) => {
      const intYear = parseInt(schoolYear.startDate, 10)
      const date = new Date(intYear)
      const offset = moment(date).utcOffset()
      setStartDate(moment(date).utcOffset(-offset).format('YYYY-MM-DD'))
    }
  })

  /**
   * queries for schools in current district, skips if no current district
   */
  useQuery(QUERY_DISTRICT_SCHOOLS, {
    variables: {
      districtId
    },
    skip: !districtId,
    onCompleted: ({ schools: querySchools }) => {
      setDistrictSchools(querySchools)
    }
  })

  // mutations

  // variables for all transaction types
  const baseMutationVariables = {
    schoolId,
    districtId: schoolId ? undefined : districtId,
    transactionTypeId: formState.transactionTypeId,
    totalAmount: formState.totalAmount,
    transactionDate: moment(formState.transactionDate).valueOf(),
    donor: formState.donor,
    checkNumber: formState.checkNumber,
    notes: formState.notes,
    approved: formState.approved
  }

  // variables to append for student transaction type
  const { students } = formState
  const studentTransactionVariables = isBulkMode
    // student transaction variables for bulk mode
    ? {
      categoryCodeId: formState.categoryCodeId,
      quantity: formState.quantity,
      ethnicityBreakdown: formState.ethnicityBreakdown,
      genderBreakdown: formState.genderBreakdown
    }
    // student transaction variables for individual mode
    //  note: we set quantity to 0, this will be the difference between bulk student and individual student transaction types
    : {
      totalAmount: studentTransactionTotal,
      categoryCodeId: formState.categoryCodeId,
      quantity: 0, // Object.keys(students).length,
      students: Object.keys(students).map((key) => students[key])
    }

  const [mutateTransaction, {
    loading: mutationLoading
  }] = formState.transactionTypeId === transactionTypeIds.STUDENT
    // if a Student Transaction, use base and student variables
    ? useMutation(id
      ? MUTATE_TRANSACTION
      : CREATE_TRANSACTION, {
      variables: id
        ? {
          ...baseMutationVariables,
          ...studentTransactionVariables,
          id: intId
        }
        : {
          ...baseMutationVariables,
          ...studentTransactionVariables
        },
      onCompleted: () => {
        history.push(id ? `/finance/${id}` : '/finance')
      }
    })
    // if any type but Student Transaction, use only base variables
    : useMutation(id
      ? MUTATE_TRANSACTION
      : CREATE_TRANSACTION, {
      variables: id
        ? {
          ...baseMutationVariables,
          id: intId
        }
        : baseMutationVariables,
      onCompleted: () => {
        history.push(id ? `/finance/${id}` : '/finance')
      }
    })

  // event listeners
  /**
   * handle change event for form inputs and sets the state for the given field.
   * Accepts event, field name and an optional overrideValue as parameters
   * @param {any} e - the event arg passed (for accessing e.target.value)
   * @param {any} field - field name to set in formState
   * @param {any} [overrideValue] - optional value to set instead of using e.target.value
   * @returns {any}
   */
  const handleChange = (e, field, overrideValue) => {
    setFormState({
      ...formState,
      [field]: overrideValue !== undefined ? overrideValue : e.target.value
    })
  }

  // validation for breakdown totals matching student count
  const checkCountsMatch = () => {
    const ethnicitiesTotal = Object.keys(formState.ethnicityBreakdown).reduce(
      (sum, key) => sum + formState.ethnicityBreakdown[key], 0
    )
    const gendersTotal = Object.keys(formState.genderBreakdown).reduce(
      (sum, key) => sum + formState.genderBreakdown[key], 0
    )
    return ethnicitiesTotal === formState.quantity && gendersTotal === formState.quantity
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    // if student transaction, validate things that aren't simple form elements
    if (formState.transactionTypeId === transactionTypeIds.STUDENT) {
      // if bulk mode, check that breakdown totals match number of students
      if (isBulkMode) {
        if (checkCountsMatch()) {
          mutateTransaction()
            .catch((err) => {
              setErrorState(err.message)
            })
        } else {
          setStudentCountError(true)
        }
      // if individual mode, check that there are students included in the form
      } else if (Object.keys(students).length > 0) {
        mutateTransaction()
          .catch((err) => {
            setErrorState(err.message)
          })
      } else {
        setNoStudentsError(true)
      }
    // if not a student transaction, do the mutation with no further checks
    } else {
      mutateTransaction()
        .catch((err) => {
          setErrorState(err.message)
        })
    }
  }

  if (transactionLoading) return <LoadingSpinner message="Loading..." />
  if (transactionError) return <div className="alert alert-danger">{transactionError.message}</div>

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {id
            ? `Edit ${title}`
            : `Add ${title}`
          }
        </h3>
      </div>
      <Card className="m-3 p-3">
        <Form
          className="col-lg-8"
          onSubmit={onFormSubmit}
          data-testid="transaction-form"
        >
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}

          <h5 className="border-bottom mb-3">Transaction Type</h5>
          <Form.Group controlId="formGroupTransactionType">
            <Form.Control
              type="select"
              as="select"
              value={formState.transactionTypeId || ''}
              onChange={(e) => {
                handleChange(e, 'transactionTypeId', parseInt(e.target.value, 10))
                setStudentCountError(false)
                setNoStudentsError(false)
              }}
              disabled={transactionTypesLoading}
              required
              data-testid="transaction-type-input"
            >
              {transactionTypesLoading
                ? <option hidden value=''>Transaction types loading...</option>
                : <option hidden value=''>Select a transaction type...</option>
              }
              {transactionTypesState?.filter((type) => !(isDistrictTransaction() && isDistrictUser && type.name === 'Donation')).map((type) => (
                <option
                  key={type.id}
                  data-testid="transaction-type-item"
                  value={type.id}>
                    {type.name}
                  </option>
              ))}
            </Form.Control>
          </Form.Group>
          {formState.transactionTypeId === 5
            ? <StudentTransactionForm
                schools={schools()}
                startDate={startDate}
                totalAmount={isBulkMode ? formState.totalAmount : studentTransactionTotal}
                transactionDate={moment(formState.transactionDate).format('YYYY-MM-DD')}
                categoryTypeId={formState.categoryTypeId}
                categoryCodeId={formState.categoryCodeId}
                students={formState.students}
                quantity={parseInt(formState.quantity, 10)}
                ethnicityBreakdown={formState.ethnicityBreakdown}
                genderBreakdown={formState.genderBreakdown}
                isBulkMode={isBulkMode}
                isAdmin={isAdmin}
                setIsBulkMode={setIsBulkMode}
                setStudentCountError={setStudentCountError}
                setNoStudentsError={setNoStudentsError}
                handleChange={handleChange}
              />
            : <FinancialTransactionForm
                isEdit={Boolean(id)}
                isAdmin={isAdmin}
                transactionTypeId={formState.transactionTypeId}
                isApproved={formState.approved}
                totalAmount={formState.totalAmount}
                startDate={startDate}
                transactionDate={moment(formState.transactionDate).format('YYYY-MM-DD')}
                donor={formState.donor}
                checkNumber={formState.checkNumber}
                notes={formState.notes}
                handleChange={handleChange}
              />
          }
          <hr />
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}

          {/* Show warning if breakdown counts don't match student count */}
          {studentCountError && (
            <Alert
              variant="danger"
            >
              {'Please make sure the Ethnicities Total and the Genders '}
              {'Total match the number of students entered.'}
            </Alert>
          )}

          {/* Show warning if no students have been added */}
          {noStudentsError && (
            <Alert
              variant="danger"
            >
              {'Please add at least one student to this transaction before submitting.'}
            </Alert>
          )}

          {!mutationLoading && (
            <>
              <Button
                variant="primary"
                type="submit"
                data-testid="transaction-form-submit-button"
              >
                Submit
              </Button>{' '}
              <Button variant="secondary" className="text-light" href={backPath}>
                Cancel
              </Button>
            </>
          )}

          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

TransactionForm.propTypes = {
  title: PropTypes.string,
  backPath: PropTypes.string,
  backLabel: PropTypes.string
}

export default TransactionForm
