import React, { useContext, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Card, Form } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import LoadingSpinner from '../../components/LoadingSpinner'
import AddressForm, { initialAddressState } from '../../components/AddressForm'
import UserContext from '../../contexts/UserContext'
import { entityStatusDictionary } from '../../constants'
import {
  CREATE_DISTRICT,
  MUTATE_DISTRICT,
  QUERY_ONE_DISTRICT
} from '../../operations/districts'

const initialState = {
  id: null,
  name: '',
  districtCode: '',
  esdNumber: '',
  numberOfStudents: 0,
  frlPercentage: '',
  status: '',
  phoneNumber: '',
  errorMessage: null
}

/**
 * Component for creating or updating a district
 * @param {any} [title='New District']
 * @param {any} [backPath='/schools/districts']
 * @param {any} [backLabel='$lt;back to district directory']
 * @returns {any}
 */
const DistrictForm = ({
  title = 'New District',
  backPath = '/schools/districts',
  backLabel = '$lt; back to district directory'
}) => {
  const history = useHistory()
  const { user: userContext } = useContext(UserContext)
  const { id } = useParams()
  const intId = parseInt(id, 10)
  const [formState, setFormState] = useState({
    ...initialState,
    id: intId || id
  })
  const [locationAddress, setLocationAddress] = useState(initialAddressState)
  const [mailingAddress, setMailingAddress] = useState({
    ...initialAddressState,
    hidden: true
  })
  const [errorState, setErrorState] = useState()

  // queries
  // TODO USER CONTEXT
  const {
    loading: districtLoading,
    error: districtError
  } = useQuery(QUERY_ONE_DISTRICT, {
    variables: { id: intId },
    skip: !id,
    onCompleted: (({ districts }) => {
      const [district] = districts
      const lAddr = district.addresses.filter((a) => a.addressType.id === 1)
      const mAddr = district.addresses.filter((a) => a.addressType.id === 2)
      setFormState({
        ...formState,
        id: district.id,
        name: district.name,
        phoneNumber: district.phoneNumber,
        districtCode: district.districtCode,
        esdNumber: district.esdNumber,
        numberOfStudents: district.numberOfStudents || 0,
        frlPercentage: parseFloat(district.frlPercentage) || 0,
        status: district.status,
        createdById: district.createdBy?.id || userContext.id,
        createdAt: district.createdAt,
        modifiedById: userContext.id
      })
      if (lAddr && lAddr.length > 0) {
        const [location] = lAddr
        setLocationAddress({
          ...locationAddress,
          entityAddressId: location.id,
          addressId: location.address?.id,
          addressTypeId: location.addressType.id,
          address1: location.address?.addressLine1,
          address2: location.address?.addressLine2,
          county: location.address?.county,
          city: location.address?.city,
          state: location.address?.state,
          zip: location.address?.zip
        })
      }
      if (mAddr && mAddr.length > 0) {
        const [mailing] = mAddr
        setMailingAddress({
          ...mailingAddress,
          entityAddressId: mailing.id,
          addressId: mailing.address?.id,
          addressTypeId: mailing.addressType.id,
          address1: mailing.address?.addressLine1,
          address2: mailing.address?.addressLine2,
          county: mailing.address?.county,
          city: mailing.address?.city,
          state: mailing.address?.state,
          zip: mailing.address?.zip,
          hidden: false
        })
      }
    })
  })

  // mutations
  const mutationAddresses = [
    {
      id: locationAddress.entityAddressId || undefined,
      addressId: locationAddress.addressId || undefined,
      addressTypeId: locationAddress.addressTypeId,
      address: {
        addressLine1: locationAddress.address1,
        addressLine2: locationAddress.address2,
        county: locationAddress.county,
        city: locationAddress.city,
        state: locationAddress.state,
        zip: locationAddress.zip
      }
    }
  ]
  // add mailing address
  if (mailingAddress && !mailingAddress.hidden) {
    mutationAddresses.push({
      id: mailingAddress.entityAddressId || undefined,
      addressId: mailingAddress.addressId || undefined,
      addressTypeId: mailingAddress.addressTypeId,
      address: {
        addressLine1: mailingAddress.address1,
        addressLine2: mailingAddress.address2,
        county: mailingAddress.county,
        city: mailingAddress.city,
        state: mailingAddress.state,
        zip: mailingAddress.zip
      }
    })
  }
  const baseMutationVariables = {
    name: formState.name,
    addresses: mutationAddresses,
    donateUrl: formState.donationUrl,
    minimumGradeId: formState.minGrade,
    maximumGradeId: formState.maxGrade,
    districtCode: formState.districtCode,
    esdNumber: formState.esdNumber,
    numberOfStudents: formState.numberOfStudents,
    frlPercentage: formState.frlPercentage.toString(),
    status: formState.status,
    phoneNumber: formState.phoneNumber
  }

  const [mutateDistrict, {
    loading: mutationLoading
  }] = useMutation(id
    ? MUTATE_DISTRICT
    : CREATE_DISTRICT, {
    variables: id
      ? {
        ...baseMutationVariables,
        id: intId
      }
      : baseMutationVariables,
    onCompleted: (({ id: returnId }) => {
      history.push(returnId
        ? `/schools/districts/${returnId}`
        : '/schools/districts')
    })
  })

  // event listeners
  /**
   * handle change event for form inputs and sets the state for the given field. Accepts event, field name and an optional overrideValue as parameters
   * @param {any} e - the event arg passed (for accessing e.target.value)
   * @param {any} field - field name to set in formState
   * @param {any} [overrideValue] - optional value to set instead of using e.target.value
   * @returns {any}
   */
  const handleChange = (e, field, overrideValue) => {
    setFormState({
      ...formState,
      [field]: overrideValue || e.target.value
    })
  }
  const onFormSubmit = (e) => {
    e.preventDefault()
    mutateDistrict()
      .catch((err) => {
        setErrorState(err.message)
      })
  }

  if (districtLoading) return <LoadingSpinner message="Loading..." />
  if (districtError) return <div className="alert alert-danger">{districtError.message}</div>

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {id
            ? `Edit ${title}`
            : `Add ${title}`
          }
        </h3>
        {backPath && backLabel && (
          <Link to={backPath} className="text-secondary">
            {backLabel}
          </Link>
        )}
      </div>
      <Card className="mt-3 p-3">
        <Form className="col-md-6" onSubmit={onFormSubmit}>
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          <Form.Group controlId="formGroupName">
            <Form.Label>District Name</Form.Label>
            <Form.Control
              type="text"
              value={formState.name || ''}
              onChange={(e) => handleChange(e, 'name')}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              value={formState.phoneNumber || ''}
              onChange={(e) => handleChange(e, 'phoneNumber')}
              required
            />
          </Form.Group>
          <AddressForm
            title="District Location and Mailing Address"
            helpfulText="If your mailing address is different from your location, uncheck the box and enter both addresses seperately"
            onChange={setLocationAddress}
            address={locationAddress}
            addressTypeId={1}
          />
          <AddressForm
            hidden={mailingAddress.hidden}
            containerClass="alert-secondary p-3 border rounded mailing-address"
            showCheckbox={true}
            checkboxTestId="mailing-check-test"
            onChange={setMailingAddress}
            address={mailingAddress}
            addressTypeId={2}
            checkLabel="Mailing address is the same as district address above"
          />
          <Form.Group controlId="formGroupOspiNumber">
            <Form.Label>District Code</Form.Label>
            <Form.Control
              type="text"
              value={formState.districtCode || ''}
              onChange={(e) => handleChange(e, 'districtCode')}
            />
          </Form.Group>
          <Form.Group controlId="formGroupEsdNumber">
            <Form.Label>ESD Number</Form.Label>
            <Form.Control
              type="text"
              value={formState.esdNumber || ''}
              onChange={(e) => handleChange(e, 'esdNumber')}
            />
          </Form.Group>
          <Form.Group controlId="formGroupNumStudents">
            <Form.Label>Number of Students Enrolled</Form.Label>
            <Form.Control
              type="number"
              value={formState.numberOfStudents.toString() || ''}
              onChange={(e) => handleChange(
                e,
                'numberOfStudents',
                parseInt(e.target.value, 10)
              )}
              min="0"
            />
          </Form.Group>
          <Form.Group controlId="formGroupFrlPercentage">
            <Form.Label>Free and Reduced Lunch (FRL) Percentage</Form.Label>
            <Form.Control
              type="number"
              value={formState.frlPercentage || ''}
              onChange={(e) => handleChange(e, 'frlPercentage')}
              min="0"
              max="100"
              step="any"
            />
          </Form.Group>
          <Form.Group controlId="formGroupStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={formState.status}
              onChange={(e) => handleChange(e, 'status')}
              required
            >
              <option value="">Choose...</option>
              {Object.keys(entityStatusDictionary).map((key) => (
                <option
                  key={key}
                  value={entityStatusDictionary[key]}
                  data-testid="status-option"
                >
                  {entityStatusDictionary[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <hr />
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          {!mutationLoading && (
            <>
              <Button variant="primary" type="submit">
                Submit
              </Button>{' '}
              <Button variant="secondary" className="text-light" href={backPath}>
                Cancel
              </Button>
            </>
          )}
          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

DistrictForm.propTypes = {
  title: PropTypes.string,
  backPath: PropTypes.string,
  backLabel: PropTypes.string
}

export default DistrictForm
