import { gql } from '@apollo/client'

// * queries
export const GET_STAFFS = gql`
  query GetStaffs(
    $roles: [Int]
  ) {
    users(
      roleId: $roles
      active: [true, false]
    ) {
      id
      first
      last
      email
      active
      investedRole {
        name
      }
      userSchools {
        school {
          id
          name
        }
        schoolPosition {
          name
        }
      }
      userDistricts {
        district {
          id
          name
        }
        schoolPosition {
          name
        }
      }
    }
  }
`
export const GET_USER_STAFFS = gql`
  query GetSchoolStaffs(
    $schoolIds: [Int] = []
    $districtId: Int = -1
  ) {
    schools(id: $schoolIds) {
      id
      name
      userSchools {
        user {
          id
          first
          last
          email
          active
          investedRole {
            name
          }
          userSchools {
            school {
              id
              name
            }
            schoolPosition {
              name
            }
          }
        }
        schoolPosition {
          name
        }
      }
    }
    districts(id: $districtId) {
      id
      name
      userDistricts {
        user {
          id
          first
          last
          email
          active
          investedRole {
            name
          }
        }
        schoolPosition {
          name
        }
      }
    }
  }
`
export const QUERY_DROPDOWNLISTS = gql`
  query GetSchoolsDistrictsInvestEdRolesAndPositions {
    schools {
      id
      name
      district {
        id
      }
    }
    districts {
      id
      name
    }
    investedRoles {
      id
      name
    }
    schoolPositions {
      id
      name
    }
  }
`
export const QUERY_ONE_STAFF = gql`
  query GetOneStaff(
    $id: Int!
  ) {
    user(id: $id) {
      id
      first
      last
      email
      active
      investedRole {
        id
      }
      userSchools {
        id
        schoolPosition {
          id
          name
        }
        school {
          id
          name
          district {
            id
          }
        }
      }
      userDistricts {
        id
        schoolPosition {
          id
          name
        }
        district {
          id
          name
        }
      }
    }
  }
`

// * mutations
export const CREATE_STAFF = gql`
  mutation CreateStaffMutation(
    $first: String!
    $last: String!
    $email: String!
    $investedRoleId: Int!
    $userSchools: [UserSchoolInput]
    $userDistrict: UserDistrictInput
  ) {
    createUser(
      first: $first
      last: $last
      investedRoleId: $investedRoleId
      email: $email
      userSchools: $userSchools
      userDistrict: $userDistrict
      roleId: 3
    ) {
      id
    }
  }
`
export const MUTATE_STAFF = gql`
  mutation UpdateStaffMutation(
    $id: Int!
    $first: String
    $last: String
    $investedRoleId: Int
    $userSchools: [UserSchoolUpdateInput]
    $userDistrict: UserDistrictUpdateInput
    $active: Boolean
  ) {
    updateUser(
      id: $id
      first: $first
      last: $last
      investedRoleId: $investedRoleId
      userSchools: $userSchools
      userDistrict: $userDistrict
      active: $active
    ) {
      affectedRows
    }
  }
`

export const DEACTIVATE_STAFF = gql`
  mutation DeactivateStaffMutation(
    $id: Int!
  ) {
    updateUser(
      id: $id
      active: false
    ) {
      affectedRows
    }
  }
`

export const DELETE_STAFF = gql`
mutation deleteStaff(
  $id: Int!
) {
    deleteUser( id: $id ) {
      affectedRows
    }
  }
`

export const RESEND_INVITATION = gql`
  mutation ResendInvitationMutation(
    $email: String!
  ) {
    resendInvitation(
      email: $email
    )
  }
`
