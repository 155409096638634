import { gql } from '@apollo/client'

// eslint-disable-next-line import/prefer-default-export
export const QUERY_CURRENT_ACCOUNT_BALANCE = gql`
  query GetBalance(
    $districtId: Int,
    $schoolId: Int,
    $schoolYearId: Int
  ) {
    accountBalance(districtId: $districtId, schoolId: $schoolId, schoolYearId: $schoolYearId) {
      fallFunding {
        percentUsed
        totalInitialFallFunding
      }
      currentBalance
      balancesByTransactionType {
        transactionType
        sum
      }
      startBalance
    }
  }
`
