import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Button, Card, Form } from 'react-bootstrap'

export const PRIVATE_CONTACT_MUTATION = gql`
  mutation PrivateContactMutation(
    $contactReason: String!
    $subject: String!
    $message: String!
  ) {
    privateContact(
      contactReason: $contactReason,
      subject: $subject,
      message: $message,
    )
  }
`
const PrivateContact = () => {
  const initialState = {
    contactReasonId: '',
    contactReason: '',
    subject: '',
    message: '',
    isSubmitting: false,
    errorMessage: null
  }
  const [formState, setFormState] = useState(initialState)
  const [errorState, setErrorState] = useState()
  const [dataReturned, setDataReturnedState] = useState(false)

  const [contactMutation, {
    loading,
    data: mutationData
  }] = useMutation(PRIVATE_CONTACT_MUTATION,
    {
      variables: {
        contactReason: formState.contactReason,
        subject: formState.subject,
        message: formState.message
      }
    })

  const handleFormSubmit = (e) => {
    e.preventDefault()
    contactMutation()
      .then(() => {
        setDataReturnedState(true)
      })
      .catch((err) => {
        setErrorState(err.message)
      })
  }

  /**
   * returns all contact reason or one
   * @param {int} [byId] - if defined, will do a find for the reason with the given id
   * @returns {Array|Object}
   */
  const contactReasons = (byId) => {
    const reasons = [
      {
        id: 1,
        text: 'Report a bug'
      },
      {
        id: 2,
        text: 'Funding question'
      },
      {
        id: 3,
        text: 'Other'
      }
    ]

    const foundReason = reasons.find((reason) => reason.id === byId)

    return foundReason || reasons
  }

  return (
    <div className="Home main-content-box">
      <div className="lander">
        <h3 className="page-title border-bottom">
          Contact Us
        </h3>
      </div>
      <Card className="m-3 p-3">
        {dataReturned && mutationData.privateContact
          ? (
            <div className="alert alert-success">Your message has been sent to InvestED</div>
          )
          : (
            <Form className="col-md-6" onSubmit={handleFormSubmit}>
              {errorState && (
                <div className="alert alert-warning">{errorState}</div>
              )}
              {(dataReturned && !mutationData.privateContact) && (
                <div className="alert alert-warning">Something went wrong. Please try again.</div>
              )}
              <Form.Group controlId="formGroupReason">
                <Form.Label>Contact Reason</Form.Label>
                <Form.Control
                  type="select"
                  as="select"
                  value={formState.contactReasonId}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      contactReasonId: e.target.value,
                      contactReason: contactReasons(parseInt(e.target.value, 10)).text
                    })
                  }}
                  required
                >
                  <option hidden value=''>Select a reason...</option>
                  {contactReasons().map((reason) => (
                    <option
                      key={reason.id}
                      value={reason.id.toString()}
                    >
                      {reason.text}
                    </option>
                  ))}

                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  value={formState.subject}
                  onChange={(e) => setFormState({
                    ...formState,
                    subject: e.target.value
                  })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGroupMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  value={formState.message}
                  as="textarea"
                  rows={5}
                  onChange={(e) => setFormState({
                    ...formState,
                    message: e.target.value
                  })
                  }
                  required
                />
              </Form.Group>
              <hr />
              {loading ? (
                <div className="alert alert-info">Loading...</div>
              ) : (
                <>
                  <Button variant="primary" type="submit" className="mr-2">
                    Submit
                  </Button>
                  <Button variant="secondary" className="text-light" href="/">
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
      </Card>
    </div>
  )
}
export default PrivateContact
