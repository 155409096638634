import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router'
import { Button, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faChalkboardTeacher,
  faChartBar,
  faFileInvoice,
  faGraduationCap,
  faHouse,
  faMoneyCheckEditAlt,
  faQuestionCircle,
  faSchool,
  faSignOutAlt
} from '@fortawesome/pro-light-svg-icons'
import UserContext from '../contexts/UserContext'
import {
  isUserAdmin,
  hasMoreThanNSchools
} from '../utils/lib'
import { QUERY_IS_YEAR_END_OPEN } from '../operations/yearEndReport'

/**
 * Sidebar navigation component, takes no parameters
 */
const Sidebar = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const { user: userContext } = useContext(UserContext)
  const userIsAdmin = isUserAdmin(userContext.role.name)
  const location = useLocation()
  const [yearEndOpen, setYearEndOpen] = useState(false)

  useQuery(QUERY_IS_YEAR_END_OPEN, {
    onCompleted: ({ isYearEndOpen = false }) => {
      setYearEndOpen(isYearEndOpen)
    }
  })

  const onMouseEnter = (e) => {
    e.preventDefault()
    setSidebarExpanded(true)
  }
  const onMouseLeave = (e) => {
    e.preventDefault()
    setSidebarExpanded(false)
  }

  return (
    <div
      className="d-print-none"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={`d-md-block bg-ed-light-blue sidebar ${sidebarExpanded ? 'expanded' : ''}`}>
        <Button variant="link text-light" className="logout" href="/logout">
          <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
          <span className="sr-only sr-only-focusable">Logout</span>
          <div className="sidebar-text">LOGOUT</div>
        </Button>
      </div>
      <Nav className={`sidebar-nav ${sidebarExpanded ? 'expanded' : ''}`}>
        <LinkContainer to="/dashboard">
          <Nav.Link active={location.pathname.includes('dashboard')}>
            <FontAwesomeIcon icon={faHouse} size="lg" />
            <span className="sr-only sr-only-focusable">Dashboard</span>
            <div className="sidebar-text">DASHBOARD</div>
          </Nav.Link>
        </LinkContainer>
        {hasMoreThanNSchools(userContext, 0) && (
          <>
            <LinkContainer to="/students">
              <Nav.Link active={location.pathname.includes('/students')}>
                <FontAwesomeIcon icon={faGraduationCap} size="lg" />
                <span className="sr-only sr-only-focusable">Students</span>
                <div className="sidebar-text">STUDENTS</div>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/staff">
              <Nav.Link active={location.pathname.includes('/staff')}>
                <FontAwesomeIcon icon={faChalkboardTeacher} size="lg" />
                <span className="sr-only sr-only-focusable">Staff</span>
                <div className="sidebar-text">STAFF</div>
              </Nav.Link>
            </LinkContainer>
          </>
        )}
        {hasMoreThanNSchools(userContext, 1) && (
          <LinkContainer to="/schools">
            <Nav.Link active={location.pathname.includes('schools')}>
              <FontAwesomeIcon icon={faSchool} size="lg" />
              <span className="sr-only sr-only-focusable">Schools</span>
              <div className="sidebar-text">SCHOOLS</div>
            </Nav.Link>
          </LinkContainer>
        )}
        {hasMoreThanNSchools(userContext, 0) && (
          <>
            <LinkContainer to="/finance">
              <Nav.Link
                active={location.pathname.includes('finance') && !location.pathname.includes('admin')}
              >
                <FontAwesomeIcon icon={faMoneyCheckEditAlt} size="lg" />
                <span className="sr-only sr-only-focusable">Finance</span>
                <div className="sidebar-text">FINANCE</div>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/reports">
              <Nav.Link active={location.pathname.includes('reports')}>
                <FontAwesomeIcon icon={faFileInvoice} size="lg" />
                <span className="sr-only sr-only-focusable">Reports</span>
                <div className="sidebar-text">REPORTS</div>
              </Nav.Link>
            </LinkContainer>
            {(yearEndOpen || userIsAdmin) && (
              <LinkContainer to="/year-end-report">
                <Nav.Link active={location.pathname.includes('year-end-report')}>
                  <FontAwesomeIcon icon={faChartBar} size="lg" />
                  <span className="sr-only sr-only-focusable">Year End Report</span>
                  <div className="sidebar-text">YEAR END REPORT</div>
                </Nav.Link>
              </LinkContainer>
            )}
          </>
        )}
        <LinkContainer to="/resources">
          <Nav.Link active={location.pathname.includes('resources')}>
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
            <span className="sr-only sr-only-focusable">Resources</span>
            <div className="sidebar-text">RESOURCES</div>
          </Nav.Link>
        </LinkContainer>
        {userIsAdmin && (
          <LinkContainer to="/admin">
            <Nav.Link active={location.pathname.includes('admin')}>
              <FontAwesomeIcon icon={faCog} size="lg" />
              <span className="sr-only sr-only-focusable">Settings</span>
              <div className="sidebar-text">SETTINGS</div>
            </Nav.Link>
          </LinkContainer>
        )}
      </Nav>
    </div>
  )
}
export default Sidebar
