import React from 'react'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import { LinkContainer } from 'react-router-bootstrap'

/**
 * Header component for anonymous users, takes no parameters
 *
 */
const PublicHeader = () => {
  const renderLogo = () => (
    <Link to="/">
      <img src="/logo.png" alt="InvestED. Immediate help for students in need." />
    </Link>
  )
  return (
    <div className="d-flex flex-nowrap justify-content-between align-items-center py-2 mb-3 border-bottom sticky-top bg-light">
      <div className="col-6">
        {renderLogo()}
      </div>
      <div className="col-6 d-flex justify-content-end align-items-center">
        <Nav>
          <LinkContainer to="/contact">
            <Nav.Link>Contact Us</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/login">
            <Nav.Link>Login</Nav.Link>
          </LinkContainer>
        </Nav>
      </div>
    </div>
  )
}

export default PublicHeader
