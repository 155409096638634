import { AUTH_TOKEN } from '../constants'

const localStorageKey = AUTH_TOKEN

const getToken = () => {
  // eslint-disable-next-line no-undef
  let token = window.localStorage.getItem(localStorageKey)
  if (token) {
    try {
      JSON.parse(token)
    } catch (e) {
      // eslint-disable-next-line no-undef
      window.localStorage.removeItem(localStorageKey)
      return ''
    }
    token = JSON.parse(token)
  }
  return token
}

const handleUserResponse = (loginObject) => {
  const stringToken = JSON.stringify(loginObject)
  // eslint-disable-next-line no-undef
  window.localStorage.setItem(localStorageKey, stringToken)
  return stringToken
}

async function logout() {
  /* eslint-disable no-undef */
  window.localStorage.clear()
  window.location.href = '/login'
  /* eslint-enable no-undef */
}

export {
  getToken, logout, localStorageKey, handleUserResponse
}
