import React from 'react'
import { Badge } from 'react-bootstrap'
import { ROLE_ADMINS } from '../constants'

export const isUserAdmin = (role) => ROLE_ADMINS.includes(role)
export const isUserDistrict = (user) => user.userDistricts?.length > 0
export const hasMoreThanNSchools = (user, n) => user.userSchools?.length > n
  || isUserAdmin(user.role.name)
  || isUserDistrict(user)

/**
 * renders a simple status badge with customizable truthy or falsy labels
 * @param {bool} [active=true]
 * @param {string} [truthyLabel='active']
 * @param {string} [falsyLabel='inactive']
 * @returns {HTMLElement}
 */
export const renderStatus = ({
  active = true,
  truthyLabel = 'active',
  falsyLabel = 'inactive'
}) => (
  <Badge
    className={active
      ? 'bg-ed-dark-blue'
      : 'bg-danger'
    }
  >
    {active
      ? truthyLabel
      : falsyLabel
    }
  </Badge>
)
/**
 * returns true if an object is empty
 * @param {object} [object=null]
 * @returns {bool}
 */
export const isEmptyObject = (object = null) => {
  if (!object) return false
  if (object.constructor !== Object) return false
  return Object.keys(object).length === 0
}
