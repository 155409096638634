import React, { useContext, useState } from 'react'
import {
  Alert, Button, Card, Form
} from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import UserContext from '../../contexts/UserContext'
import { CHANGE_PASSWORD_MUTATION, MUTATE_PROFILE } from '../../operations/profile'

export default function Profile() {
  const allUserContext = useContext(UserContext)
  const { user: userContext, setUserState } = allUserContext
  const initialState = {
    id: userContext.id,
    firstName: userContext.first,
    lastName: userContext.last,
    email: userContext.email,
    updateProfileSuccess: false,
    previousPassword: '',
    proposedPassword: '',
    confirmPassword: '',
    changePasswordSuccess: false
  }
  const [formState, setFormState] = useState(initialState)
  const [errorState, setError] = useState(null)

  const [changePasswordMutation,
    {
      loading: changePasswordRequestLoading,
      error: changePasswordRequestError
    }
  ] = useMutation(CHANGE_PASSWORD_MUTATION, {
    variables: {
      previousPassword: formState.previousPassword,
      proposedPassword: formState.proposedPassword
    }
  })

  const [mutateProfile,
    {
      loading: updateProfileRequestLoading,
      error: updateProfileRequestError
    }
  ] = useMutation(MUTATE_PROFILE, {
    variables: {
      id: formState.id,
      first: formState.firstName,
      last: formState.lastName
    }
  })

  const validate = () => {
    let valid = true
    // validate passwords match
    valid = formState.proposedPassword === formState.confirmPassword
    if (!valid) {
      setError('Password fields do not match.')
      return valid
    }

    return valid
  }

  const handleChange = (value, field) => {
    setFormState({
      ...formState,
      [field]: value
    })
  }

  const onProfileFormSubmit = (e) => {
    e.preventDefault()
    mutateProfile()
      .then(({ data }) => {
        const { updateUser } = data
        setUserState({
          ...allUserContext,
          user: {
            ...allUserContext.user,
            ...updateUser.data
          }
        })
        handleChange(updateUser, 'updateProfileSuccess')
      })
  }

  const onPasswordFormSubmit = (e) => {
    e.preventDefault()
    setError(null)
    if (!validate()) {
      return
    }
    changePasswordMutation()
      .then(({ data }) => {
        setFormState({
          ...formState,
          changePasswordSuccess: data.changePassword,
          previousPassword: '',
          proposedPassword: '',
          confirmPassword: ''
        })
      })
  }

  return (
    <div className="Profile">
      <div className="lander">
        <h1>Your profile</h1>
        <Card className="mt-3 p-3">
        {(updateProfileRequestError) && (
          <div className="alert alert-danger">{updateProfileRequestError.message}</div>
        )}
          <Form className="col-md-6" onSubmit={onProfileFormSubmit}>
            <h4 className="text-center">
              Edit Profile
            </h4>
            <Form.Group controlId="formGroupFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={formState.firstName}
                onChange={(e) => handleChange(e.target.value, 'firstName')}
                required
              />
            </Form.Group>
            <Form.Group controlId="formGroupLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={formState.lastName}
                onChange={(e) => handleChange(e.target.value, 'lastName')}
                required
              />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={formState.email}
                onChange={(e) => handleChange(e.target.value, 'email')}
                readOnly
              />
            </Form.Group>
            {/* Show success alert */}
            {formState.updateProfileSuccess && (
              <>
                <Alert
                  dismissible
                  variant="success"
                  onClose={() => handleChange(false, 'updateProfileSuccess')}
                >
                  Profile successfully updated!
                </Alert>
              </>
            )}
            {!updateProfileRequestLoading && (
              <Button variant="primary" type="submit">
                Update Profile
              </Button>
            )}
          {updateProfileRequestLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
          </Form>
        </Card>
        <Card className="mt-3 p-3">
          <Form className="col-md-6" onSubmit={onPasswordFormSubmit}>
            <h4 className="text-center">
              Change Your Password
            </h4>
            {(changePasswordRequestError) && (
              <div className="alert alert-danger">{changePasswordRequestError.message}</div>
            )}
             {(errorState) && (
              <div className="alert alert-danger">{errorState}</div>
             )}
            <Form.Group controlId="formGroupUsername">
              <Form.Control
                type="text"
                value={userContext.email}
                required
                autoComplete="username"
                readOnly
                hidden
              />
            </Form.Group>
            <Form.Group controlId="formGroupPreviousPassword">
              <Form.Label>Previous Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Previous Password"
                name="password"
                value={formState.previousPassword}
                onChange={(e) => handleChange(e.target.value, 'previousPassword')}
                required
                autoComplete="current-password"
              />
            </Form.Group>
            <Form.Group controlId="formGroupNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                name="proposedPassword"
                value={formState.proposedPassword}
                onChange={(e) => handleChange(e.target.value, 'proposedPassword')}
                required
                autoComplete="new-password"
              />
            </Form.Group>
            <Form.Group controlId="formGroupConfirmNewPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm New Password"
                name="confirmPassword"
                value={formState.confirmPassword}
                onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
                required
                autoComplete="new-password"
              />
            </Form.Group>
            {formState.changePasswordSuccess && (
              <>
                <Alert
                  dismissible
                  variant="success"
                  onClose={() => handleChange(false, 'changePasswordSuccess')}
                >
                  Password successfully changed!
                </Alert>
              </>
            )}
            {!changePasswordRequestLoading && (
              <Button variant="primary" type="submit">
                Change Password
              </Button>
            )}
            {changePasswordRequestLoading && (
              <div className="alert alert-info">Submitting...</div>
            )}
          </Form>
        </Card>
      </div>
    </div>
  )
}
