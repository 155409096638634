import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGiftCard, faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import ShareToBrandLinks from './ShareToBrandLinks'

const DonationLinkDashboard = ({ donationUrl }) => (
  <div className="donation-card">
    <div className="link-and-icon-box">
      <a href={donationUrl} target="blank" className="link-box pr-3 link-ed-dark-blue">
        {donationUrl}
      </a>
      <a href={donationUrl} target="blank" className="icon-box">
        <FontAwesomeIcon className="icon external-link" icon={faExternalLink}/>
      </a>
    </div>
    <div className="lower-box">
      <FontAwesomeIcon icon={faGiftCard} size="lg" className="gift-icon"/>
      <div className="text-box">
        <p className="share-text">
          {'Share this unique link to your social media platforms to receive donations from your followers!'}
        </p>
        <ShareToBrandLinks donationUrl={donationUrl}/>
      </div>
    </div>
  </div>
)

DonationLinkDashboard.propTypes = {
  donationUrl: PropTypes.string.isRequired
}

export default DonationLinkDashboard
