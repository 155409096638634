import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { Form, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { handleUserResponse } from '../utils/authToken'

export const CHALLENGE_MUTATION = gql`
  mutation ChallengeMutation(
    $email: String!
    $newPassword: String!
    $session: String!
  ) {
    respondToNewPasswordChallenge(email: $email, newPassword: $newPassword, session: $session) {
      user {
        id
        first
        last
        email
        role {
          name
        }
      }
      authenticationResult {
        idToken
      }
      challengeResult {
        challengeName
        session
      }
    }
  }
`

const LoginChallengeForm = ({ session, email }) => {
  const initialState = {
    email,
    session,
    password: '',
    confirm_password: '',
    errorMessage: null
  }
  const [formState, setFormState] = useState(initialState)
  const [errorState, setError] = useState(null)
  const [challengeSession, setChallengeSession] = useState({ session })
  const history = useHistory()

  // we import our loginChallengeMutation here
  const [loginChallenge, { loading, error: mutationError }] = useMutation(CHALLENGE_MUTATION, {
    variables: {
      email: formState.email,
      newPassword: formState.password,
      session: challengeSession.session
    }
  })

  const validate = () => {
    let valid = true
    // validate password min length is 8
    valid = formState.password.length >= 8
    if (!valid) {
      setError('Your new password must be at least 8 characters in length.')
      return valid
    }

    // validate passwords match
    valid = formState.password === formState.confirm_password
    if (!valid) {
      setError('Password fields do not match.')
      return valid
    }

    return valid
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    setError(null)
    if (!validate()) {
      return
    }
    loginChallenge()
      .then(({ data }) => {
        const {
          user,
          authenticationResult,
          challengeResult
        } = data.respondToNewPasswordChallenge
        if (user && authenticationResult) {
          //  TODO: save only the auth token in local storage instead of auth token and user object
          //  TODO: set expiresAt
          handleUserResponse({
            user,
            authenticationResult
          })
          history.go(0)
        } else if (challengeResult) {
          //  we dont expect another challenge here, but just in case...
          setChallengeSession(challengeResult)
          setError(`another challenge presented: ${challengeResult.challengeName}`)
        }
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <div className="inner-body">
        <main className="form-signin border rounded bg-light p-5">
          <h4 className="text-center">
            Change your password
          </h4>
          {(errorState) && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          {(mutationError && !(loading || errorState)) && (
            <div className="alert alert-warning">{mutationError.message}</div>
          )}
          {loading && (
            <div className="alert alert-info">Loading...</div>
          )}
          <Form.Group className="form-floating" controlId="formGroupEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              name="email"
              value={formState.email}
              onChange={(e) => setFormState({
                ...formState,
                email: e.target.value
              })
              }
              required
              disabled
            />
            <Form.Label>Email address</Form.Label>
          </Form.Group>
          <Form.Group className="form-floating mb-0" controlId="formGroupPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={formState.password}
              onChange={(e) => setFormState({
                ...formState,
                password: e.target.value
              })
              }
              minLength="8"
              required
            />
            <Form.Label>New Password</Form.Label>
          </Form.Group>
          <Form.Group className="form-floating mt-0" controlId="formGroupConfirmPassword">
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              value={formState.confirm_password}
              onChange={(e) => setFormState({
                ...formState,
                confirm_password: e.target.value
              })
              }
              required
            />
            <Form.Label>Confirm Password</Form.Label>
          </Form.Group>
          <Button
            variant="primary"
            size="lg"
            className="w-100"
            type="submit"
          >
            Submit &amp; Login
          </Button>
          <div className="mt3">
            <Button
              variant="link"
              size="sm"
              className="w-100"
              href="/forgot-password"
            >
              Forgot your password?
            </Button>
          </div>
        </main>
      </div>
    </Form>
  )
}

LoginChallengeForm.propTypes = {
  session: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

export default LoginChallengeForm
