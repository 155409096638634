import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, ListGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Component for displaying a single row from react-data-table-component
 *
 * @param  {string} [title] - title block
 * @param  {string} [subTitle] - descriptive text block under the title
 * @param  {bool} [bodyText] - body text block under the colData
 * @param  {array} [colData] - array of {"label":"value"} objects
 * @param  {object} [footer=<div></div>] - footer element
 * @param  {string} [editAction=""] - url to edit action, toggles edit button visibility under the footer element
 * @param  {function} [deleteAction=undefined] - function to call on delete, toggles delete button visibility under the footer element
 * @param  {string} [deleteLabel="Delete"] - Button label for delete action
 * @param  {function} [customAction] - custom function
 * @param  {object} [children=<div></div>] - children element, displayed above footer
 */
const GlobalDataTableRowView = ({
  title,
  subTitle,
  bodyText,
  colData,
  footer,
  editAction,
  deleteAction,
  deleteLabel = 'Delete',
  customAction,
  customActionLabel,
  customActionClassName = 'custom-action-btn',
  children
}) => (
  <div>
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {subTitle && (
          <Card.Subtitle>{subTitle}</Card.Subtitle>
        )}
        {colData && (
          <ListGroup variant="flush" className="py-3">
            {colData.map((d, i) => !!d.value && !d.omit && (<ListGroup.Item key={i} className="pl-0 text-dark">
                {d.label && (
                  <>
                    {d.icon && (
                      <FontAwesomeIcon
                        icon={d.icon}
                        className="mr-2"
                      />
                    )}
                    {d.label}{' '}
                  </>
                )}
                {d.value}
              </ListGroup.Item>))
            }
          </ListGroup>)
        }
        {bodyText && (
          <Card.Text>
            {bodyText}
          </Card.Text>
        )}
        {children}
      </Card.Body>
      {footer}
    </Card>
    <div className="modal-actions mt-3">
      {editAction && (
        <Button
          variant="primary"
          href={editAction}
          className="mr-1"
          data-testid="data-view-edit-btn"
        >
          Edit
        </Button>
      )}
      {deleteAction && (
        <Button
          variant="outline-danger"
          onClick={deleteAction}
          className="ml-1 delete-button-light"
          data-testid="data-view-delete-btn"
        >
          {deleteLabel}
        </Button>
      )}
      {customAction && customActionLabel && (
        <Button
          variant='outline-dark'
          className={customActionClassName}
          onClick={customAction}
          data-testid="data-view-custom-action-btn"
        >
          {customActionLabel}
        </Button>
      )}
    </div>
  </div>
)

GlobalDataTableRowView.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  colData: PropTypes.array,
  bodyText: PropTypes.string,
  footer: PropTypes.any,
  editAction: PropTypes.string,
  deleteAction: PropTypes.func,
  deleteLabel: PropTypes.string,
  customAction: PropTypes.func,
  customActionLabel: PropTypes.string,
  customActionClassName: PropTypes.string,
  children: PropTypes.node
}

GlobalDataTableRowView.defaultProps = {
  footer: <div></div>,
  children: <div></div>
}

export default GlobalDataTableRowView
