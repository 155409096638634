import { gql } from '@apollo/client'

// queries
export const QUERY_CURRENT_SCHOOL_YEAR_NAME = gql`
  query {
    schoolYear {
      id
      name
    }
  }
`
export const QUERY_CURRENT_SCHOOL_YEAR_START_DATE = gql`
  query {
    schoolYear {
      id
      startDate
    }
  }
`

export const QUERY_CURRENT_SCHOOL_YEAR_END_DATES = gql`
  query {
    schoolYear {
      id
      startDate
      endDate
      yearEndOpen
      yearEndClose
    }
  }
`

export const QUERY_SCHOOL_YEARS = gql`
  query {
    schoolYears {
      id
      name
      startDate
      endDate
      yearEndOpen
      yearEndClose
    }
  }
`
