import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilePdf,
  faFileExcel,
  faCog
} from '@fortawesome/pro-light-svg-icons'
import { reportFormats } from '../constants'

/**
 * renders export buttons
 * @param {any} [reportKey=''] - report identifier
 * @param {any} [onClick] - click event handler
 * @param {string} [className="bg-muted border-muted"] - appends to Button className
 * @param {bool} [disabled=false] - disables the button
 * @param {bool} [enablePDF=true] - show or hide the PDF action
 * @param {bool} [enableXLSX=true] - show or hide the XLSX action
 */
const ExportActionButtons = ({
  reportKey = '',
  onClick = () => {},
  className = 'bg-muted border-muted',
  disabled = false,
  enablePDF = true,
  enableXLSX = true,
  isLoading = false
}) => {
  const exportActions = [
    {
      format: reportFormats.PDF,
      icon: faFilePdf,
      omit: !enablePDF
    },
    {
      format: reportFormats.XLSX,
      icon: faFileExcel,
      omit: !enableXLSX
    }
  ]

  return exportActions
    .filter((action) => !action.omit)
    .map((action) => (
      <Button
        key={`action-btn-${action.format}`}
        className={`btn btn-sm text-light text-uppercase mr-2 ${className}`}
        onClick={(e) => {
          if (isLoading) return
          e.preventDefault()
          onClick(action.format, reportKey)
        }}
        disabled={disabled}
      >
        {action.format}{' '}
        <FontAwesomeIcon
          icon={isLoading ? faCog : action.icon}
          className={isLoading ? 'fa-spin' : ''}
        />
      </Button>
    ))
}

ExportActionButtons.propTypes = {
  reportKey: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default ExportActionButtons
