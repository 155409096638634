import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import moment from 'moment'
import { transactionTypeIds } from '../../constants'

/**
 * Sub-component rendered by TransactionForm when the transaction type
 * is anything other than Student Transaction
 * @param {boolean} isAdmin true when the user is admin or super_admin
 * @param {boolean} isEdit true when the transaction is being edited, false when being created
 * @param {number} transactionTypeId id of the transaction type
 * @param {boolean} isApproved true when the transaction's approved attribute is set to true
 * @param {string|number} totalAmount amount of transaction
 * @param {string} startDate start date of school year in format 'YYYY-MM-DD'
 * @param {string} transactionDate date of transaction in format 'YYYY-MM-DD'
 * @param {string} donor name of the donor for a donation transaction, or ''
 * @param {string} checkNumber check number for a donation, or ''
 * @param {string} notes notes for the transaction, or ''
 * @param {function} handleChange function for handling a change in form state
 * @returns {any}
 */
const FinancialTransactionForm = ({
  isAdmin,
  isEdit,
  transactionTypeId,
  isApproved,
  totalAmount,
  startDate,
  transactionDate,
  donor,
  checkNumber,
  notes,
  handleChange
}) => (
  <>
    <p className="text-ed-warm-gray-200">
      <em>
        {'Enter the amount and other details of the transaction below.'}
        <br/>
        <span className="text-red">*</span>
        {' marks a required field.'}
      </em>
    </p>
    <h5 className="border-bottom mb-3">Transaction Details</h5>
    <Form.Group controlId="formGroupAmount" className="short-field">
      <Form.Label>Amount <span className="text-red">*</span></Form.Label>
      <Form.Control
        type="number"
        step="any"
        value={totalAmount || ''}
        min={transactionTypeId === transactionTypeIds.ADJUSTMENT ? 'false' : 0.01}
        onKeyDown={(e) => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
        onChange={(e) => handleChange(e, 'totalAmount', parseFloat(e.target.value))}
        placeholder='Enter dollar amount...'
        required
        data-testid="financial-transaction-form-amount"
      />
    </Form.Group>
    <Form.Group controlId="formGroupDate" className="short-field">
      <Form.Label>Date of Transaction <span className="text-red">*</span></Form.Label>
      <Form.Control
        type="date"
        value={transactionDate}
        max={moment().format('YYYY-MM-DD')}
        min={startDate}
        onChange={(e) => handleChange(e, 'transactionDate')}
        required
        data-testid="financial-transaction-form-date"
      />
    </Form.Group>
    <Form.Group controlId="formGroupDonor" className="short-field">
      <Form.Label>Donor Name</Form.Label>
      <Form.Control
        type="text"
        value={donor}
        onChange={(e) => handleChange(e, 'donor')}
        data-testid="financial-transaction-form-donor-name"
      />
    </Form.Group>
    <Form.Group controlId="formGroupCheckNumber" className="short-field">
      <Form.Label>Check Number</Form.Label>
      <Form.Control
        type="text"
        value={checkNumber}
        onChange={(e) => handleChange(e, 'checkNumber')}
        data-testid="financial-transaction-form-check-number"
      />
    </Form.Group>
    <Form.Group controlId="formGroupNotes">
      <Form.Label>Notes</Form.Label>
      <Form.Control
        value={notes}
        as="textarea"
        rows={2}
        onChange={(e) => handleChange(e, 'notes')}
        data-testid="financial-transaction-form-notes"
      />
    </Form.Group>
    {(isAdmin && isEdit && [
      transactionTypeIds.MATCHING,
      transactionTypeIds.SEED_FUNDING
    ].includes(transactionTypeId)) && (
      <Form.Check
        type="checkbox"
        label="Approved"
        checked={isApproved}
        onChange={(e) => handleChange(null, 'approved', e.target.checked)}
      />
    )}
  </>
)

FinancialTransactionForm.propTypes = {
  isEdit: PropTypes.bool,
  isAdmin: PropTypes.bool,
  transactionTypeId: PropTypes.number,
  isApproved: PropTypes.bool,
  totalAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  startDate: PropTypes.string,
  transactionDate: PropTypes.string,
  donor: PropTypes.string,
  checkNumber: PropTypes.string,
  notes: PropTypes.string,
  handleChange: PropTypes.func
}

export default FinancialTransactionForm
