import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  useQuery,
  NetworkStatus,
  useMutation
} from '@apollo/client'
import GlobalDataTable from '../../components/GlobalDataTable'
import GlobalModal from '../../components/GlobalModal'
import GlobalDataTableRowView from '../../components/GlobalDataTableRowView'
import StudentInfoTransactions from '../../components/StudentInfoTransactions'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserContext from '../../contexts/UserContext'
import SchoolDropdown from '../../components/SchoolDropdown'
import { isUserAdmin, isUserDistrict, renderStatus } from '../../utils/lib'
import { GET_STUDENTS_DIRECTORY_BY_SCHOOL, UNENROLL_STUDENT } from '../../operations/students'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'

const StudentDirectory = () => {
  // hooks
  const {
    user: userContext,
    session,
    setCurrentSchoolAndDistrict
  } = useContext(UserContext)
  const [dataStudents, setDataStudents] = useState([])
  const [selectedStudent, setSelectedStudent] = useState(null)
  const { userSchools } = userContext
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const { currentSchool } = session
  const [unenrollStudentError, setUnenrollStudentError] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // * queries
  const {
    loading,
    error,
    data: studentData,
    refetch,
    networkStatus
  } = useQuery(GET_STUDENTS_DIRECTORY_BY_SCHOOL, {
    variables: {
      schoolIds: currentSchool
        ? [currentSchool.id]
        : []
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      const [dataSchool] = data.schools
      if (dataSchool) {
        // * FUTURE filter out nulls and sort on the server side
        const students = dataSchool.students
          .filter((aStudent) => aStudent)
          .sort((a, b) => a.last.localeCompare(b.last))
        setDataStudents(students)
      }
    }
  })

  // * mutations
  // delete a student
  const [unenrollStudent, {
    loading: unenrollStudentLoading
  }] = useMutation(UNENROLL_STUDENT, {
    variables: {
      id: selectedStudent?.id,
      schoolId: currentSchool?.id
    },
    onCompleted: () => {
      setConfirmModalOpen(false)
      setSelectedStudent(null)
      refetch()
    }
  })

  // event listeners
  const handleSchoolChange = (value, entity) => {
    if (entity === 'all') {
      setCurrentSchoolAndDistrict({}, {})
      setDataStudents(null)
    }
    if (entity === 'school') {
      setCurrentSchoolAndDistrict(value, {})
      refetch({
        variables: {
          schoolIds: [value.id]
        }
      })
    }
  }
  const rowClick = (s) => {
    setSelectedStudent(s)
  }
  const modalClose = () => {
    setSelectedStudent(null)
  }
  const handleUnenroll = () => {
    unenrollStudent()
      .catch((err) => {
        setUnenrollStudentError(err.message)
      })
  }
  const unenrollModalClose = () => {
    setConfirmModalOpen(false)
    setUnenrollStudentError(false)
  }

  const columns = [
    {
      name: 'SPACE ID',
      maxWidth: '115px',
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: 'Last Name',
      selector: (row) => row.last,
      sortable: true
    },
    {
      name: 'First Name',
      selector: (row) => row.first,
      sortable: true
    },
    {
      name: 'Gender',
      selector: (row) => row.gender.name,
      sortable: true
    },
    {
      name: 'Ethnicity',
      selector: (row) => row.ethnicity.name,
      sortable: true
    },
    {
      name: 'Grade Level',
      selector: (row) => row.grade.name,
      sortable: true
    },
    {
      name: 'Status',
      selector: (row) => renderStatus({
        active: row.active,
        truthyLabel: 'enrolled',
        falsyLabel: 'Unenrolled'
      }),
      sortable: true
    },
    {
      name: '',
      button: true,
      cell: function ActionCell(row) {
        return <Button
          variant="link text-dark"
          size="sm"
          href={`/students/edit/${row.id}`}
        >
          Edit
        </Button>
      },
      omit: isAdmin
    }
  ]

  if (loading) return <LoadingSpinner message="Loading..." />
  if (networkStatus === NetworkStatus.refetch) return <LoadingSpinner message="Refetching..." />
  if (error) return (<p>Error: {error.message}</p>)

  return (
    <>
      <div className="Home">
        <div className="lander">
          {/* TODO build this feature */}
          {/* <Button variant="light" className="float-right ml-2" href="/students/upload">
            Roster Upload
          </Button> */}
          {!isAdmin && (
            <Button variant="primary" className="float-right" href="/students/new">
              + Add New Student
            </Button>
          )}
          <h3 className="page-title border-bottom pb-5">
            <label className="float-left pr-3">
              Student Directory
            </label>
            <SchoolDropdown
              title={(currentSchool?.name || 'Select a school...')}
              visible={isAdmin || isDistrict || (userSchools.length > 1)}
              schoolsOnly={true}
              appendClassName="float-left"
              handleItemClick={handleSchoolChange}
              viewAllItemVisible={false}
            />
          </h3>
        </div>
        <div className="m-3">
          {/* Prevent the data table from rendering before the data is ready */}
          {!!studentData && (
            <GlobalDataTable
              columns={columns}
              data={dataStudents || []}
              filterHandler
              pagination
              subHeader
              selectableRows={false}
              handleRowClick={rowClick}
              noDataComponent={<div className="p-3">
                {!currentSchool && (
                  'Select a school from the dropdown above to view the student directory'
                )}
                {currentSchool && (
                  'There are no students to display'
                )}
              </div>}
            />
          )}
        </div>
      </div>
      {selectedStudent && (
        <GlobalModal
          title="Student Information"
          onClose={modalClose}
        >
          <>
            <GlobalDataTableRowView
              title={isAdmin
                ? '*********** **************'
                : `${selectedStudent.first} ${selectedStudent.last}`}
              subTitle={`SPACE ID# ${selectedStudent.id}`}
              colData = {[
                {
                  label: 'School:',
                  value: selectedStudent.school.name
                },
                {
                  label: 'Grade:',
                  value: selectedStudent.grade.name
                },
                {
                  label: 'Gender:',
                  value: selectedStudent.gender.name
                },
                {
                  label: 'Ethnicity:',
                  value: selectedStudent.ethnicity.name
                },
                {
                  label: 'Status:',
                  value: renderStatus({
                    active: selectedStudent.active,
                    truthyLabel: 'enrolled',
                    falsyLabel: 'unenrolled'
                  })
                }
              ]}
              editAction={isAdmin
                ? null
                : `/students/edit/${selectedStudent.id}`}
              deleteAction={isAdmin
                ? null
                : () => setConfirmModalOpen(true)
              }
              deleteLabel='Unenroll'
            ></GlobalDataTableRowView>
            <StudentInfoTransactions
              transactions={selectedStudent.studentTransactions}
            />
          </>
        </GlobalModal>
      )}

      {/* Confirmation modal for deleting a staff */}
      <DeleteConfirmationModal
        showModal={confirmModalOpen}
        title={'Are you sure you want to unenroll this student?'}
        body={'Unenrolling a student cannot be undone. Are you sure you want to proceed?'}
        deleteError={Boolean(unenrollStudentError)}
        errorTitle={'Something went wrong.'}
        errorBody={'Please refresh the page and try again. If the problem persists, please contact us.'}
        deleteLoading={Boolean(unenrollStudentLoading)}
        deleteModalClose={unenrollModalClose}
        deleteButtonLabel="Unenroll"
        handleDelete={handleUnenroll}
      />
    </>
  )
}

export default StudentDirectory
