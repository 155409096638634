import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import LoadingSpinner from '../../components/LoadingSpinner'
import { CREATE_ADMIN, MUTATE_ADMIN, QUERY_ADMIN } from '../../operations/admin'

const PersonnelForm = () => {
  const history = useHistory()
  const { userId } = useParams()
  const isEditMode = !!userId
  const [errorState, setErrorState] = useState('')
  const intUserId = parseInt(userId, 10)
  const initialState = {
    id: intUserId || userId,
    first: '',
    last: '',
    email: '',
    roleId: 0,
    isSuper: false,
    updatedById: 0,
    updatedAt: 0,
    createdById: null,
    createdAt: null,
    active: false
  }
  const [formState, setFormState] = useState(initialState)
  const { loading, error } = useQuery(QUERY_ADMIN, {
    variables: { id: intUserId },
    skip: !isEditMode,
    onCompleted: (({ user }) => {
      setFormState({
        ...formState,
        id: user.id,
        first: user.first,
        last: user.last,
        email: user.email,
        roleId: user.role.id,
        isSuper: user.role.id === 2,
        createdById: user.createdBy?.id,
        createdAt: user.createdAt,
        active: user.active
      })
    })
  })
  // mutations
  const baseMutationVariables = {
    id: formState.id,
    first: formState.first,
    last: formState.last,
    roleId: formState.isSuper
      ? 2
      : 1,
    email: formState.email,
    active: isEditMode
      ? formState.active
      : undefined
  }
  const [mutateAdmin, {
    loading: mutationLoading
  }] = useMutation(isEditMode
    ? MUTATE_ADMIN
    : CREATE_ADMIN, {
    variables: {
      ...baseMutationVariables
    },
    onCompleted: ((data) => {
      history.push(data.id
        ? `/admin/personnel/${data.id}`
        : '/admin/personnel')
    })
  })

  if (loading) return <LoadingSpinner message="Loading..." />
  if (error) return <div className="alert alert-danger">{error.message}</div>

  const onFormSubmit = (e) => {
    e.preventDefault()
    mutateAdmin()
      .catch((err) => {
        setErrorState(err.message)
      })
  }

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {isEditMode
            ? 'Edit InvestED Personnel'
            : 'Add New InvestED Personnel'
          }
        </h3>
        <Link to="/admin/personnel" className="text-secondary">
          &lt; back to personnel page
        </Link>
      </div>
      <Card className="mt-3 p-3">
        {(errorState) && (
          <div className="alert alert-danger">{errorState}</div>
        )}
        <Form className="col-md-6" onSubmit={onFormSubmit}>
          <Form.Group controlId="formGroupFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formState.first}
              onChange={(e) => setFormState({
                ...formState,
                first: e.target.value
              })}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={formState.last}
              onChange={(e) => setFormState({
                ...formState,
                last: e.target.value
              })}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={formState.email}
              onChange={(e) => setFormState({
                ...formState,
                email: e.target.value
              })}
              role="checkbox"
              required
            />
          </Form.Group>
          {isEditMode && (
            <Form.Check
              type="checkbox"
              label="Active"
              role="checkbox"
              checked={formState.active}
              onChange={(e) => setFormState({
                ...formState,
                active: e.target.checked
              })}
            />
          )}
          <div className="d-flex">
            <Form.Check
              type="checkbox"
              label="Is Super Admin?"
              className="mr-3"
              checked={formState.isSuper}
              onChange={() => setFormState({
                ...formState,
                isSuper: !formState.isSuper
              })}
            />
            <OverlayTrigger
              overlay={<Tooltip id="tooltip">
                {'Both Admins and Super Admins can do everything within the InvestEd Application, '}
                {'however, only Super Admins can create, update and delete more Admins and Super Admins.'}
              </Tooltip>}
            >
              <FontAwesomeIcon icon={faInfoCircle} size={'lg'}></FontAwesomeIcon>
            </OverlayTrigger>
          </div>
          <hr />
          {!mutationLoading && (
            <>
              <Button variant="primary" type="submit">
                Submit
              </Button>{' '}
              <Button variant="secondary" className="text-light" href="/admin/personnel">
                Cancel
              </Button>
            </>
          )}
          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

export default PersonnelForm
