/**
 * takes an error thrown by a graphQL mutation or query and digs through to find it inner exception and returns it as an error
 * @param {Error} [caughtError=new Error('Apollo helper default error.')] - an error thrown by graphQL server
 * @returns {Error}
 */
// eslint-disable-next-line import/prefer-default-export
export const apolloErrorExceptionParser = ({
  error = new Error('Apollo helper default error.')
}) => {
  let newError = error

  const [graphQLError] = error.graphQLErrors
  if (graphQLError?.extensions?.exception) {
    const {
      code,
      message
    } = graphQLError.extensions.exception
    if (code && message) {
      newError = new Error(message)
      newError.code = code
    }
  }

  return newError
}
