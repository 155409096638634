import React, {
  useContext,
  useState,
  useEffect
} from 'react'
import { Button } from 'react-bootstrap'
import {
  useLazyQuery,
  useQuery,
  useMutation,
  NetworkStatus
} from '@apollo/client'
import { faSchool } from '@fortawesome/pro-light-svg-icons'
import GlobalDataTable from '../../components/GlobalDataTable'
import GlobalModal from '../../components/GlobalModal'
import GlobalDataTableRowView from '../../components/GlobalDataTableRowView'
import ClipboardText from '../../components/ClipboardText'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserContext from '../../contexts/UserContext'
import {
  isUserAdmin,
  isUserDistrict,
  renderStatus
} from '../../utils/lib'
import SchoolDropdown from '../../components/SchoolDropdown'
import {
  GET_STAFFS,
  GET_USER_STAFFS,
  DEACTIVATE_STAFF,
  RESEND_INVITATION
} from '../../operations/staff'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import { apolloErrorExceptionParser } from '../../utils/apolloHelpers'

const Staff = () => {
  const {
    user: userContext,
    session,
    setCurrentSchoolAndDistrict
  } = useContext(UserContext)
  const [dataUsers, setDataUsers] = useState([])
  const [selectedStaff, setSelectedStaff] = useState(null)
  const { userSchools, userDistricts } = userContext
  const { currentSchool, currentDistrict } = session
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const schoolIdArray = userSchools?.map((s) => s.school.id)
  const districtIdArray = userDistricts?.map((d) => d.district.id)
  const isSchoolOrDistrictSelected = !!(currentSchool?.id || currentDistrict?.id)
  const [deactivateStaffError, setDeactivateStaffError] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [resendInvitationResponse, setResendInvitationResponse] = useState(null)

  // sets data users from returned query data for either district or school users
  const setDataUsersState = (schools, districts) => {
    const [school] = schools
    const [district] = districts
    if (school) {
      const schoolUsers = school.userSchools

      setDataUsers(
        schoolUsers
          // filer out nulls, and inactive if not admin
          .filter((schoolUser) => (
            schoolUser && schoolUser.user && !(!isAdmin && !schoolUser.user.active)
          ))
          .map((schoolUser) => {
            const { user = {} } = schoolUser
            const { userSchools: schoolUserSchools } = user
            const userWithSortedSchools = {
              ...user,
              userSchools: [
                schoolUserSchools.find(({ school: { id } }) => id === currentSchool.id),
                ...schoolUserSchools.filter(({ school: { id } }) => id !== currentSchool.id)
              ],
              userDistricts: []
            }
            return userWithSortedSchools
          })
      )
    }
    if (district) {
      setDataUsers(district.userDistricts
        // filer out nulls, and inactive if not admin
        .filter((userDistrict) => (
          userDistrict && userDistrict.user && !(!isAdmin && !userDistrict.user.active)
        ))
        .map((userDistrict) => {
          const { user } = userDistrict
          return ({
            ...(user || {}),
            userDistricts: [
              {
                district: {
                  id: district.id,
                  name: district.name
                },
                schoolPosition: userDistrict?.schoolPosition || {}
              }
            ],
            userSchools: []
          })
        }))
    }
  }

  // * queries
  // lazy query for when a school or district is selected
  const [
    lazyStaff, {
      loading: lazyLoading,
      error: lazyError,
      data: lazyData
    }] = useLazyQuery(GET_USER_STAFFS, {
    errorPolicy: 'ignore',
    onCompleted: ({ schools, districts }) => {
      setDataUsersState(schools, districts)
    },
    fetchPolicy: 'network-only'
  })

  // query for when no school or district is selected and we are on first load
  const {
    loading,
    error,
    data: staffData,
    refetch,
    networkStatus
  } = useQuery(isAdmin
    ? GET_STAFFS
    : GET_USER_STAFFS, {
    errorPolicy: 'ignore',
    variables: isAdmin
      ? {
        roles: [3]
      }
      : {
        schoolIds: schoolIdArray,
        districtId: districtIdArray.find((districtId) => districtId) || -1
      },
    skip: isSchoolOrDistrictSelected,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.users) {
        // * FUTURE filter out nulls and sort on the server side
        // load users to data table
        const notNullUsers = data.users
          .filter((user) => user)
          .sort((a, b) => a.last.localeCompare(b.last))
        setDataUsers(notNullUsers)
      } else if (data.schools) {
        setDataUsersState(data.schools, data.districts)
      }
    }
  })

  // refetch handler to determine which of the above queries gets called or refetched again
  const handleRefetch = () => {
    if (isSchoolOrDistrictSelected) {
      lazyStaff({
        variables: {
          districtId: currentDistrict?.id || -1,
          schoolIds: [currentSchool?.id || undefined]
        }
      })
    } else {
      refetch()
    }
  }

  // * mutations
  // deactivate a staff
  const [deactivateStaff, {
    loading: deactivateStaffLoading
  }] = useMutation(DEACTIVATE_STAFF, {
    variables: {
      id: selectedStaff?.id
    },
    onCompleted: () => {
      setConfirmModalOpen(false)
      setSelectedStaff(null)
      refetch()
    }
  })

  // resend invitation mutation
  const [resendInvitation, {
    loading: resendInvitationLoading
  }] = useMutation(RESEND_INVITATION, {
    variables: {
      email: selectedStaff?.email
    }
  })

  // event listeners
  const handleEntityChange = (value, entity) => {
    const selectedSchool = entity === 'school'
      ? value
      : {}
    const selectedDistrict = entity === 'district'
      ? value
      : {}
    setCurrentSchoolAndDistrict(selectedSchool, selectedDistrict)
  }
  const rowClick = (s) => {
    setSelectedStaff(s)
  }
  const staffModalClose = () => {
    setSelectedStaff(null)
    setResendInvitationResponse(null)
  }

  const handleDeactivate = () => {
    deactivateStaff()
      .then(() => {
        handleRefetch()
      })
      .catch((err) => {
        setDeactivateStaffError(err.message)
      })
  }
  const deactivateModalClose = () => {
    setConfirmModalOpen(false)
    setDeactivateStaffError(false)
  }

  useEffect(() => {
    handleRefetch()
  }, [
    currentSchool,
    currentDistrict
  ])

  // render stuff
  if (loading || lazyLoading) return <LoadingSpinner message="Loading..." />
  if (networkStatus === NetworkStatus.refetch) return <LoadingSpinner message="Refetching..." />

  const columns = [
    {
      name: 'SPACE ID',
      maxWidth: '115px',
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: 'Name',
      selector: (row) => row.last,
      sortable: true,
      cell: function NameCell(row) {
        return (`${row.last}, ${row.first}`)
      }
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      cell: function EmailCell(row) {
        return <div data-tag="allowRowEvents">
          <ClipboardText
            text={row.email}
          />
        </div>
      }
    },
    {
      name: 'School / District',
      selector: (row) => {
        const [userSchool] = row.userSchools
        const [userDistrict] = row.userDistricts
        return userSchool?.school?.name || userDistrict?.district?.name || ''
      },
      sortable: true,
      cell: function SchoolCell(row) {
        const [userSchool] = row.userSchools
        const [userDistrict] = row.userDistricts
        return userSchool?.school?.name || userDistrict?.district?.name || ''
      }
    },
    {
      name: 'Position',
      selector: (row) => {
        const [userSchool] = row.userSchools
        const [userDistrict] = row.userDistricts
        return userSchool?.schoolPosition?.name || userDistrict?.schoolPosition?.name || ''
      },
      sortable: true,
      cell: function PositionCell(row) {
        const [userSchool] = row.userSchools
        const [userDistrict] = row.userDistricts
        return userSchool?.schoolPosition?.name || userDistrict?.schoolPosition?.name || ''
      }
    },
    {
      name: 'InvestED Role',
      selector: (row) => row.investedRole?.name,
      sortable: true
    },
    {
      name: 'Status',
      maxWidth: '75px',
      selector: (row) => row.active,
      sortable: true,
      omit: !isAdmin,
      cell: (row) => renderStatus({ active: row.active })
    },
    {
      name: '',
      button: true,
      cell: function ActionCell(row) {
        return (
          <Button
            variant="link text-dark"
            size="sm"
            href={row.id === userContext.id ? '/profile' : `/staff/edit/${row.id}`}
            data-testid='edit-button'
          >
            Edit
          </Button>
        )
      }
    }
  ]

  // custom action props builder for passing deconstructed into the GlobalDataTableRowView component
  const customActionPropsBuilder = () => {
    let customActionLabel = null
    let customActionClassName
    let customAction = null

    if (isAdmin) {
      customActionLabel = 'Resend Invitation Email'
      customAction = () => {
        resendInvitation()
          .then(() => {
            setResendInvitationResponse({
              success: true
            })
          })
          .catch((resendError) => {
            const graphQLError = apolloErrorExceptionParser({
              error: resendError
            })

            let errorMessage = graphQLError.message
            if (graphQLError.code === 'UnsupportedUserStateException') {
              errorMessage = 'This user\'s personal password has already been set, so we cannot resend the invitation email.'
            }

            setResendInvitationResponse({
              success: false,
              message: errorMessage
            })
          })
      }

      if (resendInvitationLoading) {
        customActionLabel = 'loading'
        customActionClassName = 'custom-action-btn disabled'
        customAction = () => {}
      }
    }

    return {
      customActionLabel,
      customActionClassName,
      customAction
    }
  }

  return (
    <>
      <div className="Home">
        <div className="lander">
          <Button variant="primary" className="float-right" href="/staff/new">
            + Add Staff
          </Button>
          <h3 className="page-title border-bottom pb-5">
            <label className="float-left pr-3">
              Staff Directory
            </label>
            <SchoolDropdown
              title={((currentSchool || currentDistrict)?.name || 'Select a school or district')}
              visible={((isAdmin || isDistrict) || userSchools.concat(userDistricts).length > 1)}
              appendClassName="float-left"
              handleItemClick={handleEntityChange}
            />
          </h3>
        </div>
        <div className="m-3">
          {(lazyError || error) && (
            <p>Error: {(lazyError || error).message}</p>
          )}
          {/* Prevent the data table from rendering before the data is ready */}
          {!!(lazyData || staffData) && (
            <GlobalDataTable
              columns={columns}
              data={dataUsers}
              filterHandler
              pagination
              subHeader
              selectableRows={false}
              handleRowClick={rowClick}
              defaultSortField="last"
            />
          )}
        </div>
      </div>
      {selectedStaff && (
        <GlobalModal
          title="Staff Information"
          onClose={staffModalClose}
        >
          <GlobalDataTableRowView
            title={`${selectedStaff.first} ${selectedStaff.last}`}
            subTitle={`ID# ${selectedStaff.id}`}
            colData = {[
              {
                label: 'Status:',
                value: <>
                  {renderStatus({ active: selectedStaff.active })}
                </>,
                omit: !isAdmin
              },
              {
                label: 'Email:',
                value: <ClipboardText
                    text={selectedStaff.email}
                  />
              },
              {
                label: 'Phone:',
                value: selectedStaff.phone
              },
              {
                label: 'Schools:',
                icon: faSchool,
                value: selectedStaff.userSchools
                  .map((s, i) => (
                    <div
                      key={i}
                      className={(i % 2 === 1)
                        ? 'bg-faded-pale-yellow'
                        : ''
                      }
                    >
                      <a
                        href={`/schools/${s.school.id}`}
                        className="font-weight-bold text-dark d-block"
                      >
                        {s.school?.name}
                      </a>
                      {s.schoolPosition?.name}
                    </div>
                  )),
                omit: selectedStaff.userSchools.length === 0
              },
              {
                label: 'Districts:',
                icon: faSchool,
                value: selectedStaff.userDistricts
                  .filter((district) => district)
                  .map((d, i) => (
                    <div
                      key={i}
                      className={(i % 2 === 1)
                        ? 'bg-faded-pale-yellow'
                        : ''
                      }
                    >
                      <a
                        href={`/schools/districts/${d.district.id}`}
                        className="font-weight-bold text-dark d-block"
                      >
                        {d.district?.name}
                      </a>
                      {d.schoolPosition?.name}
                    </div>
                  )),
                omit: selectedStaff.userDistricts.length === 0
              },
              {
                label: 'InvestED Role:',
                value: selectedStaff.investedRole?.name
              },
              {
                // this block will render the response from the "resend invitation" mutation
                label: '',
                value: (() => {
                  let resendMessage = 'Something went wrong with sending the invitation.'
                  let resendAlertBg = 'danger'
                  if (resendInvitationResponse?.success) {
                    resendAlertBg = 'success'
                    resendMessage = `The user will receive a new temporary password at ${selectedStaff.email}, and be forced to change the password upon logging in. This temporary password will expire in 7 days.`
                  }
                  if (resendInvitationResponse?.message) {
                    resendMessage += ` ${resendInvitationResponse.message}`
                  }
                  return <div className={`alert alert-${resendAlertBg}`}>
                    {resendMessage}
                  </div>
                })(),
                omit: resendInvitationLoading || !resendInvitationResponse
              }
            ]}
            // one can edit one's self, but only at one's profile
            editAction={userContext.id === selectedStaff.id
              ? '/profile'
              : `/staff/edit/${selectedStaff.id}`
            }
            deleteAction={(() => {
              // one cannot deactivate one's self
              // cannot deactivate if already deactivated
              if (
                userContext.id !== selectedStaff.id
                && selectedStaff.active
              ) {
                return () => setConfirmModalOpen(true)
              }

              return null
            })()}
            deleteLabel="Deactivate"
            {...customActionPropsBuilder()}
          ></GlobalDataTableRowView>
        </GlobalModal>
      )}

      {/* Confirmation modal for deleting a staff */}
      <DeleteConfirmationModal
        showModal={confirmModalOpen}
        title={'Are you sure you want to deactivate this staff member?'}
        body={'Deactivating a staff member will prevent them from logging in. Are you sure you want to proceed?'}
        deleteError={Boolean(deactivateStaffError)}
        errorTitle={'Something went wrong.'}
        errorBody={'Please refresh the page and try again. If the problem persists, please contact us.'}
        deleteLoading={Boolean(deactivateStaffLoading)}
        deleteModalClose={deactivateModalClose}
        deleteButtonLabel="Deactivate"
        handleDelete={handleDeactivate}
      />
    </>
  )
}

export default Staff
