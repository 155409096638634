import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Form, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { handleUserResponse } from '../utils/authToken'
import LoginChallengeForm from './LoginChallengeForm'

export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String!
    $password: String!
  ) {
    login(email: $email, password: $password) {
      user {
        id
        first
        last
        email
        role {
          name
        }
      }
      authenticationResult {
        idToken
      }
      challengeResult {
        challengeName
        session
      }
    }
  }
`

const Login = () => {
  const initialState = {
    email: '',
    password: '',
    isSubmitting: false,
    errorMessage: null
  }
  const [formState, setFormState] = useState(initialState)
  const [challengeState, setChallengeState] = useState(null)
  const [errorState, setError] = useState()
  const history = useHistory()

  // we import our loginMutation here
  const [login, { loading, error: mutationError }] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: formState.email,
      password: formState.password
    }
  })

  const onFormSubmit = (e) => {
    e.preventDefault()
    setError()
    login()
      .then(({ data }) => {
        // console.log('login then', data.login)
        if (data.login.user && data.login.authenticationResult) {
          //  TODO: save only the auth token in local storage instead of auth token and user object
          //  TODO: set expiresAt
          handleUserResponse(data.login)
          history.go(0)
        } else if (data.login.challengeResult) {
          setChallengeState({
            ...data.login.challengeResult
          })
        }
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  if (challengeState) {
    return <LoginChallengeForm
      email={formState.email}
      session={challengeState.session}
    />
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <div className="inner-body">
        <main className="form-signin border rounded bg-light p-5">
          <h4 className="text-center">
            Login
          </h4>
          {(errorState) && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          {(mutationError && !(loading || errorState)) && (
            <div className="alert alert-warning">{mutationError.message}</div>
          )}
          {loading && (
            <div className="alert alert-info">Loading...</div>
          )}
          <Form.Group className="form-floating mb-0" controlId="formGroupEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              name="email"
              value={formState.email}
              onChange={(e) => setFormState({
                ...formState,
                email: e.target.value
              })
              }
              required
            />
            <Form.Label>Email address</Form.Label>
          </Form.Group>
          <Form.Group className="form-floating mb-0" controlId="formGroupPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={formState.password}
              onChange={(e) => setFormState({
                ...formState,
                password: e.target.value
              })
              }
              required
            />
            <Form.Label>Password</Form.Label>
          </Form.Group>
          <Button
            variant="primary"
            size="lg"
            className="w-100"
            type="submit"
          >
            Sign in
          </Button>
          <div className="mt3">
            <Button
              variant="link"
              size="sm"
              className="w-100"
              href="/forgot-password"
            >
              Forgot your password?
            </Button>
          </div>
        </main>
      </div>
    </Form>
  )
}
export default Login
