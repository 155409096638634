import React, { useContext, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Card, Form } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import LoadingSpinner from '../../components/LoadingSpinner'
import AddressForm, { initialAddressState } from '../../components/AddressForm'
import UserContext from '../../contexts/UserContext'
import { isUserAdmin } from '../../utils/lib'
import { entityStatusDictionary } from '../../constants'
import {
  CREATE_SCHOOL,
  MUTATE_SCHOOL,
  QUERY_ONE_SCHOOL
} from '../../operations/schools'
import { QUERY_DISTRICTS_AND_GRADES } from '../../operations/districts'

const initialState = {
  id: null,
  districtId: null,
  name: '',
  phone: '',
  website: '',
  donationUrl: '',
  minGrade: 1,
  maxGrade: 12,
  ospiNumber: '',
  esdNumber: '',
  numOfStudents: 0,
  frlPercentage: '',
  status: '',
  errorMessage: null
}

/**
 * Component form for creating or updating a school
 * @param {string} [title="New School"] - title block
 * @param {string} [backPath="/schools"] - href value for the back link
 * @param {string} [backLabel="&lt; back to school directory"] - label text for the back link
 */
const SchoolForm = ({
  title = 'New School',
  backPath = '/schools',
  backLabel = '&lt; back to school directory'
}) => {
  // hooks
  const history = useHistory()
  const { user: userContext } = useContext(UserContext)
  const { id } = useParams()
  const intId = parseInt(id, 10)
  const [formState, setFormState] = useState({
    ...initialState,
    id: intId || id
  })
  const [locationAddress, setLocationAddress] = useState(initialAddressState)
  const [mailingAddress, setMailingAddress] = useState({
    ...initialAddressState,
    hidden: true
  })
  const [errorState, setErrorState] = useState()

  // queries
  const {
    loading: schoolLoading,
    error: schoolError
  } = useQuery(QUERY_ONE_SCHOOL, {
    variables: { id: [intId] },
    skip: !id,
    onCompleted: (({ schools }) => {
      const [school] = schools
      const lAddr = school.addresses.filter((a) => a.addressType?.id === 1)
      const mAddr = school.addresses.filter((a) => a.addressType?.id === 2)
      setFormState({
        ...formState,
        id: school.id,
        districtId: school.district.id,
        name: school.name,
        phone: school.phoneNumber,
        website: school.url,
        donationUrl: school.donateUrl,
        minGrade: school.minimumGrade?.id,
        maxGrade: school.maximumGrade?.id,
        ospiNumber: school.ospiNumber,
        esdNumber: school.esdNumber,
        numOfStudents: school.numberOfStudents,
        frlPercentage: parseFloat(school.frlPercentage) || 0,
        status: school.status
      })
      if (lAddr && lAddr.length > 0) {
        const [location] = lAddr
        setLocationAddress({
          ...locationAddress,
          entityAddressId: location.id || undefined,
          addressId: location.address.id || undefined,
          addressTypeId: location.addressType.id,
          address1: location.address.addressLine1,
          address2: location.address.addressLine2 || '',
          county: location.address.county,
          city: location.address.city,
          state: location.address.state,
          zip: location.address.zip
        })
      }
      if (mAddr && mAddr.length > 0) {
        const [mailing] = mAddr
        setMailingAddress({
          ...mailingAddress,
          entityAddressId: mailing.id || undefined,
          addressId: mailing.address.id || undefined,
          addressTypeId: mailing.addressType.id,
          address1: mailing.address.addressLine1,
          address2: mailing.address.addressLine2 || '',
          county: mailing.address.county,
          city: mailing.address.city,
          state: mailing.address.state,
          zip: mailing.address.zip,
          hidden: false
        })
      }
    })
  })
  const {
    loading: districtsAndGradesLoading,
    error: districtsAndGradesError,
    data: districtsAndGrades
  } = useQuery(QUERY_DISTRICTS_AND_GRADES)

  // mutations
  const baseMutationVariables = {
    districtId: formState.districtId,
    name: formState.name,
    url: formState.website,
    addresses: [
      {
        id: locationAddress.entityAddressId || undefined,
        addressId: locationAddress.addressId || undefined,
        addressTypeId: locationAddress.addressTypeId,
        address: {
          addressLine1: locationAddress.address1,
          addressLine2: locationAddress.address2,
          county: locationAddress.county,
          city: locationAddress.city,
          state: locationAddress.state,
          zip: locationAddress.zip
        }
      }
    ],
    donateUrl: formState.donationUrl,
    minimumGradeId: formState.minGrade,
    maximumGradeId: formState.maxGrade,
    ospiNumber: formState.ospiNumber,
    esdNumber: formState.esdNumber,
    numberOfStudents: formState.numOfStudents,
    frlPercentage: formState.frlPercentage.toString(),
    status: formState.status,
    phoneNumber: formState.phone
  }
  // add mailing address
  if (mailingAddress && !mailingAddress.hidden) {
    baseMutationVariables.addresses.push({
      id: mailingAddress.entityAddressId || undefined,
      addressId: mailingAddress.addressId || undefined,
      addressTypeId: mailingAddress.addressTypeId,
      address: {
        addressLine1: mailingAddress.address1,
        addressLine2: mailingAddress.address2,
        county: mailingAddress.county,
        city: mailingAddress.city,
        state: mailingAddress.state,
        zip: mailingAddress.zip
      }
    })
  }
  const [mutateSchool, {
    loading: mutationLoading
  }] = useMutation(id
    ? MUTATE_SCHOOL
    : CREATE_SCHOOL, {
    variables: id
      ? {
        ...baseMutationVariables,
        id: intId
      }
      : baseMutationVariables,
    onCompleted: (({ id: returnId }) => {
      history.push(returnId
        ? `/schools/${returnId}`
        : '/schools')
    })
  })

  // event listeners
  const handleChange = (value, field) => {
    setFormState({
      ...formState,
      [field]: value
    })
  }
  const onFormSubmit = (e) => {
    e.preventDefault()
    mutateSchool()
      .catch((err) => {
        setErrorState(err.message)
      })
  }

  // renderings
  if (schoolLoading) return <LoadingSpinner message="Loading..." />
  if (schoolError) return <div className="alert alert-danger">{schoolError.message}</div>

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {id
            ? `Edit ${title}`
            : `Add ${title}`
          }
        </h3>
        {backPath && backLabel && (
          <Link to={backPath} className="text-secondary">
            {backLabel}
          </Link>
        )}
      </div>
      <Card className="mt-3 p-3">
        <Form className="col-md-6" onSubmit={onFormSubmit}>
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          <Form.Group controlId="formGroupName">
            <Form.Label>School Name</Form.Label>
            <Form.Control
              type="text"
              value={formState.name || ''}
              onChange={(e) => handleChange(e.target.value, 'name')}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              value={formState.phone || ''}
              onChange={(e) => handleChange(e.target.value, 'phone')}
              required
            />
          </Form.Group>
          {districtsAndGradesLoading && (
            <div>Loading districts...</div>
          )}
          {districtsAndGradesError && (
            <div className="alert alert-danger">
              <label>Error loading districts...</label>
              {districtsAndGradesError.message}
            </div>
          )}
          {districtsAndGrades && (
            <Form.Group controlId="formGroupDistrictId">
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                value={(formState.districtId || '').toString()}
                onChange={(e) => handleChange(
                  parseInt(e.target.value, 10),
                  'districtId'
                )}
                required
              >
                <option value="">Select a district...</option>
                {districtsAndGrades.districts.map((district) => (
                  <option
                    key={district.id}
                    value={district.id}
                    data-testid="district-option"
                  >
                    {district.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <AddressForm
            title="School Location and Mailing Address"
            helpfulText="If your mailing address is different from your location, uncheck the box and enter both addresses seperately"
            onChange={setLocationAddress}
            address={locationAddress}
            addressTypeId={1}
          />
          <AddressForm
            hidden={mailingAddress.hidden}
            containerClass="alert-secondary p-3 border rounded mailing-address"
            showCheckbox={true}
            checkboxTestId="mailing-check-test"
            onChange={setMailingAddress}
            address={mailingAddress}
            addressTypeId={2}
          />
          <Form.Group controlId="formGroupWebAddress" className="mt-3">
            <Form.Label>Web Address</Form.Label>
            <Form.Control
              type="text"
              value={formState.website || ''}
              onChange={(e) => handleChange(e.target.value, 'website')}
            />
          </Form.Group>
          <Form.Group controlId="formGroupDonationUrl">
            <Form.Label>Donation URL</Form.Label>
            <Form.Control
              type="text"
              value={formState.donationUrl || ''}
              onChange={(e) => handleChange(e.target.value, 'donationUrl')}
            />
          </Form.Group>
          {districtsAndGradesLoading && (
            <div>Loading grades...</div>
          )}
          {districtsAndGradesError && (
            <div className="alert alert-danger">
              <label>Error loading grades...</label>
              {districtsAndGradesError.message}
            </div>
          )}
          {districtsAndGrades && (
            <>
              <Form.Group controlId="formGroupMinGrade">
                <Form.Label>Minimum Grade Level</Form.Label>
                <Form.Control
                  as="select"
                  value={(formState.minGrade || '').toString()}
                  onChange={(e) => handleChange(
                    parseInt(e.target.value, 10),
                    'minGrade'
                  )}
                >
                  <option value="">Choose...</option>
                  {districtsAndGrades.grades.map((grade) => (
                    <option
                      key={grade.id}
                      value={grade.id}
                      data-testid="mingrade-option"
                    >
                      {grade.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupMaxGrade">
                <Form.Label>Maximum Grade Level</Form.Label>
                <Form.Control
                  as="select"
                  value={(formState.maxGrade || '').toString()}
                  onChange={(e) => handleChange(
                    parseInt(e.target.value, 10),
                    'maxGrade'
                  )}
                >
                  <option value="">Choose...</option>
                  {districtsAndGrades.grades.map((grade) => (
                    <option
                      key={grade.id}
                      value={grade.id}
                      data-testid="maxgrade-option"
                    >
                      {grade.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
          <Form.Group controlId="formGroupOspiNumber">
            <Form.Label>OSPI Number</Form.Label>
            <Form.Control
              type="text"
              value={formState.ospiNumber || ''}
              onChange={(e) => handleChange(e.target.value, 'ospiNumber')}
            />
          </Form.Group>
          <Form.Group controlId="formGroupesdNumber">
            <Form.Label>ESD Number</Form.Label>
            <Form.Control
              type="text"
              value={formState.esdNumber || ''}
              onChange={(e) => handleChange(e.target.value, 'esdNumber')}
            />
          </Form.Group>
          {isUserAdmin(userContext.role.name) && (
            <>
              <Form.Group controlId="formGroupNumStudents">
                <Form.Label>Number of Students Enrolled</Form.Label>
                <Form.Control
                  type="number"
                  className="w-50"
                  value={(formState.numOfStudents || '').toString()}
                  onChange={(e) => handleChange(
                    parseInt(e.target.value, 10),
                    'numOfStudents'
                  )}
                  min="0"
                />
              </Form.Group>
              <Form.Group controlId="formGroupFrlPercentage">
                <Form.Label>Free and Reduced Lunch (FRL) Percentage</Form.Label>
                <Form.Control
                  type="number"
                  className="w-50"
                  value={formState.frlPercentage}
                  onChange={(e) => handleChange(e.target.value, 'frlPercentage')}
                  min="0"
                  max="100"
                  step="any"
                />
              </Form.Group>
              <Form.Group controlId="formGroupStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  className="w-50"
                  value={formState.status.toLowerCase()}
                  onChange={(e) => handleChange(e.target.value, 'status')}
                  required
                >
                  <option value="">Choose...</option>
                  {Object.keys(entityStatusDictionary).map((key) => (
                    <option
                      key={key}
                      value={entityStatusDictionary[key]}
                      data-testid="status-option"
                    >
                      {entityStatusDictionary[key]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
          <hr />
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          {!mutationLoading && (
            <>
              <Button variant="primary" type="submit">
                Submit
              </Button>{' '}
              <Button variant="secondary" className="text-light" href={backPath}>
                Cancel
              </Button>
            </>
          )}
          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

SchoolForm.propTypes = {
  title: PropTypes.string,
  backPath: PropTypes.string,
  backLabel: PropTypes.string
}

export default SchoolForm
