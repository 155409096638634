/**
 * custom styles for react-data-table-component
 *
 * @constant
 * @type {object}
 * @default
*/
const customStyles = {
  subHeader: {
    style: {
      backgroundColor: 'transparent',
      position: 'relative',
      paddingLeft: '0px',
      width: '100%'
    }
  },
  header: {
    style: {
      backgroundColor: '#000'
    }
  },
  headRow: {
    style: {
      minHeight: '36px',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      backgroundColor: '#6A5745'
    }
  },
  headCells: {
    style: {
      color: '#fff',
      fontSize: '14px',
      '&:not(:last-of-type)': {
        borderRightWidth: '0px'
      },
      '&:hover': {
        color: '#fff'
      }
    }
  },
  cells: {
    style: {
      color: '#6A5745',
      fontSize: '14px',
      '&:not(:last-of-type)': {
        borderRightWidth: '0px'
      }
    }
  },
  rows: {
    style: {
      minHeight: '36px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 228, 136, 0.1)'
      },
      '&:nth-child(2n+2)': {
        backgroundColor: 'rgba(255, 228, 136, 0.35)'
      },
      '&:nth-child(2n+2):hover': {
        backgroundColor: 'rgba(255, 228, 136, 0.25)'
      }
    }
  },
  pagination: {
    style: {
      backgroundColor: 'transparent',
      fontSize: '12px',
      minHeight: '36px'
    }
  }
}

export default customStyles
