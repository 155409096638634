import { gql } from '@apollo/client'

// * Queries
export const QUERY_SCHOOLS_AND_DISTRICTS_LITE = gql`
  query GetSchoolsAndDistrictsLite {
    schools (status: "active") {
      id
      name
    }
    districts (status: "active") {
      id
      name
    }
  }
`
export const GET_SCHOOL_DONATION_LINK = gql`
  query GetSchool(
    $id: [Int]
  ) {
    schools(id: $id) {
      donateUrl
    }
  }
`
export const QUERY_SCHOOLS = gql`
  query {
    schools {
      id
      district {
        id
        name
      }
      name
      addresses {
        address {
          county
          city
        }
      }
      phoneNumber
      ospiNumber
      status
      minimumGrade {
        id
        name
      }
      maximumGrade {
        id
        name
      }
    }
  }
`
export const QUERY_ONE_SCHOOL = gql`
query($id: [Int]) {
  schools(id: $id) {
    id
    district {
      id
      name
    }
    name
    url
    donateUrl
    addresses {
      id
      addressType {
        id
        name
      }
      address {
        id
        addressLine1
        addressLine2
        county
        city
        state
        zip
      }
    }
    minimumGrade {
      id
      name
    }
    maximumGrade {
      id
      name
    }
    ospiNumber
    esdNumber
    numberOfStudents
    frlPercentage
    status
    phoneNumber
    userSchools {
      user {
        first
        last
        email
        investedRole {
          name
        }
      }
    }
    accountBalance {
      currentBalance
      fallFunding {
        percentUsed
      }
    }
  }
}
`

// * Mutations
export const CREATE_SCHOOL = gql`
  mutation CreateSchoolMutation(
    $districtId: Int!
    $name: String!
    $url: String
    $addresses: [EntityAddressInput]
    $donateUrl: String
    $minimumGradeId: Int
    $maximumGradeId: Int
    $ospiNumber: String
    $esdNumber: String
    $numberOfStudents: Int
    $frlPercentage: String
    $status: String!
    $phoneNumber: String!
  ) {
    createSchool(
      districtId: $districtId
      name: $name
      url: $url
      addresses: $addresses
      donateUrl: $donateUrl
      minimumGradeId: $minimumGradeId
      maximumGradeId: $maximumGradeId
      ospiNumber: $ospiNumber
      esdNumber: $esdNumber
      numberOfStudents: $numberOfStudents
      frlPercentage: $frlPercentage
      status: $status
      phoneNumber: $phoneNumber
    ) {
      id
    }
  }
`
export const MUTATE_SCHOOL = gql`
  mutation UpdateSchoolMutation(
    $id: Int!
    $districtId: Int
    $name: String
    $url: String
    $addresses: [EntityAddressUpdateInput]
    $donateUrl: String
    $minimumGradeId: Int
    $maximumGradeId: Int
    $ospiNumber: String
    $esdNumber: String
    $numberOfStudents: Int
    $frlPercentage: String
    $status: String
    $phoneNumber: String
  ) {
    updateSchool(
      id: $id
      districtId: $districtId
      name: $name
      url: $url
      addresses: $addresses
      donateUrl: $donateUrl
      minimumGradeId: $minimumGradeId
      maximumGradeId: $maximumGradeId
      ospiNumber: $ospiNumber
      esdNumber: $esdNumber
      numberOfStudents: $numberOfStudents
      frlPercentage: $frlPercentage
      status: $status
      phoneNumber: $phoneNumber
    ) {
      affectedRows
      data {
        id
      }
    }
  }
`
