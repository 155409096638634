import React, {
  useContext,
  useEffect,
  useState,
  useRef
} from 'react'
import { useLocation, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap'
import {
  faBalanceScale,
  faCheck,
  faClipboardList,
  faCog,
  faGraduationCap,
  faPrint,
  faPlus,
  faSchool,
  faVoteYea
} from '@fortawesome/pro-regular-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import WufooForm from 'react-wufoo-embed'
import pluralize from 'pluralize'

import UserContext from '../../contexts/UserContext'
import LoadingSpinner from '../../components/LoadingSpinner'
import AcademicYearSummary from '../../components/AcademicYearSummary'
import {
  isEmptyObject,
  isUserAdmin,
  isUserDistrict,
  renderStatus
} from '../../utils/lib'
import { useBalance } from '../../utils/hooks'
import SchoolDropdown from '../../components/SchoolDropdown'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import GlobalDataTable from '../../components/GlobalDataTable'
import TransactionsDownloadButton from '../../components/TransactionsDownloadButton'
import Steps from '../../components/Steps'
import {
  QUERY_CURRENT_SCHOOL_YEAR_END_DATES
} from '../../operations/schoolYear'
import {
  DEACTIVATE_STAFF,
  GET_USER_STAFFS
} from '../../operations/staff'
import {
  ADD_OR_UPDATE_YEARLY_REPORT_MUTATION,
  QUERY_IS_YEAR_END_OPEN,
  QUERY_YEARLY_OBJECTIVES_AND_CHOICES,
  QUERY_YEARLY_REPORTS_BY_SCHOOL_AND_YEAR,
  QUERY_DISTRICT_AND_SCHOOL_USERS_FOR_YEARLY_REPORT,
  SEND_YEARLY_REPORT_MUTATION
} from '../../operations/yearEndReport'
import {
  QUERY_ONE_SCHOOL
} from '../../operations/schools'
import {
  QUERY_STUDENT_STORIES_BY_SCHOOL_AND_YEAR
} from '../../operations/studentStories'

// vars
const YearEndReport = () => {
  const history = useHistory()
  const { pathname = '' } = useLocation()
  const [error, setError] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [yearEndComplete, setYearEndComplete] = useState(false)
  const [yearBeginComplete, setYearBeginComplete] = useState(false)
  const [nextPath, setNextPath] = useState('/year-end-report/balance')
  const [isSendingReport, setIsSendingReport] = useState(false)
  const [activeStep, setActiveStep] = useState('overview')
  const [yearlyObjectives, setYearlyObjectives] = useState([])
  const [yearEndObjectives, setYearEndObjectives] = useState([])
  const [studentStories, setStudentStories] = useState([])
  const [isYearEndOpen, setIsYearEndOpen] = useState(false)
  const [formValues, setFormValues] = useState({})
  const {
    user: userContext,
    session,
    setCurrentSchoolAndDistrict,
    setCurrentSchoolYear,
    setYearEndReportInProgress
  } = useContext(UserContext)
  const [staff, setStaff] = useState([])
  const [maximumGrade, setMaximumGrade] = useState(null)
  const [selectedStaff, setSelectedStaff] = useState(null)
  const [reportUsers, setReportUsers] = useState({
    school: [],
    district: []
  })
  const [deactivateStaffModalOpen, setDeactivateStaffModalOpen] = useState(false)
  const [deactivateStaffError, setDeactivateStaffError] = useState(false)
  const { userSchools } = userContext
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const { currentSchool } = session
  const [yearlyReports, setYearlyReports] = useState([])
  const [yearlyReportsHaveLoaded, setYearlyReportsHaveLoaded] = useState(false)
  const [schoolYear, setSchoolYear] = useState({
    id: 3
  })
  const [locationAddress, setLocationAddress] = useState({
    addressType: 'physical'
  })
  const [mailingAddress, setMailingAddress] = useState({
    addressType: 'mailing'
  })
  const { id: schoolYearId } = schoolYear
  const {
    balanceData,
    balanceLazyLoading,
    balanceLazyError,
    balanceError
  } = useBalance(schoolYear)

  let schoolId
  if (currentSchool?.id) {
    ({ id: schoolId } = currentSchool)
  }

  // Helper to set the form values state
  const setFormValueByName = (id, value, type, incompatibleChoices = []) => {
    if (type === 'choice') {
      setFormValues({
        ...formValues,
        [id]: [value]
      })
      return
    }
    if (type === 'multi_choice') {
      if (!formValues[id]) {
        setFormValues({
          ...formValues,
          [id]: [value]
        })
        return
      }

      const valueSelected = formValues[id].indexOf(value) > -1

      if (valueSelected) {
        setFormValues({
          ...formValues,
          [id]: [
            ...formValues[id].filter((existingValue) => value !== existingValue)
          ]
        })
        return
      }
      setFormValues({
        ...formValues,
        [id]: [...formValues[id], value]
          .filter((currValue) => incompatibleChoices.indexOf(currValue) === -1)
      })
    }
  }

  const wufooForm = useRef(null)

  // queries
  const {
    loading: schoolLoading = true
  } = useQuery(QUERY_ONE_SCHOOL, {
    variables: { id: [schoolId] },
    skip: !schoolId,
    onCompleted: (({ schools }) => {
      const [school] = schools
      const lAddr = school.addresses.filter((a) => a.addressType?.id === 1)
      const mAddr = school.addresses.filter((a) => a.addressType?.id === 2)

      const { maximumGrade: maximumGradeData } = school

      if (lAddr && lAddr.length > 0) {
        const [location] = lAddr
        setLocationAddress({
          ...locationAddress,
          entityAddressId: location.id || undefined,
          addressId: location.address.id || undefined,
          addressType: location.addressType.name,
          address1: location.address.addressLine1,
          address2: location.address.addressLine2 || '',
          county: location.address.county,
          city: location.address.city,
          state: location.address.state,
          zip: location.address.zip
        })
      }
      if (mAddr && mAddr.length > 0) {
        const [mailing] = mAddr
        setMailingAddress({
          ...mailingAddress,
          entityAddressId: mailing.id || undefined,
          addressId: mailing.address.id || undefined,
          addressType: mailing.addressType.name,
          address1: mailing.address.addressLine1,
          address2: mailing.address.addressLine2 || '',
          county: mailing.address.county,
          city: mailing.address.city,
          state: mailing.address.state,
          zip: mailing.address.zip
        })
      }

      setMaximumGrade(maximumGradeData)
    }),
    onError: (err) => {
      setError(err.message)
    }
  })

  const {
    data: schoolYearData = {},
    loading: schoolYearDataLoading
  } = useQuery(QUERY_CURRENT_SCHOOL_YEAR_END_DATES)

  const {
    loading: staffLoading,
    refetch: refetchStaff
  } = useQuery(GET_USER_STAFFS, {
    variables: {
      districtId: -1,
      schoolIds: [schoolId]
    },
    skip: !schoolId,
    onCompleted: ({ schools }) => {
      const [school] = schools
      if (!school) return
      setStaff(school.userSchools
        // filer out nulls, and inactive
        .filter((userSchool) => (
          userSchool && userSchool.user && !!userSchool.user.active
        ))
        .map((userSchool) => {
          const { user } = userSchool
          return ({
            ...(user || {}),
            userSchools: [
              {
                school: {
                  id: school.id,
                  name: school.name
                },
                schoolPosition: userSchool?.schoolPosition || {}
              }
            ],
            userDistricts: []
          })
        }))
    },
    onError: (err) => {
      setError(err.message)
    },
    fetchPolicy: 'network-only'
  })

  const {
    loading: yearEndOpenLoading
  } = useQuery(QUERY_IS_YEAR_END_OPEN, {
    onCompleted: ({ isYearEndOpen: open }) => {
      setIsYearEndOpen(open)
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  const {
    loading: yearlyReportsDataLoading,
    refetch: yearlyReportsDataRefetch
  } = useQuery(QUERY_YEARLY_REPORTS_BY_SCHOOL_AND_YEAR, {
    skip: !schoolId || !schoolYearId,
    variables: {
      schoolId,
      schoolYearId
    },
    onCompleted: ({ yearlyReports: reports }) => {
      setYearlyReports(reports)
      setYearlyReportsHaveLoaded(true)
    },
    onError: (err) => {
      setYearlyReportsHaveLoaded(false)
      setError(err.message)
    }
  })

  const {
    loading: studentStoriesDataLoading
  } = useQuery(QUERY_STUDENT_STORIES_BY_SCHOOL_AND_YEAR, {
    skip: !schoolId || !schoolYearId,
    variables: {
      schoolId,
      schoolYearId
    },
    onCompleted: ({ studentStories: data }) => {
      setStudentStories(data)
    },
    onError: (err) => {
      setError(err.message)
    },
    fetchPolicy: 'network-only'
  })

  const {
    loading: yearlyObjectivesLoading
  } = useQuery(QUERY_YEARLY_OBJECTIVES_AND_CHOICES, {
    variables: {
      active: true
    },
    onCompleted: ({
      yearlyObjectives: yearlyObjs
    }) => {
      // This is temporarily setting the order and incompatibleChoices and can be removed if
      // those items come from the database.
      const objectivesWithChoiceOrder = yearlyObjs.map((objective) => {
        const { yearlyObjectiveChoices = [] } = objective
        return {
          ...objective,
          yearlyObjectiveChoices: yearlyObjectiveChoices.map((yearlyObjectiveChoice) => {
            if (yearlyObjectiveChoice.name === 'opt_out') {
              return {
                ...yearlyObjectiveChoice,
                order: 1
              }
            }
            if (yearlyObjectiveChoice.name === 'opt_in') {
              return {
                ...yearlyObjectiveChoice,
                order: 0
              }
            }
            if (yearlyObjectiveChoice.name === 'manual') {
              return {
                ...yearlyObjectiveChoice,
                order: 2,
                incompatibleChoices: ['grade_up', 'grade_out']
              }
            }
            if (yearlyObjectiveChoice.name === 'grade_up') {
              return {
                ...yearlyObjectiveChoice,
                order: 1,
                incompatibleChoices: ['manual']
              }
            }
            if (yearlyObjectiveChoice.name === 'grade_out') {
              return {
                ...yearlyObjectiveChoice,
                order: 0,
                incompatibleChoices: ['manual']
              }
            }
            return yearlyObjectiveChoice
          })
        }
      })
      setYearlyObjectives(objectivesWithChoiceOrder)
      setYearEndObjectives(objectivesWithChoiceOrder.filter(({ yearlyObjectiveType }) => yearlyObjectiveType?.name === 'year_end'))
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  const {
    loading: reportUsersLoading
  } = useQuery(QUERY_DISTRICT_AND_SCHOOL_USERS_FOR_YEARLY_REPORT, {
    skip: !schoolId,
    variables: {
      schoolId
    },
    onCompleted: ({ schools }) => {
      const [school] = schools
      if (!school) return
      setReportUsers({
        school: school.userSchools,
        district: school.district?.userDistricts
      })
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  // mutations
  const [deactivateStaff, {
    loading: deactivateStaffLoading
  }] = useMutation(DEACTIVATE_STAFF, {
    variables: {
      id: selectedStaff?.id
    },
    onCompleted: () => {
      setDeactivateStaffModalOpen(false)
      setSelectedStaff(null)
      refetchStaff()
    },
    onError: (err) => {
      setDeactivateStaffModalOpen(false)
      setSelectedStaff(null)
      setError(err.message)
    }
  })

  const [addOrUpdateYearlyReportMutation] = useMutation(ADD_OR_UPDATE_YEARLY_REPORT_MUTATION, {
    onCompleted: () => {
      yearlyReportsDataRefetch()
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  const [sendYearlyReportMutation] = useMutation(SEND_YEARLY_REPORT_MUTATION, {
    onError: (err) => {
      setError(err.message)
    }
  })

  //
  // * useEffect subscriptions start here
  //
  useEffect(() => {
    if (!userContext.id) return
    setFormValueByName('email_recipients', userContext.id, 'multi_choice')
  }, [userContext])

  useEffect(() => {
    if (!process.env.REACT_APP_WUFOO_USER_ID || !process.env.REACT_APP_WUFOO_FORM_ID) return
    wufooForm.current = (
      <Card className="p-3 mb-3">
        <WufooForm
          header="hide"
          userName={process.env.REACT_APP_WUFOO_USER_ID}
          formHash={process.env.REACT_APP_WUFOO_FORM_ID}
        />
      </Card>
    )
  }, [])

  useEffect(() => {
    yearlyReports.forEach(({ objectiveValues, objective }) => {
      if (!objectiveValues) return
      setFormValues({
        ...formValues,
        [objective?.id]: objectiveValues.map(({ value }) => value)
      })
    })
  }, [yearlyReports])

  useEffect(() => {
    const yearEndReportInProgress = yearlyObjectives.length
      && yearlyReports.length
      && yearlyReports.length < yearlyObjectives.length

    setYearEndReportInProgress(yearEndReportInProgress)
  }, [yearlyReports, yearlyObjectives])

  useEffect(() => {
    if (!schoolYearDataLoading && !isEmptyObject(schoolYearData)) {
      const { schoolYear: data } = schoolYearData
      setYearEndComplete(false)
      setYearBeginComplete(false)
      setCurrentSchoolYear(data)
      setSchoolYear(data)
    }
  }, [schoolYearData, schoolYearDataLoading])

  useEffect(() => {
    if (!isFirstLoad || !yearlyReportsHaveLoaded) return
    if (yearlyReports.length > 0) {
      const lastReport = [...yearlyReports]
        .sort((
          { objective: objectiveA },
          { objective: objectiveB }
        ) => objectiveA?.id - objectiveB?.id)
        .reverse()
        .find((report) => report.objectiveValues.length > 0)

      const { objective = '' } = lastReport
      let isEndOfYearSetupComplete = false
      let isBeginningOfYearSetupComplete = false
      let next
      if (yearlyReports.length) {
        switch (objective?.name) {
          case 'balance':
            next = '/year-end-report/school-staff'
            break
          case 'staff_school_info':
            next = '/year-end-report/survey'
            break
          case 'survey_complete':
            next = '/year-end-report/review'
            break
          case 'year_end_complete':
            next = '/year-end-report/complete'
            isEndOfYearSetupComplete = true
            break
          case 'next_year_opt_in':
            next = '/year-end-report/transition-students'
            isEndOfYearSetupComplete = true
            break
          case 'transition_students':
            next = '/year-end-report/'
            isEndOfYearSetupComplete = true
            isBeginningOfYearSetupComplete = true
            break
          default:
            next = '/year-end-report'
            break
        }
      } else {
        next = '/year-end-report/balance'
      }

      setYearEndComplete(isEndOfYearSetupComplete)
      setYearBeginComplete(isBeginningOfYearSetupComplete)
      setNextPath(next)

      if (next === '/year-end-report/complete') {
        setActiveStep('overview')
      }

      history.push(next)
      setIsFirstLoad(false)
    } else {
      setYearEndComplete(false)
      setYearBeginComplete(false)
      setNextPath('/year-end-report/balance')
    }
    setIsFirstLoad(false)
  }, [yearlyReports, yearlyReportsHaveLoaded])

  useEffect(() => {
    const path = pathname.split('/').pop()
    if (path === 'year-end-report') {
      setActiveStep('overview')
    } else if (path === 'success') {
      setActiveStep('success')
    } else {
      setActiveStep(path)
    }
  }, [pathname])

  //
  // * useEffect subscriptions end here
  //

  // event handlers
  const handleDeactivate = () => {
    deactivateStaff()
      .then(() => {
        refetchStaff()
      })
      .catch((err) => {
        setDeactivateStaffError(err.message)
      })
  }

  const deactivateModalClose = () => {
    setDeactivateStaffModalOpen(false)
    setDeactivateStaffError(false)
    setSelectedStaff(null)
  }

  const getYearlyObjectiveById = (objectiveId) => yearlyObjectives.find(
    (entry) => objectiveId === entry?.id
  )

  const getYearlyReportByObjectiveId = (objectiveId) => yearlyReports.find(
    (entry) => objectiveId === entry?.objective?.id
  )

  // eslint-disable-next-line arrow-body-style
  const getYearlyReportCompleteByObjectiveId = (objectiveId) => {
    return getYearlyReportByObjectiveId(objectiveId)?.objectiveValues?.length > 0
  }

  const getFormStateValueSelectedByObjectiveId = (objectiveId, name) => {
    if (formValues[objectiveId] === undefined) return undefined
    return formValues[objectiveId].indexOf(name) > -1
  }

  const getAllStepsCompleted = () => {
    const completedReports = yearlyReports
      .filter(({ objectiveValues = [] }) => objectiveValues.length > 0)
    return completedReports.length === 6
  }

  const handleSchoolChange = (value) => {
    setIsFirstLoad(true)
    setYearEndComplete(false)
    setYearBeginComplete(false)
    setCurrentSchoolAndDistrict(value, {})
    history.push('/year-end-report')
  }

  const onChangeActiveStep = (nextStepKey) => {
    setActiveStep(nextStepKey)
  }

  const optimisticallyUpdateYearlyReport = (objectiveId) => {
    const objective = yearlyObjectives.find(({ id }) => id === objectiveId)
    setYearlyReports((prevYearlyReports) => [
      ...prevYearlyReports,
      {
        id: 'temp-id',
        objective,
        objectiveValues: [true]
      }
    ])
  }

  const removeYearEndReport = (objectiveId) => {
    setYearlyReports((prevYearlyReports) => prevYearlyReports.filter(
      ({ objective: { id } }) => objectiveId !== id
    ))
  }

  const anyLoading = () => staffLoading
    || reportUsersLoading
    || schoolYearDataLoading
    || schoolLoading
    || studentStoriesDataLoading
    || yearlyReportsDataLoading
    || yearlyObjectivesLoading
    || yearEndOpenLoading

  // loading
  if (anyLoading()) {
    return (
      <LoadingSpinner />
    )
  }

  const schoolYearRange = `${moment(Number(schoolYear.startDate)).format('YYYY')}-${moment(Number(schoolYear.endDate)).format('YYYY')}`

  const staffTableColumns = [
    {
      name: 'Name',
      selector: (row) => row.last,
      sortable: true,
      cell: function NameCell(row) {
        return (`${row.last}, ${row.first}`)
      }
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: 'Position',
      selector: (row) => {
        const [userSchool] = row.userSchools
        const [userDistrict] = row.userDistricts
        return userSchool?.schoolPosition?.name || userDistrict?.schoolPosition?.name || ''
      },
      sortable: true,
      cell: function PositionCell(row) {
        const [userSchool] = row.userSchools
        const [userDistrict] = row.userDistricts
        return userSchool?.schoolPosition?.name || userDistrict?.schoolPosition?.name || ''
      }
    },
    ...(isAdmin ? [{
      name: 'Status',
      maxWidth: '75px',
      selector: (row) => row.active,
      sortable: true,
      omit: !isAdmin,
      cell: (row) => renderStatus({ active: row.active })
    }] : []),
    {
      name: '',
      button: true,
      cell: function ActionCell(row) {
        return (
          <Link
            variant="link text-dark"
            size="sm"
            to={row.id === userContext.id ? '/profile' : `/staff/edit/${row.id}`}
            data-testid='edit-button'
          >
            Edit
          </Link>
        )
      }
    },
    {
      name: '',
      button: true,
      cell: function ActionCell(row) {
        if (userContext.id === row.id || !row.active) return null
        return (
          <Button
            variant="link text-danger"
            size="sm"
            data-testid='deactivate-button'
            onClick={() => {
              setSelectedStaff(row)
              setDeactivateStaffModalOpen(true)
            }}
          >
            Deactivate
          </Button>
        )
      }
    }
  ]

  const buildObjectiveFormByObjectiveId = (
    objective,
    config = {
      primaryFields: [],
      defaultValues: []
    }
  ) => {
    if (!objective) return null
    const {
      id: objectiveId,
      name: objectiveName,
      objectiveDataType,
      yearlyObjectiveChoices: objectiveChoices = []
    } = objective
    if (!objectiveId || !objectiveChoices.length) return null
    return (
      <Form key={`${objectiveId}-${objectiveName}`}>
        {
          objectiveChoices
            .sort((a, b) => a.order - b.order)
            .map(({
              id,
              name,
              choiceDescription,
              choiceFriendlyName,
              incompatibleChoices
            }) => {
              let choiceName = choiceFriendlyName
              const isPrimaryChoice = config?.primaryFields?.length
                && config?.primaryFields.indexOf(name) > -1

              const isCheckedByDefault = (config?.defaultValues?.indexOf(name) > -1
                && getFormStateValueSelectedByObjectiveId(objectiveId, name) === undefined)

              if (choiceFriendlyName.indexOf('{maxGradeLevel}') > -1) {
                if (maximumGrade?.name) {
                  choiceName = choiceName.replace('{maxGradeLevel}', maximumGrade?.name)
                } else {
                  choiceName = choiceName.replace('{maxGradeLevel}', '')
                }
              }

              return (
                <Form.Group
                  key={`${id}-choice-${objectiveName}`}
                  className={`${isPrimaryChoice ? '' : 'small'}`}
                >
                  {
                    choiceDescription && (
                      <p>
                        {choiceDescription}
                      </p>
                    )
                  }
                  <Form.Check
                    checked={
                      isCheckedByDefault
                      || getFormStateValueSelectedByObjectiveId(objectiveId, name)
                    }
                    onChange={() => setFormValueByName(
                      objectiveId,
                      name,
                      objectiveDataType.name,
                      incompatibleChoices
                    )}
                    type={(() => {
                      if (objectiveDataType.name === 'choice') return 'radio'
                      if (objectiveDataType.name === 'multi_choice') return 'checkbox'
                      return null
                    })()}
                    name={name}
                    label={(
                      <strong>
                        {choiceName}
                      </strong>
                    )}
                  />
                </Form.Group>
              )
            })
        }
      </Form>
    )
  }

  const percentBalanceUsed = Math.min(Math.round(balanceData.percentUsed))

  // INV-549 requested that this be set to a fixed date since it does not change
  const formattedYearEndUtcDeadline = 'June 30th'

  const overviewComponent = (
    <div className="mb-3 px-0">
      {
        yearEndComplete && (
          <Card className="rounded-0 rounded-top">
            <Card.Body>
              If you need to make any corrections to the {schoolYearRange} school year, please <Link to="/contact">contact InvestED</Link>. Any corrections after {formattedYearEndUtcDeadline} will need to be reflected on the next academic year.
            </Card.Body>
          </Card>
        )
      }
      <GlobalDataTable
        data={[
          {
            title: '90-100% of InvestED School Funding Spent',
            complete: percentBalanceUsed >= 90 ? true : percentBalanceUsed,
            isPercentage: true,
            minValue: 90,
            action: {
              title: 'View Transactions',
              action: () => history.push('/finance?return=yer')
            }
          },
          {
            title: `Student Impact Stories (You have ${studentStories.length} Impact ${pluralize('Story', studentStories.length)}${studentStories.length === 0 ? '; minimum 1 required' : ''})`,
            complete: studentStories.length > 0 || 0,
            minValue: 1,
            action: {
              title: studentStories.length > 0 ? 'View Impact Stories' : 'Write an Impact Story',
              action: () => {
                if (studentStories.length > 0) {
                  history.push('/impact-stories?return=yer')
                  return
                }
                history.push('/impact-stories/new?return=yer')
              }
            }
          },
          ...yearEndObjectives.map(({
            description,
            id: objectiveId,
            friendlyName
          }) => {
            let action
            // Adds the send button
            if (objectiveId === 4 && !!getYearlyReportCompleteByObjectiveId(objectiveId)) {
              action = {
                component: (
                  <>
                    <TransactionsDownloadButton />
                    <Button
                      className="my-2 text-uppercase text-white"
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        history.push('/year-end-report/success')
                        setYearEndComplete(false)
                        setYearBeginComplete(false)
                        setActiveStep('success')
                      }}
                    >
                      Send
                      <FontAwesomeIcon className="ml-2" icon={faEnvelope} />
                    </Button>
                  </>
                )
              }
            }
            return {
              action,
              title: friendlyName,
              helpText: description,
              complete: !!getYearlyReportCompleteByObjectiveId(objectiveId)
            }
          }),
          ...(yearEndComplete ? [{
            title: 'Next Year Setup',
            complete: yearBeginComplete,
            action: {
              hide: yearBeginComplete,
              type: 'button',
              title: 'Begin Next Year Setup',
              action: () => history.push('/year-end-report/opt-in')
            }
          }] : [])
        ]}
        columns={[
          {
            name: 'Objective',
            selector: (row) => row.title,
            cell: function ObjectiveCell(row) {
              return (
                <>
                  {row.title}
                  {row.helpText && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>{row.helpText}</Tooltip>}
                    >
                      <FontAwesomeIcon className="ml-2 text-info small" icon={faQuestionCircle} />
                    </OverlayTrigger>
                  )}
                </>
              )
            }
          },
          {
            name: 'Status',
            width: '120px',
            selector: (row) => row.complete,
            center: true,
            cell: function StatusCell(row) {
              if (typeof row.complete === 'number') {
                const textClassName = row.complete >= row.minValue ? 'text-success' : 'text-danger'
                return (
                  <span className={`d-block text-center font-weight-bolder ${textClassName}`}>
                    {`${row.complete.toString()}${row.isPercentage ? '%' : ''}`}
                  </span>
                )
              }
              if (row.complete === false) {
                return (
                  <span className='d-block text-center text-danger'>
                    not complete
                  </span>
                )
              }
              if (row.complete) {
                return (
                  <FontAwesomeIcon
                    size='lg'
                    className="text-success"
                    icon={faCheck}
                  />
                )
              }
              return '-'
            }
          },
          {
            selector: (row) => row.action,
            width: '250px',
            right: true,
            cell: function ActionCell(row) {
              if (!row.action || row.action?.hide) return null
              if (row.action?.component) return row.action.component
              return (
                <Button
                  className={row.action.type === 'button' ? 'my-2 text-uppercase' : ''}
                  size="sm"
                  variant={row.action.type === 'button' ? 'primary' : 'link'}
                  onClick={row.action.action}
                >
                  {row.action.title}
                </Button>
              )
            }
          }
        ]}
        selectableRows={false}
        pagination={false}
        subHeader={false}
      />
      {
        !yearBeginComplete && (
          <Col className="mt-3 px-0" xs={12}>
          The SPACE Portal will automatically launch each of your students
          to the next grade level. If a student has completed their journey
          at your school, we will safely land them back and deactivate them
          from your school. If you would like to revert this automatic process
          please email <a href="mailto:staff@investED.org">staff@investED.org</a>
           </Col>
        )
      }
      {
        yearBeginComplete && (
          <h4 className="mt-4 text-center">Congratulations on completing your {schoolYearRange} InvestED Year End Report</h4>
        )
      }
    </div>
  )

  const yearEndSteps = [
    {
      key: 'balance',
      title: 'Balance',
      icon: faBalanceScale,
      active: pathname.split('/').indexOf('balance') > -1,
      complete: !!getYearlyReportCompleteByObjectiveId(1),
      content: (
        <AcademicYearSummary
          className="mb-3"
          balanceData={balanceData}
          balanceError={balanceError || balanceLazyError}
          balanceLoading={balanceLazyLoading}
          schoolYearData={schoolYear}
          showTransactionsLink={true}
        />
      ),
      primaryAction: {
        action: async () => {
          optimisticallyUpdateYearlyReport(1)
          await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 1,
              value: ['true']
            }
          })
          history.push('/year-end-report/school-staff')
        },
        warning: (
          <>
            By clicking confirm, you are indicating your balance is accurate. If you think there is an error, please <Link to="/contact">contact InvestED</Link>.
          </>
        )
      },
      secondaryAction: {
        action: () => history.push('/year-end-report')
      }
    },
    {
      key: 'school-staff',
      title: 'School & Staff',
      icon: faSchool,
      active: pathname.split('/').indexOf('school-staff') > -1,
      complete: !!getYearlyReportCompleteByObjectiveId(2),
      content: (
        <Card className="p-3 mb-3">
          <Row>
            <Col className="d-flex align-items-center justify-content-between mb-3" xs={12}>
              <h3 className="h4 mb-0">
                School Information
              </h3>
              <Link to={`/schools/edit/${schoolId}`}>
                <Button variant="outline-secondary">
                  Edit School Information
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="fs-5">{currentSchool?.name}</p>
            </Col>
          </Row>
          <Row>
            {
              [locationAddress, mailingAddress].map((address) => {
                if (isEmptyObject(address)) return null
                let addressToDisplay = address
                if (address.addressType === 'mailing' && !address.address1) addressToDisplay = { ...locationAddress }
                return (
                  <Col key={address.id} xs='auto'>
                    <h4 className="h6">{`${address.addressType[0].toUpperCase()}${address.addressType.slice(1)} Address`}</h4>
                    <address>
                      {addressToDisplay.address1}<br />
                      {addressToDisplay.address2 && (
                        <>
                          <span>{addressToDisplay.address2}</span>
                          <br />
                        </>
                      )}
                      {addressToDisplay.city}, {addressToDisplay.state}{' '}
                      {addressToDisplay.zip}
                    </address>
                  </Col>
                )
              })
            }
          </Row>
          <hr/>
          <Row>
            <Col className="d-flex align-items-center justify-content-between mb-3" xs={12}>
              <h3 className="h4 mb-0">
                Staff Information
              </h3>
              <Link to="/staff">
                <Button variant="outline-secondary">
                  Edit Staff Information
                </Button>
              </Link>
            </Col>
          </Row>
          {staff && (
            <Row>
              <Col xs={12}>
                <GlobalDataTable
                  columns={staffTableColumns}
                  data={staff}
                  filterHandler
                  pagination
                  subHeader
                  selectableRows={false}
                  defaultSortField="last"
                />
              </Col>
              <Col className="mt-3" xs={12}>
                <Alert variant="warning">
                  Please create a new staff member to add staff,
                   please do not edit existing staff member with a new staff member.
                </Alert>
                <Link className="float-end" to="/staff/new">
                  <Button variant="secondary">
                    <FontAwesomeIcon className="mr-1" icon={faPlus} />
                     Add Staff
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        </Card>
      ),
      primaryAction: {
        action: async () => {
          optimisticallyUpdateYearlyReport(2)
          await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 2,
              value: ['true']
            }
          })
          history.push('/year-end-report/survey')
        },
        warning: (
          <>
            By clicking confirm, you are indicating your school and staff are accurate. If you think there is an error, please <Link to="/contact">contact InvestED</Link>.
          </>
        )
      },
      secondaryAction: {
        action: () => history.push('/year-end-report/balance')
      }
    },
    {
      key: 'survey',
      title: 'Survey',
      icon: faClipboardList,
      active: pathname.split('/').indexOf('survey') > -1,
      complete: !!getYearlyReportCompleteByObjectiveId(3),
      content: (
        <>
          {wufooForm.current}
        </>
      ),
      primaryAction: {
        action: async () => {
          optimisticallyUpdateYearlyReport(3)
          await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 3,
              value: ['true']
            }
          })
          history.push('/year-end-report/review')
        },
        warning: 'Please ensure you click Submit on the survey before clicking Confirm'
      },
      secondaryAction: {
        action: () => history.push('/year-end-report/school-staff')
      }
    },
    {
      key: 'review',
      title: 'Review & Submit',
      icon: faPrint,
      active: pathname.split('/').indexOf('review') > -1,
      complete: !!getYearlyReportCompleteByObjectiveId(4),
      content: overviewComponent,
      primaryAction: {
        action: async () => {
          optimisticallyUpdateYearlyReport(4)
          let result = await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 4,
              value: ['true']
            }
          })
          if (result.errors) {
            removeYearEndReport(4)
            return
          }

          // To satisfy the requested workflow change in INV-558 we are
          // automatically setting up next year when completing the report
          optimisticallyUpdateYearlyReport(5)
          result = await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 5,
              value: ['opt_in']
            }
          })
          if (result.errors) {
            removeYearEndReport(5)
            return
          }

          optimisticallyUpdateYearlyReport(6)
          result = await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 6,
              value: ['grade_out', 'grade_up']
            }
          })
          if (result.errors) {
            removeYearEndReport(6)
            return
          }

          history.push('/year-end-report/success')
        },
        text: 'Submit Year End Report'
      },
      secondaryAction: {
        action: () => history.push('/year-end-report/survey')
      }
    }
  ]

  const yearStartSteps = [
    {
      key: 'complete',
      title: 'Year End Complete',
      icon: faCheck,
      active: pathname.split('/').indexOf('complete') > -1,
      complete: yearEndComplete,
      content: overviewComponent
    },
    {
      key: 'opt-in',
      title: 'Next Year Opt In',
      icon: faVoteYea,
      active: pathname.split('/').indexOf('opt-in') > -1,
      complete: !!getYearlyReportCompleteByObjectiveId(5),
      content: (
        <Card className="p-3 mb-3">
          <p className="h4 text-success mb-3">Let&apos;s set up the next academic year!</p>
          <Col sm="9">
            {buildObjectiveFormByObjectiveId(getYearlyObjectiveById(5), {
              primaryFields: ['opt_in']
            })}
          </Col>
        </Card>
      ),
      primaryAction: {
        disabled: !formValues[5]?.length,
        action: async () => {
          optimisticallyUpdateYearlyReport(5)
          await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 5,
              value: formValues[5]
            }
          })
          console.log('formValues', formValues)
          if (formValues[5] && formValues[5][0] === 'opt_out') {
            setYearBeginComplete(true)
            history.push('/year-end-report')
            return
          }
          history.push('/year-end-report/transition-students')
        }
      },
      secondaryAction: {
        action: () => history.push('/year-end-report/complete')
      }
    },
    {
      key: 'transition-students',
      title: 'Transition Students',
      icon: faGraduationCap,
      active: pathname.split('/').indexOf('transition-students') > -1,
      complete: !!getYearlyReportCompleteByObjectiveId(6),
      content: (
        <Card className="p-3 mb-3">
          <p className="h4 text-success mb-3">Let&apos;s set up the next academic year!</p>
          <Col sm="9">
            {buildObjectiveFormByObjectiveId(getYearlyObjectiveById(6), {
              primaryFields: ['grade_up', 'grade_out']
            })}
          </Col>
        </Card>
      ),
      primaryAction: {
        disabled: !formValues[6]?.length,
        action: async () => {
          optimisticallyUpdateYearlyReport(6)
          await addOrUpdateYearlyReportMutation({
            variables: {
              schoolId: currentSchool?.id,
              schoolYearId: schoolYear?.id,
              objectiveId: 6,
              value: formValues[6]
            }
          })
          setYearBeginComplete(true)
          setYearEndComplete(true)
          history.push('/year-end-report')
        }
      },
      secondaryAction: {
        action: () => history.push('/year-end-report/opt-in')
      }
    }
  ]

  const overview = {
    content: overviewComponent,
    primaryAction: {
      text: `${yearlyReports.length > 0 ? 'Continue' : 'Begin'} Year End Report`,
      action: () => {
        history.push(nextPath)
      }
    }
  }

  const reportSuccess = {
    content: (
      <>
        <Card className="p-3 mb-3">
          {
            schoolYearData && schoolYearData.schoolYear && (
              <h4>Congratulations on completing your {schoolYearRange} InvestED Year End Report</h4>
            )
          }
          <p>
            Please select all people you would like to receive
             a confirmation email, which will include a dowloadable pdf.
          </p>
          <Col sm="12">
            <Form>
              <Form.Group>
                { [
                  { user: userContext },
                  ...reportUsers.school,
                  ...reportUsers.district
                ].map((item) => {
                  const { user, schoolPosition } = item
                  return (
                    <Form.Check
                      key={`report-user-${user?.id}`}
                      checked={
                        formValues.email_recipients
                          .find((recipientId) => user?.id === recipientId)
                      }
                      type="checkbox"
                      value={user?.id}
                      label={
                        <>
                          <strong>{`${user?.first} ${user?.last}`}</strong>
                          {' - '}
                          {schoolPosition?.name}
                          {' '}
                          ({user?.email})
                        </>
                      }
                      onChange={() => {
                        setFormValueByName('email_recipients', user?.id, 'multi_choice')
                      }}
                    />
                  )
                }) }
              </Form.Group>
            </Form>
          </Col>
        </Card>
        <Button
          className="float-end"
          size="lg"
          disabled={isSendingReport}
          onClick={async () => {
            setIsSendingReport(true)
            await sendYearlyReportMutation({
              variables: {
                userIds: formValues.email_recipients,
                schoolId,
                schoolYearId
              }
            })
            setIsSendingReport(false)
            setYearEndComplete(true)

            if (getAllStepsCompleted()) {
              setYearBeginComplete(true)
            }
            setActiveStep('complete')
          }}
        >
          <FontAwesomeIcon
            icon={isSendingReport ? faCog : faCheck}
            className={`mr-2 ${isSendingReport ? 'fa-spin' : ''}`}
          />
          Send & Complete
        </Button>
      </>
    )
  }

  // render the report dashboard
  return (
    <>
      {!currentSchool && (
        <div className="alert alert-warning">
          You must select a school to view reports
        </div>
      )}
      <Container>
        <Row className="pb-3 px-3">
          <Col className="p-0" xs={12}>
            {
              (!isYearEndOpen && !isAdmin) && (
                <Alert variant="warning">
                  Year End Reports are not open at this time.
                </Alert>
              )
            }
            {
              (isYearEndOpen || isAdmin) && (
                <>
                  {/* {currentSchool && schoolYearData && schoolYearData.schoolYear && (
                    <div className="form-inline float-right">
                      <select
                      className="form-control form-control-sm"
                        onChange={(e) => {
                          setSchoolYear({
                            ...schoolYear,
                            id: parseInt(e.target.value, 10)
                          })
                        }}
                        value={schoolYear.id}
                      >
                        <option value={schoolYearData.schoolYear.id}>Current School Year</option>
                        <option value={schoolYearData.schoolYear.id - 1}>Last School Year</option>
                      </select>
                    </div>
                  )} */}
                  <h3 className="page-title border-bottom pb-5">
                    <label className="float-left pr-3">
                      Year End Report - Due {formattedYearEndUtcDeadline}
                    </label>
                    <SchoolDropdown
                      title={(currentSchool?.name || 'Select a school...')}
                      visible={isAdmin || isDistrict || (userSchools.length > 1)}
                      schoolsOnly={true}
                      appendClassName="float-left"
                      handleItemClick={handleSchoolChange}
                      viewAllItemVisible={false}
                    />
                  </h3>
                  {error && (
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  )}
                </>
              )
            }
          </Col>
          {
            currentSchool
            && schoolYearData
            && schoolYearData.schoolYear
            && (isYearEndOpen || isAdmin)
            && (
              <>
                {
                  (!yearEndComplete && !yearBeginComplete) && (
                    <>
                      <Col className="mb-3 px-0" xs={12}>
                        Welcome to your {schoolYearRange} Year End Report - Please complete
                         this report only after all transactions have been submitted
                      </Col>
                      <Steps
                        onChangeActiveStep={onChangeActiveStep}
                        overview={overview}
                        activeStep={activeStep || 'overview'}
                        steps={yearEndSteps}
                        success={reportSuccess}
                      />
                    </>
                  )
                }
                {
                  (yearEndComplete && !yearBeginComplete) && (
                    <Steps
                      onChangeActiveStep={onChangeActiveStep}
                      activeStep={activeStep}
                      steps={yearStartSteps}
                      overview={overview}
                    />
                  )
                }
                {
                  (yearBeginComplete && yearEndComplete) && overviewComponent
                }
              </>
            )
          }
        </Row>
      </Container>
      {/* Confirmation modal for deleting a staff */}
      <DeleteConfirmationModal
        showModal={deactivateStaffModalOpen}
        title={'Are you sure you want to deactivate this staff member?'}
        body={'Deactivating a staff member will prevent them from logging in. Are you sure you want to proceed?'}
        deleteError={Boolean(deactivateStaffError)}
        errorTitle={'Something went wrong.'}
        errorBody={'Please refresh the page and try again. If the problem persists, please contact us.'}
        deleteLoading={Boolean(deactivateStaffLoading)}
        deleteModalClose={deactivateModalClose}
        deleteButtonLabel="Deactivate"
        handleDelete={handleDeactivate}
      />
    </>
  )
}

export default YearEndReport
