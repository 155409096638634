import { gql } from '@apollo/client'

// * queries
export const CREATE_ADMIN = gql`
  mutation CreateAdminMutation(
    $first: String!
    $last: String!
    $roleId: Int!
    $email: String!
  ) {
    createUser(
      first: $first
      last: $last
      investedRoleId: 25
      roleId: $roleId
      email: $email
    ) {
      id
    }
  }
`
export const QUERY_ADMIN = gql`
  query GetAdminAndRoles(
    $id: Int!
  ) {
    user(id: $id) {
      id
      first
      last
      email
      role {
        id
      }
      createdBy {
        id
      }
      updatedBy {
        id
      }
      createdAt
      updatedAt
      active
    }
  }
`
export const GET_ADMINS = gql`
  query GetAdmins(
    $roles: [Int]
  ) {
    users(
      roleId: $roles
      active: [true, false]
    ) {
      id
      first
      last
      email
      role {
        name
      }
      active
    }
  }
`

export const QUERY_SCHOOLS_YEAR_END_STATUS = gql`
  query GetSchoolsYearEndStatus (
    $schoolYearId: Int
    $page: Int
    $rowsPerPage: Int
    $filterText: String
    $sort: String
    $direction: String
  ) {
    schools(page: $page, rowsPerPage: $rowsPerPage, filterText: $filterText, sort: $sort, direction: $direction) {
      id
      name
      yearlyReports( schoolYearId: $schoolYearId ) {
        id
        objective {
          id
          name
          friendlyName
          yearlyObjectiveType {
            name
          }
        }
        objectiveValues {
          value
        }
      }
      studentStories( schoolYearId: $schoolYearId ) {
        id
      }
      accountBalance {
        startBalance
        currentBalance
        endBalance
        verified
      }
    }
    schoolsCount
  }
`

export const QUERY_YEARLY_REPORTS_BY_YEAR = gql`
  query GetYearlyReportslByYear (
    $schoolYearId: Int!
  ) {
    yearlyReportsByYear(
      schoolYearId: $schoolYearId
    ) {
      id
      school {
        id
      }
      objective {
        id
        name
        friendlyName
        yearlyObjectiveType {
          name
        }
      }
      objectiveValues {
        value
      }
    }
  }
`

// * mutations
export const MUTATE_ADMIN = gql`
  mutation UpdateAdminMutation(
    $id: Int!
    $first: String!
    $last: String!
    $active: Boolean
  ) {
    updateUser(
      id: $id
      first: $first
      last: $last
      active: $active
    ) {
      affectedRows
      data {
        id
      }
    }
  }
`

export const DEACTIVATE_ADMIN = gql`
  mutation DeactivateAdminMutation(
    $id: Int!
  ) {
    updateUser(
      id: $id
      active: false
    ) {
      affectedRows
    }
  }
`

export const DELETE_ADMIN = gql`
  mutation deleteAdmin(
    $id: Int!
  ) {
    deleteUser( id: $id ) {
      affectedRows
    }
  }
`

export const CLEAR_YEARLY_REPORT = gql`
  mutation ClearYearlyReport (
    $schoolId: Int!
  ) {
    clearYearlyReport (schoolId: $schoolId) {
      affectedRows
    }
  }
`
