import React, { useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Card, Form } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt, faUserGraduate } from '@fortawesome/pro-light-svg-icons'
import { DefaultEditor } from 'react-simple-wysiwyg'
import UserContext from '../../contexts/UserContext'
import { QUERY_TRANSACTION_LITE } from '../../operations/transactions'
import {
  QUERY_STUDENT_STORY,
  QUERY_STUDENT_STORY_DROPDOWN_LISTS,
  UPDATE_STUDENT_STORY,
  CREATE_STUDENT_STORY
} from '../../operations/studentStories'
import GlobalDataTable from '../../components/GlobalDataTable'
import { formatUSD, stripHtmlAndCountWords } from '../../utils/helpers'
import { useSearchParams } from '../../utils/hooks'
import {
  balanceTypes,
  studentStoryMaxWordCount,
  transactionTypeIds
} from '../../constants'
import LoadingSpinner from '../../components/LoadingSpinner'

const StudentStoryForm = () => {
  // hooks
  const { session } = useContext(UserContext)
  const history = useHistory()
  const { getParamValue } = useSearchParams()
  const { id: transactionId, storyId } = useParams()
  const initialState = {
    title: '',
    studentStoryCategoryId: -1,
    studentStoryCategoryName: '',
    story: '',
    gradeId: null,
    genderId: null,
    ethnicityId: null,
    studentStoryCategoryOther: null
  }
  const [formState, setFormState] = useState(initialState)
  const [errorState, setErrorState] = useState('')
  const [otherVisible, setOtherVisible] = useState(false)
  const [wordCount, setWordCount] = useState(0)

  // vars
  const intTransactionId = parseInt(transactionId, 10)
  const intStoryId = parseInt(storyId, 10)
  const {
    currentSchool,
    currentDistrict
  } = session
  const isEditMode = !!storyId
  const returnView = getParamValue('return')
  const returnViewYer = 'yer'
  const returnViewSis = 'sis'
  const returnViewFin = 'fin'

  // define link actions
  const viewAllStoriesAction = {
    label: 'back to all impact stories',
    path: '/impact-stories'
  }
  const viewFinanceAction = {
    label: 'back to transactions',
    path: '/finance'
  }
  const viewYearEndAction = {
    label: 'back to year end report',
    path: '/year-end-report'
  }
  let breadcrumbAction = viewAllStoriesAction
  let submitActionPath = viewAllStoriesAction.path
  if (returnView === returnViewYer) {
    breadcrumbAction = viewYearEndAction
    submitActionPath = viewYearEndAction.path
  } else if (returnView === returnViewFin) {
    breadcrumbAction = viewFinanceAction
    submitActionPath = viewFinanceAction.path
  } else if (returnView === returnViewSis) {
    breadcrumbAction = viewAllStoriesAction
    submitActionPath = viewAllStoriesAction.path
  }
  if (transactionId) {
    submitActionPath = `${viewFinanceAction.path}/${transactionId}`
  }

  // queries
  const {
    loading: listsLoading,
    error: listsError,
    data: listsData
  } = useQuery(QUERY_STUDENT_STORY_DROPDOWN_LISTS)
  const {
    loading: transactionLoading,
    data: transactionData
  } = useQuery(QUERY_TRANSACTION_LITE, {
    variables: { id: intTransactionId },
    skip: !transactionId
  })
  const {
    loading: studentStoryLoading,
    error: studentStoryError
  } = useQuery(QUERY_STUDENT_STORY, {
    variables: { id: intStoryId },
    skip: !isEditMode,
    onCompleted: ({ studentStory }) => {
      setWordCount(
        stripHtmlAndCountWords(studentStory.story)
      )
      setFormState({
        ...formState,
        title: studentStory.title,
        studentStoryCategoryId: studentStory.studentStoryCategory.id,
        studentStoryCategoryName: studentStory.studentStoryCategory.name,
        story: studentStory.story,
        gradeId: studentStory.grade?.id,
        genderId: studentStory.gender?.id,
        ethnicityId: studentStory.ethnicity?.id,
        studentStoryCategoryOther: studentStory.studentStoryCategoryOther
      })
      setOtherVisible(studentStory.studentStoryCategory.name === 'Other')
    }
  })

  // mutations
  const baseMutationVariables = {
    id: intStoryId,
    transactionId: intTransactionId,
    schoolId: currentSchool?.id || null,
    districtId: currentDistrict?.id || null,
    ...formState
  }
  const [mutateStudent, {
    loading: mutationLoading
  }] = useMutation(isEditMode
    ? UPDATE_STUDENT_STORY
    : CREATE_STUDENT_STORY, {
    variables: {
      ...baseMutationVariables
    },
    onCompleted: (() => {
      history.push(submitActionPath)
    })
  })

  // custom validation
  const validWordCount = () => wordCount <= studentStoryMaxWordCount && wordCount > 0
  const validate = () => {
    let valid = true
    // validate student impact story work count
    valid = validWordCount()
    if (!valid) {
      setErrorState('The length of your story should be between 5 and 10 sentences (350 words max.)')
      return valid
    }

    return valid
  }

  // event listeners
  const handleChange = (value, field) => {
    setFormState({
      ...formState,
      [field]: value
    })
  }
  const handleContentEditorChange = (value, field) => {
    const countWords = stripHtmlAndCountWords(value)
    setWordCount(countWords)
    handleChange(value, field)
  }
  const onFormSubmit = (e) => {
    e.preventDefault()
    setErrorState('')
    if (!validate()) {
      return
    }
    mutateStudent()
      .catch((err) => {
        setErrorState(err.message)
      })
  }

  // render
  if (studentStoryLoading) return <LoadingSpinner message="Loading..." />
  if (studentStoryError) return (<p>Error: {studentStoryError.message}</p>)

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {isEditMode
            ? 'Edit Student Impact Story'
            : 'New Student Impact Story'
          }
        </h3>
        <Link to={breadcrumbAction.path} className="text-secondary">
          &lt; {breadcrumbAction.label}
        </Link>
      </div>
      <Card className="m-3 p-3">
        <div className="student-story-block">
          <div className="alert alert-info alert-student-story">
            <span className="fa-stack fa-2x story-stacked-icons mr-2">
              <FontAwesomeIcon
                icon={faCommentAlt}
                className="fa-stack-1x"
              />
              <FontAwesomeIcon
                icon={faUserGraduate}
                className="fa-stack-1x story-icon-size"
                size="xs"
              />
            </span>
            Student Impact Story
          </div>
          <div>
            <ul>
              <li>
                <b>Protect the student’s anonymity. </b>
                {'Ask yourself, “Would people in my community recognize this student from my story?” '}
                {'If the answer is “Yes”, the story must be modified. Do not share the specific number '}
                {'of siblings or other information (like specific award) that might identify a student.'}
              </li>
              <li>Do not use names or nicknames in the story.</li>
              <li>
                Use third person voice (he/she/they), past tense.
              </li>
              <li>
                <b>The story must have impact. </b>
                {'The story must identify how the use of InvestED funds addressed a specific need or a '}
                {'struggle that a student faced. For example, InvestED funds were utilized to help purchase '}
                {'glasses. As a result, her grades started to improve, and she is now making A’s and B’s.'}
              </li>
              <li>
                {'Do not thank InvestED; Instead, focus your remarks on '}
                the student and how they were impacted.
              </li>
              <li>
                The first time you use an acronym, please spell it out.
              </li>
              <li>
                The length of your story should be between 5 and 10 sentences (350 words max.)
              </li>
              <li>
                {'We recommend that you type your story in another program and '}
                paste the content into the text box below.
              </li>
              <li>
                <b>
                  {'After you enter the story, you must click on the submit button for '}
                  your work to be saved.
                </b>
              </li>
            </ul>
          </div>
        </div>
        <h5 className="flex-heading border-bottom mb-3">
          <div>Student Impact Story Details</div>
        </h5>
        {transactionLoading && (
          <div className="alert alert-light">Transaction loading...</div>
        )}
        {transactionData && (
          <div className="transaction-wrapper">
            <p>
              <small className="text-muted">The transaction below is for your reference</small>
            </p>
            <GlobalDataTable
              columns={[
                {
                  name: 'Date',
                  width: '115px',
                  selector: (row) => new Date(row.transactionDate).toLocaleDateString(),
                  sortable: true
                },
                {
                  name: 'Type',
                  selector: (row) => row.transactionType.name,
                  sortable: true,
                  cell: function TypeCell(row) {
                    return (
                      <div className="transaction-type-cell">
                        <div className="transaction-type" data-tag="allowRowEvents">{`${row.transactionType.name}`}</div>
                        {row.transactionType.id === transactionTypeIds.STUDENT && (
                          <div className="transaction-type secondary" data-tag="allowRowEvents">
                            {`${row.categoryCode.categoryType.name}; ${row.categoryCode.number}: ${row.categoryCode.name}`}
                          </div>
                        )}
                      </div>
                    )
                  }
                },
                {
                  name: 'Notes',
                  selector: (row) => row.notes,
                  sortable: true,
                  omit: true
                },
                {
                  name: 'Amount',
                  selector: (row) => row.totalAmount,
                  sortable: true,
                  width: '150px',
                  right: true,
                  cell: function AmountCell(row) {
                    const { balanceType } = row.transactionType
                    return (
                      <div className={balanceType === balanceTypes.DEBIT ? 'text-red' : ''}>
                        {balanceType === balanceTypes.DEBIT
                          ? formatUSD.format(-row.totalAmount)
                          : formatUSD.format(row.totalAmount)}
                      </div>
                    )
                  }
                }
              ]}
              data={[transactionData.transaction]}
              selectableRows={false}
              wrapperClassName="no-pointer"
              pagination={false}
            ></GlobalDataTable>
          </div>
        )}
        <Form className="pt-3" onSubmit={onFormSubmit}>
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          <div className="col-md-6 px-0">
            <Form.Group controlId="formGroupStoryTitle">
              <Form.Label>Story Title <span className="text-red">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={formState.title}
                onChange={(e) => handleChange(e.target.value, 'title')}
                required
              />
            </Form.Group>
            {listsLoading && (
              <div>Loading categories...</div>
            )}
            {listsLoading && (
              <div>Loading genders...</div>
            )}
            {listsLoading && (
              <div>Loading ethnicities...</div>
            )}
            {listsLoading && (
              <div>Loading grades...</div>
            )}
            {listsError && (
              <div className="alert alert-danger">
                <label>Error loading drop down select lists.</label>
                {listsError.message}
              </div>
            )}
            {listsData?.studentStoryCategories && (<>
              <Form.Group controlId="formGroupCategory">
                <Form.Label>Story Category <span className="text-red">*</span></Form.Label>
                <Form.Control
                  as="select"
                  value={formState.studentStoryCategoryId || ''}
                  onChange={(e) => handleChange(parseInt(e.target.value, 10), 'studentStoryCategoryId')}
                  required
                >
                  <option value="">Select a category...</option>
                  {listsData.studentStoryCategories.map(({ id, name }, i) => (
                    <option
                      key={i}
                      value={id}
                      data-testid="category-item"
                    >
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {otherVisible && (
                <Form.Group controlId="formGroupStoryCategoryOther">
                  <Form.Label>Other Story Category <span className="text-red">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={formState.studentStoryCategoryOther || ''}
                    onChange={(e) => handleChange(e.target.value, 'studentStoryCategoryOther')}
                    required
                  />
                </Form.Group>
              )}
            </>)}
            {listsData?.genders && (<Form.Group controlId="formGroupGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                value={formState.genderId || ''}
                onChange={(e) => handleChange(parseInt(e.target.value, 10), 'genderId')}
              >
                <option value="">Select a gender...</option>
                {listsData.genders.map((gender, i) => (
                  <option
                    key={i}
                    value={gender.id}
                    data-testid="gender-item"
                  >
                    {gender.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>)}
            {listsData?.ethnicities && (<Form.Group controlId="formGroupEthnicity">
              <Form.Label>Race/Ethnicity</Form.Label>
              <Form.Control
                as="select"
                value={formState.ethnicityId || ''}
                onChange={(e) => handleChange(parseInt(e.target.value, 10), 'ethnicityId')}
              >
                <option value="">Select an ethnicity...</option>
                {listsData.ethnicities.map((ethnicity, i) => (
                  <option
                    key={i}
                    value={ethnicity.id}
                    data-testid="ethnicity-item"
                  >
                    {ethnicity.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>)}
            {listsData?.grades && (<Form.Group controlId="formGroupGrade">
              <Form.Label>Grade</Form.Label>
              <Form.Control
                as="select"
                value={formState.gradeId || ''}
                onChange={(e) => handleChange(parseInt(e.target.value, 10), 'gradeId')}
              >
                <option value="">Select a grade level...</option>
                {listsData.grades.map((grade, i) => (
                  <option
                    key={i}
                    value={grade.id}
                    data-testid="grade-item"
                  >
                    {grade.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>)}
          </div>
          <Form.Group className="mt-4 pb-0 mb-1">
            <Form.Label>Student Impact Story <span className="text-red">*</span></Form.Label>
          </Form.Group>
          <DefaultEditor
            value={formState.story}
            onChange={(e) => handleContentEditorChange(e.target.value, 'story')}
          />
          <div className="text-right">
            <small
              className={
                validWordCount()
                  ? 'text-dark'
                  : 'text-danger'
              }
            >
              word count: {wordCount}
            </small>
          </div>
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          <hr />
          {!mutationLoading && (
            <>
              <Button variant="primary" type="submit">
                <span data-testid="submit-button">
                  Submit
                </span>
              </Button>{' '}
              <Button variant="secondary" className="text-light" href="/finance">
                Cancel
              </Button>
            </>
          )}
          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

export default StudentStoryForm
