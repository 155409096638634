import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button, Card, Col, Form, Row
} from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import { faBackspace } from '@fortawesome/pro-light-svg-icons'
import {
  QUERY_DROPDOWNLISTS,
  QUERY_ONE_STAFF,
  CREATE_STAFF,
  MUTATE_STAFF
} from '../../operations/staff'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserContext from '../../contexts/UserContext'
import { isUserAdmin, isUserDistrict } from '../../utils/lib'

const StaffForm = () => {
  const history = useHistory()
  const { id: staffId } = useParams()
  const [errorState, setErrorState] = useState('')
  const {
    user: userContext,
    session
  } = useContext(UserContext)
  const intUserId = parseInt(staffId, 10)
  const initialSchool = {
    schoolId: session.currentSchool?.id
      || userContext.userSchools.find((school) => school)?.school?.id,
    schoolPositionId: 0
  }
  const initialUserSchools = [initialSchool]
  const initialState = {
    id: staffId,
    first: '',
    last: '',
    email: '',
    districtUserId: undefined,
    districtId: session.currentDistrict?.id
      || userContext.userDistricts.find((district) => district)?.district?.id,
    investEdRoleId: 0,
    roleId: 0,
    isSubmitting: false,
    errorMessage: null,
    active: false
  }
  const [formState, setFormState] = useState(initialState)
  const [filterDistrict, setFilterDistrict] = useState()
  const [userSchools, setUserSchools] = useState(initialUserSchools)

  // vars
  const { districtId } = formState
  const isEditMode = !!staffId
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrictStaff = isUserDistrict(userContext)
  const isSchoolStaff = !(isAdmin || isDistrictStaff)

  // queries
  const {
    loading,
    data: userData,
    error
  } = useQuery(QUERY_ONE_STAFF, {
    variables: { id: intUserId },
    skip: !isEditMode,
    onCompleted: (({ user }) => {
      // get the first userSchool for district filtering purposes
      const [firstUserSchool] = user.userSchools
      const [userDistrict] = user.userDistricts

      // set the filter district if the user is school staff
      setFilterDistrict(firstUserSchool?.school?.district?.id)

      setUserSchools(user.userSchools.map((userSchool) => ({
        id: userSchool.id,
        schoolId: userSchool.school.id,
        schoolPositionId: userSchool.schoolPosition.id
      })))

      setFormState({
        ...formState,
        id: user.id,
        first: user.first,
        last: user.last,
        email: user.email,
        investEdRoleId: user.investedRole?.id,
        districtUserId: userDistrict?.id,
        districtId: userDistrict?.district?.id,
        districtName: userDistrict?.district?.id,
        districtPositionId: userDistrict?.schoolPosition?.id,
        active: user.active
      })
    })
  })
  const {
    loading: dropDownsLoading,
    error: dropDownsError,
    data: dropDownsData
  } = useQuery(QUERY_DROPDOWNLISTS)

  // mutations
  const baseMutationVariables = {
    id: formState.id,
    first: formState.first,
    last: formState.last,
    investedRoleId: formState.investEdRoleId,
    userSchools: districtId || (isSchoolStaff && isEditMode)
      ? undefined
      : userSchools,
    userDistrict: districtId
      ? {
        id: isEditMode
          ? formState.districtUserId
          : undefined,
        districtId: isEditMode
          ? undefined
          : districtId,
        schoolPositionId: formState.districtPositionId
      }
      : undefined,
    email: isEditMode
      ? undefined
      : formState.email,
    active: isAdmin && isEditMode
      ? formState.active
      : undefined
  }
  const [mutateStaff, {
    loading: mutationLoading
  }] = useMutation(isEditMode
    ? MUTATE_STAFF
    : CREATE_STAFF, {
    variables: {
      ...baseMutationVariables
    },
    onCompleted: ((data) => {
      history.push(data.id
        ? `/staff/${data.id}`
        : '/staff')
    })
  })

  if (loading) return <LoadingSpinner message="Loading..." />
  if (error) return (<p>Error: {error.message}</p>)

  /**
   * client-side validation before form submit
   * @returns {boolean}
   */
  const validateBeforeMutation = () => {
    setErrorState('')
    if (!districtId && userSchools?.length <= 0) {
      setErrorState('Please add at least one school.')
      return false
    }
    return true
  }

  // event listeners

  const handleChange = (value, field) => {
    setErrorState('')
    setFormState({
      ...formState,
      [field]: value
    })
  }
  const handleAddAnotherSchool = (e) => {
    setErrorState('')
    e.preventDefault()
    setUserSchools((prevSchools) => {
      prevSchools.push({
        ...initialSchool
      })
      return prevSchools
    })
    setFormState((prevState) => ({
      ...prevState,
      userSchools,
      districtId: undefined,
      districtUserId: undefined
    }))
  }
  /**
   * @param {object} data
   * @param {number} index
   */
  const handleChangeUserSchool = (newData, index) => {
    setErrorState('')
    setUserSchools((prevData) => {
      const nextSelection = {
        ...prevData[index],
        ...newData
      }
      // eslint-disable-next-line no-param-reassign
      prevData[index] = nextSelection
      return prevData
    })
    setFormState((prevState) => ({
      ...prevState,
      userSchools,
      districtId: undefined,
      districtUserId: undefined
    }))
  }
  /**
   * @param {number} index
   */
  const handleRemoveSchool = (index) => {
    setErrorState('')

    // don't handle if index is 0
    if (index <= 0) {
      return
    }

    setUserSchools((prevData) => {
      prevData.splice(index, 1)
      return prevData
    })
    setFormState((prevState) => ({
      ...prevState,
      userSchools,
      districtId: undefined,
      districtUserId: undefined
    }))
  }
  const onFormSubmit = (e) => {
    e.preventDefault()
    if (validateBeforeMutation()) {
      mutateStaff()
        .catch((err) => {
          setErrorState(err.message)
        })
    }
  }

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {isEditMode
            ? 'Edit Staff Account'
            : 'Add New Staff Account'
          }
        </h3>
        <Link to="/staff" className="text-secondary">
          &lt; back to staff directory
        </Link>
      </div>
      <Card className="mt-3 p-3">
        <Form className="col-md-6" onSubmit={onFormSubmit}>
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          <Form.Group controlId="formGroupFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formState.first}
              onChange={(e) => handleChange(e.target.value, 'first')}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={formState.last}
              onChange={(e) => handleChange(e.target.value, 'last')}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={formState.email}
              onChange={(e) => handleChange(e.target.value, 'email')}
              required
              disabled={staffId}
            />
          </Form.Group>
          {userData && userData.user && (
            <>
              {userData.user.userDistricts?.length > 0 && (
                <Form.Group>
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    value={userData.user.userDistricts[0].district.name}
                    readOnly
                  />
                </Form.Group>
              )}

              {isSchoolStaff && userData.user.userSchools?.length > 0 && dropDownsData?.schools && (
                <Form.Group>
                  {userData.user.userSchools.map((userSchool, index) => (<Row key={`school-staff-userSchool-${index}`}>
                    <Col>
                      {index === 0 && (
                        <Form.Label>School</Form.Label>
                      )}
                      <Form.Control
                        type="text"
                        value={userSchool.school.name}
                        readOnly
                      />
                    </Col>
                    <Col>
                      {index === 0 && (
                        <Form.Label>Position</Form.Label>
                      )}
                      <Form.Control
                        type="text"
                        value={userSchool.schoolPosition.name}
                        readOnly
                      />
                    </Col>
                  </Row>))}
                </Form.Group>
              )}
            </>
          )}
          {dropDownsLoading && (
            <div>Loading staff options...</div>
          )}
          {dropDownsError && (
            <div className="alert alert-danger">
              <label>Error loading staff options...</label>
              {dropDownsError.message}
            </div>
          )}
          {!isEditMode && dropDownsData && dropDownsData.districts?.length > 0 && (
            <>
              <Form.Group controlId="formGroupDistrictId">
                <Row>
                  <Col>
                    <Form.Label>District</Form.Label>
                    <Form.Control
                      as="select"
                      value={(formState.districtId || '').toString()}
                      onChange={(e) => setFormState({
                        ...formState,
                        schoolId: undefined,
                        districtId: parseInt(e.target.value, 10)
                      })}
                    >
                      <option value="">Select a district...</option>
                      {dropDownsData.districts.map((district) => (
                        <option
                          key={district.id}
                          value={district.id}
                          data-testid="district-option"
                        >
                          {district.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      as="select"
                      value={formState?.districtPositionId?.toString?.()}
                      onChange={(e) => setFormState({
                        ...formState,
                        districtPositionId: parseInt(e.target.value, 10)
                      })}
                      required={formState.districtId}
                    >
                      <option value="">Select a position...</option>
                      {dropDownsData?.schoolPositions.map((schoolPosition) => (
                        <option
                          key={schoolPosition.id}
                          value={schoolPosition.id}
                          data-testid="districtposition-option"
                        >
                          {schoolPosition.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <div className="pl-3 py-1 bg-faded-pale-yellow">
                or
              </div>
            </>
          )}

          {!(isSchoolStaff && isEditMode) && (<Form.Group controlId="formGroupSchoolId">
            <fieldset>
              {userSchools.map((userSchool, index) => (<Row key={`userSchool-${index}`}>
                <Col>
                {index === 0 && (
                  <Form.Label>{isEditMode ? 'Schools' : 'School'}</Form.Label>
                )}
                  <Form.Control
                    as="select"
                    value={userSchools[index]?.schoolId?.toString?.()}
                    onChange={(e) => handleChangeUserSchool({
                      schoolId: parseInt(e.target.value, 10)
                    }, index)}
                    required={!formState.districtId}
                  >
                    <option value="">Select a school...</option>
                    {dropDownsData?.schools
                      .filter((school) => (
                        filterDistrict && filterDistrict === school.district.id)
                        || (!filterDistrict))
                      .map((school) => (
                        <option
                          key={school.id}
                          value={school.id}
                          data-testid="school-option"
                        >
                          {school.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
                <Col>
                  {index === 0 && (
                    <Form.Label>{isEditMode ? 'Positions' : 'Position'}</Form.Label>
                  )}
                  <Form.Control
                    as="select"
                    value={userSchools[index]?.schoolPositionId?.toString?.()}
                    onChange={(e) => handleChangeUserSchool({
                      schoolPositionId: parseInt(e.target.value, 10)
                    }, index)}
                    required={!formState.districtId}
                  >
                    <option value="">Select a position...</option>
                    {dropDownsData?.schoolPositions.map((schoolPosition) => (
                      <option
                        key={schoolPosition.id}
                        value={schoolPosition.id}
                        data-testid="schoolposition-option"
                      >
                        {schoolPosition.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col xs="auto" className="align-self-end pb-2 px-0">
                  <Button
                    variant="link"
                    className={`m-0 p-0 text-danger ${index === 0 ? 'text-light' : ''}`}
                    onClick={() => handleRemoveSchool(index)}
                    disabled={index === 0}
                  >
                    <FontAwesomeIcon icon={faBackspace} />
                  </Button>
                </Col>
              </Row>))
            }
            </fieldset>
            {isEditMode
              && !(userData?.user?.userDistricts?.length > 0)
              && (<div className="text-right">
              <Button variant="link" onClick={handleAddAnotherSchool} className="m-0 p-0 text-info">
                add another school
              </Button>
            </div>)}
          </Form.Group>)}

          {dropDownsData && dropDownsData.investedRoles?.length > 0 && (
            <Form.Group controlId="formGroupInvestEdRole">
              <Form.Label>InvestED Role</Form.Label>
              <Form.Control
                as="select"
                value={formState.investEdRoleId?.toString()}
                onChange={(e) => handleChange(parseInt(e.target.value, 10), 'investEdRoleId')}
                required
              >
                <option value="">choose a role...</option>
                {dropDownsData.investedRoles.map((investedRole) => (
                  <option
                    key={investedRole.id}
                    value={investedRole.id}
                    data-testid="investedrole-option"
                  >
                    {investedRole.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {isAdmin && isEditMode && (
            <Form.Check
              type="checkbox"
              label="Active"
              role="checkbox"
              checked={formState.active}
              onChange={(e) => handleChange(e.target.checked, 'active')}
            />
          )}
          <hr />
          {!mutationLoading && (
            <>
              <Button variant="primary" type="submit">
                Submit
              </Button>{' '}
              <Button variant="secondary" className="text-light" href="/staff">
                Cancel
              </Button>
            </>
          )}
          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

export default StaffForm
