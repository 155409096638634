import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { faCheck, faCog } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Steps = ({
  activeStep,
  onChangeActiveStep,
  overview,
  steps,
  success
}) => {
  const [stepKeys, setStepKeys] = useState([])
  const [isNextStepLoading, setIsNextStepLoading] = useState(false)
  const [activeKeyIndex, setActiveKeyIndex] = useState()

  useEffect(() => {
    setStepKeys(steps.map(({ key }, i) => {
      if (key === activeStep) setActiveKeyIndex(i)
      return key
    }))
  }, [steps])

  return (
    <section aria-label="progress" className="report-progress px-0">
      <header className="mb-3">
        <div className="report-progress-bar">
          {
            steps.map((step, i) => {
              let sectionWidth = 150 / steps.length - 1
              if (i === 0 || i === steps.length - 1) sectionWidth /= 2
              return (
                <span
                  key={`step-bar-${step.key}`}
                  style={{ width: `${sectionWidth}%` }}
                  className={`report-progress-bar-section ${step.complete || activeStep === 'success' ? 'report-progress-bar-section--complete' : ''} ${step.active ? 'report-progress-bar-section--active' : ''}`}
                />
              )
            })
          }
        </div>
        <ul className="report-progress-list">
            {
              steps.map((step) => (
                <li
                  key={`step-progress-${step.key}`}
                  className={`report-progress-list-item ${step.complete || activeStep === 'success' ? 'report-progress-list-item--complete' : ''} ${step.active ? 'report-progress-list-item--active' : ''}`}
                  aria-current={step.active}
                >
                  <span className="report-progress-indicator">
                    {
                      step.complete || activeStep === 'success'
                        ? (
                          <FontAwesomeIcon
                            size='lg'
                            className="report-progress-icon"
                            icon={faCheck}
                          />
                        )
                        : (
                          <FontAwesomeIcon
                            size='lg'
                            className="report-progress-icon"
                            icon={step.icon}
                          />
                        )
                    }
                  </span>
                  <span className="report-progress-title">{step.title}</span>
                </li>
              ))
            }
          </ul>
      </header>
      {
        overview?.content && activeStep === 'overview' && (
          <>
            {overview.content}
            { overview.secondaryAction && overview.secondaryAction?.active && (
              <Button
                size="lg"
                variant="secondary"
                onClick={() => {
                  if (overview.secondaryAction?.action) {
                    overview.secondaryAction?.action(stepKeys[0])
                  }
                  onChangeActiveStep(undefined)
                }}
              >
                {overview.secondaryAction?.text || 'Go Back'}
              </Button>
            )
            }
            <Button
              size="lg"
              variant="primary"
              className="float-right"
              onClick={async () => {
                if (overview.primaryAction?.action) {
                  await overview.primaryAction?.action(stepKeys[0])
                }
                onChangeActiveStep(stepKeys[0])
              }}
            >
              {overview.primaryAction?.text || (
                <>
                  <FontAwesomeIcon
                    size='lg'
                    className="mr-2"
                    icon={faCheck}
                  />
                  Confirm
                </>
              )}
            </Button>
          </>
        )
      }
      {
        success?.content && activeStep === 'success' && (
          <>
            {success.content}
          </>
        )
      }
      <div>
        {
          steps.filter((({ key }) => key === activeStep))
            .map(({
              key,
              content,
              primaryAction,
              secondaryAction
            }) => (
              <React.Fragment key={`step-content-${key}`}>
                { content }
                <div className="d-flex flex-nowrap flex-row">
                  <div className="flex-shrink-0">
                    {
                      steps[activeKeyIndex]?.secondaryAction?.action && (
                        <Button
                          className="text-white flex-grow-0"
                          size="lg"
                          variant="secondary"
                          onClick={async () => {
                            const nextStep = stepKeys[activeKeyIndex - 1]
                            if (secondaryAction?.action) await secondaryAction.action(nextStep)
                            onChangeActiveStep(stepKeys[activeKeyIndex + 1])
                          }}
                        >
                          {steps[activeKeyIndex]?.secondaryAction?.text || 'Go Back'}
                        </Button>
                      )
                    }
                  </div>
                  <div className="flex-grow-1 d-flex justify-content-end align-items-top">
                    {
                      steps[activeKeyIndex]?.primaryAction?.warning && (
                        <div className="col-6 small text-end">
                          {steps[activeKeyIndex]?.primaryAction?.warning}
                        </div>
                      )
                    }
                    <div className="flex-shrink-0">
                      {
                        steps[activeKeyIndex]?.primaryAction?.action && (
                          <Button
                            size="lg"
                            variant="primary"
                            disabled={(
                              isNextStepLoading
                              || steps[activeKeyIndex].primaryAction.disabled)
                            }
                            onClick={async () => {
                              setIsNextStepLoading(true)
                              const nextStep = stepKeys[activeKeyIndex + 1]
                              // onChangeActiveStep(nextStep)
                              if (primaryAction?.action) await primaryAction.action(nextStep)
                              setIsNextStepLoading(false)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={isNextStepLoading ? faCog : faCheck}
                              className={`mr-2 ${isNextStepLoading ? 'fa-spin' : ''}`}
                            />
                            {steps[activeKeyIndex]?.primaryAction?.text || 'Confirm'}
                          </Button>
                        )
                      }
                    </div>
                  </div>
                </div>
                {
                  !!(
                    steps[activeKeyIndex]?.tertiaryAction?.action
                    && steps[activeKeyIndex]?.tertiaryAction?.text
                  ) && (
                    <div className="d-flex flex-nowrap flex-row my-3">
                      <div className="flex-grow-1 d-flex justify-content-end align-items-top">
                        <div className="flex-shrink-0">
                          {
                            steps[activeKeyIndex].tertiaryAction.action && (
                              <Button
                                variant="link"
                                onClick={async () => {
                                  if (steps[activeKeyIndex].tertiaryAction.action) {
                                    await steps[activeKeyIndex].tertiaryAction.action()
                                  }
                                }}
                              >
                                {steps[activeKeyIndex].tertiaryAction.text}
                              </Button>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
              </React.Fragment>
            ))
        }
      </div>
    </section>
  )
}

Steps.defaultProps = {
  activeStep: '',
  onChangeActiveStep: null,
  overview: null
}

Steps.propTypes = {
  activeStep: PropTypes.string,
  onChangeActiveStep: PropTypes.func,
  overview: PropTypes.shape({
    content: PropTypes.node.isRequired,
    primaryAction: PropTypes.shape({
      action: PropTypes.func,
      active: PropTypes.bool,
      text: PropTypes.string
    }),
    secondaryAction: PropTypes.shape({
      action: PropTypes.func,
      active: PropTypes.bool,
      text: PropTypes.string
    })
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.shape().isRequired,
      complete: PropTypes.bool,
      content: PropTypes.node.isRequired,
      primaryAction: PropTypes.shape({
        action: PropTypes.func,
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        text: PropTypes.string,
        warning: PropTypes.node
      }),
      secondaryAction: PropTypes.shape({
        action: PropTypes.func,
        active: PropTypes.bool,
        text: PropTypes.string
      }),
      tertiaryAction: PropTypes.shape({
        action: PropTypes.func,
        active: PropTypes.bool,
        text: PropTypes.string
      })
    }).isRequired
  ).isRequired,
  success: PropTypes.shape({
    content: PropTypes.node.isRequired
  })
}

export default Steps
