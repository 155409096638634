import { gql } from '@apollo/client'

export const QUERY_FILTER_LISTS = gql`
  query {
    genders {
      id
      name
    }
    ethnicities {
      id
      name
    }
    grades {
      id
      name
    }
    transactionTypes {
      id
      name
    }
    categoryTypes {
      id
      name
      categoryCodes {
        id
        name
      }
    }
  }
`

export const QUERY_TRANSACTION_STUDENTS_REPORT = gql`
  query GetTransactionStudentsByDate(
    $startDate: Timestamp
    $endDate: Timestamp
    $schoolId: [Int]
  ) {
    schools(id: $schoolId) {
      name
      transactions(transactionDateRange: [$startDate, $endDate]) {
        transactionDate
        categoryCode {
          id
          name
          number
          categoryType {
            name
          }
        }
        studentTransactions {
          id
          amount
          student {
            first
            last
            gender {
              name
            }
            ethnicity {
              name
            }
            grade {
              name
            }
          }
        }
      }
    }
  }
`

export const QUERY_TRANSACTIONS_REPORT = gql`
  query GetTransactionsByDate(
    $startDate: Timestamp,
    $endDate: Timestamp,
    $transactionTypeId: [Int]
    $schoolId: [Int]
  ) {
    transactions(
      transactionDateRange: [$startDate, $endDate]
      transactionTypeId: $transactionTypeId
      schoolId: $schoolId
    ) {
      id
      totalAmount
      donor
      transactionType {
        name
        balanceType
      }
      categoryCode {
        id
        name
        number
        categoryType {
          name
        }
      }
      transactionDate
      notes
      quantity
      studentTransactions {
        id
      }
      balance
    }
  }
`
export const QUERY_STUDENTS_ROSTER_REPORT = gql`
  query GetStudentRosterReport(
    $schoolId: [Int]
  ) {
    schools(id: $schoolId) {
      name
      students {
        id
        first
        last
        grade {
          name
        }
        gender {
          name
        }
        ethnicity {
          name
        }
        active
      }
    }
  }
`
