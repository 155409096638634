import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Card,
  Col,
  Container,
  Row
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { flattenNameProperties } from '../../utils/helpers'
import {
  reportPages,
  reportFormats
} from '../../constants'
import UserContext from '../../contexts/UserContext'
import {
  exportStudentRosterPdf,
  exportTransactionsPdf,
  exportTransactionStudentsPdf
} from '../../utils/pdf'
import {
  exportStudentRosterXlsx,
  exportTransactionStudentsXlsx,
  exportTransactionsXlsx
} from '../../utils/xlsx'
import {
  QUERY_STUDENTS_ROSTER_REPORT,
  QUERY_TRANSACTION_STUDENTS_REPORT,
  QUERY_TRANSACTIONS_REPORT
} from '../../operations/reports'
import { QUERY_CURRENT_SCHOOL_YEAR_START_DATE } from '../../operations/schoolYear'
import CustomizeReport from './CustomizeReport'
import LoadingSpinner from '../../components/LoadingSpinner'
import { headersDictionary } from '../../utils/reports.columns'
import { isUserAdmin, isUserDistrict } from '../../utils/lib'
import SchoolDropdown from '../../components/SchoolDropdown'
import ExportActionButtons from '../../components/ExportActionButtons'

const initialReportOptions = {
  queryToRun: () => {},
  reportTitle: '',
  currentSchoolName: '',
  initialVariables: {},
  format: reportFormats.PDF,
  exportFunctionToRun: () => {}
}

const ReportsDashboard = () => {
  const [error, setError] = useState(null)
  const [reportHeaders, setReportHeaders] = useState([])
  const [filteredReportData, setFilteredReportData] = useState(null)
  const [isRefetching, setIsRefetching] = useState(false)

  // * reportParam has a useEffect subscription
  const { report: reportParam } = useParams()

  // * customReportOptions has a useEffect subscription
  const [customReportOptions, setCustomReportOptions] = useState(initialReportOptions)

  // * reportData has a useEffect subscription
  const [reportData, setReportData] = useState([])

  // * queryCompleted has a useEffect subscription
  const [queryCompleted, setQueryCompleted] = useState(null)

  // vars
  const isCustomizeMode = !!reportParam
  const {
    user: userContext,
    session,
    setCurrentSchoolAndDistrict
  } = useContext(UserContext)
  const { userSchools } = userContext
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const { currentSchool } = session
  const currentDate = new Date()
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime()
  const endDate = currentDate.getTime()

  // queries
  const useAsyncSchoolYearQuery = () => {
    const { refetch } = useQuery(QUERY_CURRENT_SCHOOL_YEAR_START_DATE, { skip: true })
    return refetch
  }
  const getSchoolYear = useAsyncSchoolYearQuery()

  const [lazyGetTransactionsByDate, {
    loading: transactionsLoading
  }] = useLazyQuery(QUERY_TRANSACTIONS_REPORT, {
    onCompleted: (data) => {
      const transactions = data.transactions || []
      setQueryCompleted(transactions)
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  // student transactions query
  const [lazyGetTransactionStudentsByDate, {
    loading: transactionStudentsLoading
  }] = useLazyQuery(QUERY_TRANSACTION_STUDENTS_REPORT, {
    onCompleted: (data) => {
      const transactionStudents = []

      // loop through schools
      data.schools.forEach((school) => {
        const { name: schoolName } = school

        // loop through transactions within a school
        school.transactions.forEach((transaction) => {
          // loop through students within a transaction
          transaction.studentTransactions.forEach((studentTransaction) => {
            const { student, amount } = studentTransaction

            // gather data into a flat object
            const flatTransactionStudent = {
              amount,
              ...transaction,
              ...flattenNameProperties(student),
              category: transaction.categoryCode,
              studentName: `${student.last}, ${student.first}`,
              schoolName
            }

            transactionStudents.push(flatTransactionStudent)
          })
        })
      })

      // set data to state
      setQueryCompleted(transactionStudents)
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  // student roster query
  const [lazyGetStudentRoster, {
    loading: studentsLoading
  }] = useLazyQuery(QUERY_STUDENTS_ROSTER_REPORT, {
    onCompleted: (data) => {
      const students = []

      // loop through schools to get students
      data.schools.forEach((school) => {
        const { name: schoolName } = school

        // loop through students to format/flatten the properties
        school.students.forEach((student) => {
          const flatStudent = {
            ...flattenNameProperties(student),
            studentName: `${student.last}, ${student.first}`,
            schoolName
          }

          students.push(flatStudent)
        })
      })

      setQueryCompleted(students)
    },
    onError: (err) => {
      setError(err.message)
    }
  })

  /**
   * defines properties for specific reports based on report name and desired format
   * @param {string} reportName - report name: see reportPages for definitions
   * @param {string} format - report format: see reportFormats for definitions
   * @returns {Object} - reportOptions
   */
  const defineReportOptions = async (reportName, format) => {
    setIsRefetching(true)
    // declares which lazyQuery to use
    let queryToRun

    // declares which export function to use
    let exportFunctionToRun

    // declares variables to use with queryToRun
    let variables = {
      schoolId: [currentSchool?.id],
      endDate
    }

    // declares specific filtering capabilities
    let filterOptions = {
      showTransactionFilters: false,
      showStudentFilters: false
    }

    // await first day of school year
    let startDateForReport = firstDayOfMonth

    // if we are defining options for a YTD report, we need to wait for the school year query
    if ([
      reportPages.transactionsYtd,
      reportPages.studentTransactions
    ].includes(reportName)) {
      const {
        data: schoolYearData
      } = await getSchoolYear()

      if (schoolYearData.schoolYear) {
        const intYear = parseInt(schoolYearData.schoolYear.startDate, 10)
        const date = new Date(intYear)
        const offset = date.getTimezoneOffset()
        startDateForReport = new Date(intYear + offset * 100000).getTime()
      }
    }

    // transactions report options (YTD or MTD)
    if ([
      reportPages.transactionsMtd,
      reportPages.transactionsYtd
    ].includes(reportName)) {
      variables = {
        ...variables,
        startDate: startDateForReport
      }
      filterOptions = {
        ...filterOptions,
        showTransactionFilters: true
      }
      queryToRun = lazyGetTransactionsByDate
      exportFunctionToRun = format === reportFormats.XLSX
        ? exportTransactionsXlsx
        : exportTransactionsPdf
    }

    // transaction students report options
    if (reportName === reportPages.studentTransactions) {
      variables = {
        ...variables,
        startDate: startDateForReport
      }
      filterOptions = {
        ...filterOptions,
        showStudentFilters: true,
        showTransactionFilters: true
      }
      queryToRun = lazyGetTransactionStudentsByDate
      exportFunctionToRun = format === reportFormats.XLSX
        ? exportTransactionStudentsXlsx
        : exportTransactionStudentsPdf
    }

    // student roster report options
    if (reportName === reportPages.studentRoster) {
      variables = {
        schoolId: [currentSchool.id]
      }
      filterOptions = {
        ...filterOptions,
        showStudentFilters: true
      }
      queryToRun = lazyGetStudentRoster
      exportFunctionToRun = format === reportFormats.XLSX
        ? exportStudentRosterXlsx
        : exportStudentRosterPdf
    }

    // * FUTURE run student impact stories report

    // * FUTURE YER reports

    setIsRefetching(false)

    return {
      variables,
      filterOptions,
      queryToRun,
      exportFunctionToRun
    }
  }

  // event handlers
  const handleSchoolChange = (value, entity) => {
    if (entity === 'school') {
      setCurrentSchoolAndDistrict(value, {})
    }
  }

  // export handler for dashboard view
  const reportClickHandler = async (format, report) => {
    const reportOptions = await defineReportOptions(report, format)

    // set the column headers for the report before we need to set the data
    setReportHeaders(headersDictionary[report])

    // set query options to the state
    return setCustomReportOptions({
      ...customReportOptions,
      queryToRun: reportOptions.queryToRun,
      queryToRefetch: reportOptions.queryToRefetch,
      initialVariables: reportOptions.variables,
      reportTitle: report.replace(/[-]/i, ' '),
      reportKey: report,
      exportFunctionToRun: reportOptions.exportFunctionToRun,
      filterOptions: reportOptions.filterOptions,
      format
    })
  }

  // export handler for customized view
  const customReportClickHandler = async (format, report) => {
    const reportOptions = await defineReportOptions(report, format)
    reportOptions.exportFunctionToRun({
      titlePrefix: currentSchool.name,
      data: filteredReportData || reportData
    })
  }

  // render options
  const anyLoading = () => transactionsLoading
    || transactionStudentsLoading
    || studentsLoading

  const reportCardsOptions = [
    {
      groupTitle: 'Finance Reports',
      key: 'finance',
      cards: [
        {
          title: 'Monthly Transactions Report',
          description: 'Download this ready to go, month-to-date transactions report.',
          reportKey: reportPages.transactionsMtd
        },
        {
          title: 'Yearly Transactions Report',
          description: 'Download this ready to go, year-to-date transactions report.',
          reportKey: reportPages.transactionsYtd
        }
      ]
    },
    {
      groupTitle: 'Student Reports',
      key: 'student',
      cards: [
        {
          title: 'Student Transactions Report',
          description: 'Download this ready to go, year-to-date student transactions report.',
          reportKey: reportPages.studentTransactions
        },
        {
          title: 'Student Roster Report',
          description: 'Download your school\'s student roster.',
          reportKey: reportPages.studentRoster
        },
        {
          title: 'Student Impact Story Report',
          description: 'Download this year\'s Student Impact Stories report.',
          reportKey: reportPages.studentStories,
          omit: true
        }
      ]
    },
    {
      groupTitle: 'Year End Reports',
      key: 'yer',
      omit: true,
      cards: [
        {
          title: 'Year End Survey Report',
          description: 'Download the year end survey report.',
          reportKey: reportPages.yearEndReport
        }
      ]
    }
  ]

  // if report param is defined as a report page
  const reportName = reportPages[Object.keys(reportPages).find(
    (key) => reportPages[key] === reportParam
  )]

  //
  // * useEffect subscriptions start here
  //

  // * subscribed to the report url parameter
  // * calls event which will set customReportOptions state variable, and trigger it's useEffect
  useEffect(() => {
    if (isCustomizeMode && reportName) {
      reportClickHandler('customize', reportName)
    }
  }, [reportParam])

  // * subscribed to customReportOptions state variable, will run designated lazyQuery if conditions are right
  // * sets queryCompleted state variable, and trigger it's useEffect
  useEffect(() => {
    // clear queryCompleted state
    setQueryCompleted(null)
    const runQuery = customReportOptions.queryToRun
    if (typeof runQuery === 'function') {
      runQuery({
        variables: {
          ...customReportOptions.initialVariables
        }
      })
    }
    if (isCustomizeMode && customReportOptions.customize && reportData.length) {
      // client side filters
      const {
        genderIds,
        ethnicityIds,
        gradeSelected,
        categoryCodeId
      } = customReportOptions.initialVariables

      // apply the filters based on whats set in the variables
      const filteredData = reportData
        .filter((row) => {
          const clearances = [true]
          if (genderIds && !genderIds.includes(row.gender)) {
            clearances.push(false)
          }
          if (ethnicityIds && !ethnicityIds.includes(row.ethnicity)) {
            clearances.push(false)
          }
          if (gradeSelected && !gradeSelected.includes(row.grade)) {
            clearances.push(false)
          }
          if (categoryCodeId && !categoryCodeId.includes(row.categoryCode?.id)) {
            clearances.push(false)
          }
          return !clearances.includes(false)
        })

      // filtered data and original data match, clear the filtered data so the original data is back to normal
      if (filteredData.length === reportData.length) {
        setFilteredReportData(null)
      } else {
        setFilteredReportData(filteredData)
      }
    }
  }, [customReportOptions])

  // * subscribed to queryCompleted state variable, as well as each lazyQuery's loading property
  // * sets reportData based on data returned by lazy query, and triggers the reportData useEffect
  useEffect(() => {
    const isLoading = anyLoading()
    if (queryCompleted && !isLoading) {
      setReportData([
        ...queryCompleted
      ])
    }
  }, [
    queryCompleted,
    transactionsLoading,
    transactionStudentsLoading,
    studentsLoading
  ])

  // * subscribed to reportData state variable
  // * last useEffect in the chain
  useEffect(() => {
    // if on the dashboard page, export the data to download
    if (reportData && reportData.length > 0 && !isCustomizeMode) {
      customReportOptions.exportFunctionToRun({
        titlePrefix: currentSchool.name,
        data: reportData
      })
    }
  }, [reportData])

  //
  // * useEffect subscriptions end here
  //

  // loading
  if (anyLoading() || isRefetching) {
    return (
      <LoadingSpinner />
    )
  }

  // render a customizable report page
  if (isCustomizeMode && !anyLoading()) {
    // deconstruct filterOptions
    const { filterOptions } = customReportOptions

    return (
      <CustomizeReport
        {...customReportOptions}
        setVariables={(newVariables) => {
          setCustomReportOptions({
            ...customReportOptions,
            initialVariables: newVariables,
            customize: true
          })
        }}
        dataRows={filteredReportData || reportData}
        headers={reportHeaders}
        {...filterOptions}
      >
        <div className="text-right mt-0 pt-0 mb-2">
          <ExportActionButtons
            reportKey={customReportOptions.reportKey}
            onClick={customReportClickHandler}
            className="btn-primary"
          />
        </div>
      </CustomizeReport>
    )
  }

  // render the report dashboard
  return (
    <>
      {!currentSchool && (
        <div className="alert alert-warning">
          You must select a school to view reports
        </div>
      )}
      <Container>
        <Row className="pb-3 px-3">
          <Col className="p-0" xs={12}>
            <>
              <h3 className="page-title border-bottom pb-5">
                <label className="float-left pr-3">
                  Reports Dashboard
                </label>
                <SchoolDropdown
                  title={(currentSchool?.name || 'Select a school...')}
                  visible={isAdmin || isDistrict || (userSchools.length > 1)}
                  schoolsOnly={true}
                  appendClassName="float-left"
                  handleItemClick={handleSchoolChange}
                  viewAllItemVisible={false}
                />
              </h3>
              {error && (
                <div className="alert alert-danger">
                  {error}
                </div>
              )}
            </>
          </Col>
          {reportCardsOptions
            .filter((group) => !group.omit)
            .map((group) => (
              <Col
                xs={12}
                md={6}
                key={group.key}
              >
                <h5>{group.groupTitle}</h5>
                {group.cards
                  .filter((card) => !card.omit)
                  .map((card) => (
                    <Card
                      className="report-card border-muted mb-3"
                      key={card.reportKey}
                    >
                      <Card.Header
                        className="d-flex justify-content-between bg-light py-0 border-muted"
                      >
                        <label
                          className="pt-0 pb-2 mt-2 mb-0"
                        >
                          {card.title}
                        </label>
                        <Link
                          to={`/reports/${card.reportKey}`}
                          className={`customize-report-link link-ed-dark-blue pt-0 pb-2 mt-2 mb-0${!currentSchool ? ' btn disabled' : ''}`}
                        >
                          view and customize
                        </Link>
                      </Card.Header>
                      <Card.Body
                        className="py-2 mb-0"
                      >
                        <div
                          className="report-quick-buttons float-right pl-2 pt-2 pb-3"
                        >
                          <ExportActionButtons
                            reportKey={card.reportKey}
                            onClick={reportClickHandler}
                            disabled={!currentSchool}
                          />
                        </div>
                        <p
                          className="text-muted mb-1"
                        >
                          <small>{card.description}</small>
                        </p>
                      </Card.Body>
                    </Card>
                  ))}
              </Col>
            ))}
        </Row>
      </Container>
    </>
  )
}

export default ReportsDashboard
