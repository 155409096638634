import { gql } from '@apollo/client'

// * Queries
export const QUERY_DISTRICTS = gql`
  query {
    districts {
      id
      name
      districtCode
      phoneNumber
      addresses {
        address {
          county
          city
        }
      }
      status
    }
  }
`
export const QUERY_ONE_DISTRICT = gql`
  query (
    $id: Int
  ) {
    districts(id: $id) {
      id
      name
      addresses {
        id
        addressType {
          id
          name
        }
        address {
          id
          addressLine1
          addressLine2
          county
          city
          state
          zip
        }
      }
      districtCode
      esdNumber
      numberOfStudents
      frlPercentage
      status
      phoneNumber
      userDistricts {
        district {
          id
          name
        }
      }
    }
  }
`
export const QUERY_DISTRICTS_AND_GRADES = gql`
  query {
    districts {
      id
      name
    }
    grades {
      id
      name
      sortOrder
    }
  }
`
export const QUERY_DISTRICT_SCHOOLS = gql`
  query (
    $districtId: Int
  ) {
    schools(
      districtId: $districtId
      status: "active"
    ) {
      id
      name
    }
  }
`

// * Mutations
export const CREATE_DISTRICT = gql`
  mutation CreateDistrictMutation(
    $name: String!
    $districtCode: String
    $esdNumber: String
    $numberOfStudents: Int
    $frlPercentage: String
    $status: String!
    $phoneNumber: String!
    $addresses: [EntityAddressInput]
  ) {
    createDistrict(
      name: $name
      districtCode: $districtCode
      esdNumber: $esdNumber
      numberOfStudents: $numberOfStudents
      frlPercentage: $frlPercentage
      status: $status
      phoneNumber: $phoneNumber
      addresses: $addresses
    ) {
      id
    }
  }
`
export const MUTATE_DISTRICT = gql`
  mutation UpdateDistrictMutation(
    $id: Int!
    $name: String
    $districtCode: String
    $esdNumber: String
    $numberOfStudents: Int
    $frlPercentage: String
    $status: String
    $phoneNumber: String
    $addresses: [EntityAddressUpdateInput]
  ) {
    updateDistrict(
      id: $id
      name: $name
      districtCode: $districtCode
      esdNumber: $esdNumber
      numberOfStudents: $numberOfStudents
      frlPercentage: $frlPercentage
      status: $status
      phoneNumber: $phoneNumber
      addresses: $addresses
    ) {
      affectedRows
      data {
        id
      }
    }
  }
`
