import React from 'react'
import UserContext from '../contexts/UserContext'
import CoordinatorDashboard from './user/Dashboard'
import AdminDashboard from './admin/Dashboard'
import { isUserAdmin } from '../utils/lib'

const Dashboard = () => (
  <UserContext.Consumer>
    {({ user }) => (
      isUserAdmin(user.role.name)
        ? <AdminDashboard />
        : <CoordinatorDashboard />
    )}
  </UserContext.Consumer>
)

export default Dashboard
