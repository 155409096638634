import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { formatUSD } from '../utils/helpers'

// TODO add jsdocs and deconstructed properties
// TODO the slice reverse slice map wont be needed when we get the query right in a near future feature branch
const RecentActivity = (props) => (
  <div className="recent-activity">
    <div>
      {props.transactions.length === 0 && <div className="text-center mb-3">There are no transactions to show.</div>}
      {props.transactions
        .slice(0)
        .reverse()
        .slice(0, 3)
        .map((transaction, i) => {
          // Balance adjustments can be 'credit', 'debit', or 'both'.
          // This handles the 'both' case based on the value
          let parsedBalanceType = transaction.transactionType.balanceType
          if (parsedBalanceType === 'both') {
            parsedBalanceType = transaction.totalAmount < 0
              ? 'debit'
              : 'credit'
          }
          return (
            <div key={i} className="recent-transaction border-bottom mb-1">
              <div className="transaction-statement mb-1">
                <div className="transaction-summary">
                  {`${transaction.transactionType.name}`}
                  {transaction.donor && ` from ${transaction.donor}`}
                  {` for the amount of ${formatUSD.format(transaction.totalAmount)} was `}
                  {`${parsedBalanceType.toLowerCase()}ed.`}
                </div>
                <div className="time-since-transaction">{moment(transaction.transactionDate).fromNow()}</div>
              </div>
            </div>
          )
        })}
    </div>
    <Link to="/finance" className="view-ledger-link">View Ledger</Link>
  </div>
)

RecentActivity.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({
    transactionType: PropTypes.shape({
      name: PropTypes.string,
      balanceType: PropTypes.string
    }),
    transactionDate: PropTypes.number,
    donor: PropTypes.string,
    totalAmount: PropTypes.number
  })).isRequired
}

export default RecentActivity
