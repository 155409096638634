import { gql } from '@apollo/client'

// * queries
export const QUERY_TRANSACTION = gql`
  query (
    $id: Int!
  ) {
    transaction(id: $id) {
      totalAmount
      school {
        name
      }
      transactionType {
        id
        name
      }
      categoryCode {
        id
        name
        number
        categoryType {
          id
          name
        }
      }
      quantity
      ethnicityBreakdown
      genderBreakdown
      transactionDate
      donor
      checkNumber
      notes
      approved
      studentTransactions {
        id
        student {
          id
        }
        amount
        notes
      }
    }
  }
`
export const QUERY_TRANSACTION_LITE = gql`
  query (
    $id: Int!
  ) {
    transaction(id: $id) {
      totalAmount
      transactionType {
        name
      }
      categoryCode {
        name
        categoryType {
          name
        }
      }
      transactionDate
      notes
    }
  }
`

export const QUERY_TRANSACTIONS = gql`
  query GetTransactions(
    $districtId: Int,
    $schoolYearId: Int,
    $schoolId: [Int],
    $includeStartBalance: Boolean
  ) {
    transactions(districtId: $districtId, schoolId: $schoolId, schoolYearId: $schoolYearId, includeStartBalance: $includeStartBalance) {
      id
      createdBy {
        first
        last
      }
      createdAt
      school {
        name
      }
      district {
        name
      }
      transactionType {
        id
        name
        balanceType
      }
      transactionDate
      categoryCode {
        name
        number
        categoryType {
          name
        }
      }
      donor
      notes
      totalAmount
      balance
      quantity
      approved
      studentStory {
        id
      }
    }
  }
`
export const QUERY_STUDENT_TRANSACTIONS = gql`
  query GetTransaction(
    $id: Int!
  ) {
    transaction(id: $id) {
      createdAt
      studentTransactions {
        student {
          id
          first
          last
          grade {
            name
          }
          gender {
            name
          }
          ethnicity {
            name
          }
          school {
            name
          }
        }
        notes
        amount
      }
      studentStory {
        id
        title
        story
        grade {
          name
        }
        gender {
          name
        }
        ethnicity {
          name
        }
        studentStoryCategory {
          id
          name
        }
        studentStoryCategoryOther
        createdBy {
          first
          last
        }
      }
    }
  }
`
export const QUERY_TRANSACTION_TYPES = gql`
  query {
    transactionTypes {
      id
      name
      investedOnly
    }
  }
`
export const QUERY_ACTIVE_CATEGORY_TYPES = gql`
  query {
    activeCategoryTypes {
      id
      name
      categoryCodes {
        id
        name
        number
      }
    }
  }
`

// * mutations
export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction(
    $schoolYearId: Int,
    $schoolId: Int
    $districtId: Int
    $transactionTypeId: Int!
    $categoryCodeId: Int
    $totalAmount: Float!
    $checkNumber: String
    $quantity: Int
    $notes: String
    $transactionDate: Timestamp!
    $donor: String
    $ethnicityBreakdown: JSON
    $genderBreakdown: JSON
    $students: [TransactionStudentInput]
  ) {
    createTransaction(
      schoolYearId: $schoolYearId,
      schoolId: $schoolId,
      districtId: $districtId,
      categoryCodeId: $categoryCodeId,
      transactionTypeId: $transactionTypeId,
      totalAmount: $totalAmount,
      checkNumber: $checkNumber,
      quantity: $quantity,
      notes: $notes,
      transactionDate: $transactionDate,
      donor: $donor,
      ethnicityBreakdown: $ethnicityBreakdown,
      genderBreakdown: $genderBreakdown,
      students: $students
    ) {
        id
      }
    }
`
export const MUTATE_TRANSACTION = gql`
  mutation UpdateTransactionMutation(
    $id: Int!
    $schoolYearId: Int,
    $schoolId: Int
    $districtId: Int
    $transactionTypeId: Int!
    $categoryCodeId: Int
    $totalAmount: Float!
    $checkNumber: String
    $quantity: Int
    $notes: String
    $transactionDate: Timestamp!
    $donor: String
    $ethnicityBreakdown: JSON
    $genderBreakdown: JSON
    $approved: Boolean
    $students: [TransactionStudentUpdateInput]
  ) {
    updateTransaction(
      id: $id
      schoolYearId: $schoolYearId
      schoolId: $schoolId
      districtId: $districtId
      transactionTypeId: $transactionTypeId
      categoryCodeId: $categoryCodeId
      totalAmount: $totalAmount
      checkNumber: $checkNumber
      quantity: $quantity
      notes: $notes
      transactionDate: $transactionDate
      donor: $donor
      ethnicityBreakdown: $ethnicityBreakdown
      genderBreakdown: $genderBreakdown
      approved: $approved
      students: $students
    ) {
      affectedRows
      data {
        id
      }
    }
  }
`
export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction(
    $id: Int!
  ) {
    deleteTransaction( id: $id ) {
      affectedRows
    }
  }
`
export const APPROVE_TRANSACTION = gql`
  mutation ApproveTransaction(
    $id: Int!
  ) {
    approveTransaction( id: $id ) {
      data {
        id
      }
    }
  }
`
