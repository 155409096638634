import React from 'react'
import { Image } from 'react-bootstrap'

/**
 * Simple Not Found component to display when no valid route is found by router
 * @returns {any}
 */
export default function NotFound() {
  return (
    <div className="NotFound text-center m-3">
      <Image alt="SPACE Portal banner image" src="/space-portal-banner.jpg" width="100%"/>
      <h2 className="mt-4"><strong>404 Page Not Found</strong></h2>
      <h2 className="mt-4">Uh oh, looks like you visited a page that is out of this galaxy.</h2>
      <p>We’ve notified our SPACE crew to get you back on track.</p>
    </div>
  )
}
