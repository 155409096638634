import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Profile from './pages/user/Profile'
import SchoolsOrDistricts from './pages/user/SchoolsOrDistricts'
import SchoolForm from './pages/user/SchoolForm'
import DistrictForm from './pages/user/DistrictForm'
import Finance from './pages/user/Finance'
import Dashboard from './pages/Dashboard'
import Admin from './pages/admin/Admin'
import Personnel from './pages/admin/Personnel'
import PersonnelForm from './pages/admin/PersonnelForm'
import PrivateContact from './pages/user/PrivateContact'
import Staff from './pages/user/Staff'
import StaffForm from './pages/user/StaffForm'
import StudentDirectory from './pages/user/StudentDirectory'
import NotFound from './pages/NotFound'
import { logout } from './utils/authToken'
import StudentForm from './pages/user/StudentForm'
import Resources from './pages/user/Resources'
import TransactionForm from './pages/user/TransactionForm'
import StudentStoryForm from './pages/user/StudentStoryForm'
import ReportsDashboard from './pages/reports/ReportsDashboard'
import YearEndReportDashboard from './pages/reports/YearEndReportDashboard'
import StudentStories from './pages/user/StudentStories'
import YearEndStatus from './pages/admin/YearEndStatus'
import AdminStudentStories from './pages/admin/AdminStudentStories'

const PrivateRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/dashboard" />
    </Route>
    <Route exact path="/dashboard">
      <Dashboard />
    </Route>
    <Route exact path="/profile">
      <Profile />
    </Route>
    <Route exact path="/students">
      <StudentDirectory />
    </Route>
    <Route exact path="/students/new">
      <StudentForm />
    </Route>
    <Route exact path="/students/edit/:id">
      <StudentForm />
    </Route>
    <Route exact path="/schools/new">
      <SchoolForm
        title="New School"
        backPath="/schools"
        backLabel="&lt; back to school directory"
      />
    </Route>
    <Route exact path="/schools/districts">
      <SchoolsOrDistricts
        title="District Directory"
        path="/schools/districts"
        newLabel="+ Add District"
        otherPath="/schools"
        otherLabel="Manage Schools"
        isDistrict
      />
    </Route>
    <Route exact path="/schools/:id?">
      <SchoolsOrDistricts
        title="School Directory"
        path="/schools"
        newLabel="+ Add School"
        otherPath="/schools/districts"
        otherLabel="Manage Districts"
      />
    </Route>
    <Route exact path="/schools/districts/new">
      <DistrictForm
        title="New District"
        backPath="/schools/districts"
        backLabel="&lt; back to district directory"
      />
    </Route>
    <Route exact path="/schools/edit/:id">
      <SchoolForm
        title="School"
        backPath="/schools"
        backLabel="&lt; back to school directory"
      />
    </Route>
    <Route exact path="/schools/districts/edit/:id">
      <DistrictForm
        title="District"
        backPath="/schools/districts"
        backLabel="&lt; back to district directory"
      />
    </Route>
    <Route exact path="/staff">
      <Staff />
    </Route>
    <Route exact path="/staff/new">
      <StaffForm />
    </Route>
    <Route exact path="/staff/edit/:id">
      <StaffForm />
    </Route>
    <Route exact path="/admin">
      <Admin />
    </Route>
    <Route exact path="/admin/personnel/new">
      <PersonnelForm />
    </Route>
    <Route exact path="/admin/personnel/:userId?">
      <Personnel />
    </Route>
    <Route
      exact
      path="/admin/personnel/edit/:userId"
      component={PersonnelForm}
    />
    <Route exact path="/admin/year-end-status">
      <YearEndStatus />
    </Route>
    <Route exact path="/admin/impact-stories-export">
      <AdminStudentStories />
    </Route>
    <Route exact path="/finance/new">
      <TransactionForm
        title="Transaction"
        backPath="/finance"
        backLabel="&lt; back to finance"
      />
    </Route>
    <Route exact path="/finance/edit/:id">
      <TransactionForm
        title="Transaction"
        backPath="/finance"
      />
    </Route>
    <Route exact path="/finance/student-story/new/:id?">
      <StudentStoryForm />
    </Route>
    <Route exact path="/finance/student-story/edit/:storyId">
      <StudentStoryForm />
    </Route>
    <Route exact path="/finance/:transactionId?">
      <Finance />
    </Route>
    <Route exact path="/contact">
      <PrivateContact />
    </Route>
    <Route exact path="/reports/:report?">
      <ReportsDashboard />
    </Route>
    <Route path="/year-end-report">
      <YearEndReportDashboard />
    </Route>
    <Route exact path="/impact-stories">
      <StudentStories />
    </Route>
    <Route exact path="/impact-stories/new">
      <StudentStoryForm />
    </Route>
    <Route exact path="/impact-stories/edit/:storyId">
      <StudentStoryForm />
    </Route>
    <Route exact path="/resources">
      <Resources />
    </Route>
    <Route exact path="/login">
      <Redirect to="/dashboard" />
    </Route>
    <Route exact path="/logout">
      {() => {
        logout()
      }}
    </Route>
    {/* Finally, catch all unmatched routes */}
    <Route>
      <NotFound />
    </Route>
  </Switch>
)

export default PrivateRoutes
