import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faCommentAlt, faFileExcel } from '@fortawesome/pro-light-svg-icons'
import { faUserGraduate } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  exportStudentStoriesXlsx
} from '../../utils/xlsx'

import { QUERY_ALL_STUDENT_STORIES_BY_YEAR } from '../../operations/studentStories'
import { QUERY_CURRENT_SCHOOL_YEAR_NAME } from '../../operations/schoolYear'
import { useSearchParams } from '../../utils/hooks'
import StudentStoryDetails from '../../components/StudentStoryDetails'
import LoadingSpinner from '../../components/LoadingSpinner'
import SchoolYearSelector from '../../components/SchoolYearSelector'

const AdminStudentStories = () => {
  const [studentStories, setStudentStories] = useState([])
  const [isExporting, setIsExporting] = useState(false)
  const { getParamValue } = useSearchParams()
  const returnView = getParamValue('return')

  const [schoolYear, setSchoolYear] = useState({
    id: null
  })
  const {
    loading: schoolYearLoading
  } = useQuery(QUERY_CURRENT_SCHOOL_YEAR_NAME, {
    onCompleted: (data) => {
      setSchoolYear({ ...data?.schoolYear })
    }
  })

  // * queries
  const {
    loading,
    refetch: refetchStudentStories
  } = useQuery(QUERY_ALL_STUDENT_STORIES_BY_YEAR, {
    skip: schoolYearLoading,
    variables: { schoolYearId: schoolYear?.id },
    onCompleted: ({ studentStories: queriedStories = [] }) => {
      setStudentStories(queriedStories)
    }
  })

  useEffect(() => {
    refetchStudentStories()
  }, [schoolYear])

  // * renders
  if (loading || schoolYearLoading) return <LoadingSpinner message="Loading..." />

  return (
    <>
      <div className="Home">
        <div className="lander">
          <SchoolYearSelector selectedSchoolYear={schoolYear} setSchoolYear={setSchoolYear} />
          <h3 className="page-title border-bottom pb-5">
            <label className="float-left pr-3">
              Export Student Impact Stories
            </label>
          </h3>
          <Button
            className="text-light bg-ed-dark-blue"
            size="sm"
            variant="info"
            onClick={async () => {
              setIsExporting(true)
              await exportStudentStoriesXlsx({
                data: studentStories.map((story) => {
                  // Its possible that a story is defined on a district or a school, so we need
                  // to look at the object and determine which values to use for each type.
                  const [schoolAddress] = story.school?.addresses || []
                  const [districtAddress] = story.district?.addresses || []
                  const county = schoolAddress?.address
                    ? schoolAddress.address.county
                    : districtAddress?.address.county
                  const district = story.district?.name || story.school?.district.name
                  const esdNumber = story.district?.esdNumber || story.school?.esdNumber || ''

                  return {
                    school: story.school?.name,
                    district,
                    county,
                    esdNumber,
                    storyTitle: story.title,
                    storyCategory: story.studentStoryCategory?.name,
                    gender: story.gender?.name,
                    ethnicity: story.ethnicity?.name,
                    story: story.story
                  }
                })
              })
              setIsExporting(false)
            }}
          >
            <FontAwesomeIcon
              icon={isExporting ? faCog : faFileExcel}
              className={isExporting ? 'fa-spin' : ''}
            />
            {' Export Impact Stories'}
          </Button>
          {returnView === 'yer' && (
            <Link to='/year-end-report' className="text-secondary">
              &lt; back to year end report
            </Link>
          )}
        </div>
        <div className="impact-stories student-story-block m-3 pb-3">
          <div className="alert alert-info alert-student-story">
            <span className="fa-stack fa-2x story-stacked-icons mr-2">
              <FontAwesomeIcon
                icon={faCommentAlt}
                className="fa-stack-1x"
              />
              <FontAwesomeIcon
                icon={faUserGraduate}
                className="fa-stack-1x story-icon-size"
                size="xs"
              />
            </span>
            Student Impact Stories
          </div>
          {studentStories.length > 0 && studentStories.map((studentStory, index) => (
            <div className="px-3" key={studentStory.id}>
              <StudentStoryDetails studentStory={studentStory} />
              {index !== studentStories.length - 1 && (
                <hr />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AdminStudentStories
