import React, { useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Card, Form } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserContext from '../../contexts/UserContext'
import {
  QUERY_STUDENT,
  QUERY_DROPDOWNLISTS,
  CREATE_STUDENT,
  MUTATE_STUDENT
} from '../../operations/students'
import { QUERY_SCHOOLS, QUERY_ONE_SCHOOL } from '../../operations/schools'
import {
  isUserAdmin,
  isUserDistrict
} from '../../utils/lib'
import { validGradesForSchool } from '../../utils/formHelpers'

const StudentForm = () => {
  // hooks
  const {
    user: userContext,
    session
  } = useContext(UserContext)
  const { currentSchool } = session
  const history = useHistory()
  const { id: studentId } = useParams()
  const intStudentId = parseInt(studentId, 10)
  const [errorState, setError] = useState('')
  const initialState = {
    studentId,
    first: '',
    last: '',
    gradeId: -1,
    genderId: -1,
    ethnicityId: -1,
    schoolId: currentSchool?.id || null,
    active: false
  }
  const [formState, setFormState] = useState(initialState)

  // vars
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const isEditMode = !!studentId

  // queries
  const {
    loading,
    error
  } = useQuery(QUERY_STUDENT, {
    variables: {
      id: intStudentId
    },
    skip: !isEditMode,
    onCompleted: (({ student }) => {
      setFormState({
        ...formState,
        studentId: student.id,
        first: student.first,
        last: student.last,
        gradeId: student.grade.id,
        genderId: student.gender.id,
        ethnicityId: student.ethnicity.id,
        active: student.active
      })
    })
  })
  const {
    loading: dropDownsLoading,
    error: dropDownsError,
    data: dropDownsData
  } = useQuery(QUERY_DROPDOWNLISTS)
  const {
    loading: schoolsLoading,
    error: schoolsError,
    data: schoolsData
  } = useQuery(QUERY_SCHOOLS, {
    skip: (!(isAdmin || isDistrict))
  })
  const {
    loading: selectedSchoolLoading,
    error: selectedSchoolError,
    data: selectedSchoolData
  } = useQuery(QUERY_ONE_SCHOOL, {
    variables: { id: [formState.schoolId] },
    skip: !formState?.schoolId || isAdmin || isDistrict
  })
  const baseMutationVariables = {
    id: formState.studentId,
    first: formState.first,
    last: formState.last,
    gradeId: formState.gradeId,
    genderId: formState.genderId,
    ethnicityId: formState.ethnicityId,
    active: formState.active,
    schoolId: formState.schoolId
  }

  // mutations
  const [mutateStudent, {
    loading: mutationLoading
  }] = useMutation(isEditMode
    ? MUTATE_STUDENT
    : CREATE_STUDENT, {
    variables: {
      ...baseMutationVariables
    },
    onCompleted: ((data) => {
      history.push(data.id
        ? `/students/${data.id}`
        : '/students')
    })
  })

  // event listeners
  const handleChange = (value, field) => {
    setFormState({
      ...formState,
      [field]: value
    })
  }
  const onFormSubmit = (e) => {
    e.preventDefault()
    mutateStudent()
      .catch((err) => {
        setError(err.message)
      })
  }

  // render stuff
  if (loading) return <LoadingSpinner message="Loading..." />
  if (error) return (<p>Error: {error.message}</p>)

  // When an admin views the form all schools are loaded but when a non admin
  // loads the just the selected school is loaded
  const selectedSchool = selectedSchoolData
    ? selectedSchoolData.schools?.find((s) => s.id === formState?.schoolId)
    : schoolsData?.schools?.find((s) => s.id === formState?.schoolId)
  const grades = validGradesForSchool(dropDownsData?.grades, selectedSchool)

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">
          {isEditMode
            ? 'Edit Student Record'
            : 'Add New Student Record'
          }
        </h3>
        <Link to="/students" className="text-secondary">
          &lt; back to student directory
        </Link>
      </div>
      <Card className="mt-3 p-3">
        <Form className="col-md-6" onSubmit={onFormSubmit}>
          {errorState && (
            <div className="alert alert-danger">{errorState}</div>
          )}
          <Form.Group controlId="formGroupFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formState.first}
              onChange={(e) => handleChange(e.target.value, 'first')}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={formState.last}
              onChange={(e) => handleChange(e.target.value, 'last')}
              required
            />
          </Form.Group>
          {dropDownsLoading && (
            <>
              <div>Loading genders...</div>
              <div>Loading ethnicities...</div>
              <div>Loading grades...</div>
            </>
          )}
          {dropDownsError && (
            <div className="alert alert-danger">
              <label>Error loading student fields.</label>
              {dropDownsError.message}
            </div>
          )}
          {dropDownsData && (
            <>
              <Form.Group controlId="formGroupGender">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  value={formState.genderId}
                  onChange={(e) => handleChange(parseInt(e.target.value, 10), 'genderId')}
                  required
                >
                  <option value="">Select a gender...</option>
                  {dropDownsData.genders.map((gender, i) => (
                    <option
                      key={i}
                      value={gender.id}
                      data-testid="gender-item"
                    >
                      {gender.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupEthnicity">
                <Form.Label>Race/Ethnicity</Form.Label>
                <Form.Control
                  as="select"
                  value={formState.ethnicityId}
                  onChange={(e) => handleChange(parseInt(e.target.value, 10), 'ethnicityId')}
                  required
                >
                  <option value="">Select an ethnicity...</option>
                  {dropDownsData.ethnicities.map((ethnicity, i) => (
                    <option
                      key={i}
                      value={ethnicity.id}
                      data-testid="ethnicity-item"
                    >
                      {ethnicity.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupGrade">
                <Form.Label>Grade</Form.Label>
                <Form.Control
                  as="select"
                  value={formState.gradeId}
                  onChange={(e) => handleChange(parseInt(e.target.value, 10), 'gradeId')}
                  required
                >
                  <option value="">Select a grade level...</option>
                  {grades.map((grade, i) => (
                    <option
                      key={i}
                      value={grade.id}
                      data-testid="grade-item"
                    >
                      {grade.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
          {schoolsLoading && (
            <div>Loading schools...</div>
          )}
          {schoolsError && (
            <div className="alert alert-danger">
              <label>Error loading schools...</label>
              {schoolsError.message}
            </div>
          )}
          {selectedSchoolLoading && (
            <div>Loading selected school...</div>
          )}
          {selectedSchoolError && (
            <div className="alert alert-danger">
              <label>Error loading selected school...</label>
              {selectedSchoolError.message}
            </div>
          )}
          {schoolsData && (
            <Form.Group controlId="formGroupSchoolId">
              <Form.Label>School</Form.Label>
              <Form.Control
                as="select"
                value={(formState.schoolId || '').toString()}
                onChange={(e) => setFormState({
                  ...formState,
                  schoolId: parseInt(e.target.value, 10)
                })}
                required={true}
              >
                <option value="">Select a school...</option>
                {schoolsData.schools.map((school) => (
                  <option
                    key={school.id}
                    value={school.id}
                    data-testid="school-option"
                  >
                    {school.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Check
            type="checkbox"
            label="Enrolled"
            role="checkbox"
            checked={formState.active}
            onChange={(e) => handleChange(e.target.checked, 'active')}
          />
          <hr />
          {!mutationLoading && (
            <>
              <Button variant="primary" type="submit">
                Submit
              </Button>{' '}
              <Button variant="secondary" className="text-light" href="/students">
                Cancel
              </Button>
            </>
          )}
          {mutationLoading && (
            <div className="alert alert-info">Submitting...</div>
          )}
        </Form>
      </Card>
    </div>
  )
}

export default StudentForm
