import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  OverlayTrigger,
  ProgressBar,
  Tooltip
} from 'react-bootstrap'
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'

import { formatUSD } from '../utils/helpers'
import GlobalDataTable from './GlobalDataTable'

/**
 * displays account balance with IFF spending percentage and progress
 * @param {boolean} [balanceLoading=true] - flag to determine if balance data is loaded
 * @param {object} [balanceData={}] - balance data
 * @param {object} [balanceError={}] - balance error
 * @param {object} [schoolYearData={}] - school year data
 * @param {boolean} [showTransactionsLink=false] - shows a link to transactions
 * @param {string} [transactionsPath='/finance'] - pathname for transactions link
 */
const AcademicYearSummary = ({
  balanceData = null,
  className = '',
  balanceError = null,
  balanceLoading = true,
  schoolYearData = {},
  showTransactionsLink = false,
  transactionsPath = '/finance'
}) => {
  // data table column definitions
  const summaryColumns = [
    {
      name: 'Academic Year Summary',
      grow: 1,
      selector: (row) => row.transactionType
    },
    {
      name: '',
      selector: (row) => row.sum,
      width: '150px',
      right: true,
      cell: function AmountCell(row) {
        const { sum } = row
        return (
          <div className={sum < 0 ? 'text-red' : ''}>
            {formatUSD.format(sum)}
          </div>
        )
      }
    }
  ]

  return (
    <div className={`styled-table ${className}`}>
      {/* Load summary categories */}
      {balanceLoading && !balanceData && (
        <div>Loading balance...</div>
      )}
      {balanceError && (
        <div className="alert alert-danger">
          <label>Error loading transaction summary...</label>
          {balanceError.message}
        </div>
      )}
      {balanceData && balanceData.balancesByTransactionType && (
        <GlobalDataTable
          columns={summaryColumns}
          data={balanceData.balancesByTransactionType.filter(
            (transaction) => transaction.sum !== 0
          )}
          selectableRows={false}
          subHeader={false}
          pagination={false}
          wrapperClassName="no-pointer data-rows-print-border"
        />
      )}

      {/* Load summary percent and balance row */}
      {balanceError && (
        <div className="alert alert-danger">
          <label>Error loading balance summary...</label>
          {balanceError.message}
        </div>
      )}
      {balanceData && (
        <Card className="ledger-balance-row">
          <div className="ledger-balance-left-box">
            <div className="row-title-box">
              <b>Ledger Balance</b>
            </div>
            <div className="percentage-and-text-box">
              <div className="percentage-box">
                {Math.min(Math.round(balanceData.percentUsed), 100) || 0}%
              </div>
              <div className="text-box">{`of InvestED School Funds spent in ${
                schoolYearData?.name || 'the school year'
              }`}</div>
              <div className="info-icon">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip">
                      {
                        'Each year InvestED sends all partner schools a check in the fall and winter of each academic year. These checks are referred to as the '
                      }
                      <strong>InvestED School Funding (ISF)</strong>
                      {'. We ask that each school '}
                      <strong>spend 90% or more</strong>
                      {
                        ' of these two checks. The percentage labeled here is the percentage spent of the combined two checks only.'
                      }
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size={'lg'}
                  ></FontAwesomeIcon>
                </OverlayTrigger>
              </div>
            </div>
            <div
              className="progress-bar-box"
              data-testid="iff-percent-progress-bar"
            >
              <ProgressBar now={balanceData.percentUsed} />
            </div>
          </div>
          <div
            className="ledger-balance-amount"
            data-testid="ledger-balance-amount"
          >
            {formatUSD.format(balanceData.currentBalance || 0)}
          </div>
        </Card>
      )}

      {!!showTransactionsLink && (
        <div className="ledger-footer-link">
          <Link to={transactionsPath}>View Transactions</Link>
        </div>
      )}
    </div>
  )
}

AcademicYearSummary.propTypes = {
  balanceData: PropTypes.shape({}).isRequired,
  balanceError: PropTypes.shape({}),
  balanceLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  schoolYearData: PropTypes.shape({}).isRequired,
  showTransactionsLink: PropTypes.bool,
  transactionsPath: PropTypes.string
}

export default AcademicYearSummary
