import React from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import GlobalDataTable from './GlobalDataTable'

/**
 * Displays transactions in a small table
 * @date 2021-08-04
 * @param {array} [transactions=[]] - array of transactions
 */
const StudentInfoTransactions = ({
  transactions = []
}) => {
  const history = useHistory()

  const handleClick = (transactionId) => {
    history.push(`/finance/${transactionId}`)
  }

  const columns = [
    {
      name: 'Date',
      maxWidth: '110px',
      selector: (row) => new Date(row.transaction.transactionDate).toLocaleDateString(),
      sortable: false
    },
    {
      name: 'Category Code',
      sortable: false,
      selector: (row) => row.categoryCode?.name,
      cell: function CategoryCell(row) {
        return (
          <div className="text-dark" data-tag="allowRowEvents">
            {row.transaction.categoryCode?.categoryType?.name}:{' '}
            <label className="text-secondary" data-tag="allowRowEvents">{row.transaction.categoryCode?.name}</label>
          </div>
        )
      }
    },
    {
      name: 'Amount',
      maxWidth: '100px',
      selector: (row) => `$${row.transaction.totalAmount}`,
      sortable: false
    },
    {
      name: '',
      button: true,
      cell: function ActionCell(row) {
        return <Button
          variant="link"
          className="text-primary"
          size="sm"
          onClick={() => handleClick(row.transaction.id)}
        >
          view more
        </Button>
      }
    }
  ]

  return (
    <div className="mt-3">
      <h5>Recent Transactions</h5>
      <GlobalDataTable
        columns={columns}
        data={transactions}
        selectableRows={false}
        handleRowClick={(row) => handleClick(row.transaction.id)}
      />
    </div>
  )
}

StudentInfoTransactions.propTypes = {
  transactions: PropTypes.array
}

export default StudentInfoTransactions
