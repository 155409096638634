import moment from 'moment'
import XLSX from 'xlsx'
import {
  studentRosterHeaders,
  transactionsHeaders,
  transactionStudentsHeaders,
  studentStoriesHeaders
} from './reports.columns'

const dateForFileName = moment().format('YYYY-MM-DD_HHmmss')

/**
 * generates XLSX using xlsx library
 * @param {array} [data=[]] - array of arrays, zeroth is column headings, rest are data rows
 * @param {array} [colWidths=[]] - array of column widths (in character widths)
 * @param {array} [colFormats=[]] - array of format types of form { t: 'd', z: 'mm/dd/YYYY' } (t is type: s = tring, n = number, d = date, z is format for types 'n' and 'd')
 * @param {string} [fileName='InvestED.xlsx'] - full filename for the exported file
 * @param {string} [title='Report'] - title of the Excel worksheet (limited to 31 characters)
 * @returns {any}
 */
export const generateXlsx = ({
  data = [],
  colWidths = [],
  colFormats = [],
  fileName = 'InvestED.xlsx',
  title = 'Report'
}) => {
  // convert the data (an array of arrays) to an excel worksheet
  const ws = XLSX.utils.aoa_to_sheet(data)
  ws['!cols'] = colWidths

  // create a new excel workbook and append the worksheet
  const wb = XLSX.utils.book_new()

  // sheet title not to exceed an unacceptable limit of 31 characters
  // or contain any of the following characters: \ / [ ] * ?
  const sheetTitle = title.substr(0, 31).replace(/[?*/\\[\]]/g, '-')
  XLSX.utils.book_append_sheet(wb, ws, sheetTitle)

  // set cell types and formats based on values in reports.columns
  for (let row = 1; row < data.length; row += 1) {
    for (let col = 0; col < colFormats.length; col += 1) {
      if (colFormats[col].t !== 's') {
        const cell = ws[XLSX.utils.encode_cell({ r: row, c: col })]
        if (cell) {
          cell.t = colFormats[col].t
          cell.z = colFormats[col].z
        }
      }
    }
  }

  XLSX.writeFile(wb, fileName)
}

/**
 * custom sort function
 * @param {any} rowData - array or object with properties
 * @param {array} sortBy - array defining the sort order
 * @returns {array}
 */
export const customSort = ({ rowData, sortBy }) => {
  const sortByColumnKeys = sortBy.reduce((obj, item, index) => ({
    ...obj,
    [item]: index
  }), {})
  // sorting properties by given sortBy array, if a proerty is not defined from the sortBy array we assign -1 to its value so that it continues to sort
  return rowData.sort((a, b) => ((sortByColumnKeys[a] || -1) - (sortByColumnKeys[b] || -1)))
}

/**
 * formats data from a data table to an exportable xlsx
 * @param {string} [fileName=''] - file name for saving the file to the client's file system
 * @param {string} [title='Report'] - title of the Excel worksheet (limited to 31 characters)
 * @param {array} [columns=[]] - column data with formatting options
 * @param {array} [data=[]] - array of arrays, zeroth is column headings, rest are data rows
 */
export const exportDataTableHelper = ({
  fileName = 'report',
  title = 'Report',
  columns = [],
  data = []
}) => {
  // set the filename to be exported
  const exportFileName = `${fileName}.xlsx`

  // map the column names of the data table to headers array for the xlsx function
  const filteredColumns = columns
    .filter((column) => !!column.exportKey)

  const headers = filteredColumns
    .map((column) => column.name)

  const colWidths = filteredColumns
    .map((column) => ({ wch: column.widthInChars }))

  const colFormats = filteredColumns
    .map((column) => ({ t: column.dataType, z: column.format || null }))

  const keys = filteredColumns
    .map((column) => column.exportKey)

  const keysAndSelectors = filteredColumns
    .map((column) => {
      const {
        exportKey,
        selector,
        exportCell
      } = column
      return {
        exportKey,
        selector,
        exportCell
      }
    })

  // here we are working mapping the existing data array given to the table to an output format friendly to the xlsx generator
  // using the customSort method to order the properties of a row object as defined by the column headers (keys)
  const dataRows = [].concat(data).map((row) => customSort({
    rowData: Object.keys(row),
    sortBy: keys
  })
    .filter((key) => keys.includes(key))
    .map((key) => {
      const column = keysAndSelectors.find((col) => col.exportKey === key)
      // if column has cell function
      if (column.cell) {
        const cell = column.cell(row)
        // if cell is a react component and just a string is nested as children
        if (cell.props?.children && typeof cell.props.children === 'string') {
          return cell.props.children
        }
        // if cell is a react component, and more react components are nested as children
        if (cell.props?.children.length) {
          return cell.props.children.filter((component) => component.props?.children).map((component) => component.props.children).join('\n')
        }
      }
      // if none of the above is true, and if column has selector function
      if (column.selector) {
        return column.selector(row)
      }
      // if none of the above is true just return the object property by default
      return row[key]
    }))

  const rows = [headers, ...dataRows]

  // feed the xlsx generator
  generateXlsx({
    data: rows,
    colWidths,
    colFormats,
    fileName: exportFileName,
    title
  })
}

/**
 * function for exporting transactions as xlsx
 * @param {string} [titlePrefix=''] - prefix string for filename
 * @param {array} [data=[]] - data array for the table body
 */
export const exportTransactionsXlsx = ({
  titlePrefix = '',
  data = []
}) => {
  // similar to the columns we setup for the data table
  const headers = transactionsHeaders

  // * FUTURE filter out nulls and sort on the server side
  const rows = [].concat(data)
    .sort((a, b) => a.transactionDate - b.transactionDate)

  exportDataTableHelper({
    fileName: `${titlePrefix}-transactions-${dateForFileName}`,
    data: rows,
    columns: headers,
    title: 'Transactions'
  })
}

/**
 * function for exporting transaction students as xlsx
 * @param {string} [titlePrefix=''] - prefix string for filename
 * @param {array} [data=[]] - data array for the table body
 */
export const exportTransactionStudentsXlsx = ({
  titlePrefix = '',
  data = []
}) => {
  // similar to the columns we setup for the data table component
  const headers = transactionStudentsHeaders

  // * FUTURE filter out nulls and sort on the server side
  const rows = [].concat(data)
    .sort((a, b) => (a.transaction?.transactionDate || -1) - (b.transaction?.transactionDate || -1))

  exportDataTableHelper({
    fileName: `InvestED-${titlePrefix}-students-impacted-ytd-${dateForFileName}`,
    data: rows,
    columns: headers,
    title: 'Students Impacted'
  })
}

/**
 * function for exporting student impact stories as xlsx
 * @param {string} [titlePrefix=''] - prefix string for filename
 * @param {array} [data=[]] - data array for the table body
 */
export const exportStudentStoriesXlsx = ({
  data = []
}) => {
  // similar to the columns we setup for the data table component
  const headers = studentStoriesHeaders

  const rows = [].concat(data)

  exportDataTableHelper({
    fileName: `InvestED-student-impact-stories-${dateForFileName}`,
    data: rows,
    columns: headers,
    title: 'Student Impact Stories'
  })
}

/**
 * function for exporting student roster as xlsx
 * @param {string} [titlePrefix=''] - prefix string for filename
 * @param {array} [data=[]] - data array for the table body
 */
export const exportStudentRosterXlsx = ({
  titlePrefix = '',
  data = []
}) => {
  const headers = studentRosterHeaders

  // * FUTURE filter out nulls and sort on the server side
  const rows = [].concat(data)
    .sort((a, b) => (a.last - b.last))

  exportDataTableHelper({
    fileName: `InvestED-${titlePrefix}-student-roster-${dateForFileName}`,
    data: rows,
    columns: headers,
    title: 'Student Roster'
  })
}
