import React from 'react'

const initialUserSchool = {
  schoolPosition: {
    name: 'UNKNOWN'
  },
  school: {
    id: null,
    name: 'UNKNOWN'
  }
}
const initialUserDistrict = {
  schoolPosition: {
    name: 'UNKNOWN'
  },
  district: {
    id: null,
    name: 'UNKNOWN'
  }
}

export const initialUserState = {
  user: {
    id: null,
    first: 'UNKNOWN',
    last: 'UNKNOWN',
    email: 'UNKNOWN',
    investedRole: {
      name: 'UNKNOWN'
    },
    role: {
      name: 'UNKNOWN'
    },
    userSchools: [initialUserSchool],
    userDistricts: [initialUserDistrict]
  },
  session: {
    currentSchool: initialUserSchool,
    currentDistrict: initialUserSchool,
    currentSchoolYear: null
  }
}

const UserContext = React.createContext(initialUserState)

export default UserContext
