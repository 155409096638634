import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-light-svg-icons'

/**
  * Component for displaying loading spinner
  *
  * @param  {string} [message] - optional message to display under the spinning icon
  */
const LoadingSpinner = ({ message }) => (
  <div className="main-spinner">
    <FontAwesomeIcon icon={faCog} className="fa-spin" size="6x" />
    {message && (
      <label>{message}</label>
    )}
  </div>
)

LoadingSpinner.propTypes = {
  message: PropTypes.string
}

export default LoadingSpinner
