import React from 'react'
import {
  Card,
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap'

export default function Dashboard() {
  return (
    <Container>
      <Row className="pb-3 px-3">
        <Col className="p-0 banner-image-column" md={12} lg={8}>
          <Image alt="SPACE Portal banner image" className="banner-image" src="./space-portal-banner.jpg" width="100%"/>
        </Col>
        <Col className="p-0">
          <Card className="banner-text-card">
            <Card.Body>
              <p className="mb-0">
                <strong>{'Welcome to the InvestED SPACE portal.'}</strong>
                {' The central hub for our school partners to manage funding for students in need. You can get started by '}
                <a href="/finance/new">{'adding a transaction'}</a>
                {', adding '}
                <a href="/staff">{'staff'}</a>
                {', or '}
                <a href="/students">{'students'}</a>
                {" to the system, or viewing your school's "}
                <a href="/finance">{'financial ledger'}</a>
                {'. if you need assistance you can '}
                <a href="/contact">{'contact us here'}</a>
                {'.'}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
