import React, { useContext } from 'react'
import { Card, Image } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import UserContext from '../../contexts/UserContext'
import DonationLinkResources from '../../components/DonationLinkResources'
import { GET_SCHOOL_DONATION_LINK } from '../../operations/schools'

/**
 * Repository for static resources, links, and info for users
 * @returns {any}
 */
const Resources = () => {
  const { user: userContext } = useContext(UserContext)
  const { userSchools } = userContext
  const schoolId = userSchools.length ? userSchools[0].school.id : null

  const { loading, data, error } = useQuery(GET_SCHOOL_DONATION_LINK, {
    variables: { id: [schoolId] },
    skip: !schoolId
  })
  const donationUrl = data?.schools[0].donateUrl

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom">Resources</h3>
      </div>
      <Card className="m-3 p-0">
        <div className="p-3">
          {loading && <div>Loading public donation link...</div>}
          {error && <div className="alert alert-danger">{error.message}</div>}
          {donationUrl && <DonationLinkResources donationUrl={donationUrl}/>}
        </div>
        <div className="p-3">

          <h4>Program Coordinator Handbook</h4>
          <p>
            {'Please download this handbook as a reference guide throughout the year. You will find '}
            {'resources on using the platform, information about InvestED and how to use the funds.'}
          </p>
          <a
            href="./2023-2024_InvestED-Coordinator-Handbook.pdf"
            target="_blank"
            className="link-ed-dark-blue"
          >
            2023 Coordinator Handbook.pdf
          </a>

          {/* <h4 className="mt-5">Training Videos</h4>
          <p>
            {'For your convenience we have filmed multiple training videos to help you navigate '}
            {'the new SPACE Portal. Please click on any video below.'}
          </p> */}
          {/* TODO: Add videos here */}

          {/* <h4 className="mt-5">Training PowerPoint</h4> */}
          {/* TODO: Add PowerPoint here */}

          <h4 className="mt-5">Sign Up for a Training</h4>
          <p>
            {'We have multiple trainings coming up, please click the link below. If you would like to '}
            {'ask InvestED to conduct a training for your district, please contact staff at'}
            <a
              className="link-ed-dark-blue"
              target="blank"
              href="mailto:staff@invested.org"
            >
              staff@invested.org
            </a>
          </p>
          <a
            href="https://invested-wa.eventbrite.com/"
            target="blank"
            className="link-ed-dark-blue"
          >
            Sign Up for a Training
          </a>

          {/* Marketing */}
          <h4 className="mt-5">Marketing Material</h4>
          <p>
            {'Every year, InvestED gets many requests from schools for various marketing materials. '}
            {'Here are some that you are free to use to help raise money for your students.'}
          </p>

          <p className="mt-4">
            <strong><em>Marketing Packet:</em></strong>
            {' The downloadable packet can be used as a reference guide on using the following material.'}
          </p>
          <a
            href="./marketing-resource-guide.pdf"
            target="blank"
            className="link-ed-dark-blue"
          >
            Marketing Resource Guide
          </a>

          {/* Logo */}
          <p className="mt-4">
            <strong><em>InvestED Logo:</em></strong>
            {' You can place this logo on your website to link to your URL link or invested.org.'}
          </p>
          <div className="logo-download-box">
            <a
            href="./images/invested-logo.jpg"
            download
            className="link-ed-dark-blue thumbnail-box"
            >
              <Image
                alt="InvestED logo. Immediate help for students in need."
                src="./images/invested-logo.jpg"
                width="200px"
              />
              <p>click to download</p>
            </a>
          </div>

          {/* Posters */}
          <p className="mt-4">
            <strong><em>InvestED Posters:</em></strong>
            {' You can print these posters in color to hang up at your school, or around town '}
            {'to help raise more funds for your InvestED account.'}
          </p>
          <div className="posters-box">
            <div className="col-6 col-md-3">
              <a
              href="./invested-poster-1.pdf"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED marketing poster 1"
                  src="./images/invested-poster-1.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
              href="./invested-poster-2.pdf"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED marketing poster 2"
                  src="./images/invested-poster-2.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
              href="./invested-poster-3.pdf"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED marketing poster 3"
                  src="./images/invested-poster-3.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
              href="./invested-poster-4.pdf"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED marketing poster 4"
                  src="./images/invested-poster-4.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
          </div>

          {/* Social Media */}
          <p className="mt-4">
            <strong><em>Social Media:</em></strong>
            {' Please download the documents to post on social media. If you want '}
            {'an example of text to post with these images, '}
            <strong>please download the Marketing Resource Guide</strong>
            {'.'}
          </p>

          <p className="mt-4">
            <strong><em>For the InvestED School Funding Check:</em></strong>
          </p>
          <div className="posters-box">
            <div className="col-6 col-md-3">
              <a
              href="./images/iff-social-media-post-for-hs.png"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED School Funding media post for high school"
                  src="./images/iff-social-media-post-for-hs.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
              href="./images/iff-social-media-post-for-ms.png"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED School Funding media post for middle school"
                  src="./images/iff-social-media-post-for-ms.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
          </div>

          <p className="mt-4">
            <strong><em>To Promote Your Online Fundraising Page:</em></strong>
          </p>
          <div className="posters-box">
            <div className="col-6 col-md-3">
              <a
              href="./images/online-fundraising-post-1.png"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED digital fundraising media post 1"
                  src="./images/online-fundraising-post-1.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
              href="./images/online-fundraising-post-2.png"
              download
              className="link-ed-dark-blue thumbnail-box"
              >
                <Image
                  alt="InvestED digital fundraising media post 2"
                  src="./images/online-fundraising-post-2.png"
                  width="100%"
                />
                <p>click to download</p>
              </a>
            </div>
          </div>

          {/* Press Releases */}
          <p className="mt-4">
            <strong><em>Press Releases:</em></strong>
            {' If you would like to submit any press releases to promote your InvestED account, we have '}
            {'provided two for you to use. Please replace the highlighted text to customize the press release.'}
          </p>
          <div className="press-releases-box mb-4">
            <a
              download
              href="./press-release-initial-fall-funding.docx"
              className="link-ed-dark-blue"
            >
              For the Initial School Funding Check
            </a>
            <a
              download
              href="./press-release-fundraising-campaign.docx"
              className="link-ed-dark-blue"
            >
              For Your Specific Fundraising Campaign
            </a>
          </div>

          {/* <h4 className="mt-5">Frequently Asked Questions</h4> */}
        </div>
      </Card>
    </div>
  )
}
export default Resources
