import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import {
  Col,
  Row,
  Collapse,
  Button
} from 'react-bootstrap'
import { MultiSelect } from 'react-multi-select-component'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faTimes,
  faSlidersH,
  faChevronUp,
  faChevronDown
} from '@fortawesome/pro-light-svg-icons'
import GlobalDataTable from '../../components/GlobalDataTable'
import { QUERY_FILTER_LISTS } from '../../operations/reports'

/**
 * renders data table with customization options
 * @param {Array} [dataRows=[]] - data table rows
 * @param {Array} [headers=[]] - data table headers
 * @param {string} [reportTitle=''] -  page title
 * @param {string} [titlePrefix=''] - page title prefix
 * @param {Object} [initialVariables={}] - initial state and query variables object
 * @param {bool} [showTransactionFilters=false] - visibility of transaction type filters
 * @param {bool} [showStudentFilters=false] - visibility of genders, ethnicity, and grade level filters
 * @param {element} [children] - child react element
 * @param {function} [setVariables] - callback function to set variables
 * @returns {any}
 */
const CustomizeReport = ({
  dataRows = [],
  headers = [],
  reportTitle = '',
  titlePrefix = '',
  initialVariables = {},
  showTransactionFilters = false,
  showStudentFilters = false,
  children,
  setVariables = () => {}
}) => {
  const {
    startDate,
    endDate,
    transactionTypeId,
    categoryCodeId,
    genderIds,
    ethnicityIds,
    gradeIds
  } = initialVariables

  // state for filter lists data
  const [listsData, setListData] = useState({
    transactionTypes: [],
    categoryCodes: [],
    genders: [],
    ethnicities: [],
    grades: []
  })

  // state for selected filter values
  const [dateRange, setDateRange] = useState([
    new Date(startDate || null),
    new Date(endDate || null)
  ])
  const [typeSelected, setTypeSelected] = useState(
    transactionTypeId?.length
      ? transactionTypeId.map((typeId) => ({ value: typeId }))
      : []
  )
  const [categorySelected, setCategorySelected] = useState(
    categoryCodeId?.length
      ? categoryCodeId?.map((codeId) => ({ value: codeId }))
      : []
  )
  const [genderSelected, setGenderSelected] = useState(
    genderIds?.length
      ? genderIds.map((genderId) => ({ value: genderId }))
      : []
  )
  const [ethnicitySelected, setEthnicitySelected] = useState(
    ethnicityIds?.length
      ? ethnicityIds.map((ethnicityId) => ({ value: ethnicityId }))
      : []
  )
  const [gradeSelected, setGradeSelected] = useState(
    gradeIds?.length
      ? gradeIds.map((gradeId) => ({ value: gradeId }))
      : []
  )

  // ui state
  const [showFilters, setShowFilters] = useState(true)

  /**
   * map id and name properties to value and label respectively
   * @param {Array} [items=[]] - array of items to map
   * @param {string} [valueKey='id'] - optional key for the value mapping
   * @returns {any}
   */
  const mapToOptions = ({
    items = [],
    valueKey = 'id'
  }) => items.map((item) => ({
    value: item[valueKey],
    label: item.name
  }))

  // queries
  const {
    loading: listsLoading,
    error: listsError
  } = useQuery(QUERY_FILTER_LISTS, {
    onCompleted: ({
      genders: dataGenders,
      ethnicities: dataEthnicities,
      grades: dataGrades,
      transactionTypes: dataTransactionTypes,
      categoryTypes: dataCategoryTypes
    }) => {
      // simple map genders, ethnicities, grades, and transactionTypes to their own option arrays
      const genderOptions = mapToOptions({
        items: dataGenders,
        valueKey: 'name'
      })
      const ethnicityOptions = mapToOptions({
        items: dataEthnicities,
        valueKey: 'name'
      })
      const gradeOptions = mapToOptions({
        items: dataGrades,
        valueKey: 'name'
      })
      const typeOptions = mapToOptions({
        items: dataTransactionTypes
      })

      // loop through categoryTypes and categoryCodes to map to options array
      const codeOptions = []
      dataCategoryTypes.forEach(({
        name: categoryName,
        categoryCodes
      }) => {
        categoryCodes.forEach(({
          id,
          name: codeName
        }) => {
          codeOptions.push({
            value: id,
            label: `${categoryName}: ${codeName}`
          })
        })
      })

      // set the above data to the state
      setListData({
        ...listsData,
        genders: genderOptions,
        ethnicities: ethnicityOptions,
        grades: gradeOptions,
        transactionTypes: typeOptions,
        categoryCodes: codeOptions
      })
    }
  })

  // vars
  const fileNamePrefix = titlePrefix.replace(/\s/i, '-').toLowerCase()

  /**
   * maps react-multi-select-component selected items to array of values
   * @param {Array} selectedArray
   * @returns {Array}
   */
  const mapValues = (selectedArray) => selectedArray.map((selectedObject) => selectedObject.value)

  /**
   * Custom value renderer for react-multi-select-component
   * @param {Array} selected
   * @param {Array} options
   * @returns {string}
   */
  const customValueRenderer = (selected, options) => {
    const noneSelected = 'Select one or more'

    if (selected.length && options.length) {
      // returns a short value to more than one selection
      if (selected.length > 1) {
        return `${selected.length} selections`
      }

      // returns selected item label for one selection
      let customValue = ''
      const item = selected[0]
      const { label } = options.find((option) => option.value === item.value)
      customValue += `${label}`
      return customValue
    }

    // default returns short value for no selection
    return noneSelected
  }

  // useEffect subscribed to state changes and will trigger lazyQueries from parent
  useEffect(() => {
    // set all available selections to a new object
    const newVariables = {
      ...initialVariables,
      startDate: dateRange?.length
        ? dateRange[0].getTime()
        : new Date(),
      endDate: dateRange?.length
        ? dateRange[1].getTime()
        : new Date(),
      transactionTypeId: typeSelected.length
        ? mapValues(typeSelected)
        : undefined,
      categoryCodeId: categorySelected.length
        ? mapValues(categorySelected)
        : undefined,
      genderIds: genderSelected.length
        ? mapValues(genderSelected)
        : undefined,
      ethnicityIds: ethnicitySelected.length
        ? mapValues(ethnicitySelected)
        : undefined,
      gradeSelected: gradeSelected.length
        ? mapValues(gradeSelected)
        : undefined
    }

    // set variables here to trigger parent effects
    setVariables({
      ...initialVariables,
      ...newVariables
    })
  }, [
    dateRange,
    typeSelected,
    categorySelected,
    genderSelected,
    ethnicitySelected,
    gradeSelected
  ])

  return (
    <div className="Home">
      <div className="lander">
        <h3 className="page-title border-bottom pb-5">
          <label className="float-left pr-3 text-capitalize">
            Reports Dashboard: {reportTitle}
          </label>
        </h3>
        <Link to='/reports' className="text-secondary">
          &lt; back to reports dashboard
        </Link>
      </div>
      <div className="m-3">
        {(showStudentFilters || showTransactionFilters) && (
          <div className="pb-3">
            <Button
              variant="link"
              size="sm"
              className="text-dark text-decoration-none"
              onClick={() => setShowFilters(!showFilters)}
              aria-expanded={showFilters}
              aria-controls="collapsableFilters"
            >
              <FontAwesomeIcon
                icon={faSlidersH}
                className="mr-2"
              />{' '}
              {showFilters
                ? (
                  <>
                    {'Hide customization options '}
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      className="ml-2"
                    />
                  </>
                )
                : (
                  <>
                    {'Show customization options '}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="ml-2"
                    />
                  </>
                )
              }
            </Button>
          </div>
        )}
        {listsError && !listsLoading && (
          <div className="alert alert-danger">There was an error loading the customization options. Please try refreshing the page.</div>
        )}
        <Collapse in={showFilters}>
          <Row id="collapsableFilters" className="mb-3">
            {showTransactionFilters && (
              <>
                <Col
                  sm={12}
                  md={4}
                  lg={3}
                >
                  <label
                    id="dateRange"
                    className="d-block"
                  >Date Range</label>
                  <DateRangePicker
                    nativeInputAriaLabel="Custom Date Range"
                    onChange={setDateRange}
                    value={dateRange}
                    maxDate={new Date()}
                    calendarAriaLabel="Toggle calendar"
                    className="bg-light border-muted rounded text-dark"
                    calendarIcon={(
                      <FontAwesomeIcon
                        icon={faCalendar}
                      />
                    )}
                    clearIcon={(
                      <FontAwesomeIcon
                        icon={faTimes}
                      />
                    )}
                  />
                </Col>
                <Col
                  sm={6}
                  md={4}
                  lg={3}
                >
                  {/* This filter we don't want to show if we are looking at a student transactions report (because that is only ever on transaction type) */}
                  {!(showTransactionFilters && showStudentFilters) && (
                    <>
                      <label id="labelTypes">Transaction Type</label>
                      <MultiSelect
                        isLoading={listsLoading}
                        onChange={setTypeSelected}
                        options={listsData.transactionTypes}
                        value={typeSelected}
                        labelledBy="labelTypes"
                        valueRenderer={customValueRenderer}
                      />
                    </>
                  )}
                  <label id="labelCodes">Category Codes</label>
                  <MultiSelect
                    isLoading={listsLoading}
                    onChange={setCategorySelected}
                    options={listsData.categoryCodes}
                    value={categorySelected}
                    labelledBy="labelCodes"
                    valueRenderer={customValueRenderer}
                  />
                </Col>
              </>
            )}
            {showStudentFilters && (
              <Col
                sm={6}
                md={4}
                lg={6}
              >
                <Row>
                  <Col
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    <label id="labelGender">Gender</label>
                    <MultiSelect
                      isLoading={listsLoading}
                      onChange={setGenderSelected}
                      options={listsData.genders}
                      value={genderSelected}
                      labelledBy="labelGender"
                      valueRenderer={customValueRenderer}
                    />
                  </Col>
                  <Col
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    <label id="labelEthnicity">Ethnicity</label>
                    <MultiSelect
                      isLoading={listsLoading}
                      onChange={setEthnicitySelected}
                      options={listsData.ethnicities}
                      value={ethnicitySelected}
                      labelledBy="labelEthnicity"
                      valueRenderer={customValueRenderer}
                    />
                  </Col>
                  <Col
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    <label id="labelGrade">Grade</label>
                    <MultiSelect
                      isLoading={listsLoading}
                      onChange={setGradeSelected}
                      options={listsData.grades}
                      value={gradeSelected}
                      labelledBy="labelGrade"
                      valueRenderer={customValueRenderer}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Collapse>
        <div>
          {children}
        </div>
        <GlobalDataTable
          data={dataRows}
          columns={headers}
          subHeader={false}
          selectableRows={false}
          wrapperId="financeTable"
          exportFileName={`${fileNamePrefix}-finance-ledger-export-${moment().format('YYYY-MM-DD_HHmmss')}`}
          wrapperClassName="no-pointer"
        />
      </div>
    </div>
  )
}

CustomizeReport.propTypes = {
  dataRows: PropTypes.array,
  headers: PropTypes.array,
  reportTitle: PropTypes.string,
  titlePrefix: PropTypes.string,
  initialVariables: PropTypes.any,
  setVariables: PropTypes.func,
  showTransactionFilters: PropTypes.bool,
  showStudentFilters: PropTypes.bool,
  children: PropTypes.element
}

export default CustomizeReport
