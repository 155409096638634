import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCommentAlt,
  faPrint
} from '@fortawesome/pro-light-svg-icons'
import { faUserGraduate } from '@fortawesome/pro-regular-svg-icons'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { QUERY_CURRENT_SCHOOL_YEAR_NAME } from '../../operations/schoolYear'
import {
  QUERY_TRANSACTIONS,
  QUERY_TRANSACTION,
  QUERY_STUDENT_TRANSACTIONS,
  QUERY_TRANSACTION_TYPES,
  DELETE_TRANSACTION,
  APPROVE_TRANSACTION
} from '../../operations/transactions'
import GlobalDataTable from '../../components/GlobalDataTable'
import UserContext from '../../contexts/UserContext'
import { formatUSD, printThisPage } from '../../utils/helpers'
import { isUserAdmin, isUserDistrict } from '../../utils/lib'
import { useBalance, useSearchParams } from '../../utils/hooks'
import GlobalModal from '../../components/GlobalModal'
import SchoolDropdown from '../../components/SchoolDropdown'
import LoadingSpinner from '../../components/LoadingSpinner'
import { balanceTypes, reportFormats, transactionTypeIds } from '../../constants'
import ExportActionButtons from '../../components/ExportActionButtons'
import { exportDataTableHelper as exportPdf } from '../../utils/pdf'
import { exportDataTableHelper as exportXlsx } from '../../utils/xlsx'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import { formatDollarAmountCell } from '../../utils/reports.columns'
import AcademicYearSummary from '../../components/AcademicYearSummary'
import StudentStoryDetails from '../../components/StudentStoryDetails'
import SchoolYearSelector from '../../components/SchoolYearSelector'

/**
 * Component for displaying account summary info and transactions ledger
 * @returns {any}
 */
const Finance = () => {
  const {
    user: userContext,
    session,
    setCurrentSchoolAndDistrict
  } = useContext(UserContext)
  const { transactionId } = useParams()
  const { userSchools, userDistricts } = userContext
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const [schoolYear, setSchoolYear] = useState({})
  const { currentSchool, currentDistrict } = session
  const schoolId = currentSchool?.id
  const districtId = currentDistrict?.id
  const isSchoolOrDistrictSelected = !!(currentSchool?.id || currentDistrict?.id)
  const [transactions, setTransactions] = useState()
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [deleteTransactionError, setDeleteTransactionError] = useState(false)
  const [transactionModalData, setTransactionModalData] = useState(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [approveError, setApproveError] = useState(null)
  const { getParamValue } = useSearchParams()
  const returnView = getParamValue('return')

  const {
    balanceData,
    lazyBalance,
    balanceLazyLoading,
    balanceLazyError,
    balanceLoading,
    balanceError,
    refetchBalance
  } = useBalance(schoolYear)

  /**
   * determines if the ledger is for a district
   */
  const isDistrictFinance = () => (
    districtId && !schoolId
  )

  // * queries
  const [
    lazyGetStudentTransactions, {
      error: lazyError,
      loading: lazyLoading
    }
  ] = useLazyQuery(QUERY_STUDENT_TRANSACTIONS, {
    onCompleted: (studentTransactions) => {
      setTransactionModalData(studentTransactions.transaction)
    },
    fetchPolicy: 'network-only'
  })

  const [lazyGetOneTransaction] = useLazyQuery(QUERY_TRANSACTION, {
    variables: { id: parseInt(transactionId, 10) },
    onCompleted: ({ transaction }) => {
      if (transaction) {
        setSelectedTransaction({
          ...transaction,
          id: parseInt(transactionId, 10)
        })
      }
    },
    fetchPolicy: 'network-only'
  })

  const onTransactionsCompleted = (data) => {
    setTransactions(data.transactions)
  }

  const [
    lazyTransactions, {
      loading: transactionsLazyLoading,
      error: transactionsLazyError
    }] = useLazyQuery(QUERY_TRANSACTIONS, {
    errorPolicy: 'ignore',
    onCompleted: onTransactionsCompleted,
    fetchPolicy: 'network-only'
  })

  const {
    loading: transactionsLoading,
    error: transactionsError,
    refetch: refetchTransactions
  } = useQuery(QUERY_TRANSACTIONS, {
    variables: {
      districtId: isDistrictFinance() ? districtId : undefined,
      schoolId: schoolId ? [schoolId] : undefined,
      schoolYearId: schoolYear.id
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !isSchoolOrDistrictSelected,
    onCompleted: onTransactionsCompleted
  })

  const {
    data: schoolYearData
  } = useQuery(QUERY_CURRENT_SCHOOL_YEAR_NAME)

  const {
    data: transactionTypesData
  } = useQuery(QUERY_TRANSACTION_TYPES)

  // * mutations
  const [deleteTransaction, {
    loading: deleteTransactionLoading
  }] = useMutation(DELETE_TRANSACTION, {
    variables: {
      id: selectedTransaction?.id
    },
    onCompleted: () => {
      setConfirmModalOpen(false)
      setSelectedTransaction(null)
      setTransactionModalData(null)
      refetchTransactions()
    }
  })

  const [approveTransaction] = useMutation(APPROVE_TRANSACTION, {
    variables: {
      id: selectedTransaction?.id
    },
    onCompleted: () => {
      setSelectedTransaction(null)
      refetchBalance()
      refetchTransactions()
    }
  })

  const handleRefetch = () => {
    if (isSchoolOrDistrictSelected) {
      lazyBalance({
        variables: {
          districtId: districtId || undefined,
          schoolId: schoolId || undefined,
          schoolYearId: schoolYear.id
        }
      })
      lazyTransactions({
        variables: {
          districtId: districtId || undefined,
          schoolId: schoolId ? [schoolId] : undefined,
          schoolYearId: schoolYear.id
        }
      })
    } else {
      refetchBalance()
      refetchTransactions()
    }
  }

  // event listeners
  const handleEntityChange = (value, entity) => {
    const selectedSchool = entity === 'school'
      ? value
      : {}
    const selectedDistrict = entity === 'district'
      ? value
      : {}
    setCurrentSchoolAndDistrict(selectedSchool, selectedDistrict)
  }
  const rowClick = (t) => {
    setSelectedTransaction(t)
    if (t.transactionType.name === 'Student Transaction') {
      lazyGetStudentTransactions({
        variables: { id: t.id }
      })
    }
  }
  const transactionModalClose = () => {
    setSelectedTransaction(null)
    setTransactionModalData(null)
  }
  const handleDelete = () => {
    deleteTransaction()
      .then(() => {
        setSelectedTransaction(null)
        refetchBalance()
        refetchTransactions()
      })
      .catch((err) => {
        setDeleteTransactionError(err.message)
      })
  }
  const deleteModalClose = () => {
    setConfirmModalOpen(false)
    setDeleteTransactionError(false)
  }
  const handleApprove = () => {
    approveTransaction()
      .catch((err) => {
        setApproveError(err)
      })
  }

  useEffect(() => {
    if (transactionId) {
      lazyGetOneTransaction()
    }
  }, [transactionId])

  useEffect(() => {
    handleRefetch()
  }, [
    currentSchool,
    currentDistrict,
    schoolYear.id
  ])

  useEffect(() => {
    setSchoolYear(schoolYearData?.schoolYear || {})
  }, schoolYearData)

  // data table column definitions
  // const summaryColumns = [
  //   {
  //     name: 'Academic Year Summary',
  //     grow: 1,
  //     selector: (row) => row.transactionType
  //   },
  //   {
  //     name: '',
  //     selector: (row) => row.sum,
  //     width: '150px',
  //     right: true,
  //     cell: function AmountCell(row) {
  //       const { sum } = row
  //       return (
  //         <div className={sum < 0 ? 'text-red' : ''}>
  //           {formatUSD.format(sum)}
  //         </div>
  //       )
  //     }
  //   }
  // ]

  const transactionsColumns = [
    {
      name: 'Date',
      exportKey: 'transactionDate',
      dataType: 'd',
      format: 'mm/dd/yyyy',
      width: '115px',
      widthInChars: 10,
      selector: (row) => row.transactionDate,
      sortable: true,
      cell: function StudentStoryCell(row) {
        return new Date(row.transactionDate).toLocaleDateString()
      },
      pdfCell: function DateCell(row) {
        return new Date(parseInt(row.transactionDate, 10)).toLocaleDateString()
      }
    },
    {
      name: 'Type',
      exportKey: 'transactionType',
      dataType: 's',
      widthInChars: 45,
      selector: (row) => row.transactionType.name,
      sortable: true,
      cell: function TypeCell(row) {
        let transactionTypeName = row.transactionType.name
        if (row.transactionType.id === transactionTypeIds.STUDENT && row.quantity > 0) {
          transactionTypeName = 'Bulk Purchase - Student Transaction'
        }
        if ([
          transactionTypeIds.MATCHING,
          transactionTypeIds.SEED_FUNDING
        ].includes(row.transactionType.id) && row.approved === false) {
          transactionTypeName += ' - Pending'
        }
        return (
          <div className="transaction-type-cell">
            <div className="transaction-type" data-tag="allowRowEvents">{`${transactionTypeName}`}</div>
            {row.transactionType.id === transactionTypeIds.STUDENT && (
              <div className="transaction-type secondary" data-tag="allowRowEvents">
                {`${row.categoryCode.categoryType.name}; ${row.categoryCode.number}: ${row.categoryCode.name}`}
              </div>
            )}
            {row.transactionType.id === transactionTypeIds.DONATION && row.donor && (
              <div className="transaction-type secondary" data-tag="allowRowEvents">
                {`from ${row.donor}`}
              </div>
            )}
          </div>
        )
      }
    },
    {
      name: '',
      sortable: false,
      cell: function StudentStoryCell(row) {
        return row.studentStory
          ? <div className="border border-ed-dark-blue rounded py-1 px-2">
            <FontAwesomeIcon
              icon={faUserGraduate}
              size="lg"
              className="text-ed-dark-blue"
              data-tag="allowRowEvents"
            />
          </div>
          : ''
      }
    },
    {
      name: 'Notes',
      exportKey: 'notes',
      dataType: 's',
      widthInChars: 45,
      selector: (row) => row.notes,
      sortable: true,
      omit: true
    },
    {
      name: 'Amount',
      exportKey: 'totalAmount',
      dataType: 'n',
      format: '0.00',
      widthInChars: 10,
      selector: (row) => row.totalAmount,
      sortable: true,
      width: '150px',
      right: true,
      cell: (row) => (() => {
        const { balanceType } = row.transactionType
        return (
          <div className={balanceType === balanceTypes.DEBIT || row.totalAmount < 0 ? 'text-red' : ''}>
            {balanceType === balanceTypes.DEBIT
              ? formatUSD.format(-row.totalAmount)
              : formatUSD.format(row.totalAmount)}
          </div>
        )
      })(),
      pdfCell: (row) => formatDollarAmountCell({
        amount: row.totalAmount,
        balanceType: row.transactionType.balanceType
      })
    },
    {
      name: 'Balance',
      exportKey: 'balance',
      dataType: 'n',
      format: '0.00',
      selector: (row) => row.balance,
      sortable: false,
      width: '150px',
      widthInChars: 10,
      right: true,
      cell: function BalanceCell(row) {
        return (
          <div className={row.balance < 0 ? 'text-red' : ''}>
            {formatUSD.format(row.balance)}
          </div>
        )
      },
      pdfCell: (row) => formatDollarAmountCell({
        amount: row.totalAmount,
        balanceType: row.transactionType.balanceType
      })
    }
  ]

  // export event handler
  const exportHandler = (format) => {
    const currentDate = moment()
    const exportFunctionToRun = format === reportFormats.PDF
      ? exportPdf
      : exportXlsx

    exportFunctionToRun({
      fileName: `InvestED-${currentSchool.name}-${currentDate.format('YYYY-MM-DD_HHmmss')}`,
      title: `${currentSchool.name} - Finance Ledger - ${currentDate.format('YYYY')}`,
      columns: transactionsColumns,
      data: transactions,
      didParseCell: ({ cell }) => {
        const [text] = cell?.text
        // all cells that have a negative dollar amount will be red text
        if (text && text.indexOf('-$') === 0) {
          // eslint-disable-next-line no-param-reassign
          cell.styles.textColor = [237, 24, 32]
        }
      }
    })
  }

  if (balanceLoading) return <LoadingSpinner message="Loading..." />

  console.log({
    balanceError,
    balanceLazyError
  })

  // render
  return (
    <>
      {(!currentSchool && !currentDistrict) && (
        <div className="alert alert-warning">
          You must select a school or district to view its financial data
        </div>
      )}
      <div className="Home">
        <div className="lander">
          <SchoolYearSelector selectedSchoolYear={schoolYear} setSchoolYear={setSchoolYear} />
          <h3 className="page-title border-bottom pb-5">
            <label className="float-left pr-3">
              Account Ledger
            </label>
            <SchoolDropdown
              title={(
                (currentSchool?.name || currentDistrict?.name)
                || 'Select a school or district')
              }
              visible={((isAdmin || isDistrict) || userSchools.concat(userDistricts).length > 1)}
              appendClassName="float-left"
              handleItemClick={handleEntityChange}
              viewAllItemVisible={false}
            />
          </h3>
          {returnView === 'yer' && (
            <Link to='/year-end-report' className="text-secondary">
              &lt; back to year end report
            </Link>
          )}
        </div>
        <div className="m-3">
          <AcademicYearSummary
            balanceData={balanceData}
            balanceError={balanceError || balanceLazyError}
            balanceLoading={balanceLazyLoading}
            schoolYearData={schoolYear}
          />
          <div className="mt-3">
            <div className="float-right mt-0 pt-0 mb-2 text-muted d-print-none">
              Export{' '}
              <Button
                className="btn btn-sm btn-muted text-light text-uppercase mr-2"
                onClick={(e) => {
                  e.preventDefault()
                  printThisPage()
                }}
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
              <ExportActionButtons
                reportKey="finance-ledger-report"
                onClick={exportHandler}
                className="btn-muted"
                disabled={!transactions}
                enableXLSX={true}
              />
            </div>
            <h4>
              Transactions
            </h4>
          </div>
          {(!transactions?.length || transactionsLoading || transactionsLazyLoading) && (
            <div>Loading transactions...</div>
          )}
          {(transactionsError || transactionsLazyError) && (
            <div className="alert alert-danger">
              <label>Error loading transactions...</label>
              {transactionsError.message}
            </div>
          )}
          {(transactions && transactions?.length >= 0) && (
            <GlobalDataTable
              columns={transactionsColumns}
              data={transactions || []}
              selectableRows={false}
              handleRowClick={rowClick}
            />
          )}
        </div>
      </div>
      {selectedTransaction && (
        <GlobalModal
          title="Transaction Details"
          onClose={transactionModalClose}
        >
          <div className="mb-2">{'Transaction ID: '}<strong>{selectedTransaction.id}</strong></div>
          <div className="mb-2">{'Transaction Date: '}
            <strong>{new Date(selectedTransaction.transactionDate).toLocaleDateString()}</strong>
          </div>
          {
            selectedTransaction.school?.name && (
              <div className="mb-2">{'School: '}
                <strong>{selectedTransaction.school?.name}</strong>
              </div>
            )
          }
          {
            selectedTransaction.district?.name && (
              <div className="mb-2">{'District: '}
                <strong>{selectedTransaction.district?.name}</strong>
              </div>
            )
          }
          {selectedTransaction.donor && (
            <div className="mb-2">{'Donor: '}
              <strong>{selectedTransaction.donor}</strong>
            </div>
          )}
          {(!selectedTransaction.quantity || selectedTransaction.quantity <= 0) && (
            <div className="mb-2">{'Transaction Type: '}
              <strong>{selectedTransaction.transactionType.name}</strong>
            </div>
          )}
          {selectedTransaction.quantity >= 1 && (
            <>
              <div className="mb-2">{'Transaction Type: '}
                <strong>Bulk Purchase - {selectedTransaction.transactionType.name}</strong>
              </div>
              <div className="mb-2">{'Students Impacted: '}
                <strong>{selectedTransaction.quantity}</strong>
              </div>
            </>
          )}
          <div className="mb-2">{'Transaction Amount: '}
            <strong>{formatUSD.format(selectedTransaction.totalAmount)}</strong>
          </div>
          {[
            transactionTypeIds.MATCHING,
            transactionTypeIds.SEED_FUNDING
          ].includes(selectedTransaction?.transactionType.id) && (
            <div className="mb-2">{'Status: '}
              <strong>{selectedTransaction.approved ? 'Approved' : 'Pending'}</strong>
            </div>
          )}
          {selectedTransaction.notes && (
            <div className="mb-2">
              {'Notes: '}
              {selectedTransaction.notes}
            </div>
          )}
          {selectedTransaction.categoryCode?.categoryType?.name && (
            <div className="mb-2">{'Category: '}
              <strong>{selectedTransaction.categoryCode.categoryType.name}</strong>
            </div>
          )}
          {selectedTransaction.categoryCode?.categoryType?.name && (
            <div className="mb-2">{'Code: '}
              <strong>{`${selectedTransaction.categoryCode.number} - ${selectedTransaction.categoryCode.name}`}</strong>
            </div>
          )}
          {lazyError && <div className="alert alert-danger">Error loading student information.</div>}
          {lazyLoading && <div className="alert alert-info">Loading transaction details.</div>}
          {transactionModalData?.studentTransactions.length > 0 && (
            <GlobalDataTable
              columns={[
                {
                  name: 'SPACE ID',
                  width: '100px',
                  selector: (row) => row.student.id
                },
                {
                  name: 'Name',
                  selector: (row) => row.student.last,
                  cell: function NameCell(row) {
                    return (
                      <span className="ws-nowrap">
                        {`${row.student.last}, ${row.student.first}`}
                        {isDistrictFinance() && (
                          <small className="text-muted d-block">
                            {row.student.school.name}
                          </small>
                        )}
                      </span>
                    )
                  }
                },
                {
                  name: 'Notes',
                  selector: (row) => row.notes
                },
                {
                  name: 'Grade',
                  width: '75px',
                  selector: (row) => row.student.grade.name
                },
                {
                  name: 'Gender',
                  width: '90px',
                  selector: (row) => row.student.gender.name
                },
                {
                  name: 'Race/Ethnicity',
                  width: '125px',
                  selector: (row) => row.student.ethnicity.name
                }
              ]}
              data={transactionModalData.studentTransactions}
              selectableRows={false}
              wrapperClassName="no-pointer"
            />
          )}
          {selectedTransaction.transactionType.id === transactionTypeIds.STUDENT
            && !lazyLoading
            && (
              <div className="student-story-block">
                <div className="alert alert-info alert-student-story">
                  <span className="fa-stack fa-2x story-stacked-icons mr-2">
                    <FontAwesomeIcon
                      icon={faCommentAlt}
                      className="fa-stack-1x"
                    />
                    <FontAwesomeIcon
                      icon={faUserGraduate}
                      className="fa-stack-1x story-icon-size"
                      size="xs"
                    />
                  </span>
                  Student Impact Story
                </div>
                {transactionModalData?.studentStory
                  ? <StudentStoryDetails studentStory={transactionModalData.studentStory} />
                  : (<p>
                    <a href={`/finance/student-story/new/${selectedTransaction.id}`}>Click here</a> if you would like to create a student impact story for this transaction.
                  </p>)
                }
              </div>
            )}
          <hr/>
          {selectedTransaction?.createdBy && (
            <div className=""><small>{`Transaction created by ${selectedTransaction.createdBy.first} ${selectedTransaction.createdBy.last}`}</small></div>
          )}
          {selectedTransaction?.createdAt && (
            <div><small>{`Transaction created on ${moment(selectedTransaction?.createdAt).format('dddd, MMMM Do, YYYY h:mma')}`}</small></div>
          )}
          <hr/>

          {/* Only display an edit button if the user has access to this transaction type */}
          {/* If user is admin, or... */}
          {(isAdmin
            // if transaction type is not invested only and not a donation
            || ((transactionTypesData?.transactionTypes.filter((type) => (
              type.id === selectedTransaction.transactionType.id))[0].investedOnly === false)
              && selectedTransaction.transactionType.name !== 'Donation'))
            && (
            <>
              <Button
                variant="primary"
                href={`/finance/edit/${selectedTransaction.id}`}
                className="m-1"
              >
                Edit
              </Button>
              {/* Show an Approve button to admins if it's a matching or seed transaction and not already approved */}
              {([
                transactionTypeIds.MATCHING,
                transactionTypeIds.SEED_FUNDING
              ].includes(selectedTransaction?.transactionType.id)
                && !selectedTransaction?.approved
              ) && (
                <Button
                  variant="primary"
                  onClick={handleApprove}
                  className="ml-1 mr-1"
                >
                  Approve
                </Button>
              )}
              <Button
                variant="outline-danger"
                onClick={() => setConfirmModalOpen(true)}
                className="m-1 delete-button-light"
              >
                Delete
              </Button>
              {approveError && (
                <div className="alert alert-danger mt-2">There was an error approving the transaction.</div>
              )}
            </>
            )
          }
        </GlobalModal>
      )}

      {/* Confirmation modal for deleting a transaction */}
      <DeleteConfirmationModal
        showModal={confirmModalOpen}
        title={'Are you sure you want to delete this transaction?'}
        body={'Deleting a transaction cannot be undone. Are you sure you want to proceed?'}
        deleteError={Boolean(deleteTransactionError)}
        errorTitle={'Something went wrong.'}
        errorBody={'Please refresh the page and try again. If the problem persists, please contact us.'}
        deleteLoading={Boolean(deleteTransactionLoading)}
        deleteModalClose={deleteModalClose}
        handleDelete={handleDelete}
      />
    </>
  )
}

export default Finance
