import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Button, Card, Form } from 'react-bootstrap'

export const PUBLIC_CONTACT_MUTATION = gql`
  mutation PublicContactMutation(
    $name: String!
    $email: String!
    $subject: String
    $message: String!
  ) {
    publicContact(
      name: $name,
      email: $email,
      subject: $subject,
      message: $message,
    )
  }
`
const PublicContact = () => {
  const initialState = {
    name: '',
    email: '',
    subject: '',
    message: '',
    isSubmitting: false,
    errorMessage: null
  }
  const [formState, setFormState] = useState(initialState)
  const [errorState, setErrorState] = useState()
  const [dataReturned, setDataReturnedState] = useState(false)

  const [contactMutation, {
    loading,
    data: mutationData
  }] = useMutation(PUBLIC_CONTACT_MUTATION,
    {
      variables: {
        name: formState.name,
        email: formState.email,
        subject: formState.subject,
        message: formState.message
      }
    })

  const handleFormSubmit = (e) => {
    e.preventDefault()
    contactMutation()
      .then(() => {
        setDataReturnedState(true)
      })
      .catch((err) => {
        setErrorState(err.message)
      })
  }

  return (
    <div className="Home main-content-box">
      <div className="lander">
        <h3 className="page-title border-bottom">
          Contact Us
        </h3>
      </div>
      <Card className="m-3 p-3">
        {dataReturned && mutationData.publicContact
          ? (
            <div className="alert alert-success">Your message has been sent to InvestED</div>
          )
          : (
            <Form className="col-md-6" onSubmit={handleFormSubmit}>
              {(errorState) && (
                <div className="alert alert-warning">{errorState}</div>
              )}
              {(dataReturned && !mutationData.publicContact) && (
                <div className="alert alert-warning">Something went wrong. Please try again.</div>
              )}
              <Form.Group controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formState.name}
                  onChange={(e) => setFormState({
                    ...formState,
                    name: e.target.value
                  })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  value={formState.email}
                  onChange={(e) => setFormState({
                    ...formState,
                    email: e.target.value
                  })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGroupSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  value={formState.subject}
                  onChange={(e) => setFormState({
                    ...formState,
                    subject: e.target.value
                  })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formGroupMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  value={formState.message}
                  as="textarea"
                  rows={5}
                  onChange={(e) => setFormState({
                    ...formState,
                    message: e.target.value
                  })
                  }
                  required
                />
              </Form.Group>
              <hr />
              {loading ? (
                <div className="alert alert-info">Loading...</div>
              ) : (
                <>
                  <Button variant="primary" type="submit" className="mr-2">
                    Submit
                  </Button>
                  <Button variant="secondary" className="text-light" href="/">
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
      </Card>
    </div>
  )
}
export default PublicContact
