import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import GlobalDataTable from '../../components/GlobalDataTable'
import GlobalModal from '../../components/GlobalModal'
import GlobalDataTableRowView from '../../components/GlobalDataTableRowView'
import ClipboardText from '../../components/ClipboardText'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserContext from '../../contexts/UserContext'
import { ROLE_SUPER_ADMIN } from '../../constants'
import { DEACTIVATE_ADMIN, GET_ADMINS } from '../../operations/admin'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import { renderStatus } from '../../utils/lib'

const Personnel = () => {
  //  * FUTURE use userId from params to show the modal
  // const { userId } = useParams()
  const [selectedAdmin, setSelectedAdmin] = useState(null)
  const [personnel, setPersonnel] = useState([])
  const [deactivateAdminError, setDeactivateAdminError] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // * queries
  const {
    loading,
    data: personnelData,
    error,
    refetch
  } = useQuery(GET_ADMINS, {
    variables: { roles: [1, 2] },
    onCompleted: (data) => {
      if (data.users) {
        // * FUTURE filter out nulls and sort on the server side
        const users = data.users
          .filter((aPerson) => aPerson)
          .sort((a, b) => a.last.localeCompare(b.last))
        setPersonnel(users)
      }
    },
    notifyOnNetworkStatusChange: true
  })

  // * mutations
  // delete an admin
  const [deactivateAdmin, {
    loading: deactivateAdminLoading
  }] = useMutation(DEACTIVATE_ADMIN, {
    variables: {
      id: selectedAdmin?.id
    },
    onCompleted: () => {
      setConfirmModalOpen(false)
      setSelectedAdmin(null)
      refetch()
    }
  })

  if (loading) return <LoadingSpinner message="Loading..." />
  if (error) return <div>error</div>

  const rowClick = (p) => {
    setSelectedAdmin(p)
  }
  const personnelModalClose = () => {
    setSelectedAdmin(null)
  }
  const handleDeactivate = () => {
    deactivateAdmin()
      .catch((err) => {
        setDeactivateAdminError(err.message)
      })
  }
  const deactivateModalClose = () => {
    setConfirmModalOpen(false)
    setDeactivateAdminError(false)
  }

  return (
    <UserContext.Consumer>
      {({ user }) => {
        const isSuperAdmin = user.role.name === ROLE_SUPER_ADMIN

        const columns = [
          {
            name: 'SPACE ID',
            maxWidth: '115px',
            selector: (row) => row.id,
            sortable: true
          },
          {
            name: 'Name',
            selector: (row) => row.last,
            sortable: true,
            cell: function NameCell(row) {
              return (`${row.last}, ${row.first}`)
            }
          },
          {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
            cell: function EmailCell(row) {
              return <div data-tag="allowRowEvents">
                <ClipboardText
                  text={row.email}
                />
              </div>
            }
          },
          {
            name: 'Role',
            selector: (row) => row.role.name,
            sortable: false
          },
          {
            name: 'Status',
            maxWidth: '75px',
            selector: (row) => row.active,
            sortable: true,
            cell: (row) => renderStatus({ active: row.active })
          },
          {
            name: '',
            button: true,
            cell: function ActionCell(row) {
              return <Button
                variant="link text-dark"
                size="sm"
                href={user.id === row.id
                  ? '/profile'
                  : `/admin/personnel/edit/${row.id}`}
              >
                Edit
              </Button>
            },
            omit: !isSuperAdmin
          }
        ]

        return (
          <>
            <div className="Home">
              <div className="lander">
                {isSuperAdmin && (
                  <Button variant="primary" className="float-right" href="/admin/personnel/new">
                    + Add Personnel
                  </Button>
                )}
                <h3 className="page-title border-bottom">
                  InvestED Personnel
                </h3>
                <Link to="/admin" className="text-secondary">
                  &lt; back to settings page
                </Link>
              </div>
              <div className="mt-3 px-3">
                {/* Prevent the data table from rendering before the data is ready */}
                {!!personnelData && (
                  <GlobalDataTable
                    columns={columns}
                    data={personnel}
                    filterHandler
                    pagination
                    subHeader
                    selectableRows={false}
                    handleRowClick={rowClick}
                  />
                )}
              </div>
            </div>
            {selectedAdmin && (
              <GlobalModal
                title="Personnel Information"
                onClose={personnelModalClose}
              >
                <GlobalDataTableRowView
                  title={`${selectedAdmin.last}, ${selectedAdmin.first}`}
                  subTitle={`ID# ${selectedAdmin.id}`}
                  colData = {[
                    {
                      label: 'Status:',
                      value: renderStatus({ active: selectedAdmin.active })
                    },
                    {
                      label: 'Email:',
                      value: selectedAdmin.email
                    },
                    {
                      label: 'Role:',
                      value: selectedAdmin.role.name
                    }
                  ]}
                  editAction={(
                    () => {
                      // one can edit one's self, but only at one's profile
                      if (user.id === selectedAdmin.id) {
                        return '/profile'
                      }

                      // only super admin can edit admin personnel
                      if (isSuperAdmin) {
                        return `/admin/personnel/edit/${selectedAdmin.id}`
                      }

                      return undefined
                    })()
                  }
                  deleteAction={(() => {
                    // only super admin can deactivate
                    // if selected user is active
                    // and one cannot deactivate one's self
                    if (
                      isSuperAdmin
                      && selectedAdmin.active
                      && selectedAdmin.id !== user.id
                    ) {
                      return () => setConfirmModalOpen(true)
                    }
                    return null
                  })()}
                  deleteLabel="Deactivate"
                ></GlobalDataTableRowView>
              </GlobalModal>
            )}

            {/* Confirmation modal for deleting a staff */}
            <DeleteConfirmationModal
              showModal={confirmModalOpen}
              title={'Are you sure you want to deactivate this user?'}
              body={'Deactivating this user will prevent them from logging in. Are you sure you want to proceed?'}
              deleteError={Boolean(deactivateAdminError)}
              errorTitle={'Something went wrong.'}
              errorBody={'Please refresh the page and try again. If the problem persists, please contact us.'}
              deleteLoading={Boolean(deactivateAdminLoading)}
              deleteModalClose={deactivateModalClose}
              deleteButtonLabel='Deactivate'
              handleDelete={handleDeactivate}
            />
          </>
        )
      }}
    </UserContext.Consumer>
  )
}

export default Personnel
