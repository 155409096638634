import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { ProgressBar } from 'react-bootstrap'
import AddTransactionButton from './AddTransactionButton'
import { QUERY_CURRENT_ACCOUNT_BALANCE } from '../operations/accountBalance'
import { formatIFFPercent, formatUSD } from '../utils/helpers'

/**
 * component for showing  add transaction button adn optionally render some account balance info
 * @param {boolean} [showBalance=false] - true indicates showing balance info, alternatively shoing just an action button
 * @param {int} schoolId - school Id is necessary for showing account balance
 * @param {string} schoolYear - string representation of the school year
 */
const CoordinatorActions = ({
  showBalance = false,
  schoolId,
  schoolYear = new Date().getFullYear()
}) => {
  const [percent, setPercent] = useState(0)
  const { data } = useQuery(QUERY_CURRENT_ACCOUNT_BALANCE, {
    variables: { schoolId },
    skip: !(schoolId && showBalance),
    onCompleted: ({ accountBalance }) => {
      const roundedPercent = Math.min(Math.round(accountBalance?.fallFunding.percentUsed), 100)
      setPercent(roundedPercent)
    }
  })

  const renderAddTransactionButton = () => (
    <div className="d-print-none">
      <AddTransactionButton path="/finance/new">
        <>
          {' '}
          Add Transaction
        </>
      </AddTransactionButton>
    </div>
  )

  if (showBalance && schoolId && data && data.accountBalance) {
    return (
      <div className="header-actions">
        <div>
          <AddTransactionButton
            path="/finance/new"
            appendedClassName="float-right"
            schoolId
            rounded
          />
          <div>
            <label className="h6">Account Balance</label>
            <label className="h5 balance">
              {
                formatUSD.format(data.accountBalance.currentBalance)
              }
            </label>
            <ProgressBar now={percent} />
            <label className="h5">
              {formatIFFPercent(data.accountBalance.fallFunding?.percentUsed)}
              {`% of funds spent in ${schoolYear}`}
            </label>
          </div>
        </div>
      </div>
    )
  }
  return renderAddTransactionButton()
}

CoordinatorActions.propTypes = {
  showBalance: PropTypes.bool,
  schoolId: PropTypes.number,
  schoolYear: PropTypes.string
}

export default CoordinatorActions
