import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from 'react-bootstrap'
import { formatUSD } from '../utils/helpers'

/**
 * displays account balance with IFF spending percentage and progress
 * @param {decimal} [percentFallFunding=0] - percentage of fall funding spending
 * @param {decimal} [accountBalance=0] - decimal formatted to currency
 * @param {string} [schoolYear] - string representation of the current school year
 */
const AccountBalanceAndProgress = ({
  percentFallFunding = 0,
  accountBalance = 0,
  schoolYear = new Date().getFullYear()
}) => (
  <div className="account-balance-box">
    <div className="account-balance">
      <span className="text-ed-warm-gray-200">$</span>
      {/* Format the balance as USD, but slice off the differently-styled dollar sign */}
      <span className="balance-number">{formatUSD.format(accountBalance).slice(1)}</span>
    </div>
    <div className="percent-box text-ed-green mb-2">
      <strong>{percentFallFunding}%</strong>{` of InvestED School Funding Spent in ${schoolYear}`}
    </div>
    <ProgressBar now={percentFallFunding} />
  </div>
)

AccountBalanceAndProgress.propTypes = {
  percentFallFunding: PropTypes.number,
  accountBalance: PropTypes.number,
  schoolYear: PropTypes.string
}

export default AccountBalanceAndProgress
