import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import LoadingSpinner from '../../components/LoadingSpinner'
import {
  QUERY_GENDERS,
  QUERY_GRADES,
  QUERY_ETHNICITIES,
  CREATE_STUDENT
} from '../../operations/students'
import {
  QUERY_SCHOOLS
} from '../../operations/schools'
import { validGradesForSchool } from '../../utils/formHelpers'

/**
 * This form is rendered by a modal opened when user clicks Create Student
 * button while creating a student transaction
 * @param {array} schools array of schools to choose form for newly created student
 * @param {function} setModalOpen to close the modal on cancel or submit
 * @param {function} handleNewStudentCreated to handle callback after new student has been created
 * @returns {any}
 */
const NewStudentForm = ({
  schools = [],
  setModalOpen,
  handleNewStudentCreated
}) => {
  // hooks
  const [errorState, setError] = useState('')
  const initialState = {
    first: '',
    last: '',
    gradeId: -1,
    genderId: -1,
    ethnicityId: -1,
    active: false,
    schoolId: schools.length === 1
      ? schools[0].id
      : -1
  }
  const [formState, setFormState] = useState(initialState)

  // queries
  const {
    loading: gendersLoading,
    error: gendersError,
    data: gendersData
  } = useQuery(QUERY_GENDERS)
  const {
    loading: ethnicitiesLoading,
    error: ethnicitiesError,
    data: ethnicitiesData
  } = useQuery(QUERY_ETHNICITIES)
  const {
    loading: gradesLoading,
    error: gradesError,
    data: gradesData
  } = useQuery(QUERY_GRADES)
  const {
    loading: schoolsLoading,
    error: schoolsError,
    data: schoolsData
  } = useQuery(QUERY_SCHOOLS)

  const selectedSchool = schoolsData?.schools?.find((s) => s.id === formState?.schoolId)
  const grades = validGradesForSchool(gradesData?.grades, selectedSchool)

  // mutations
  const baseMutationVariables = {
    first: formState.first,
    last: formState.last,
    gradeId: formState.gradeId,
    genderId: formState.genderId,
    ethnicityId: formState.ethnicityId,
    active: formState.active,
    schoolId: formState.schoolId
  }
  const [mutateStudent, {
    loading: mutationLoading
  }] = useMutation(CREATE_STUDENT, {
    variables: {
      ...baseMutationVariables
    },
    onCompleted: (() => {
      handleNewStudentCreated()
      setModalOpen(false)
    })
  })

  // event listeners
  const handleChange = (value, field) => {
    setFormState({
      ...formState,
      [field]: value
    })
  }
  const onFormSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    mutateStudent()
      .catch((err) => {
        setError(err.message)
      })
  }

  // render stuff
  if (mutationLoading) return <LoadingSpinner message="Loading..." />

  return (
    <Form className="col-md-6" onSubmit={onFormSubmit}>
      {errorState && (
        <div className="alert alert-danger">{errorState}</div>
      )}
      <Form.Group controlId="formGroupFirstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          value={formState.first}
          onChange={(e) => handleChange(e.target.value, 'first')}
          required
        />
      </Form.Group>
      <Form.Group controlId="formGroupLastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          value={formState.last}
          onChange={(e) => handleChange(e.target.value, 'last')}
          required
        />
      </Form.Group>
      {gendersLoading && (
        <div>Loading genders...</div>
      )}
      {ethnicitiesLoading && (
        <div>Loading ethnicities...</div>
      )}
      {gradesLoading && (
        <div>Loading grades...</div>
      )}
      {schoolsLoading && (
        <div>Loading schools...</div>
      )}
      {gendersError && (
        <div className="alert alert-danger">
          <label>Error loading genders..</label>
          {gendersError.message}
        </div>
      )}
      {ethnicitiesError && (
        <div className="alert alert-danger">
          <label>Error loading ethnicities..</label>
          {ethnicitiesError.message}
        </div>
      )}
      {gradesError && (
        <div className="alert alert-danger">
          <label>Error loading grades..</label>
          {gradesError.message}
        </div>
      )}
      {schoolsError && (
        <div className="alert alert-danger">
          <label>Error loading schools..</label>
          {schoolsError.message}
        </div>
      )}
      {gendersData && (<Form.Group controlId="formGroupGender">
        <Form.Label>Gender</Form.Label>
        <Form.Control
          as="select"
          value={formState.genderId}
          onChange={(e) => handleChange(parseInt(e.target.value, 10), 'genderId')}
          required
        >
          <option value="">Select a gender...</option>
          {gendersData.genders.map((gender, i) => (
            <option
              key={i}
              value={gender.id}
              data-testid="gender-item"
            >
              {gender.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>)}
      {ethnicitiesData && (<Form.Group controlId="formGroupEthnicity">
        <Form.Label>Race/Ethnicity</Form.Label>
        <Form.Control
          as="select"
          value={formState.ethnicityId}
          onChange={(e) => handleChange(parseInt(e.target.value, 10), 'ethnicityId')}
          required
        >
          <option value="">Select an ethnicity...</option>
          {ethnicitiesData.ethnicities.map((ethnicity, i) => (
            <option
              key={i}
              value={ethnicity.id}
              data-testid="ethnicity-item"
            >
              {ethnicity.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>)}

      {schools.length && schools.length > 1 && (
        <Form.Group controlId="formGroupSchool">
          <Form.Label>School</Form.Label>
          <Form.Control
            as="select"
            value={formState.schoolId}
            onChange={(e) => handleChange(parseInt(e.target.value, 10), 'schoolId')}
            required
          >
            <option value="">Select a school...</option>
            {schools.map((school) => (
              <option
                key={school.id}
                value={school.id}
                data-testid="school-item"
              >
                {school.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      {grades && (<Form.Group controlId="formGroupGrade">
        <Form.Label>Grade</Form.Label>
        <Form.Control
          as="select"
          value={formState.gradeId}
          onChange={(e) => handleChange(parseInt(e.target.value, 10), 'gradeId')}
          required
        >
          <option value="">Select a grade level...</option>
          {grades.map((grade, i) => (
            <option
              key={i}
              value={grade.id}
              data-testid="grade-item"
            >
              {grade.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>)}
      <Form.Check
        type="checkbox"
        label="Enrolled"
        checked={formState.active}
        onChange={(e) => handleChange(e.target.checked, 'active')}
      />
      <hr />
      {!mutationLoading && (
        <>
          <Button variant="primary" type='submit'>
            Submit
          </Button>{' '}
          <Button variant="secondary" className="text-light" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
        </>
      )}
      {mutationLoading && (
        <div className="alert alert-info">Submitting...</div>
      )}
    </Form>
  )
}

NewStudentForm.propTypes = {
  schools: PropTypes.array,
  setModalOpen: PropTypes.func,
  handleNewStudentCreated: PropTypes.func
}

export default NewStudentForm
