import {
  balanceTypes,
  reportPages
} from '../constants'
import { formatUSD } from './helpers'

/**
 * formats an amount to USD, optional balance type for debit or credit
 * @param {number | bigint} [amount=0] - number to format ot currency
 * @param {string} [balanceType=balanceTypes.CREDIT] - balance type defined as "debit" or "credit"
 * @returns {string}
 */
export const formatDollarAmountCell = ({
  amount = 0,
  balanceType = balanceTypes.CREDIT
}) => {
  if ((balanceType && balanceType === balanceTypes.DEBIT)) {
    if (amount > 0) {
      return formatUSD.format(-amount)
    }
    return formatUSD.format(amount)
  }

  return formatUSD.format(amount)
}

// selector function for amount without currency formatting
const amountSelector = (row) => {
  const balanceType = row.transactionType?.balanceType

  if (balanceType === balanceTypes.DEBIT) {
    return -row.totalAmount
  }

  return row.totalAmount
}

// selector function for date columns
const dateSelector = (row) => {
  if (row.transactionDate) {
    return new Date(row.transactionDate).toLocaleDateString()
  }

  return ''
}

// selector function for transaction type
const typeSelector = (row) => {
  if (!row.transactionType) {
    return ''
  }
  let transactionTypeName = row.transactionType.name

  if (row.transactionType.name === 'Student Transaction' && row.quantity > 0) {
    transactionTypeName = 'Bulk Purchase - Student Transaction'
  }

  if (row.transactionType.name === 'Student Transaction') {
    transactionTypeName += `\n${row.categoryCode.categoryType.name}; ${row.categoryCode.number}: ${row.categoryCode.name}`
  }

  if (row.transactionType.name === 'Donation' && row.donor) {
    transactionTypeName += `\nfrom ${row.donor}`
  }

  return transactionTypeName
}

// selector for category type and code
const categorySelector = (row) => {
  if (row.category) {
    return `${row.category.categoryType.name}; ${row.category.number || ''}: ${row.category.name}`
  }

  return ''
}

// selector function for student status
const statusSelector = (row) => {
  if (row.active) {
    return 'Enrolled'
  }

  return 'Unenrolled'
}

export const transactionsHeaders = [
  {
    name: 'Date',
    widthInChars: 10,
    dataType: 'd',
    format: 'mm/dd/yyyy',
    exportKey: 'transactionDate',
    selector: dateSelector
  },
  {
    name: 'Transaction Type',
    widthInChars: 45,
    dataType: 's',
    exportKey: 'transactionType',
    selector: typeSelector
  },
  {
    name: 'Notes',
    widthInChars: 45,
    dataType: 's',
    exportKey: 'notes',
    selector: (row) => row.notes || ''
  },
  {
    name: 'Amount',
    widthInChars: 10,
    dataType: 'n',
    format: '0.00',
    exportKey: 'totalAmount',
    selector: amountSelector,
    pdfCell: (row) => formatDollarAmountCell({
      amount: row.totalAmount,
      balanceType: row.transactionType?.balanceType
    }),
    cell: (row) => formatDollarAmountCell({
      amount: row.totalAmount,
      balanceType: row.transactionType?.balanceType
    })
  }
]

export const transactionStudentsHeaders = [
  {
    name: 'Date',
    widthInChars: 10,
    dataType: 'd',
    format: 'mm/dd/yyyy',
    exportKey: 'transactionDate',
    selector: dateSelector
  },
  {
    name: 'Student Name',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'studentName',
    selector: (row) => row.studentName
  },
  {
    name: 'Category',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'category',
    selector: categorySelector
  },
  {
    name: 'School',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'schoolName',
    selector: (row) => row.schoolName || ''
  },
  {
    name: 'Gender',
    widthInChars: 10,
    dataType: 's',
    exportKey: 'gender',
    selector: (row) => row.gender || ''
  },
  {
    name: 'Ethnicity',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'ethnicity',
    selector: (row) => row.ethnicity || ''
  },
  {
    name: 'Grade',
    widthInChars: 5,
    dataType: 's',
    exportKey: 'grade',
    selector: (row) => row.grade || ''
  },
  {
    name: 'Amount',
    widthInChars: 10,
    dataType: 'n',
    format: '0.00',
    exportKey: 'amount',
    selector: (row) => row.amount || '',
    pdfCell: (row) => formatDollarAmountCell({
      amount: row.amount
    }),
    cell: (row) => formatDollarAmountCell({
      amount: row.amount
    })
  }
]

export const studentRosterHeaders = [
  {
    name: 'SPACE ID',
    widthInChars: 10,
    dataType: 'n',
    exportKey: 'id',
    selector: (row) => row.id
  },
  {
    name: 'Student Name',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'studentName',
    selector: (row) => row.studentName
  },
  {
    name: 'School',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'schoolName',
    selector: (row) => row.schoolName
  },
  {
    name: 'Grade',
    widthInChars: 5,
    dataType: 's',
    exportKey: 'grade',
    selector: (row) => row.grade
  },
  {
    name: 'Gender',
    widthInChars: 10,
    dataType: 's',
    exportKey: 'gender',
    selector: (row) => row.gender
  },
  {
    name: 'Ethnicity',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'ethnicity',
    selector: (row) => row.ethnicity
  },
  {
    name: 'Status',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'active',
    selector: statusSelector
  }
]

export const studentStoriesHeaders = [
  {
    name: 'School',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'school',
    selector: (row) => row.school
  },
  {
    name: 'District',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'district',
    selector: (row) => row.district
  },
  {
    name: 'County',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'county',
    selector: (row) => row.county
  },
  {
    name: 'ESD Number',
    widthInChars: 6,
    dataType: 'n',
    exportKey: 'esdNumber',
    selector: (row) => row.esdNumber
  },
  {
    name: 'Title',
    widthInChars: 30,
    dataType: 's',
    exportKey: 'storyTitle',
    selector: (row) => row.storyTitle
  },
  {
    name: 'Category',
    widthInChars: 30,
    dataType: 's',
    exportKey: 'storyCategory',
    selector: (row) => row.storyCategory
  },
  {
    name: 'Gender',
    widthInChars: 10,
    dataType: 's',
    exportKey: 'gender',
    selector: (row) => row.gender
  },
  {
    name: 'Ethnicity',
    widthInChars: 20,
    dataType: 's',
    exportKey: 'ethnicity',
    selector: (row) => row.ethnicity
  },
  {
    name: 'Story',
    widthInChars: 30,
    dataType: 's',
    exportKey: 'story',
    selector: (row) => row.story
  }
]

export const headersDictionary = {
  [reportPages.transactionsMtd]: transactionsHeaders,
  [reportPages.transactionsYtd]: transactionsHeaders,
  [reportPages.studentTransactions]: transactionStudentsHeaders,
  [reportPages.studentRoster]: studentRosterHeaders,
  [reportPages.studentStories]: studentStoriesHeaders
}
