import React, { useState, useEffect } from 'react'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import * as auth from './utils/authToken'
import UserContext from './contexts/UserContext'
import PrivateApp from './PrivateApp'
import PublicApp from './PublicApp'
import LoadingSpinner from './components/LoadingSpinner'
import useLocalStorage from './utils/useLocalState'
import {
  LAST_DISTRICT_SELECTED,
  LAST_SCHOOL_SELECTED,
  LAST_SCHOOL_YEAR
} from './constants'
import {
  QUERY_IS_YEAR_END_OPEN,
  QUERY_YEARLY_OBJECTIVES_AND_CHOICES,
  QUERY_YEARLY_REPORTS_BY_SCHOOL_AND_YEAR
} from './operations/yearEndReport'
import './styles/App.scss'

export const GET_ME = gql`
  query {
    me {
      id
      first
      last
      email
      investedRole {
        name
      }
      role {
        name
      }
      userSchools {
        schoolPosition {
          name
        }
        school {
          id
          name
        }
      }
      userDistricts {
        schoolPosition {
          name
        }
        district {
          id
          name
        }
      }
    }
  }
`

export const App = () => {
  const [userState, setUserState] = useState(null)
  const [yearlyObjectives, setYearlyObjectives] = useState([])
  const [yearlyReports, setYearlyReports] = useState([])
  const [lastSchool, setLastSchool] = useLocalStorage(
    LAST_SCHOOL_SELECTED,
    userState?.session?.currentSchool
  )
  const [lastDistrict, setLastDistrict] = useLocalStorage(
    LAST_DISTRICT_SELECTED,
    userState?.session?.currentDistrict
  )
  const [lastSchoolYear, setLastSchoolYear] = useLocalStorage(
    LAST_SCHOOL_YEAR,
    null
  )

  const [yearEndReportInProgress, setYearEndReportInProgress] = useState(false)

  const userId = () => {
    const token = auth.getToken()
    return token
      ? token.user?.id
      : null
  }

  const {
    loading,
    error
  } = useQuery(GET_ME, {
    skip: !userId(),
    onCompleted: ({ me }) => {
      setUserState({
        ...userState,
        user: me,
        session: {
          currentSchool: me.userSchools.find((school) => school)?.school || lastSchool,
          currentDistrict: me.userDistricts.find((district) => district)?.district || lastDistrict,
          currentSchoolYear: lastSchoolYear
        }
      })
    }
  })

  const [lazyYearlyReports, {
    data: lazyYearlyReportsData
  }] = useLazyQuery(QUERY_YEARLY_REPORTS_BY_SCHOOL_AND_YEAR, {
    errorPolicy: 'none',
    onCompleted: ({ yearlyReports: data }) => {
      setYearlyReports(data)
    }
  })

  const {
    data: yearEndOpen
  } = useQuery(QUERY_IS_YEAR_END_OPEN)

  useQuery(QUERY_YEARLY_OBJECTIVES_AND_CHOICES, {
    errorPolicy: 'none',
    onCompleted: ({ yearlyObjectives: data }) => {
      setYearlyObjectives(data)
    }
  })

  useEffect(() => {
    if (
      yearEndOpen
      && yearlyObjectives.length
      && yearlyReports.length
      && yearlyReports.length < yearlyObjectives.length
    ) {
      setYearEndReportInProgress(true)
    }
  }, [yearlyObjectives, yearlyReports, yearEndOpen])

  useEffect(() => {
    if (
      userState?.session?.currentSchoolYear
      && userState?.session?.currentSchool
      && !lazyYearlyReportsData
    ) {
      lazyYearlyReports({
        variables: {
          schoolId: userState?.session?.currentSchool.id,
          schoolYearId: userState.session.currentSchoolYear.id
        }
      })
    }
  }, [userState?.session])

  if (loading) return (<LoadingSpinner message="Loading..." />)
  if (error) return (<p>Error: {error.message}</p>)

  if (userId && userState) {
    const userContextValue = {
      user: userState.user,
      session: {
        currentSchool: userState.session.currentSchool,
        currentDistrict: userState.session.currentDistrict,
        currentSchoolYear: userState.session.currentSchoolYear
      },
      setCurrentSchool: (school) => {
        setLastSchool(school)
        setUserState({
          ...userState,
          session: {
            ...userState.session,
            currentSchool: school
          }
        })
      },
      setCurrentDistrict: (district) => {
        setLastDistrict(district)
        setUserState({
          ...userState,
          session: {
            ...userState.session,
            currentDistrict: district
          }
        })
      },
      setCurrentSchoolAndDistrict: (school, district) => {
        setLastSchool(school)
        setLastDistrict(district)
        setUserState({
          ...userState,
          session: {
            ...userState.session,
            currentSchool: school,
            currentDistrict: district
          }
        })
      },
      setCurrentSchoolYear: (schoolYear) => {
        setLastSchoolYear(schoolYear)
        setUserState({
          ...userState,
          session: {
            ...userState.session,
            currentSchoolYear: schoolYear
          }
        })
      },
      setUserState,
      yearEndReportInProgress,
      setYearEndReportInProgress
    }
    return (
      <UserContext.Provider value={userContextValue}>
        <PrivateApp />
      </UserContext.Provider>
    )
  }

  return <PublicApp />
}
