import React, { useState } from 'react'
import {
  Badge,
  Button
} from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import { formatUSD } from '../../utils/helpers'
import GlobalDataTable from '../../components/GlobalDataTable'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import {
  QUERY_SCHOOLS_YEAR_END_STATUS,
  QUERY_YEARLY_REPORTS_BY_YEAR,
  CLEAR_YEARLY_REPORT
} from '../../operations/admin'
import {
  QUERY_CURRENT_SCHOOL_YEAR_NAME
} from '../../operations/schoolYear'

const valueIsInvalid = (value) => value === null || value === undefined

const YearEndStatus = () => {
  const [schools, setSchools] = useState([])
  const [selectedSchoolId, setSelectedSchoolId] = useState()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [clearYearEndError, setClearYearEndError] = useState()
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(5)
  const [filterText, setFilterText] = useState('')
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')

  // * data columns
  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      field: 'name'
    },
    {
      name: 'Impact Stories',
      cell: function ImpactStoriesCell(row) {
        const { studentStories = [] } = row
        return studentStories.length
      },
      sortable: false
    },
    {
      name: 'Starting Balance',
      cell: function StartBalanceCell(row) {
        const startBalance = row?.accountBalance?.startBalance
        if (valueIsInvalid(startBalance)) {
          return '-'
        }
        return formatUSD.format(startBalance)
      },
      sortable: false
    },
    {
      name: 'Current Balance',
      cell: function CurrentBalanceCell(row) {
        const currentBalance = row?.accountBalance?.currentBalance
        if (valueIsInvalid(currentBalance)) {
          return '-'
        }
        return formatUSD.format(currentBalance)
      },
      sortable: false
    },
    {
      name: 'Ending Balance',
      cell: function EndBalanceCell(row) {
        const endBalance = row?.accountBalance?.endBalance
        if (valueIsInvalid(endBalance)) {
          return '-'
        }
        return formatUSD.format(endBalance)
      },
      sortable: false
    },
    {
      name: 'Status',
      cell: function StatusCell(row) {
        const { yearlyReports = [], accountBalance = {} } = row
        if (accountBalance?.verified === true) {
          return <Badge className="bg-success">SUBMITTED</Badge>
        }
        if (yearlyReports.length === 0) {
          return <Badge className="bg-muted">NOT STARTED</Badge>
        }
        const highestYearEndEntry = yearlyReports.reduce((prev, current) => (
          prev.objective.id > current.objective.id ? prev : current
        ))
        const {
          objective: {
            name: objectiveName = ''
          } = {}
        } = highestYearEndEntry

        return <Badge className="bg-ed-dark-blue">{objectiveName}</Badge>
      },
      sortable: false
    },
    {
      name: '',
      cell: function ActionCell(row) {
        return <Button
          variant="outline-secondary bg-light"
          size="sm"
          onClick={() => {
            setSelectedSchoolId(row.id)
            setConfirmModalOpen(true)
          }}
        >
          Clear Year End Entries
        </Button>
      },
      sortable: false
    }
  ]

  // * queries
  const {
    data: schoolYearData
  } = useQuery(QUERY_CURRENT_SCHOOL_YEAR_NAME)

  const {
    loading: loadingYearlyReportsByYear,
    data: yearlyReportsByYearData
  } = useQuery(QUERY_YEARLY_REPORTS_BY_YEAR, {
    variables: {
      schoolYearId: schoolYearData?.schoolYear.id
    },
    skip: schoolYearData === null
  })

  const lookupYearlyReports = (school) => {
    const yearlyReports = yearlyReportsByYearData?.yearlyReportsByYear?.filter(
      (y) => y.school.id === school.id
    )
    return yearlyReports ? { yearlyReports } : {}
  }

  const {
    loading: loadingSchools,
    refetch: refetchSchools
  } = useQuery(QUERY_SCHOOLS_YEAR_END_STATUS, {
    onCompleted: ({ schools: queriedSchools = [], schoolsCount }) => {
      console.log('schoolYearData?.schoolYear.id', schoolYearData?.schoolYear.id)
      console.log('🚀 ~ file: YearEndStatus.js ~ queriedSchools', queriedSchools)
      // * FUTURE filter out nulls and sort on the server side

      // FUTURE fetching all the yearly reports for a single year and
      // updating the fetched schools with them is a workaround for
      // an error being raised when attempting to add SchoolYear
      // details to QUERY_SCHOOLS_YEAR_END_STATUS
      const updatedSchools = queriedSchools.map((s) => ({
        ...s,
        ...lookupYearlyReports(s)
      }))
      console.log('🚀 ~ file: YearEndStatus.js ~ updatedSchools', updatedSchools)

      setSchools(updatedSchools)
      setTotalRows(schoolsCount)
    },
    variables: {
      schoolYearId: schoolYearData?.schoolYear.id,
      page: currentPage,
      rowsPerPage: currentRowsPerPage,
      filterText,
      sort: sortColumn,
      direction: sortDirection
    },
    skip: schoolYearData === null || yearlyReportsByYearData == null,
    notifyOnNetworkStatusChange: true
  })

  // * mutations
  const [clearYearlyReport, {
    loading: clearYearlyReportLoading
  }] = useMutation(CLEAR_YEARLY_REPORT, {
    variables: {
      schoolId: selectedSchoolId
    },
    onCompleted: () => {
      setConfirmModalOpen(false)
      setSelectedSchoolId(null)
      refetchSchools()
    },
    onError: (err) => {
      setConfirmModalOpen(false)
      setSelectedSchoolId(null)
      setClearYearEndError(err.message)
    }
  })

  // * event handlers
  const confirmModalClose = () => {
    setConfirmModalOpen(false)
    setClearYearEndError(false)
  }

  const handlePerRowsChange = (rows) => setCurrentRowsPerPage(rows)
  const handlePageChange = (page) => setCurrentPage(page)
  const handleFilterChange = (text) => {
    setFilterText(text)
    setConfirmModalOpen(false)
    setSelectedSchoolId(null)
    setSchools([])
  }
  const handleSort = (column, direction) => {
    setSortColumn(column.field)
    setSortDirection(direction)
  }

  // * render
  return (
    <>
      <div className="Home">
        <div className="lander">
          <h3 className="page-title border-bottom">Year End Status</h3>
        </div>
        <div className="m-3">
          {!!schools && (
            <GlobalDataTable
              columns={columns}
              data={schools}
              filterHandler
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={loadingSchools || loadingYearlyReportsByYear}
              setServerFilterText={handleFilterChange}
              subHeader
              selectableRows={false}
              wrapperClassName="no-pointer data-rows-print-border"
              handleServerSort={handleSort}
              // Through trial and error fetching the GetSchoolsYearEndStatus
              // query in production 400 was the maximum number of results that
              // could be reliably returned under the 30s request timeout
              paginationRowsPerPageOptions={[10, 25, 50, 100, 200, 400]}
              // In production a request for "All" records takes longer than the
              // maximum request duration of 30s
              selectAllRowsItem={false}
            />
          )}
        </div>
      </div>
      {/* Confirmation modal for deleting a staff */}
      <DeleteConfirmationModal
        showModal={confirmModalOpen}
        title={'Are you sure you want to clear this schools yearly report entries?'}
        body={'Clearing year end entries will reset a schools year end status and delete their affirmations for the current school year. It will not reset any automated new year setup tasks that may have already been completed. Are you sure you want to proceed?'}
        deleteError={Boolean(clearYearEndError)}
        errorTitle={'Something went wrong.'}
        errorBody={'Please refresh the page and try again. If the problem persists, please contact us.'}
        deleteLoading={Boolean(clearYearlyReportLoading)}
        deleteModalClose={confirmModalClose}
        deleteButtonLabel="Clear Year End Entries"
        handleDelete={clearYearlyReport}
      />
    </>
  )
}

export default YearEndStatus
