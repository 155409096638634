import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useLazyQuery, useQuery } from '@apollo/client'
import { formatUSD } from '../../utils/helpers'
import GlobalDataTable from '../../components/GlobalDataTable'
import GlobalModal from '../../components/GlobalModal'
import NewStudentForm from './NewStudentForm'
import { QUERY_ACTIVE_CATEGORY_TYPES } from '../../operations/transactions'
import {
  GET_STUDENTS_BY_SCHOOL_IDS,
  QUERY_ETHNICITIES,
  QUERY_GENDERS
} from '../../operations/students'

/**
 * Component for entering a student transaction
 * @param {array} schools array of schools associated with the transaction
 * @param {string} startDate start date of school year in format 'YYYY-MM-DD'
 * @param {number} totalAmount amount of transaction
 * @param {string} transactionDate date of transaction in format 'YYYY-MM-DD'
 * @param {number} categoryTypeId id of category type
 * @param {number} categoryCodeId id of category code
 * @param {Object} students object storing students associated with the transaction
 * @param {number} quantity number of students associated with the transaction
 * @param {Object} genderBreakdown object storing genders associated with the transaction
 * @param {Object} ethnicityBreakdown object storing ethnicities associated with the transaction
 * @param {boolean} isBulkMode true when bulk mode selected
 * @param {boolean} isAdmin true when user is admin or super_admin
 * @param {function} setIsBulkMode function for toggling bulk mode
 * @param {function} setStudentCountError function for toggling error for student count not matching breakdown counts
 * @param {function} setNoStudentsError function for toggling error for when no students are selected
 * @param {function} handleChange function for handling a change in form state
 * @returns {any}
 */
const StudentTransactionForm = ({
  schools,
  startDate,
  totalAmount,
  transactionDate,
  categoryTypeId,
  categoryCodeId,
  students,
  quantity,
  genderBreakdown,
  ethnicityBreakdown,
  isBulkMode,
  isAdmin,
  setIsBulkMode,
  setStudentCountError,
  setNoStudentsError,
  handleChange
}) => {
  // hooks
  const [categoryCodesState, setCategoryCodesState] = useState()
  const [completeStudentsList, setCompleteStudentsList] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const ethnicitiesTotal = Object.keys(ethnicityBreakdown).reduce(
    (sum, key) => sum + ethnicityBreakdown[key], 0
  )
  const gendersTotal = Object.keys(genderBreakdown).reduce(
    (sum, key) => sum + genderBreakdown[key], 0
  )
  const isMultiSchool = schools.length && schools.length > 1

  // functions for handling students included in transaction
  const addStudentToIncluded = (student) => {
    if (student.length) {
      const newIncludedStudents = {
        ...students,
        [student[0].id]: {
          studentId: student[0].id,
          amount: null,
          notes: ''
        }
      }
      handleChange(null, 'students', newIncludedStudents)
      setNoStudentsError(false)
    }
  }

  const updateIncludedStudent = (studentId, field, value) => {
    const updatedStudents = {
      ...students
    }
    updatedStudents[studentId][field] = field === 'amount' ? parseFloat(value) : value
    handleChange(null, 'students', updatedStudents)
  }

  const removeIncludedStudent = (id) => {
    const updatedStudents = {
      ...students
    }
    delete updatedStudents[id]
    handleChange(null, 'students', updatedStudents)
  }

  /**
   * loops through available schools and pushes all students into one array, adding the school name to each student object
   * @param {array} [availableSchools=[]] - array of available schools
   */
  const allStudents = (availableSchools = []) => {
    const combinedStudents = []

    availableSchools.forEach(({
      students: schoolStudents = [],
      name: schoolName
    }) => {
      combinedStudents.push(...schoolStudents.map((student) => ({
        ...student,
        schoolName
      })))
    })

    return combinedStudents
  }

  const schoolIds = () => schools.map((school) => school.id)

  // functions for handling bulk transaction
  const updateEthnicityBreakdown = (itemId, value) => {
    const updatedBreakdown = {
      ...ethnicityBreakdown
    }
    updatedBreakdown[itemId] = value
    handleChange(null, 'ethnicityBreakdown', updatedBreakdown)
  }

  const updateGenderBreakdown = (itemId, value) => {
    const updatedBreakdown = {
      ...genderBreakdown
    }
    updatedBreakdown[itemId] = value
    handleChange(null, 'genderBreakdown', updatedBreakdown)
  }

  // queries
  const {
    loading: categoryTypesLoading,
    data: categoryTypesData
  } = useQuery(QUERY_ACTIVE_CATEGORY_TYPES, {
    onCompleted: (({ activeCategoryTypes }) => {
      const categoryCodes = {}
      activeCategoryTypes.forEach((cat) => {
        categoryCodes[cat.id] = cat.categoryCodes
      })
      setCategoryCodesState(categoryCodes)
    })
  })

  const {
    loading: studentsLoading
  } = useQuery(GET_STUDENTS_BY_SCHOOL_IDS, {
    variables: { id: schoolIds() },
    onCompleted: ({ schools: querySchools }) => {
      const districtStudents = allStudents(querySchools)
      setCompleteStudentsList(districtStudents)
    }
  })

  const {
    loading: ethnicitiesLoading,
    error: ethnicitiesError,
    data: ethnicitiesData
  } = useQuery(QUERY_ETHNICITIES, {
    onCompleted: ({ ethnicities }) => {
      const ethnicityCounts = { ...ethnicityBreakdown }
      ethnicities.forEach((ethnicity) => {
        if (!ethnicityCounts[ethnicity.id]) {
          ethnicityCounts[ethnicity.id] = 0
        }
      })
      handleChange(null, 'ethnicityBreakdown', ethnicityCounts)
    }
  })

  const {
    loading: gendersLoading,
    error: gendersError,
    data: gendersData
  } = useQuery(QUERY_GENDERS, {
    onCompleted: ({ genders }) => {
      const genderCounts = { ...genderBreakdown }
      genders.forEach((gender) => {
        if (!genderCounts[gender.id]) {
          genderCounts[gender.id] = 0
        }
      })
      handleChange(null, 'genderBreakdown', genderCounts)
    }
  })

  const [lazyGetStudents] = useLazyQuery(GET_STUDENTS_BY_SCHOOL_IDS, {
    variables: { id: schoolIds() },
    onCompleted: ({ schools: querySchools }) => {
      const studentsList = allStudents(querySchools)
      const newStudent = studentsList[studentsList.length - 1]
      setCompleteStudentsList(studentsList)
      addStudentToIncluded([newStudent])
    },
    fetchPolicy: 'network-only'
  })

  // columns for the table of students included in the transaction
  const studentColumns = [
    {
      name: 'Student',
      maxWidth: '30%',
      minWidth: '150px',
      selector: (row) => row.first,
      cell: function StudentCell(row) {
        const studentObject = completeStudentsList.filter((student) => (
          student.id === row.studentId
        ))[0]

        // this table can render before this list comes through
        // so only use these properties if the object exists
        return studentObject
          ? (<span>
              {`${studentObject.first} ${studentObject.last}`}
              {isMultiSchool && (
                <small className="text-muted d-block">
                  {studentObject.schoolName}
                </small>
              )}
            </span>)
          : 'Loading...'
      }
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      maxWidth: '20%',
      minWidth: '140px',
      cell: function AmountCell(row) {
        return (
          <Form.Control
            type="number"
            step="any"
            min={0.01}
            value={students[row.studentId]?.amount || ''}
            onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
            onChange={(e) => updateIncludedStudent(row.studentId, 'amount', e.target.value)}
            required
          />
        )
      }
    },
    {
      name: 'Notes',
      selector: (row) => row.notes,
      cell: function NotesCell(row) {
        return (
          <Form.Control
            type="text"
            value={students[row.studentId]?.notes || ''}
            onChange={(e) => updateIncludedStudent(row.studentId, 'notes', e.target.value)}
          />
        )
      }
    },
    {
      name: '',
      width: '10%',
      cell: function RemoveCell(row) {
        return <FontAwesomeIcon
          onClick={() => removeIncludedStudent(row.studentId)}
          icon={faMinusCircle}
          size="lg"
        >
        </FontAwesomeIcon>
      }
    }
  ]

  return (
    <>
      <p className="text-ed-warm-gray-200">
        <em>
          {'Add students and enter other details of the transaction below.'}<br/>
          <span className="text-red">*</span>{' marks a required field.'}
        </em>
      </p>

      <h5 className="flex-heading border-bottom mb-3">
        {isBulkMode
          ? (
            <div>{'Student Details '}</div>
          )
          : (
          <div>{'Students Served '}<span className="text-red">*</span></div>
          )
        }

        <div
          className="student-transaction-link link-ed-dark-blue"
          onClick={() => {
            setIsBulkMode(!isBulkMode)
            setNoStudentsError(false)
            setStudentCountError(false)
          }}
        >
          {isBulkMode ? 'Switch to Individual Student Mode' : 'Switch to Bulk Purchase Mode'}
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">
              {isBulkMode
                ? 'Individual Student Mode allows you to add students individually by searching for and/or creating them.'
                : 'Bulk Purchase Mode allows you to enter transaction details without including specific students.'
              }
            </Tooltip>}
          >
            <FontAwesomeIcon icon={faQuestionCircle}/>
          </OverlayTrigger>
        </div>
      </h5>

      {isBulkMode
        // If bulk mode selected, display the bulk form fields
        ? (
          <div className="col-md-9 pl-0 pb-3">
            <p className="text-ed-warm-gray-200">
              <em>
                {'Enter the number of students as well as gender and ethnicity breakdown totals below'}
              </em>
            </p>
            <Form.Group controlId="formGroupStudentCount" className="short-field">
              <Form.Label>Number of Students <span className="text-red">*</span></Form.Label>
              <Form.Control
                type="number"
                min={1}
                value={quantity || ''}
                onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                onChange={(e) => handleChange(e, 'quantity', parseInt(e.target.value, 10))}
                placeholder='Enter number of students...'
                required
              />
            </Form.Group>

            {/* Ethnicity breakdown */}
            <h6 className="border-bottom mb-3">Ethnicity Breakdown <span className="text-red">*</span></h6>
            <p className="text-ed-warm-gray-200">
              <em>
                {'Note that the sum of all ethnicities must be equal to the total number of students.'}
              </em>
            </p>
            <Form.Group>
              {ethnicitiesLoading && (
                <div>Loading ethnicities...</div>
              )}
              {ethnicitiesError && (
                <div className="alert alert-danger">
                  <label>Error loading ethnicities..</label>
                  {ethnicitiesError.message}
                </div>
              )}
              {ethnicitiesData && (
                ethnicitiesData.ethnicities.map((ethnicity, i) => (
                  <div key={i} className="inline-input-row d-flex">
                    <Form.Label>{ethnicity.name}</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      value={ethnicityBreakdown[ethnicity.id] || ''}
                      onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                      onChange={(e) => {
                        updateEthnicityBreakdown(
                          ethnicity.id, parseInt(e.target.value || 0, 10)
                        )
                        setStudentCountError(false)
                      }}
                      data-testid="ethnicity-item"
                    />
                  </div>
                ))
              )}
              <div className="border-top">
                <div className="breakdown-totals-row">
                  <strong>Ethnicities Total</strong>
                  <strong className={ethnicitiesTotal !== quantity ? 'text-red' : ''}>{ethnicitiesTotal}</strong>
                </div>
              </div>
            </Form.Group>

            {/* Gender breakdown */}
            <h6 className="border-bottom mb-3">Gender Breakdown <span className="text-red">*</span></h6>
            <p className="text-ed-warm-gray-200">
              <em>
                {'Note that the sum of all genders must be equal to the total number of students.'}
              </em>
            </p>
            <Form.Group>
              {gendersLoading && (
                <div>Loading genders...</div>
              )}
              {gendersError && (
                <div className="alert alert-danger">
                  <label>Error loading genders..</label>
                  {gendersError.message}
                </div>
              )}
              {gendersData && (
                gendersData.genders.map((gender, i) => (
                  <div key={i} className="inline-input-row d-flex">
                    <Form.Label>{gender.name[0].toUpperCase() + gender.name.slice(1)}</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      value={genderBreakdown[gender.id] || ''}
                      onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                      onChange={(e) => {
                        updateGenderBreakdown(
                          gender.id, parseInt(e.target.value || 0, 10)
                        )
                        setStudentCountError(false)
                      }}
                      data-testid="gender-item"
                    />
                  </div>
                ))
              )}
              <div className="border-top">
                <div className="breakdown-totals-row">
                  <strong>Genders Total</strong>
                  <strong className={gendersTotal !== quantity ? 'text-red' : ''}>{gendersTotal}</strong>
                </div>
              </div>
            </Form.Group>

          </div>
        )
        // If bulk mode not selected, display the individual students form fields
        : (
          <>
            <p className="text-ed-warm-gray-200">
              <em>
                {'You can search for individual students below and select them if found. '}
                {'If no results are found, you can create a new student.'}
              </em>
            </p>

            {/* Filtered search dropdown of students to select */}
            <Form.Group controlId="formGroupSearch">
              <div className="dropdown-and-button-box">
                <Typeahead
                  clearButton
                  id="student-search"
                  labelKey={(option) => `${option.id} ${option.first} ${option.last}`}
                  renderMenuItemChildren={(option) => (
                    <div className="d-flex">
                      <div className="name-column">
                        {`${option.first} ${option.last}`}
                        {isMultiSchool && (
                          <small className="text-muted">
                            {option.schoolName}
                          </small>
                        )}
                      </div>
                      <div className="secondary-column">
                        <small>
                          ID: {option.id}
                        </small>
                      </div>
                      <div className="secondary-column">
                        <small>
                          {option.gender.name}
                        </small>
                      </div>
                      <div className="secondary-column">
                        <small>
                          Grade: {option.grade.name}
                        </small>
                      </div>
                    </div>
                  )}
                  options={completeStudentsList.filter((student) => !students[student.id])}
                  placeholder={studentsLoading ? 'Loading students...' : 'Search for a student by name or ID...'}
                  selected={[]}
                  disabled={studentsLoading}
                  onChange={addStudentToIncluded}
                />
                {!isAdmin && (
                  <div
                    className="student-transaction-link link-ed-dark-blue pt-1 pb-2"
                    onClick={() => setModalOpen(true)}
                  >
                    Create a New Student
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip">
                        {'Can\'t find the student you\'re looking for? Click to create a new student.'}
                      </Tooltip>}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle}/>
                    </OverlayTrigger>
                  </div>
                )}

                {/* Modal for adding a new student */}
                {modalOpen && (
                  <GlobalModal
                    title="Create Student"
                    onClose={() => setModalOpen(false)}
                  >
                    <NewStudentForm
                      schools={schools}
                      setModalOpen={setModalOpen}
                      handleNewStudentCreated={lazyGetStudents}
                    />
                  </GlobalModal>
                )}
              </div>

              {/* FUTURE implement link for selecting many students at once */}
              {/* <a className="student-transaction-link link-ed-dark-blue">
                {'Select many students at once'}
                <FontAwesomeIcon icon={faQuestionCircle}/>
              </a> */}
            </Form.Group>

            {/* Table of students included in transaction */}
            <Form.Group className="form-table">
              <GlobalDataTable
                columns={studentColumns}
                data={Object.keys(students).map((key) => students[key])}
                selectableRows={false}
                pagination={false}
                noDataComponent={
                  <div className="p-1 text-ed-warm-gray-200">{'No students selected. Search and add them above.'}</div>
                }
              />
              {/* Render total amount row only if students have been included */}
              {Object.keys(students).length > 0 && (
                <div className="student-table-footer">
                  <div className="label">Total Amount</div>
                  <div className="total">{formatUSD.format(totalAmount)}</div>
                </div>
              )}
            </Form.Group>
          </>
        )
      }
      <h5 className="border-bottom mb-3 mt-3">Transaction Details</h5>

      {/* If bulk mode, render an amount box */}
      {isBulkMode && (
        <Form.Group controlId="formGroupAmount" className="short-field">
          <Form.Label>Total Transaction Amount <span className="text-red">*</span></Form.Label>
          <Form.Control
            type="number"
            step="any"
            min={0.01}
            onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
            value={totalAmount || ''}
            onChange={(e) => handleChange(e, 'totalAmount', parseFloat(e.target.value))}
            placeholder='Enter dollar amount...'
            required
            data-testid="student-transaction-form-amount"
          />
        </Form.Group>
      )}

      {/* Date */}
      <Form.Group controlId="formGroupDate" className="short-field mb-0">
        <Form.Label>Date of Transaction <span className="text-red">*</span></Form.Label>
        <Form.Control
          type="date"
          max={moment().format('YYYY-MM-DD')}
          min={startDate || false}
          value={transactionDate}
          onChange={(e) => handleChange(e, 'transactionDate')}
          required
        />
      </Form.Group>

      <p className="text-ed-warm-gray-200">
        <em>
          <small>
            {'The date of the purchase must be today or earlier, and within the current fiscal year.'}
          </small>
        </em>
      </p>

      <h6 className="border-bottom mb-3">Category and Code <span className="text-red">*</span></h6>

      {/* Category Types */}
      <Form.Group controlId="formGroupCategory" className="d-flex">
        <div className="left-label pl-2 pr-2">Category</div>
        <Form.Control
          className="no-left-radius"
          type="select"
          as="select"
          value={categoryTypeId || ''}
          onChange={(e) => {
            handleChange(e, 'categoryTypeId', parseInt(e.target.value, 10))
          }}
          disabled={categoryTypesLoading}
          required
        >
          {categoryTypesLoading
            ? <option hidden value=''>Categories loading...</option>
            : <option hidden value=''>Select a category...</option>
          }
          {categoryTypesData?.activeCategoryTypes.map((type) => (
            <option key={type.id} value={type.id}>{type.name}</option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Category Codes */}
      <Form.Group controlId="formGroupCode" className="d-flex">
        <div className="left-label pl-2 pr-2">Code</div>
        <Form.Control
          className="no-left-radius"
          type="select"
          as="select"
          value={categoryCodeId || ''}
          onChange={(e) => handleChange(e, 'categoryCodeId', parseInt(e.target.value, 10))}
          required
        >
          {categoryTypeId && (
            <option hidden value=''>Select a category code...</option>
          )}
          {categoryCodesState?.[categoryTypeId]?.map((type) => (
            <option key={type.id} value={type.id}>({type.number}) {type.name}</option>
          ))}
        </Form.Control>
      </Form.Group>
    </>
  )
}

StudentTransactionForm.propTypes = {
  schools: PropTypes.array,
  startDate: PropTypes.string,
  totalAmount: PropTypes.number,
  transactionDate: PropTypes.string,
  categoryTypeId: PropTypes.number,
  categoryCodeId: PropTypes.number,
  students: PropTypes.object,
  quantity: PropTypes.number,
  genderBreakdown: PropTypes.object,
  ethnicityBreakdown: PropTypes.object,
  isBulkMode: PropTypes.bool,
  isAdmin: PropTypes.bool,
  setIsBulkMode: PropTypes.func,
  setStudentCountError: PropTypes.func,
  setNoStudentsError: PropTypes.func,
  handleChange: PropTypes.func
}

export default StudentTransactionForm
