import React from 'react'
import {
  Card,
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap'

export default function Home() {
  return (
    <Container>
      <Row className="p-3">
        <Col className="p-0 banner-image-column" md={12} lg={8}>
          <Image alt="SPACE Portal banner image" className="banner-image" src="./space-portal-banner.jpg" width="100%"/>
        </Col>
        <Col className="p-0">
          <Card className="banner-text-card">
            <Card.Body>
              <p className="mb-0">
                <strong>{'Welcome to the InvestED SPACE portal.'}</strong>
                {' The central hub for our school partners to manage funding for students in need. If you are a coordinator, you can '}
                <a href="/login">{'login here'}</a>
                {', or '}
                <a href="/forgot-password">{'click here'}</a>
                {' if you forgot '}
                <a href="/forgot-password">{'your password'}</a>
                {'. Please '}
                <a href="/contact">{'contact us'}</a>
                {' if you need further assistance.'}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={4} className="p-3">
          <Image alt="Space portal quote 1" src="./images/space-portal-quote-1.jpg" width="100%" rounded/>
        </Col>
        <Col sm={12} lg={4} className="p-3">
          <Image alt="Seed program donations" src="./images/space-portal-seed-donations.jpg" width="100%" rounded/>
        </Col>
        <Col sm={12} lg={4} className="p-3">
          <Image alt="Space portal quote 2" src="./images/space-portal-quote-2.jpg" width="100%" rounded/>
        </Col>
      </Row>
    </Container>
  )
}
