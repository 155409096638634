import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'react-bootstrap'

const FilterComponent = ({
  filterText = '',
  onFilter = () => {},
  onClear = () => {}
}) => (
  <>
    <Form.Control
      type="text"
      size="sm"
      placeholder="Filter results"
      aria-label="Filter input"
      value={filterText}
      onChange={onFilter}
    />
    {!!filterText && (
      <Button
        variant="link"
        size="sm"
        className="data-table-clear-btn text-dark"
        onClick={onClear}
        data-testid="clear-btn"
      >
        X
      </Button>
    )}
  </>
)

FilterComponent.propTypes = {
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func
}

export default FilterComponent
