import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

const Admin = () => (
  <div className="Home">
    <div className="lander">
      <h3 className="page-title border-bottom">InvestED Application Settings</h3>
    </div>
    <Card className="mt-3 p-3">
      <div className="admin-link">
        <Link to="/admin/personnel" className="text-dark">
          <span className="link-title">Manage InvestED Personnel</span><br />
          <span className="text-muted">Directory listing of personnel with admin access to the SPACE Portal</span>
        </Link>
      </div>
      <div className="admin-link">
        <Link to="/admin/year-end-status" className="text-dark">
          <span className="link-title">Year End Status</span><br />
          <span className="text-muted">View year end status of each school</span>
        </Link>
      </div>
      <div className="admin-link">
        <Link to="/admin/impact-stories-export" className="text-dark">
          <span className="link-title">Export Student Impact Stories</span><br />
          <span className="text-muted">Export a report of all student impact stories for a given year</span>
        </Link>
      </div>
      <div className="admin-link">
        <Link to="/admin/finance" className="text-dark">
          <span className="link-title">Financial Features</span><br />
          <span className="text-muted">Fund matching caps, rules and ratios</span>
        </Link>
      </div>
      <div className="admin-link">
        <Link to="/admin/lists" className="text-dark">
          <span className="link-title">Drop Down Lists and Other Options</span><br />
          <span className="text-muted">Transaction types, categories and codes per, genders, ethnicities, positions...</span>
        </Link>
      </div>
      <div className="admin-link">
        <Link to="/admin/content" className="text-dark">
          <span className="link-title">Content Management</span><br />
          <span className="text-muted">Customizable pages and system emails</span>
        </Link>
      </div>
      <div className="admin-link">
        <Link to="/admin/customer-tools" className="text-dark">
          <span className="link-title">Customer Service Tools</span><br />
          <span className="text-muted">Audit trails, contact log, coordinator login reports</span>
        </Link>
      </div>
    </Card>
  </div>
)
export default Admin
