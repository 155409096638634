import { gql } from '@apollo/client'

export const MUTATE_PROFILE = gql`
  mutation MutateProfile(
    $id: Int!
    $first: String
    $last: String
  ) {
    updateUser(
      id: $id
      first: $first
      last: $last
    ) {
      data {
        first
        last
      }
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation(
    $previousPassword: String!
    $proposedPassword: String!
  ) {
    changePassword(
      previousPassword: $previousPassword
      proposedPassword: $proposedPassword
    )
  }
`
