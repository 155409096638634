import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import customStyles from './GlobalDataTable.styles'
import FilterComponent from './FilterComponent'
import {
  exportDataTableHelper
} from '../utils/pdf'

/**
 * react-data-table-component wrapper
 *
 * @param  {array} [data=[]] - data to populate the table
 * @param  {array} [columns=[]] - explicite list of columns
 * @param  {bool} [subHeader=false] - shows the sub header which contains the filter input text box
 * @param  {bool} [selectableRows=true] - displays checkbox to select rows
 * @param  {object} [pagination=true] - implements pagination on the client side
 * @param  {object} [noDataComponent] - jsx component to render when data is empty
 * @param  {function} [handleRowClick] - function to handle click event on a row
 * @param  {any} [noDataComponent] - string or component to render when no data rows
 * @param  {string} [wrapperClassName=""] - class name for wrapper component
 * @param  {string} [wrapperId=""] - id for wrapper element, required if exporting
 * @param  {string} [defaultSortField="name"] - data field to sort the table by default
 * @param  {string} [exportFileName=""] - file name for exporting
 * @param  {boolean} [paginationServer=false] - changes the default pagination to work with server side pagination
 * @param  {number} [paginationTotalRows] - the total row count for your table, defaults to data.length
 * @param  {function} [onChangeRowsPerPage] - (currentRowsPerPage, currentPage) => {}
 * @param  {function} [onChangePage] - (page, totalRows) => {}
 * @param  {boolean}  [progressPending = false] - whether the data is loading
 * @param  {func}     [setServerFilterText] - set text to filter serverside
 * @param  {func}     [handleServerSort] - set sort for serverside sorting
 */
const GlobalDataTable = ({
  data = [],
  columns = [],
  subHeader = true,
  selectableRows = true,
  pagination = true,
  handleRowClick = () => {},
  noDataComponent = (
    <div className="p-3">
      There are no records to display
    </div>
  ),
  wrapperClassName = '',
  wrapperId = '',
  defaultSortField = 'name',
  exportFileName = '',
  paginationServer = false,
  paginationTotalRows,
  paginationRowsPerPageOptions = [10, 15, 20, 25, 30],
  selectAllRowsItem = true,
  onChangeRowsPerPage,
  onChangePage,
  progressPending = false,
  setServerFilterText,
  handleServerSort
}) => {
  const [filteredData, setFilteredData] = React.useState()
  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)

  React.useEffect(() => {
    if (paginationServer) {
      setFilteredData()
    }
  }, [data])

  // export the data table in it's current filtered state
  // TODO capture sorted state
  const exportHandler = (e) => {
    e.preventDefault()

    exportDataTableHelper({
      exportFileName,
      columns,
      data: filteredData || data
    })
  }

  // subheader for filtering component
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilteredData(null)
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
        if (paginationServer) {
          setServerFilterText('')
        }
      }
    }
    const handleFilter = (value) => {
      const filteredItems = data?.filter(
        (item) => JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
      )
      setFilteredData(filteredItems)
      setFilterText(value)
      if (paginationServer) {
        setServerFilterText(value)
      }
    }

    return (
      <div
        className="mb-2 data-table-sub-header d-print-none"
      >
        {!!wrapperId && data.length && (
          <Button
            className="btn-export mt-1"
            size="sm"
            onClick={exportHandler}
          >
            Export
          </Button>
        )}
        <FilterComponent
          onFilter={(e) => handleFilter(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    )
  }, [filterText, resetPaginationToggle, filteredData])

  return (
    <div
      id={wrapperId}
      className={`global-data-table ${wrapperClassName}`}
    >
        <DataTable
          data={filteredData || data}
          columns={columns}
          defaultSortField={defaultSortField}
          noHeader
          customStyles={customStyles}
          pagination={pagination}
          paginationResetDefaultPage={false}// {resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          paginationComponentOptions={{ selectAllRowsItem }}
          subHeader={subHeader}
          subHeaderAlign="left"
          subHeaderComponent={subHeaderComponentMemo}
          selectableRows={selectableRows}
          persistTableHead
          onRowClicked={handleRowClick}
          noDataComponent={noDataComponent}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          progressPending={progressPending}
          sortServer={paginationServer}
          onSort={handleServerSort}
        />
    </div>
  )
}

GlobalDataTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  subHeader: PropTypes.bool,
  selectableRows: PropTypes.bool,
  pagination: PropTypes.bool,
  handleRowClick: PropTypes.func,
  noDataComponent: PropTypes.any,
  wrapperClassName: PropTypes.string,
  wrapperId: PropTypes.string,
  defaultSortField: PropTypes.string,
  exportFileName: PropTypes.string,
  paginationServer: PropTypes.bool,
  paginationTotalRows: PropTypes.number,
  paginationRowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  selectAllRowsItem: PropTypes.bool,
  onChangeRowsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  progressPending: PropTypes.bool,
  setServerFilterText: PropTypes.func,
  handleServerSort: PropTypes.func
}

export default GlobalDataTable
