import { gql } from '@apollo/client'

// queries
export const GET_STUDENTS_BY_SCHOOL_IDS = gql`
  query (
    $id: [Int]!
  ) {
    schools(id: $id) {
      name
      students {
        id
        first
        last
        grade {
          name
        }
        gender {
          name
        }
      }
    }
  }
`
export const GET_STUDENTS_DIRECTORY_BY_SCHOOL = gql`
  query GetStudentsBySchool(
    $schoolIds: [Int] = []
  ) {
    schools(id: $schoolIds) {
      id
      name
      students {
        id
        first
        last
        grade {
          id
          name
        }
        gender {
          id
          name
        }
        ethnicity {
          id
          name
        }
        school {
          id
          name
        }
        active
        studentTransactions {
          transaction {
            id
            totalAmount
            transactionDate
            categoryCode {
              name
              categoryType {
                name
              }
            }
          }
        }
      }
    }
  }
`
export const QUERY_STUDENT = gql`
  query GetOneStudent (
    $id: Int!
  ) {
    student(id: $id) {
      id
      first
      last
      grade {
        id
      }
      gender {
        id
      }
      ethnicity {
        id
      }
      school {
        id
      }
      active
    }
  }
`
export const QUERY_DROPDOWNLISTS = gql`
  query {
    genders {
      id
      name
    }
    ethnicities {
      id
      name
    }
    grades {
      id
      name
    }
  }
`
export const QUERY_GENDERS = gql`
  query {
    genders {
      id
      name
    }
  }
`
export const QUERY_ETHNICITIES = gql`
  query {
    ethnicities {
      id
      name
    }
  }
`
export const QUERY_GRADES = gql`
  query {
    grades {
      id
      name
    }
  }
`

// mutations
export const CREATE_STUDENT = gql`
  mutation CreateStudent (
    $first: String!
    $last: String!
    $gradeId: Int!
    $genderId: Int!
    $ethnicityId: Int!
    $schoolId: Int!
    $active: Boolean!
  ) {
    createStudent(
      first: $first
      last: $last
      gradeId: $gradeId
      genderId: $genderId
      ethnicityId: $ethnicityId
      schoolId: $schoolId
      active: $active
    ) {
      id
    }
  }
`
export const MUTATE_STUDENT = gql`
  mutation UpdateStudent (
    $id: Int!
    $first: String!
    $last: String!
    $gradeId: Int!
    $genderId: Int!
    $ethnicityId: Int!
    $schoolId: Int!
    $active: Boolean!
  ) {
    updateStudent(
      id: $id
      first: $first
      last: $last
      gradeId: $gradeId
      genderId: $genderId
      ethnicityId: $ethnicityId
      schoolId: $schoolId
      active: $active
    ) {
      affectedRows
    }
  }
`

export const UNENROLL_STUDENT = gql`
  mutation UnenrollStudentMutation(
    $id: Int!
    $schoolId: Int
  ) {
    updateStudent(
      id: $id
      schoolId: $schoolId
      active: false
    ) {
      affectedRows
    }
  }
`
