import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import ModalProvider from './ModalContext'
import * as auth from '../utils/authToken'
import { API_ENDPOINT } from '../constants'

function getBearer() {
  const token = auth.getToken()
  return token
    ? token.authenticationResult.idToken
    : ''
}

// TODO re-authenticate and retry on successful reauthentication
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors.message?.toLowerCase() === 'forbidden') {
      auth.logout()
    }
  }
  if (networkError) {
    if ([401, 403].includes(networkError.statusCode)) {
      auth.logout()
    }
  }
})
const httpLink = createHttpLink({
  uri: `${API_ENDPOINT}/graphql`
})
const authLink = setContext((_, { headers }) => {
  // eslint-disable-next-line no-undef
  const token = getBearer()
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
})
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache()
})

const AppProviders = ({ children }) => (
  <Router>
    <ApolloProvider client={client}>
      <ModalProvider>
        {children}
      </ModalProvider>
    </ApolloProvider>
  </Router>
)

AppProviders.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppProviders
