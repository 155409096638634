import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Collapse
} from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-light-svg-icons'

const StudentStoryDetails = ({
  studentStory = {}
}) => {
  const [storyOpen, setStoryOpen] = useState(false)

  return (
    <>
      <h5 className="pr-3">
        {studentStory.title}
        <small className="float-right">
          <Button
            variant="info"
            size="sm"
            href={`/impact-stories/edit/${studentStory.id}`}
            className="bg-ed-dark-blue text-light"
          >
            edit student impact story
          </Button>
        </small>
      </h5>
      {/* This condition was added because of data without createdBy info */}
      {/* If real data will always have that, this should be removed */}
      {studentStory.createdBy && (
        <div><small>{`Student impact story created by ${studentStory.createdBy.first} ${studentStory.createdBy.last}`}</small></div>
      )}
      <div><small>{`Student impact story created on ${moment(studentStory?.createdAt).format('dddd, MMMM Do, YYYY h:mma')}`}</small></div>
      <div>
        <b>
          {'Student Impact Story Category: '}
          {studentStory.studentStoryCategory.name === 'Other'
            ? (`Other - ${studentStory.studentStoryCategoryOther}`)
            : (studentStory.studentStoryCategory.name)
          }
        </b>
      </div>
      <Collapse in={storyOpen}>
        <div id="studentStoryCollapsable">
          {(studentStory.grade
            || studentStory.gender
            || studentStory.ethnicity)
            && (
              <ul className="list-group list-group-flush border-bottom border-top mb-3">
                {studentStory.grade && (
                  <li className="list-group-item text-dark">Grade: {studentStory.grade.name}</li>
                )}
                {studentStory.gender && (
                  <li className="list-group-item text-dark">Gender: {studentStory.gender.name}</li>
                )}
                {studentStory.ethnicity && (
                  <li className="list-group-item text-dark">Ethnicity: {studentStory.ethnicity.name}</li>
                )}
              </ul>
            )}
          <div
            className="px-3"
            dangerouslySetInnerHTML={{
              __html: studentStory.story
            }}
          />
        </div>
      </Collapse>
      <div>
        <Button
          variant="link"
          size="sm"
          className="text-dark pl-0 mt-1"
          onClick={() => setStoryOpen(!storyOpen)}
          aria-expanded={storyOpen}
          aria-controls="studentStoryCollapsable"
        >
          {storyOpen
            ? (<>
              {'Click here to hide the story details '}
              <FontAwesomeIcon
                className="ml-2"
                icon={faChevronUp}
              />
            </>)
            : (<>
              {'Click here to show more story details '}
              <FontAwesomeIcon
                className="ml-2"
                icon={faChevronDown}
              />
            </>)
          }
        </Button>
      </div>
    </>
  )
}

StudentStoryDetails.propTypes = {
  studentStory: PropTypes.object
}

export default StudentStoryDetails
