import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import UserContext from './contexts/UserContext'
import PrivateHeader from './components/PrivateHeader'
import Sidebar from './components/Sidebar'
import PrivateRoutes from './PrivateRoutes'
import CoordinatorActions from './components/CoordinatorActions'
import { balancePathnames } from './constants'

/**
 * Renders the private app, for authenticated user
 */
const PrivateApp = () => {
  const location = useLocation()
  return <UserContext.Consumer>
    {({ user: userContext, session, yearEndReportInProgress }) => {
      const {
        currentSchool,
        currentDistrict,
        currentSchoolYear
      } = session
      const showBalance = !!currentSchool?.id && !balancePathnames.includes(location.pathname)
      return (
        <>
          <PrivateHeader
            userName={userContext.first}
            schoolName={(currentSchool?.name || currentDistrict?.name) || ''}
          >
            <CoordinatorActions
              showBalance={showBalance}
              schoolId={currentSchool?.id}
              schoolYearId={currentSchoolYear?.id}
            />
          </PrivateHeader>
          <div className="App container pr-0 mx-0 container-right">
            <Sidebar />
            <div className="main-container">
              {
                !!(yearEndReportInProgress && !(location.pathname.indexOf('/year-end-report') > -1)) && (
                  <div className="alert alert-warning">
                    You have a year end report in progress <Link to="/year-end-report">Continue Year End Report</Link>
                  </div>
                )
              }
              <PrivateRoutes />
            </div>
          </div>
        </>
      )
    }}
  </UserContext.Consumer>
}

export default PrivateApp
