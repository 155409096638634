import * as React from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useLocation } from 'react-router'

import { QUERY_CURRENT_ACCOUNT_BALANCE } from '../operations/accountBalance'
import UserContext from '../contexts/UserContext'

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)
  React.useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : undefined),
    [dispatch]
  )
}

// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchPokemon(pokemonName))
// }, [pokemonName, run])
const defaultInitialState = { status: 'idle', data: null, error: null }
function useAsync(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState
  })
  const [{ status, data, error }, setState] = React.useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current
  )

  const safeSetState = useSafeDispatch(setState)

  const setData = React.useCallback(
    (d) => safeSetState({ d, status: 'resolved' }),
    [safeSetState]
  )
  const setError = React.useCallback(
    (e) => safeSetState({ e, status: 'rejected' }),
    [safeSetState]
  )
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState]
  )

  const run = React.useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          'The argument passed to useAsync().run must be a promise. Maybe a function that\'s passed isn\'t returning anything?'
        )
      }
      safeSetState({ status: 'pending' })
      return promise.then(
        (d) => {
          setData(d)
          return d
        },
        (e) => {
          setError(e)
          return Promise.reject(e)
        }
      )
    },
    [safeSetState, setData, setError]
  )

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset
  }
}

const initialSummaryState = {
  totalInitialFallFunding: null,
  percentUsed: null,
  currentBalance: null,
  balancesByTransactionType: null
}

export function useBalance(schoolYear) {
  const { session } = React.useContext(UserContext)
  const { currentSchool, currentDistrict } = session
  const schoolId = currentSchool?.id
  const districtId = currentDistrict?.id
  const isSchoolOrDistrictSelected = !!(currentSchool?.id || currentDistrict?.id)

  const [balanceData, setBalanceData] = React.useState(initialSummaryState)

  const onBalanceCompleted = ({ accountBalance }) => {
    if (accountBalance) {
      const {
        balancesByTransactionType,
        startBalance,
        fallFunding,
        currentBalance
      } = accountBalance
      setBalanceData({
        totalInitialFallFunding: fallFunding?.totalInitialFallFunding,
        percentUsed: Math.round(fallFunding?.percentUsed),
        currentBalance,
        balancesByTransactionType: [
          {
            transactionType: 'Starting Balance',
            sum: startBalance
          },
          ...(balancesByTransactionType || [])
        ]
      })
    } else {
      setBalanceData({
        totalInitialFallFunding: 0,
        percentUsed: 0,
        currentBalance: 0,
        balancesByTransactionType: []
      })
    }
  }

  const [
    lazyBalance, {
      loading: balanceLazyLoading,
      error: balanceLazyError
    }] = useLazyQuery(QUERY_CURRENT_ACCOUNT_BALANCE, {
    errorPolicy: 'ignore',
    onCompleted: onBalanceCompleted,
    fetchPolicy: 'network-only'
  })

  const {
    loading: balanceLoading,
    error: balanceError,
    refetch: refetchBalance
  } = useQuery(QUERY_CURRENT_ACCOUNT_BALANCE, {
    variables: {
      districtId,
      schoolId,
      schoolYearId: schoolYear.id
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !isSchoolOrDistrictSelected,
    onCompleted: onBalanceCompleted
  })

  return {
    balanceData,
    lazyBalance,
    balanceLazyLoading,
    balanceLazyError,
    balanceLoading,
    balanceError,
    refetchBalance
  }
}

export function useSearchParams() {
  const { search } = useLocation()

  const rawQueryString = search ? search.substring(1) : ''
  const searchParams = new URLSearchParams(rawQueryString)

  function getParamValue(paramName) {
    return searchParams.get(paramName)
  }

  return {
    searchParams: searchParams.entries(),
    getParamValue
  }
}

export default { useAsync, useBalance }
