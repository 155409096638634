/**
 * Given a list of grades and a school, return the list of grades that exists in
 * the school
 *
 * If grades or school is falsey, returns the grades parameter unmodified
 *
 * grades is an array of objects with an integer id property school is an object
 * with a minimumGrade and maximumGrade grade properties each of which, in turn,
 * has an id property
 *
 * The id for grade K is 13 so filtering the list of grades that are within the
 * min and max for the school requires special handling
 */
// eslint-disable-next-line import/prefer-default-export
export const validGradesForSchool = (grades, school) => (
  grades && school
    ? grades.filter(
      (grade) => (school?.minimumGrade?.id === 13
      || grade.id >= school?.minimumGrade?.id)
      && grade.id <= school?.maximumGrade?.id
    )
    : grades
)
