import React from 'react'
import PublicHeader from './components/PublicHeader'
import PublicRoutes from './PublicRoutes'

export default function PublicApp() {
  return (
    <>
      <PublicHeader />
      <div className="App container">
        <PublicRoutes />
      </div>
    </>
  )
}
