import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import LoadingSpinner from '../components/LoadingSpinner'

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation(
    $email: String!
  ) {
    forgotPassword(email: $email)
  }
`
export const FORGOT_PASSWORD_CONFIRM_MUTATION = gql`
  mutation ForgotPasswordConfirmMutation(
    $confirmationCode: String!
    $email: String!
    $password: String!
  ) {
    confirmForgotPassword(
      confirmationCode: $confirmationCode
      email: $email
      password: $password
    )
  }
`

/**
 * Component for user to submit a forgotten password request, and then submit a confirmation code to complete the process, takes no parameters
 */
const ForgotPassword = () => {
  const initialState = {
    email: '',
    confirmationCode: '',
    password: '',
    confirmPassword: '',
    challengeMode: false,
    successMode: false,
    triedPasswordRequest: false,
    triedConfirm: false
  }
  const [formState, setFormState] = useState(initialState)
  const [errorState, setErrorState] = useState('')

  // mutations
  const [forgotPassMutation, {
    loading: forgotPasswordRequestLoading,
    error: forgotPasswordRequestError
  }] = useMutation(FORGOT_PASSWORD_MUTATION, {
    variables: {
      email: formState.email
    }
  })
  const [confirmPasswordMutation, {
    loading: confirmPasswordRequestLoading,
    error: confirmPasswordRequestError
  }] = useMutation(FORGOT_PASSWORD_CONFIRM_MUTATION, {
    variables: {
      email: formState.email,
      confirmationCode: formState.confirmationCode,
      password: formState.password
    }
  })

  // validation
  const validate = () => {
    let valid = true
    // validate password min length is 8
    valid = formState.password.length >= 8
    if (!valid) {
      setErrorState('Your new password must be at least 8 characters in length.')
      return valid
    }

    // validate passwords match
    valid = formState.password === formState.confirmPassword
    if (!valid) {
      setErrorState('Password fields do not match.')
      return valid
    }

    return valid
  }

  // event listeners
  const onFormSubmit = (e) => {
    e.preventDefault()
    setErrorState('')

    // first submit, to request changing of the password
    if (!formState.challengeMode) {
      forgotPassMutation()
        .then(({ data }) => {
          setFormState({
            ...formState,
            challengeMode: data.forgotPassword,
            triedPasswordRequest: true
          })
        })
    } else {
      // second submit, to confirm new password
      if (!validate()) {
        return
      }
      confirmPasswordMutation()
        .then(({ data }) => {
          setFormState({
            ...formState,
            successMode: data.confirmForgotPassword,
            triedConfirm: true
          })
        })
    }
  }
  const onResetClick = () => {
    setFormState({
      ...initialState
    })
  }

  // render
  return (
    <Form onSubmit={onFormSubmit}>
      <div className="inner-body">
        <main className="form-signin border rounded bg-light p-5">
          <h4 className="text-center">
            Forgot your password?
          </h4>
          {/* Error alert */}
          {((forgotPasswordRequestError || confirmPasswordRequestError)
            && !(forgotPasswordRequestLoading || confirmPasswordRequestLoading))
            && (
              <div className="alert alert-warning">
                {(forgotPasswordRequestError || confirmPasswordRequestError).message}
              </div>
            )}
          {errorState && (
            <div className="alert alert-warning">
              {errorState}
            </div>
          )}
          {/* Initial forgot password form */}
          {!formState.challengeMode && !formState.successMode && (
            <>
              {/* Error alert: Password request denied */}
              {formState.triedPasswordRequest && (
                <div className="alert alert-warning">
                  Change password request denied
                </div>
              )}

              <Form.Group className="form-floating mb-3" controlId="formGroupEmail">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="email"
                  value={formState.email}
                  onChange={(e) => setFormState({
                    ...formState,
                    email: e.target.value
                  })
                  }
                  required
                />
                <Form.Label>Email address</Form.Label>
              </Form.Group>
              {!forgotPasswordRequestLoading && (
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100"
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </>
          )}

          {/* Confirm forgot password form */}
          {(formState.challengeMode && !formState.successMode) && (
            <>
              {/* Error alert: confirm password request denied */}
              {formState.triedConfirm && (
                <div className="alert alert-warning">
                  Change password request denied
                </div>
              )}
              <div className="alert alert-success">Forgot password email has been sent to {formState.email}. Please check your email and enter the confirmation code received complete the reset of your password.</div>
              <Form.Group className="form-floating mb-3" controlId="formGroupEmail">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="email"
                  value={formState.email}
                  required
                  disabled
                />
                <Form.Label>Email address</Form.Label>
              </Form.Group>
              <Form.Group className="form-floating mb-3" controlId="formGroupChallengeMode">
                <Form.Control
                  type="text"
                  placeholder="Challenge Code"
                  name="challenge"
                  value={formState.confirmationCode}
                  onChange={(e) => setFormState({
                    ...formState,
                    confirmationCode: e.target.value
                  })
                  }
                  required
                />
                <Form.Label>Challenge Code</Form.Label>
              </Form.Group>
              <Form.Group className="form-floating mb-0" controlId="formGroupPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formState.password}
                  onChange={(e) => setFormState({
                    ...formState,
                    password: e.target.value
                  })
                  }
                  minLength="8"
                  required
                />
                <Form.Label>New Password</Form.Label>
              </Form.Group>
              <Form.Group className="form-floating mt-0" controlId="formGroupConfirmPassword">
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={formState.confirmPassword}
                  onChange={(e) => setFormState({
                    ...formState,
                    confirmPassword: e.target.value
                  })
                  }
                  required
                />
                <Form.Label>Confirm Password</Form.Label>
              </Form.Group>

              {/* hiding the buttons on click (and loading) */}
              {!confirmPasswordRequestLoading && (
                <div className="text-center">
                  <Button
                    variant="light"
                    size="lg"
                    className="mr-3"
                    onClick={onResetClick}
                  >
                    Start over
                  </Button>
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                  >
                    Submit &amp; Login
                  </Button>
                </div>
              )}
            </>
          )}

          {/* Loading Spinner div shown when things are loading */}
          {(forgotPasswordRequestLoading || confirmPasswordRequestLoading) && (
            <LoadingSpinner message="Please wait..." />
          )}

          {/* Success mode, password confirmed and reset, Link to return to login form */}
          {formState.successMode && (
            <div className="alert alert-success">You have successfully reset your password. Return to the login page and sign in.</div>
          )}
          {!(forgotPasswordRequestLoading || confirmPasswordRequestLoading) && (
            <>
              <div className="m-5">
                <Button
                  variant="button"
                  size="lg"
                  className="w-100"
                  href="/login"
                >
                  Return to Login
                </Button>
              </div>
              <div className="mt-3">
                <Button
                  variant="link"
                  size="sm"
                  className="w-100"
                  href="/contact"
                >
                  Contact us for more help
                </Button>
              </div>
            </>
          )}
        </main>
      </div>
    </Form>
  )
}
export default ForgotPassword
