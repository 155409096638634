import React, { useContext } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGraduate } from '@fortawesome/pro-regular-svg-icons'
import { useLazyQuery } from '@apollo/client'
import { QUERY_TRANSACTIONS } from '../operations/transactions'
import UserContext from '../contexts/UserContext'
import { formatUSD } from '../utils/helpers'
import { balanceTypes, transactionTypeIds } from '../constants'
import ExportActionButtons from './ExportActionButtons'
import { exportDataTableHelper as exportPdf } from '../utils/pdf'
import { formatDollarAmountCell } from '../utils/reports.columns'

const TransactionsDownloadButton = () => {
  // * hooks & vars
  const {
    session
  } = useContext(UserContext)
  const { currentSchool } = session
  const schoolId = currentSchool?.id

  const transactionsColumns = [
    {
      name: 'Date',
      exportKey: 'transactionDate',
      dataType: 'd',
      format: 'mm/dd/yyyy',
      width: '115px',
      widthInChars: 10,
      selector: (row) => row.transactionDate,
      sortable: true,
      cell: function DateCell(row) {
        return new Date(row.transactionDate).toLocaleDateString()
      },
      pdfCell: function DateCell(row) {
        return new Date(parseInt(row.transactionDate, 10)).toLocaleDateString()
      }
    },
    {
      name: 'Type',
      exportKey: 'transactionType',
      dataType: 's',
      widthInChars: 45,
      selector: (row) => row.transactionType?.name,
      sortable: true,
      cell: function TypeCell(row) {
        let transactionTypeName = row.transactionType?.name || ''
        if (row.transactionType.id === transactionTypeIds.STUDENT && row.quantity > 0) {
          transactionTypeName = 'Bulk Purchase - Student Transaction'
        }
        if ([
          transactionTypeIds.MATCHING,
          transactionTypeIds.SEED_FUNDING
        ].includes(row.transactionType.id) && row.approved === false) {
          transactionTypeName += ' - Pending'
        }
        return (
          <div className="transaction-type-cell">
            <div className="transaction-type" data-tag="allowRowEvents">{`${transactionTypeName}`}</div>
            {row.transactionType.id === transactionTypeIds.STUDENT && (
              <div className="transaction-type secondary" data-tag="allowRowEvents">
                {`${row.categoryCode.categoryType.name}; ${row.categoryCode.number}: ${row.categoryCode.name}`}
              </div>
            )}
            {row.transactionType.id === transactionTypeIds.DONATION && row.donor && (
              <div className="transaction-type secondary" data-tag="allowRowEvents">
                {`from ${row.donor}`}
              </div>
            )}
          </div>
        )
      }
    },
    {
      name: '',
      sortable: false,
      cell: function StudentStoryCell(row) {
        return row.studentStory
          ? <div className="border border-ed-dark-blue rounded py-1 px-2">
            <FontAwesomeIcon
              icon={faUserGraduate}
              size="lg"
              className="text-ed-dark-blue"
              data-tag="allowRowEvents"
            />
          </div>
          : ''
      }
    },
    {
      name: 'Notes',
      exportKey: 'notes',
      dataType: 's',
      widthInChars: 45,
      selector: (row) => row.notes,
      sortable: true,
      omit: true
    },
    {
      name: 'Amount',
      exportKey: 'totalAmount',
      dataType: 'n',
      format: '0.00',
      widthInChars: 10,
      selector: (row) => row.totalAmount,
      sortable: true,
      width: '150px',
      right: true,
      cell: (row) => (() => {
        const { balanceType } = row.transactionType
        return (
          <div className={balanceType === balanceTypes.DEBIT || row.totalAmount < 0 ? 'text-red' : ''}>
            {balanceType === balanceTypes.DEBIT
              ? formatUSD.format(-row.totalAmount)
              : formatUSD.format(row.totalAmount)}
          </div>
        )
      })(),
      pdfCell: (row) => formatDollarAmountCell({
        amount: row.totalAmount,
        balanceType: row.transactionType?.balanceType || balanceTypes.CREDIT
      })
    },
    {
      name: 'Balance',
      exportKey: 'balance',
      dataType: 'n',
      format: '0.00',
      selector: (row) => row.balance,
      sortable: false,
      width: '150px',
      widthInChars: 10,
      right: true,
      cell: function BalanceCell(row) {
        return (
          <div className={row.balance < 0 ? 'text-red' : ''}>
            {formatUSD.format(row.balance)}
          </div>
        )
      },
      pdfCell: (row) => formatDollarAmountCell({
        amount: row.balance,
        balanceType: row.transactionType?.balanceType || balanceTypes.CREDIT
      })
    }
  ]

  // * queries
  const [
    downloadTransactions, {
      loading: transactionsLazyLoading
    }] = useLazyQuery(QUERY_TRANSACTIONS, {
    variables: {
      schoolId: [schoolId],
      includeStartBalance: true
    },
    errorPolicy: 'ignore',
    onCompleted: ({ transactions }) => {
      const currentDate = moment()

      exportPdf({
        fileName: `InvestED-${currentSchool.name}-${currentDate.format('YYYY-MM-DD_HHmmss')}`,
        title: `${currentSchool.name} - Year End Report - ${currentDate.format('YYYY')}`,
        columns: transactionsColumns,
        data: transactions,
        didParseCell: ({ cell }) => {
          const [text] = cell?.text
          // all cells that have a negative dollar amount will be red text
          if (text && text.indexOf('-$') === 0) {
            // eslint-disable-next-line no-param-reassign
            cell.styles.textColor = [237, 24, 32]
          }
        }
      })
    },
    fetchPolicy: 'network-only'
  })

  // * render
  return (
    <ExportActionButtons
      reportKey="year-end-report"
      onClick={() => downloadTransactions()}
      className="btn-muted"
      enableXLSX={false}
      isLoading={transactionsLazyLoading}
      disabled={!currentSchool?.id}
    />
  )
}

export default TransactionsDownloadButton
