import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Context } from '../contexts/ModalContext'

/**
 * Component wrapper for react-bootstrap Modal component
 *
 * @param  {string} title - title block
 * @param  {object} children - children element for display in the Modal.Body
 * @param  {object} onClose - onHide handler
 * @param  {object} [footer] - footer element
 */
const GlobalModal = ({
  onClose,
  children,
  title,
  footer
}) => {
  const modalNode = useContext(Context)

  return modalNode
    ? ReactDOM.createPortal(
      <Modal
        show
        onHide={onClose}
        size="lg"
        aria-labelledby="global-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="global-modal-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        {footer}
      </Modal>,
      modalNode
    )
    : null
}

GlobalModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.instanceOf(Modal.Footer),
  onClose: PropTypes.func.isRequired
}

export default GlobalModal
