import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

/**
 * Modal for confirming deletion of records
 * @param {bool} [showModal=false] whether modal should display
 * @param {string} [title='Are you sure?'] title text for normal state
 * @param {string} [body='This action cannot be undone. Are you sure you want to proceed?'] body text for normal state
 * @param {bool} [deleteError=false] whether the deletion resulted in error
 * @param {string} [errorTitle='Something went wrong.'] title text for error state
 * @param {string} [errorBody='Please refresh the page and try again. If the problem persists, please contact us.'] body text for error state
 * @param {bool} [deleteLoading=false] whether the deletion is in progress, disables Delete button when true
 * @param {function} deleteModalClose function to call when modal closed
 * @param {string} [deleteButtonLabel="Delete"] label for delete button
 * @param {function} handleDelete function to call when Delete button clicked
 * @returns {any}
 */
const DeleteConfirmationModal = ({
  showModal = false,
  title = 'Are you sure?',
  body = 'This action cannot be undone. Are you sure you want to proceed?',
  deleteError = false,
  errorTitle = 'Something went wrong.',
  errorBody = 'Please refresh the page and try again. If the problem persists, please contact us.',
  deleteLoading = false,
  deleteModalClose,
  deleteButtonLabel = 'Delete',
  handleDelete
}) => (
  <Modal
    show={showModal}
    onHide={deleteModalClose}
  >
    <Modal.Header>
      <Modal.Title>
        {deleteError
          ? <span className="text-danger">{errorTitle}</span>
          : title
        }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {deleteError
        ? errorBody
        : body
      }
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={deleteModalClose} className="text-white">
        Cancel
      </Button>
      <Button
        variant="danger"
        onClick={handleDelete}
        className="text-white"
        disabled={deleteLoading}
        data-testid="delete-confirm-btn"
      >
        {deleteButtonLabel}
      </Button>
    </Modal.Footer>
  </Modal>
)

DeleteConfirmationModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  deleteError: PropTypes.bool,
  errorTitle: PropTypes.string,
  errorBody: PropTypes.string,
  deleteLoading: PropTypes.bool,
  deleteModalClose: PropTypes.func,
  deleteButtonLabel: PropTypes.string,
  handleDelete: PropTypes.func
}

export default DeleteConfirmationModal
