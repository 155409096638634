import { gql } from '@apollo/client'

export const QUERY_IS_YEAR_END_OPEN = gql`
  query {
    isYearEndOpen
  }
`

export const QUERY_YEARLY_OBJECTIVES_AND_CHOICES = gql`
  query (
    $active: Boolean
  ) {
   yearlyObjectives(
      active: $active
    ) {
      id
      name
      friendlyName
      successFriendlyName
      description
      objectiveDataType {
        name
      }
      yearlyObjectiveType {
        name
      }
      yearlyObjectiveChoices {
        id
        name
        choiceFriendlyName
        choiceDescription
        choiceDataType {
          name
        }
      }
    }
  }
`

export const QUERY_YEARLY_REPORTS_BY_SCHOOL_AND_YEAR = gql`
  query GetYearlyReportsBySchoolAndYear (
    $schoolId: Int!
    $schoolYearId: Int
  ) {
    yearlyReports(
      schoolId: $schoolId
      schoolYearId: $schoolYearId
    ) {
      id
      objective {
        id
        name
        friendlyName
        yearlyObjectiveType {
          name
        }
        objectiveDataType {
          name
        }
        description
      }
      objectiveValues {
        value
      }
    }
  }
`

export const QUERY_DISTRICT_AND_SCHOOL_USERS_FOR_YEARLY_REPORT = gql`
query GetDistrictAndSchoolUsersForYearlyReport (
  $schoolId: Int!
){
  schools (id: [$schoolId]) {
    district {
      userDistricts {
        schoolPosition {
          name
        }
        user {
          id
          first
          last
          email
        }
      }
    }
    userSchools {
      schoolPosition {
        name
      }
      user {
        id
        first
        last
        email
      }
    }
  }
}
`

export const ADD_OR_UPDATE_YEARLY_REPORT_MUTATION = gql`
  mutation AddOrUpdateYearlyReport(
    $schoolId: Int!
    $schoolYearId: Int
    $objectiveId: Int!
    $value: [String!]!
  ) {
    addOrUpdateToYearlyReport(
      schoolId: $schoolId
      schoolYearId: $schoolYearId
      objectiveId: $objectiveId
      objectiveValue: $value
    ) {
      affectedRows
      data {
        id
        objectiveValues {
          value
        }
      }
    }
  }
`

export const SEND_YEARLY_REPORT_MUTATION = gql`
  mutation SendYearEndReport(
    $userIds: [Int!]!
    $schoolId: Int!
    $schoolYearId: Int
  ) {
    sendYearEndReport(
      userIds: $userIds
      schoolId: $schoolId
      schoolYearId: $schoolYearId
    ) {
      sent
    }
  }
`
