import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import { balanceTypes } from '../constants'
import {
  studentRosterHeaders,
  transactionsHeaders,
  transactionStudentsHeaders
} from './reports.columns'

const totalPagesExp = '{total_pages_count_string}'
const dateForTitle = moment().format('YYYY')
const dateForFileName = moment().format('YYYY-MM-DD_HHmmss')

/**
 * custom style hook, sets the cell fontStyle to bold for only the last row
 * @param {Object} cell - hookData.cell
 * @param {Object} row - hookData.row
 * @param {Object} table - hookData.table
 * @returns {any}
 */
const customCellParserRenderLastRowBold = ({
  cell,
  row,
  table
}) => {
  const { body } = table

  if (
    body.length
    && (row.index === body.length - 1)
  ) {
    // eslint-disable-next-line no-param-reassign
    cell.styles.fontStyle = 'bold'
  }
}

/**
 * generates PDF using jsPdf AutoTable library
 * @param {array} [data=[]] - data array for the table body
 * @param {array} [headers=[]] - column headers
 * @param {string} [fileName='InvestED.pdf'] - full filename for the exported file
 * @param {string} [title='Report'] - title rendered in the report
 * @param {object} [format={orientation:'l', unit: 'pt', format: 'a4'}] - format for jsPDF
 * @param {function} [didParseCell=(cell, data) => {}] - Called when the plugin finished parsing cell content. Can be used to override content or styles for a specific cell.
 */
export const generatePdf = ({
  data = [],
  headers = [],
  fileName = 'InvestED.pdf',
  title = 'Report',
  format = {
    orientation: 'l',
    unit: 'pt',
    format: 'a4'
  },
  didParseCell = () => {}
}) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF(format)

  autoTable(doc, {
    head: [headers],
    headStyles: { fillColor: [115, 144, 184] },
    body: data,
    didDrawPage: (page) => {
      // Header
      doc.setFontSize(14)
      doc.setTextColor(106, 87, 69)
      doc.addImage('/logo.png', 'png', page.settings.margin.left, 5, 113, 45)
      doc.text(title, page.settings.margin.left + 125, 35)

      // Footer
      let str = `Page ${doc.internal.getNumberOfPages()}`
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str += ` of ${totalPagesExp}`
      }
      doc.setFontSize(10)

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      const { pageSize } = doc.internal
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
      doc.text(str, page.settings.margin.left, pageHeight - 10)
    },
    margin: { top: 65 },
    didParseCell
  })

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp)
  }

  doc.save(fileName)
}

/**
 * custom sort function
 * @param {any} rowData - array or object with properties
 * @param {array} sortBy - array defining the sort order
 * @returns {array}
 */
export const customSort = ({ rowData, sortBy }) => {
  const sortByColumnKeys = sortBy.reduce((obj, item, index) => ({
    ...obj,
    [item]: index
  }), {})
  // sorting properties by given sortBy array, if a proerty is not defined from the sortBy array we assign -1 to its value so that it continues to sort
  return rowData.sort((a, b) => ((sortByColumnKeys[a] || -1) - (sortByColumnKeys[b] || -1)))
}

/**
 * formats data from a data table to an exportable pdf
 * @param {string} [fileName=''] - file name for saving the file to the client's file system
 * @param {string} [title='Report'] - title for the rendered report
 * @param {array} [columns=[]] - column data with formatting options
 * @param {array} [data=[]] - data array for the table body
 * @param {function} [didParseCell=(cell, data) => {}] - Called when the plugin finished parsing cell content. Can be used to override content or styles for a specific cell.
 */
export const exportDataTableHelper = ({
  fileName = '',
  title = 'Report',
  columns = [],
  data = [],
  didParseCell = () => {}
}) => {
  // set the filename to be exported
  const exportFileName = `${fileName}.pdf`

  // map the column names of the data table to headers array for the pdf function
  const filteredColumns = columns
    .filter((column) => !!column.exportKey)

  const headers = filteredColumns
    .map((column) => column.name)

  const keys = filteredColumns
    .map((column) => column.exportKey)

  const keysAndSelectors = filteredColumns
    .map((column) => {
      const {
        exportKey,
        selector,
        exportCell,
        pdfCell
      } = column
      return {
        exportKey,
        selector,
        exportCell,
        pdfCell
      }
    })

  // here we are working mapping the existing data array given to the table to an output format friendly to the pdf generator
  // using the customSort method to order the properties of a row object as defined by the column headers (keys)
  const rows = [].concat(data).map((row) => customSort({
    rowData: Object.keys(row),
    sortBy: keys
  })
    .filter((key) => keys.includes(key))
    .map((key) => {
      const column = keysAndSelectors.find((col) => col.exportKey === key)
      // if column has pdfCell, it is specifically for PDF rendering
      if (column.pdfCell) {
        return column.pdfCell(row)
      }
      // if column has cell function
      if (column.cell) {
        const cell = column.cell(row)
        // if cell is a react component and just a string is nested as children
        if (cell.props?.children && typeof cell.props.children === 'string') {
          return cell.props.children
        }
        // if cell is a react component, and more react components are nested as children
        if (cell.props?.children.length) {
          return cell.props.children.filter((component) => component.props?.children).map((component) => component.props.children).join('\n')
        }
      }
      // if none of the above is true, and if column has selector function
      if (column.selector) {
        return column.selector(row)
      }
      // if none of the above is true just return the object property by default
      return row[key]
    }))

  // feed the pdf generator
  generatePdf({
    data: rows,
    headers,
    fileName: exportFileName,
    title,
    didParseCell
  })
}

/**
 * function for exporting transactions as pdf
 * @param {string} [titlePrefix=''] - prefix string for filename and title
 * @param {array} [data=[]] - data array for the table body
 */
export const exportTransactionsPdf = ({
  titlePrefix = '',
  data = []
}) => {
  // similar to the columns we setup for the data table
  const headers = transactionsHeaders

  // * FUTURE filter out nulls and sort on the server side
  let runningTotal = 0
  const rows = [].concat(data)
    .sort((a, b) => a.transactionDate - b.transactionDate)

  // loop through transactions to calculate running balance
  rows.forEach((row) => {
    const balanceType = row.transactionType?.balanceType
    // if the transaction type is debit, add the negative of the amount
    runningTotal += balanceType === balanceTypes.DEBIT ? -row.totalAmount : row.totalAmount
  })

  // push total row
  rows.push({
    transactionDate: null,
    transactionType: null,
    notes: 'Total: ',
    totalAmount: runningTotal
  })

  exportDataTableHelper({
    fileName: `${titlePrefix}-transactions-${dateForFileName}`,
    title: `${titlePrefix} - Transactions - ${dateForTitle}`,
    columns: headers,
    data: rows,
    didParseCell: customCellParserRenderLastRowBold
  })
}

/**
 * function for exporting transaction students as pdf
 * @param {string} [titlePrefix=''] - prefix string for filename and title
 * @param {array} [data=[]] - data array for the table body
 */
export const exportTransactionStudentsPdf = ({
  titlePrefix = '',
  data = []
}) => {
  // similar to the columns we setup for the data table component
  const headers = transactionStudentsHeaders

  // * FUTURE filter out nulls and sort on the server side
  let runningTotal = 0
  const rows = [].concat(data)
    .sort((a, b) => (a.transaction?.transactionDate || -1) - (b.transaction?.transactionDate || -1))

  // loop through transactions to calculate running balance
  rows.forEach((row) => {
    runningTotal += row.amount
  })

  // append total amount row
  rows.push({
    amount: runningTotal,
    transactionDate: null,
    transaction: null,
    studentName: null,
    category: null,
    schoolName: null,
    gender: null,
    ethnicity: null,
    grade: null
  })

  exportDataTableHelper({
    fileName: `InvestED-${titlePrefix}-students-impacted-ytd-${dateForFileName}`,
    title: `${titlePrefix} - Students Impacted - Year to Date - ${dateForTitle}`,
    columns: headers,
    data: rows
  })
}

/**
 * function for exporting student roster as pdf
 * @param {string} [titlePrefix=''] - prefix string for filename and title
 * @param {array} [data=[]] - data array for the table body
 */
export const exportStudentRosterPdf = ({
  titlePrefix = '',
  data = []
}) => {
  const headers = studentRosterHeaders

  // * FUTURE filter out nulls and sort on the server side
  const rows = [].concat(data)
    .sort((a, b) => (a.last - b.last))

  exportDataTableHelper({
    fileName: `InvestED-${titlePrefix}-student-roster-${dateForFileName}`,
    title: `${titlePrefix} - Student Roster - ${dateForTitle}`,
    columns: headers,
    data: rows
  })
}

// * FUTURE const exportStudentStoriesPdf
