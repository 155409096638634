import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import ClipboardText from './ClipboardText'

const ShareToBrandLinks = ({ donationUrl }) => (
  <div className="brand-links">
    <a
      href={`https://twitter.com/share?url=${donationUrl}`}
      target="blank"
      className="twitter-share-button"
      data-show-count="false"
      data-testid="twitter"
    >
      <FontAwesomeIcon className="icon inverted" icon={faTwitter} size='lg'/>
    </a>
    <div className="fb-share-button" data-href={donationUrl} data-layout="button_count" data-size="small">
      <a
        target="blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=${donationUrl}`}
        className="fb-xfbml-parse-ignore"
        data-testid="facebook"
      >
        <FontAwesomeIcon className="icon inverted" icon={faFacebookF} size='lg'/>
      </a>
    </div>
    <a
      href={`https://www.linkedin.com/shareArticle?mini=true&url=${donationUrl}`}
      target="blank"
      data-testid="linkedin"
      >
      <FontAwesomeIcon className="icon inverted" icon={faLinkedinIn} size='lg'/>
    </a>
    <ClipboardText text={donationUrl} showText={false} iconSize="lg" buttonClass="text-ed-dark-blue hover-ed-dark-blue p-1"/>
  </div>
)

ShareToBrandLinks.propTypes = {
  donationUrl: PropTypes.string.isRequired
}

export default ShareToBrandLinks
