import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'

/**
 * Renders clickable icon to easily copy text to the clipboard
 *
 * @param  {string} [text=""] - input text to be copied to clipboard
 * @param  {boolean} [showText=true] - render the text next to the icon or not
 * @param  {string} [iconSize="sm"] - FontAwesomeIcon size property
 * @param  {string} [buttonClass="text-dark"] - class name to change appearance of the button
 */
const ClipboardText = ({
  text = '',
  showText = true,
  iconSize = 'sm',
  buttonClass = 'text-dark'
}) => {
  const [copySuccess, setCopySuccess] = useState('')
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const copyToClipboard = async (textToCopy) => {
    console.log('copy click')
    // eslint-disable-next-line no-undef
    await navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('copied!')
        setCopySuccess('Copied')
        setShow(true)
      })
      .then(() => {
        setTimeout(() => {
          console.log('hide')
          setShow(false)
          setCopySuccess('')
        }, 2000)
      })
      .catch(() => setCopySuccess('Failed to copy!'))
  }

  return (
    <>
      {showText && <span className="pr-1">{text}</span>}
      <OverlayTrigger
        placement="right"
        show={show}
        overlay={<Tooltip id="tooltip">
          {copySuccess}
        </Tooltip>}
        trigger="click"
      >
        <Button
          variant="link"
          ref={target}
          className={`py-0 pl-0 pr-1 ${buttonClass}`}
          onClick={() => copyToClipboard(text)}
          role="button"
        >
          <FontAwesomeIcon
            icon={faCopy}
            className="cursor-pointer"
            size={iconSize}
          />
        </Button>
      </OverlayTrigger>
    </>
  )
}

ClipboardText.propTypes = {
  text: PropTypes.string,
  showText: PropTypes.bool,
  iconSize: PropTypes.string,
  buttonClass: PropTypes.string
}

export default ClipboardText
