import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt } from '@fortawesome/pro-light-svg-icons'
import { faUserGraduate } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { QUERY_STUDENT_STORIES_WITH_DETAILS } from '../../operations/studentStories'
import UserContext from '../../contexts/UserContext'
import { isUserAdmin, isUserDistrict } from '../../utils/lib'
import { useSearchParams } from '../../utils/hooks'
import StudentStoryDetails from '../../components/StudentStoryDetails'
import SchoolDropdown from '../../components/SchoolDropdown'
import LoadingSpinner from '../../components/LoadingSpinner'
import SchoolYearSelector from '../../components/SchoolYearSelector'

const StudentStories = () => {
  const {
    user: userContext,
    session,
    setCurrentSchoolAndDistrict
  } = useContext(UserContext)
  const { userSchools, userDistricts } = userContext
  const isAdmin = isUserAdmin(userContext.role.name)
  const isDistrict = isUserDistrict(userContext)
  const [schoolYear, setSchoolYear] = useState({
    id: null
  })
  const { currentSchool, currentDistrict } = session
  const schoolId = currentSchool?.id
  // const districtId = currentDistrict?.id
  // const isSchoolOrDistrictSelected = !!(currentSchool?.id || currentDistrict?.id)
  const [studentStories, setStudentStories] = useState([])
  const { getParamValue } = useSearchParams()
  const returnView = getParamValue('return')

  // * queries
  const {
    loading
  } = useQuery(QUERY_STUDENT_STORIES_WITH_DETAILS, {
    variables: { schoolId, schoolYearId: schoolYear.id },
    skip: !schoolId,
    onCompleted: ({ studentStories: queriedStories = [] }) => {
      setStudentStories(queriedStories)
    }
  })

  // * event listeners
  const handleEntityChange = (value, entity) => {
    const selectedSchool = entity === 'school'
      ? value
      : {}
    const selectedDistrict = entity === 'district'
      ? value
      : {}
    setCurrentSchoolAndDistrict(selectedSchool, selectedDistrict)
  }

  // * renders
  if (loading) return <LoadingSpinner message="Loading..." />

  return (
    <>
      {(!currentSchool && !currentDistrict) && (
        <div className="alert alert-warning">
          You must select a school or district to view student impact stories.
        </div>
      )}
      <div className="Home">
        <div className="lander">
          <SchoolYearSelector selectedSchoolYear={schoolYear} setSchoolYear={setSchoolYear} />
          <h3 className="page-title border-bottom pb-5">
            <label className="float-left pr-3">
              Student Impact Stories
            </label>
            <SchoolDropdown
              title={(
                (currentSchool?.name || currentDistrict?.name)
                || 'Select a school or district')
              }
              visible={((isAdmin || isDistrict) || userSchools.concat(userDistricts).length > 1)}
              appendClassName="float-left"
              handleItemClick={handleEntityChange}
              viewAllItemVisible={false}
            />
          </h3>
          {returnView === 'yer' && (
            <Link to='/year-end-report' className="text-secondary">
              &lt; back to year end report
            </Link>
          )}
        </div>
        <div className="impact-stories student-story-block m-3 pb-3">
          <div className="alert alert-info alert-student-story">
            <div className="float-right">
              <Button
                variant="light"
                size="sm"
                className="text-ed-dark-blue pt-0"
                href={'/impact-stories/new'}
              >
                Create Impact Story
              </Button>
            </div>
            <span className="fa-stack fa-2x story-stacked-icons mr-2">
              <FontAwesomeIcon
                icon={faCommentAlt}
                className="fa-stack-1x"
              />
              <FontAwesomeIcon
                icon={faUserGraduate}
                className="fa-stack-1x story-icon-size"
                size="xs"
              />
            </span>
            Student Impact Stories
          </div>
          {studentStories.length > 0 && studentStories.map((studentStory, index) => (
            <div className="px-3" key={studentStory.id}>
              <StudentStoryDetails studentStory={studentStory} />
              {index !== studentStories.length - 1 && (
                <hr />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default StudentStories
