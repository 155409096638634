/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
import React from 'react'

import { useQuery } from '@apollo/client'
import { QUERY_SCHOOL_YEARS } from '../operations/schoolYear'

const SchoolYearSelector = ({ selectedSchoolYear, setSchoolYear }) => {
  const {
    data: schoolYearsData
  } = useQuery(QUERY_SCHOOL_YEARS)

  const schoolYearsOptions = schoolYearsData?.schoolYears?.map(
    ({ id, name }) => <option key={name} value={id}>{name}</option>
  )

  const dropdown = (
    <div className='form-inline float-right'>
      <select
        className='form-control form-control-sm'
        onChange={(e) => {
          const newSelectedSchoolYear = schoolYearsData?.schoolYears?.find(
            (sy) => sy.id === parseInt(e.target.value, 10)
          )
          setSchoolYear(newSelectedSchoolYear)
        }}
        value={selectedSchoolYear.id}
      >
        {schoolYearsOptions}
      </select>
    </div>
  )

  return dropdown
}

export default SchoolYearSelector
