import React, {
  useRef,
  useState,
  useEffect
} from 'react'
import PropTypes from 'prop-types'

export const Context = React.createContext()

const ModalProvider = ({ children }) => {
  const modalRef = useRef()
  const [context, setContext] = useState()

  // re-render is triggered after initial render
  useEffect(() => {
    setContext(modalRef.current)
  }, [])

  return (
    <>
      <Context.Provider value={context}>
        {children}
      </Context.Provider>
      <div ref={modalRef} />
    </>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ModalProvider
