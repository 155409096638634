import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import {
  Typeahead, Highlighter, Menu, MenuItem
} from 'react-bootstrap-typeahead'
import { useQuery } from '@apollo/client'
import {
  QUERY_SCHOOLS_AND_DISTRICTS_LITE
} from '../operations/schools'

/**
 * A react-bootstrap DropdownButton for Schools and/or Districts
 * @param {string} [title="Select a school or district"] - Title of top level button, use it within handleItemClick function to set to selected school or district
 * @param {function} handleItemClick - function for handling when an item is clicked.
 * @param {boolean} [visible=true] - when set to false, this component will not render
 * @param {string} [appendClassName=""] - className to append to existing className
 * @param {string} [viewAllItemLabel="All Staff"] - label text for view all DropdownItem
 * @param {string} [viewAllItemVisible=true] - show or hide the view all, first item in the list
 * @param {string} [schoolsOnly=false] - show only schools
 * @returns {SchoolDropdown}
 */
const SchoolDropdown = ({
  title = 'Select a school or district',
  handleItemClick = (entity, entityType) => {
    console.log(`Pass your own handler function that accepts two params:\n\t1. entity: ${entity} (school or district) and \n\t2. entityType ${entityType} "school" or "district"`)
  },
  visible = true,
  appendClassName = '',
  viewAllItemLabel = 'All Staff',
  viewAllItemVisible = true,
  schoolsOnly = false
}) => {
  const [entitiesState, setEntitiesState] = useState({
    schools: [],
    districts: []
  })
  const {
    loading: entitiesLoading,
    error: entitiesError
  } = useQuery(QUERY_SCHOOLS_AND_DISTRICTS_LITE, {
    onCompleted: (data) => {
      const { schools, districts } = data
      setEntitiesState({
        schools: schools.map((school) => ({ ...school, entityType: 'school' })),
        districts: schoolsOnly
          ? []
          : districts.map((district) => ({ ...district, entityType: 'district' }))
      })
    }
  })

  const options = [
    ...viewAllItemVisible ? [{ id: 'all', name: viewAllItemLabel }] : [],
    ...entitiesState.schools.length > 0 ? [{ id: 'divider', name: 'Schools' }] : [],
    ...entitiesState.schools,
    ...entitiesState.districts.length > 0 ? [{ id: 'divider', name: 'Districts' }] : [],
    ...entitiesState.districts
  ]

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      const { value } = e.target
      if (value === viewAllItemLabel) {
        handleItemClick(null, 'all')
      } else {
        const selected = options.find((option) => option.name === value)
        const { entityType } = selected
        handleItemClick(selected, entityType)
      }
    }
  }

  const renderMenu = (results, menuProps, state) => (
    <Menu className='rbt-menu' {...menuProps}>
      {viewAllItemVisible && (
        <MenuItem
          onClick={() => handleItemClick(null, 'all')}
          option={{ name: viewAllItemLabel }}
        >
          {viewAllItemLabel}
        </MenuItem>
      )}
      {entitiesLoading && (
        <MenuItem option='schoolsLoading'>Schools loading...</MenuItem>
      )}
      {entitiesError && (
        <MenuItem option='schoolsError'>Schools error: {entitiesError.message}</MenuItem>
      )}
      {results.map((result, index) => {
        const { entityType, id, name } = result
        if (id === 'all') return null
        if (id === 'divider') {
          return (
            <React.Fragment key={index}>
              <Dropdown.Divider />
              <Dropdown.Header>{name}</Dropdown.Header>
            </React.Fragment>
          )
        }
        return (
          <MenuItem
            key={index}
            onClick={() => handleItemClick(result, entityType)}
            option={result}
            position={index}
            data-testid={`${entityType}-item`}
          >
            <Highlighter search={state.text}>
              {name}
            </Highlighter>
          </MenuItem>
        )
      })}
    </Menu>
  )

  return (
    visible && (
      <Typeahead
        className={`text-dark table-filter-dropdown w-25 ${appendClassName}`}
        id='school-dropdown'
        labelKey='name'
        onKeyDown={handleKeyDown}
        options={options}
        placeholder={title}
        renderMenu={renderMenu}
        maxResults={1500}
      />
    )
  )
}

SchoolDropdown.propTypes = {
  title: PropTypes.string,
  handleItemClick: PropTypes.func,
  visible: PropTypes.bool,
  schoolsOnly: PropTypes.bool,
  appendClassName: PropTypes.string,
  viewAllItemLabel: PropTypes.string,
  viewAllItemVisible: PropTypes.bool
}

export default SchoolDropdown
