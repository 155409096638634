import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUserHeadset } from '@fortawesome/pro-light-svg-icons'

// TODO make NavDropdown more better
/**
 * Header component for authenticated users
 *
 * @param  {string} [userName="Valued InvestED User"] - user's name
 * @param  {string} schoolName - current school name
 * @param  {object} children - DOM or React elements
 */
const PrivateHeader = ({
  userName = 'Valued InvestED User',
  schoolName = '',
  children = <div></div>
}) => {
  const renderLogo = () => (
    <Link to="/">
      <img src="/logo.png" alt="InvestED. Immediate help for students in need." />
    </Link>
  )
  return (
    <div className="d-flex flex-nowrap justify-content-between align-items-center py-2 mb-3 border-bottom sticky-top bg-light">
      <div className="col-4">
        {renderLogo()}
      </div>
      <div className="col-4 text-center">
        {schoolName && (
          <h5 className="my-0 py-0 organization-title">
            {schoolName}
          </h5>
        )}
        <div className="user-title">
          <NavDropdown
            title={`Welcome, ${userName}`}
            id="user-dropdown"
            alignRight
          >
            <NavDropdown.Item
              eventKey="1.1"
              href="/profile"
            >
              Account Profile
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="1.2"
              href="/profile/school"
            >
              School Profile
            </NavDropdown.Item>
            <NavDropdown.Divider className="mx-2" />
            <NavDropdown.Item
              eventKey="1.3"
              className="d-flex align-items-center justify-content-between"
              href="/contact"
            >
              <label className="mb-0">Contact Us</label>
              <FontAwesomeIcon icon={faUserHeadset} />
            </NavDropdown.Item>
            <NavDropdown.Divider className="mx-2" />
            <NavDropdown.Item
              eventKey="1.4"
              className="d-flex align-items-center justify-content-between"
              href="/logout"
            >
              <label className="mb-0">Logout</label>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end align-items-center">
        {children}
      </div>
    </div>
  )
}
PrivateHeader.propTypes = {
  userName: PropTypes.string,
  schoolName: PropTypes.string,
  children: PropTypes.node
}

export default PrivateHeader
